import { defineComponent } from "vue";
export default defineComponent({
    name: "AxionInputSwitch",
    props: {
        value: {
            required: true,
            type: null,
            validator: (value) => typeof value === "boolean" || value === null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, { emit }) {
        function toggle() {
            if (!props.disabled) {
                emit("change", !props.value);
            }
        }
        return {
            toggle
        };
    }
});
