import { defineComponent } from "vue";
import { AxionButton } from "@axisvue/buttons";
import NotificationBadge from "@/shared/components/notifications/NotificationBadge.vue";
export default defineComponent({
    components: {
        AxionButton,
        NotificationBadge
    },
    props: {
        isBinMode: {
            type: Boolean,
            default: false,
            required: true
        },
        itemsNumber: {
            type: Number,
            required: true
        },
        scansStorageTenant: {
            type: Object,
            required: true,
            default: () => {
                return {};
            }
        }
    },
    setup(_, { emit }) {
        const clickHandler = () => emit("click");
        return {
            clickHandler
        };
    }
});
