<template>
  <span class="py-3 text-center text-sm text-control-high md:py-2">
    {{ itemsStart }} to {{ itemsEnd }} of
    {{ numberOfItems }}
  </span>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    numberOfItems: {
      type: Number,
      required: true
    }
  },
  computed: {
    itemsStart() {
      if (this.numberOfItems === 0) {
        return 0;
      }
      return (this.currentPage - 1) * this.perPage + 1;
    },
    itemsEnd() {
      const n = this.perPage * this.currentPage;
      if (n > this.numberOfItems) return this.numberOfItems;

      return n;
    }
  }
};
</script>
