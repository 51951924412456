/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "robot-industrial": {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    data: '<path pid="0" fill="currentColor" d="M18.41 4L16 6.41v.18L18.41 9H22v2h-4.41L16 9.41V12h-1a2 2 0 01-2-2V7.5H9.86c-.09.37-.24.72-.44 1.05L15.18 19H20a2 2 0 012 2v1H2v-1a2 2 0 012-2h6.61l-4.69-8.5a4.006 4.006 0 01-3.81-3A4 4 0 015 2.63c2.13-.55 4.31.73 4.86 2.87H13V3a2 2 0 012-2h1v2.59L17.59 2H22v2h-3.59M6 4.5a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2z"/>'
  }
});
