import { defineComponent } from "vue";
import { AxionInputBase } from "@axisvue/forms";
export default defineComponent({
    components: {
        AxionInputBase
    },
    props: {
        inputAttributes: {
            type: Object,
            default: undefined
        },
        inputPlaceholder: {
            type: String,
            default: undefined
        },
        inputValue: {
            type: String,
            default: ""
        },
        inputType: {
            type: String,
            required: true
        }
    },
    setup(_, context) {
        const inputHandler = (event) => {
            context.emit("inputValueChanged", event);
        };
        return { inputHandler };
    }
});
