/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 19h4V5h-4M6 19h4V5H6v14z"/>'
  }
})
