import Repository from "@/api/repository";
const baseUrl = "/devices/v1";
const baseUrlV2 = "/devices/v2";
export default {
    getOptionsV2(deviceId) {
        return Repository.get(`${baseUrlV2}/optionsForDevice?deviceId=${deviceId}`);
    },
    getConfigurationsForDeviceV2(deviceId) {
        return Repository.get(`${baseUrlV2}/configurationsForDevice?deviceId=${deviceId}`);
    },
    getConfigurationsV2(firmwareNumber) {
        return Repository.get(`${baseUrlV2}/configurations`, {
            params: { firmwareNumber }
        });
    },
    updateConfigurationsV2(deviceId, configurations) {
        return Repository.post(`${baseUrlV2}/configurationsForDevice?deviceId=${deviceId}`, configurations);
    },
    getConfigurationsForSerialNumberV2(serialNumber) {
        return Repository.get(`${baseUrlV2}/configurationsForSerial?serialNumber=${serialNumber}`);
    },
    getAllDevicesWithinTenant() {
        return Repository.get(`${baseUrl}/getAllDevicesWithinTenant`);
    },
    getDeviceTimezoneOffset(deviceId) {
        return Repository.get(`${baseUrl}/getDeviceTimezoneOffset`, {
            params: { deviceId }
        });
    },
    getEditDevice(deviceId) {
        return Repository.get(`${baseUrl}/edit`, { params: { id: deviceId } });
    },
    getDeviceInfo(firmwareNumber) {
        return Repository.get(`${baseUrl}/info`, {
            params: { firmwareNumber }
        });
    },
    editDevice(device) {
        return Repository.post(`${baseUrl}/edit`, device);
    },
    /**
     * Gets the current backlash value for a device.
     * @param deviceId The unique identifier of the device.
     * @returns The current backlash value represented by a number.
     */
    getDeviceBacklash(deviceId) {
        return Repository.get(`${baseUrl}/backlash?deviceId=${deviceId}`);
    },
    /**
     * Updates the backlash value for a device.
     * @param deviceId The unique identifier of the device.
     * @param backlash The updated backlash value for the device.
     * @returns
     */
    updateDeviceBacklash(deviceId, backlash) {
        return Repository.post(`${baseUrl}/backlash?deviceId=${deviceId}&backlash=${backlash}`);
    },
    resetDevice(deviceId) {
        return Repository.post(`${baseUrl}/resetDevice`, "=" + deviceId, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        });
    },
    followDevice(serialNumber) {
        return Repository.post(`${baseUrl}/follow?serialNumber=${serialNumber}`, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        });
    },
    unfollowDevice(serialNumber) {
        return Repository.post(`${baseUrl}/unfollow?serialNumber=${serialNumber}`, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        });
    },
    getAvailableDevices() {
        return Repository.get(`${baseUrl}/availableDeviceTypesForTenant`);
    },
    getOptions(deviceId) {
        return Repository.get(`${baseUrl}/optionsForDevice?deviceId=${deviceId}`);
    },
    getDeviceType(serialNumber) {
        return Repository.get(`${baseUrl}/GetDeviceType?serialNumber=${serialNumber}`);
    },
    getConfigurations(deviceId) {
        return Repository.get(`${baseUrl}/configurationsForDevice?deviceId=${deviceId}`);
    },
    updateConfigurations(deviceId, configurations) {
        return Repository.post(`${baseUrl}/configurationsForDevice?deviceId=${deviceId}`, configurations);
    },
    getAttachedDevices(deviceId) {
        return Repository.get(`${baseUrl}/attachedDevices?attachmentId=${deviceId}`);
    },
    updateDeviceConnection(deviceId, attachedDeviceId, value) {
        return Repository.post(`${baseUrl}/setDeviceToAttachmentStatus?attachmentId=${deviceId}&deviceId=${attachedDeviceId}&isConnected=${value}`);
    },
    findDevice(serialNumber) {
        return Repository.get(`${baseUrl}/findDevices?firmwareOrSerial=${serialNumber}`);
    },
    connectDevice(deviceId, newDeviceId) {
        return Repository.post(`${baseUrl}/linkDeviceToAttachment?attachmentId=${deviceId}&deviceId=${newDeviceId}`);
    }
};
