/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud-lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 17c.5 0 1 .5 1 1v4c0 .5-.5 1-1 1h-5c-.5 0-1-.5-1-1v-4c0-.5.5-1 1-1v-1.5c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5V17m-1 0v-1.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5V17h3m-1.64-7.97C21.95 9.22 24 11.36 24 14l-.06.77A4.497 4.497 0 0019.5 11C17 11 15 13 15 15.5v.26c-.61.55-1 1.35-1 2.24v1H6c-3.31 0-6-2.69-6-6 0-3.1 2.34-5.64 5.35-5.96A7.496 7.496 0 0112 3c3.64 0 6.67 2.59 7.36 6.03z"/>'
  }
})
