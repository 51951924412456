/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'application': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 4a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h14m0 14V8H5v10h14z"/>'
  }
})
