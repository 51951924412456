/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buffer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.6 2.86c2.67 1.24 5.4 2.53 8.06 3.77.15.07.34.12.34.32s-.19.24-.34.31C18 8.5 15.3 9.77 12.62 11a1.3 1.3 0 01-1.24 0C8.69 9.76 6 8.5 3.32 7.25 3.18 7.19 3 7.14 3 6.94c0-.18.18-.23.31-.29C6 5.39 8.74 4.1 11.44 2.85c.29-.13.86-.12 1.16.01M12 21.15c-.2 0-.34-.08-.62-.18-2.69-1.24-5.38-2.5-8.05-3.75-.14-.07-.33-.11-.33-.32 0-.2.19-.24.34-.31.44-.21.89-.42 1.33-.63.45-.2.89-.2 1.33.01 1.79.83 3.57 1.66 5.35 2.49.44.21.88.2 1.32 0 1.78-.84 3.56-1.67 5.33-2.5.44-.2.87-.21 1.29-.01.48.21.95.44 1.42.66.07.03.14.07.2.12.13.1.13.27 0 .35-.08.06-.17.11-.26.15-2.65 1.27-5.32 2.49-7.99 3.72-.2.1-.47.2-.66.2m0-4.98c-.1 0-.45-.1-.64-.17-2.68-1.26-5.36-2.5-8.02-3.76-.14-.06-.34-.11-.34-.31 0-.21.2-.25.35-.32.45-.22.9-.43 1.35-.64.43-.19.86-.19 1.3.03 1.78.82 3.58 1.66 5.38 2.5.41.19.83.19 1.25 0 1.8-.85 3.6-1.69 5.41-2.53.41-.19.83-.19 1.25 0 .47.22.95.44 1.42.66.06.03.13.06.19.11.14.11.14.26-.01.38-.05.04-.12.07-.18.1-2.71 1.28-5.4 2.53-8.1 3.78-.19.09-.53.17-.61.17z"/>'
  }
})
