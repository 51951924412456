/**
 * Transforms the minutes of the timezone offset to hours and minutes -> example: 120 => UTC 02:00
 * @param {number} offsetInMinutes - the timezone offset in minutes.
 */
export function formatOffsetInHoursAndMinutes(offsetInMinutes) {
    const hours = Math.abs(offsetInMinutes / 60);
    const realHours = Math.floor(hours);
    const minutes = Math.round((hours - realHours) * 60);
    const sign = offsetInMinutes >= 0 ? "+" : "-";
    return `(UTC ${sign}${realHours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")})`;
}
