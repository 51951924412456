import Repository from "@/api/repository";
const resource = `manage/v1/distributors`;
export default {
    getDistributors() {
        return Repository.get(`${resource}`);
    },
    getManagers() {
        return Repository.get(`${resource}/managers`);
    },
    editDistributor(model) {
        return Repository.post(`${resource}/edit`, model);
    },
    getDistributorDevices(id) {
        return Repository.get(`${resource}/${id}/devices`);
    },
    removeDistributor(id) {
        return Repository.delete(`${resource}/${id}/remove`);
    },
    removeDeviceFromDistributor(distributorId, deviceId) {
        return Repository.post(`${resource}/${distributorId}/device/${deviceId}/remove`);
    },
    assignManagerToDistributor(distributorId, managerId) {
        return Repository.post(`${resource}/${distributorId}/device/${managerId}/assign`);
    },
    addDistributor(model) {
        return Repository.post(`${resource}/add`, model);
    },
    addManager(email) {
        return Repository.post(`${resource}/addManager?email=${email}`);
    }
};
