import { makeInjectionKey, transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { withProjectsFilters } from "@/shared/providers/withProjects/useProjectsFilters/withProjectsFilters";
const ProjectRepository = RepositoryFactory.projects;
/**
 * Provider that handles global state for projects.
 */
export function withProjects(availableDevices) {
    const projects = ref([]);
    const numberOfProjects = ref(0);
    const fetching = ref(false);
    const { search, resourceFilterOptions, selectedResourceFilter, selectedSorting, devicesFilterOptions, selectedDevicesFilter, setSearch, setSelectedResourceFilter, setSelectedDevicesFilter } = withProjectsFilters(availableDevices);
    async function fetchProjects({ page, pageSize, bin, cancelToken }) {
        fetching.value = true;
        try {
            const { data } = await ProjectRepository.getProjects({
                page,
                pageSize,
                deviceType: selectedDevicesFilter.value == 0 ? null : selectedDevicesFilter.value,
                search: search.value,
                sortBy: selectedSorting.value,
                resourceFilter: selectedResourceFilter.value,
                bin,
                cancelToken
            });
            const mappedData = transformObjectKeysPascalToCamelCase(data);
            if (mappedData.projects) {
                projects.value = mappedData.projects;
            }
            if (mappedData.numberOfProjects) {
                numberOfProjects.value = mappedData.numberOfProjects;
            }
        }
        catch (error) {
            throw new Error(error);
        }
        finally {
            fetching.value = false;
        }
    }
    /**
     * Used for editing the local projects list state.
     * Currently, only project name and description can be edited withing the app.
     *
     * @param project - object containing project information
     */
    function editItemFromProjectsList(project) {
        const projectIndex = projects.value.findIndex(item => item.id === project.id);
        if (projectIndex > -1 && project.name !== undefined) {
            projects.value[projectIndex].name = project.name;
        }
        if (projectIndex > -1 && project.description !== undefined) {
            projects.value[projectIndex].description = project.description;
        }
    }
    /**
     * Used to delete the project from the local projects list state.
     *
     * @param id - project id for deletion
     */
    function deleteItemFromProjectsList(id) {
        projects.value = projects.value.filter(item => item.id !== id);
    }
    /**
     * Used to follow the project from the local projects list state.
     *
     * @param id - project id
     */
    function followItemFromProjectsList(id) {
        const projectIndex = projects.value.findIndex(item => item.id === id);
        if (projectIndex > -1) {
            projects.value[projectIndex].isFollowing = true;
        }
    }
    /**
     * Used to unfollow the project from the local projects list state.
     *
     * @param id - project id
     */
    const unfollowItemFromProjectsList = async (id) => {
        try {
            await ProjectRepository.postUnfollowProject(id);
            const projectIndex = projects.value.findIndex(item => item.id === id);
            if (projectIndex > -1) {
                projects.value[projectIndex].isFollowing = false;
            }
        }
        catch (error) {
            throw new Error(error);
        }
    };
    const createProject = async (project) => {
        try {
            await ProjectRepository.postCreateProject(project);
        }
        catch (error) {
            throw new Error(error);
        }
    };
    async function moveProjectToBin(id) {
        fetching.value = true;
        try {
            await ProjectRepository.moveProjectToBin(id);
            // save changes locally
            projects.value = projects.value.filter(item => item.id !== id);
        }
        catch (error) {
            throw new Error(error);
        }
        finally {
            fetching.value = false;
        }
    }
    return {
        projects,
        numberOfProjects,
        fetching,
        search,
        resourceFilterOptions,
        selectedResourceFilter,
        selectedSorting,
        devicesFilterOptions,
        selectedDevicesFilter,
        fetchProjects,
        editItemFromProjectsList,
        deleteItemFromProjectsList,
        followItemFromProjectsList,
        unfollowItemFromProjectsList,
        moveProjectToBin,
        setSearch,
        setSelectedResourceFilter,
        setSelectedDevicesFilter,
        createProject
    };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing.
 * Use `provideProjects` and `useProjects` instead.
 */
export const ProjectsInjectionKey = makeInjectionKey();
/**
 * Provides `Projects` to all descendants of a component.
 *
 * Use in conjunction with `useProjects`.
 */
export function provideProjects(projects) {
    provide(ProjectsInjectionKey, projects);
}
/**
 * Injects `Projects` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideProjects`, otherwise an error will
 * be thrown
 */
export function useProjects() {
    const projects = inject(ProjectsInjectionKey);
    if (projects === undefined)
        throw new Error("Projects was not provided.");
    return projects;
}
