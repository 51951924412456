/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "device-lux": {
    width: 16,
    height: 16,
    viewBox: "0 0 338 294",
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M338 218v-45.7a46.3 46.3 0 00-46.3-46.3H33V36.6a9.6 9.6 0 019.6-9.6h174.9a13.78 13.78 0 0013.38-10.92A13.4 13.4 0 00217.5 0H15A15 15 0 000 15v236.5c0 .3.2.5.5.5H18v42h273.7a46.3 46.3 0 0046.3-46.3V218z" fill="#000"/>'
  }
});
