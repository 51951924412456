/**
 *
 * @param {[number, number]} size - Width and Height of the canvas
 * @param {[number, number, number, number]} rgba - Array of rgba values of the canvas color (between 0-255)
 */
export default function ([width, height], rgba) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    if (ctx) {
        ctx.beginPath();
        ctx.fillStyle = `rgba(${rgba.join(",")})`;
        ctx.fillRect(0, 0, width, height);
    }
    return canvas;
}
