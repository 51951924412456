import { defineComponent } from "vue";
import DialogBase from "./DialogBase.vue";
import DialogContent from "./DialogContent.vue";
import DialogButtons from "./DialogButtons.vue";
export default defineComponent({
    components: {
        DialogBase,
        DialogContent,
        DialogButtons
    },
    props: {
        dialogProps: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        inputValue: {
            type: String,
            required: true
        }
    },
    setup(_, context) {
        const input = (value) => {
            context.emit("input", value);
        };
        const cancel = () => {
            context.emit("cancel");
        };
        const submit = () => {
            context.emit("submit");
        };
        return { input, cancel, submit };
    }
});
