import { DialogType } from "../types/DialogType";
import { defineComponent, computed } from "vue";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: { AxionButton },
    props: {
        cancelButtonText: {
            type: String,
            default: "cancel"
        },
        cancelButtonAriaLabel: {
            type: String,
            default: undefined
        },
        cancelButtonType: {
            type: String,
            default: "secondary"
        },
        okButtonText: {
            type: String,
            default: "ok"
        },
        okButtonAriaLabel: {
            type: String,
            default: undefined
        },
        okButtonType: {
            type: String,
            default: "primary"
        },
        type: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const cancel = () => context.emit("cancel");
        const submit = () => context.emit("submit");
        const IsAlertDialog = computed(() => props.type === DialogType.Alert);
        return { cancel, submit, IsAlertDialog };
    }
});
