/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contrast': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.38 20.9a2 2 0 01-1.28-1.27L19.63 3.1a2 2 0 011.27 1.28L4.38 20.9M20 16v2h-7v-2h7M3 6h3V3h2v3h3v2H8v3H6V8H3V6z"/>'
  }
})
