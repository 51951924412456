import { makeInjectionKey } from "@axisvue/utils";
import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { LatLng } from "leaflet";
import { inject, provide, reactive } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { findGroupByAreasIds } from "@/pages/wellPlate/_providers/withGroups/helpers/findGroupByAreasIds";
import Group from "../../Mapview/shapes/group";
import ShapeEnum from "../types/enums/ShapeEnum";
import { VirtualWellPlateMode } from "../types/enums/VirtualWellPlateMode";
import { _addShapeToData } from "./WellPlateShapesOperations/AddShape";
import { _clearShapesFromData, _deleteShapes } from "./WellPlateShapesOperations/ClearAllShapes";
import { _getShapeById } from "./WellPlateShapesOperations/GetShapeById";
import { _getAllPolygonIdsFromData, _getAllPolygonsFromData } from "./WellPlateShapesOperations/PolygonOperations";
import { _getAllROIsFromData, _getAllROIsIdsFromData, _getAllROIsInWell } from "./WellPlateShapesOperations/ROIOperations";
import { _getAllWellsFromData, _getAllWellsIdsFromData, _getPatternScanWells, _getWellByROI, _getWellsByROIs } from "./WellPlateShapesOperations/WellOperations";
const wellPlateRepository = RepositoryFactory.omni3v1.wellPlates;
/**
 * This Provider is responsible for handling all the operations on the virtual wellplate
 */
export function withVirtualWellplate() {
    const data = reactive({
        shapes: {
            byId: {},
            ids: []
        },
        selectedShape: {},
        groupSelected: new Group([]),
        mouseLocation: new LatLng(0, 0),
        mouseStart: new LatLng(0, 0),
        viewROIs: false,
        mode: VirtualWellPlateMode.RESULTS,
        defaultWellPlates: [],
        patternScanWells: {}
    });
    return {
        data,
        getData: () => data,
        /**
         * Resets the shapes object to an empty object
         */
        clearAllShapes: () => {
            data.shapes = { ..._clearShapesFromData(data).shapes };
        },
        /**
         * Adds a shape to the shapes object
         *  @param command $command - The command to be executed
         */
        addShape: (command) => {
            data.shapes = { ..._addShapeToData(data, command).shapes };
        },
        /** Returns the shape with the following id */
        getShapeById: (id) => _getShapeById(data, id),
        /** Returns an array of all ROI Ids */
        getAllROIsIds: () => _getAllROIsIdsFromData(data),
        /** Returns an array of all ROIs */
        getAllROIs: () => _getAllROIsFromData(data),
        /** Returns an array of all Wells Ids */
        getAllWellsIds: () => _getAllWellsIdsFromData(data),
        /** Returns an array of all Wells */
        getAllWells: () => _getAllWellsFromData(data),
        /** Returns an array of all Polygon Ids */
        getAllPolygonsIds: () => _getAllPolygonIdsFromData(data),
        /** Returns an array of all Polygons */
        getAllPolygons: () => _getAllPolygonsFromData(data),
        /** Get All ROIs inside a Well */
        getAllROIsInWell: (wellId) => _getAllROIsInWell(data, wellId),
        /** Get Well by ROI */
        getWellByROI: (roiId) => _getWellByROI(data, roiId),
        getWellsByROIs: (roiIds) => _getWellsByROIs(data, roiIds),
        getPatternScanWells: (areas) => _getPatternScanWells(data, areas),
        setPatternScanWells: (areas) => {
            data.patternScanWells = _getPatternScanWells(data, areas);
        },
        /**
         * TODO improve comment
         * In case of well, rois ids will be returned, in case of single area, only single id will be returned.
         * The idea behind is to not have a function that returns 2 different types string | string[].
         * Since api for groups, etc. accept array of ids, it make sense to unify the return type to always be array.
         */
        getShapeIdsByType: (shapeId) => {
            const shape = data.shapes.byId[shapeId];
            if ((shape === null || shape === void 0 ? void 0 : shape.type) === ShapeEnum.WELL) {
                const roisInWell = _getAllROIsInWell(data, shapeId);
                return roisInWell.map(roi => roi.id);
            }
            else {
                return [shapeId];
            }
        },
        /** Checks if any of the groups contains a shape and return the group */
        groupContainsShape: (groups, shapeId) => {
            const shape = data.shapes.byId[shapeId];
            if ((shape === null || shape === void 0 ? void 0 : shape.type) === ShapeEnum.WELL) {
                const roisInWell = _getAllROIsInWell(data, shapeId).map(roi => roi.id);
                return findGroupByAreasIds(groups, roisInWell);
            }
            return findGroupByAreasIds(groups, [shapeId]);
        },
        deleteShapes: (areaNames) => {
            data.shapes = { ..._deleteShapes(data, areaNames).shapes };
        },
        fetchDefaultWellPlates: async () => {
            try {
                const response = await wellPlateRepository.getDefaultWellPlates();
                const mappedData = transformObjectKeysPascalToCamelCase(response.data);
                data.defaultWellPlates = mappedData;
                return mappedData;
            }
            catch (error) {
                console.warn("Error while fetching default well plates: ", error);
            }
        }
    };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing. Use {@link providewithVirtualWellplate} and `usewithVirtualWellplate` instead.
 */
export const VirtualWellplateInjectionKey = makeInjectionKey();
/**
 * Provides {@link withVirtualWellplate} to all descendants of a component.
 *
 * Use in conjunction with {@link useVirtualWellplate}.
 */
export function provideVirtualWellplate(VirtualWellplate) {
    provide(VirtualWellplateInjectionKey, VirtualWellplate);
}
/**
 * Injects {@link withVirtualWellplate} into a component.
 *
 * Make sure a parent component provides the right state
 * by using {@link providewithVirtualWellplate}, otherwise an error will
 * be thrown
 */
export function useVirtualWellplate() {
    const virtualWellplate = inject(VirtualWellplateInjectionKey);
    if (virtualWellplate === undefined)
        throw new Error("withVirtualWellplate was not provided.");
    return virtualWellplate;
}
