/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 4v4h4V4h-4m6 0v4h4V4h-4m0 6v4h4v-4h-4m0 6v4h4v-4h-4m-2 4v-4h-4v4h4m-6 0v-4H4v4h4m0-6v-4H4v4h4m0-6V4H4v4h4m2 6h4v-4h-4v4M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4c-1.08 0-2-.9-2-2V4a2 2 0 012-2z"/>'
  }
})
