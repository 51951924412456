/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 4H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2m-3 13H7v-2h10m0-2H7v-2h10m3-2h-3V6h3"/>'
  }
})
