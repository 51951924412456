/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thermometer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 13V5a3 3 0 00-6 0v8a5 5 0 106 0m-3-9a1 1 0 011 1v3h-2V5a1 1 0 011-1z"/>'
  }
})
