/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-collapse-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.08 4.08L20 12l-7.92 7.92-1.41-1.42 5.5-5.5H2v-2h14.17l-5.5-5.5 1.41-1.42M20 12v10h2V2h-2v10z"/>'
  }
})
