import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
const experimentsRepository = RepositoryFactory.omni3v1.experiments;
// fetches scan states - specifically, every event (scan) has a state whether waiting, processing, etc.
export function withScansEstimate() {
    async function fetchScansEstimateOnExperimentId(experimentId) {
        try {
            const { data } = await experimentsRepository.getScanStates(experimentId);
            data.States = data.States.splice(data.States.length - data.NumberOfActiveScans);
            return transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            console.warn("[Calendar] Error while fetching scans estimates.", error);
        }
    }
    return {
        fetchScansEstimateOnExperimentId
    };
}
export const { provide: provideScansEstimate, use: useScansEstimate } = setupProvider();
