import { defineComponent } from "vue";
import WaveWrapper from "./WaveWrapper.vue";
// @ts-ignore
import _checkboxMixin from "./_checkboxMixin";
export default defineComponent({
    components: {
        WaveWrapper
    },
    mixins: [_checkboxMixin],
    props: {
        // model comes from checkbox mixin
        // eslint-disable-next-line vue/require-default-prop
        model: [String, Boolean, Object, Number, Array],
        id: {
            type: String,
            required: true
        },
        checkboxClasses: {
            type: String,
            default: () => ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
});
