import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { makeInjectionKey } from "@axisvue/utils";
import Vue, { computed, inject, provide, reactive, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { ScanState } from "@/pages/wellPlate/_providers/withScans/types/IScanStates";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withScans(experimentId) {
    const scanData = reactive({
        scans: [
            {
                UnixTime: 0,
                HasSlicesData: false
            }
        ]
    });
    const scanTimepoints = ref([]);
    const currentScan = reactive({
        UnixTime: 0,
        HasSlicesData: false
    });
    const scanStates = ref(null);
    // For old experiments we won't receive correct scanStates(it will be empty array),
    // which is why we use scanData to check in that case.
    const completedScanStateExists = computed(() => {
        var _a, _b;
        const states = (_b = (_a = scanStates === null || scanStates === void 0 ? void 0 : scanStates.value) === null || _a === void 0 ? void 0 : _a.states) !== null && _b !== void 0 ? _b : [];
        if (states.length) {
            return states.some(scan => (scan === null || scan === void 0 ? void 0 : scan.scanState) === ScanState.COMPLETED);
        }
        else {
            return scanData.scans.length > 0;
        }
    });
    const completedScanTimepoints = computed(() => {
        var _a;
        const indices = (_a = scanStates.value) === null || _a === void 0 ? void 0 : _a.states.filter(state => state.scanState === ScanState.COMPLETED).map(state => state.id);
        if (!indices) {
            return [];
        }
        const completedScans = indices.map(index => scanTimepoints.value[Number(index)]);
        return completedScans;
    });
    function setCurrentScan(unixTime) {
        Vue.set(currentScan, "UnixTime", unixTime);
    }
    function setScanNames(scans) {
        scans.forEach(scan => {
            scanTimepoints.value.push(scan.UnixTime.toString());
        });
    }
    async function fetchScans(omni3ExperimentId = experimentId) {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getScans(omni3ExperimentId);
            scanData.scans = data.Scans;
            scanTimepoints.value.length = 0;
            setScanNames(data.Scans);
            if (scanData.scans.length) {
                currentScan.UnixTime = scanData.scans[0].UnixTime;
            }
        }
        catch (error) {
            console.warn("Error while fetching experiment scans: ", error);
        }
    }
    async function fetchScanStates() {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getScanStates(experimentId);
            scanStates.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            console.error("Error while fetching experiment scan states: ", error);
        }
    }
    return {
        scanData,
        currentScan,
        scanTimepoints,
        completedScanTimepoints,
        scanStates,
        completedScanStateExists,
        setCurrentScan,
        fetchScans,
        fetchScanStates
    };
}
export const ScansInjectionKey = makeInjectionKey();
/**
 * Provides `Scans` to all descendants of a component.
 *
 * Use in conjunction with `useScans`.
 */
export function provideScans(Scans) {
    provide(ScansInjectionKey, Scans);
}
/**
 * Injects `Scans` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideScans`, otherwise an error will
 * be thrown
 */
export function useScans() {
    const Scans = inject(ScansInjectionKey);
    if (Scans === undefined)
        throw new Error("Scans was not provided.");
    return Scans;
}
