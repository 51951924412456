import Repository from "@/api/repository";
const resource = "/alerts/confluency-alert";
export default {
    getAlerts(experimentId) {
        return Repository.get(`${resource}?experimentId=${experimentId}`);
    },
    createAlert(experimentId, model) {
        return Repository.post(`${resource}?experimentId=${experimentId}`, model);
    },
    removeAlert(experimentId) {
        return Repository.delete(`${resource}?experimentId=${experimentId}`);
    }
};
