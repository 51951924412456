import Vue, { defineComponent, onBeforeMount, provide } from "vue";
import router from "@/router";
import { initializeDialogsPlugin, DialogInjectionKey } from "@axisvue/dialogs";
import { provideBreadCrumb } from "@/shared/components/navigation/BreadCrumbs/withBreadCrumbs";
import { provideToastr } from "./globals/plugins/toastr/useToastr";
import { provideLayoutType, withLayoutType } from "@/shared/providers/withLayoutType/withLayoutType";
import { provideProjects, withProjects } from "@/shared/providers/withProjects/withProjects";
import { provideAvailableDevices, withAvailableDevices } from "@/shared/providers/withAvailableDevices/withAvailableDevices";
import { provideProjectActions, withProjectActions } from "@/shared/providers/withProjects/useProjectActions/useProjectActions";
import { provideBinMode, withBinMode } from "@/shared/providers/withBinMode/withBinMode";
import { provideLicense, withLicense } from "@/shared/providers/withLicense/withLicense";
import { provideScanPlans, withScanPlans } from "@/shared/providers/withScanPlans/withScanPlans";
import { PROJECTS_BIN_ROUTE_NAME } from "@/pages/projects/_providers/constants";
import { provideLoggedInUser, withLoggedInUser } from "@/shared/providers/withLoggedInUser/withLoggedInUser";
import { provideUsersWithinTenant, withUsersWithinTenant } from "@/shared/providers/withUsersWithinTenant/withUsersWithinTenant";
import { provideRegisteredDevices, withRegisteredDevices } from "@/shared/providers/withRegisteredDevices/withRegisteredDevices";
import { provideActiveExperiments, withActiveExperiments } from "@/shared/providers/withActiveExperiments/withActiveExperiments";
import { provideReservations, withReservations } from "@/shared/providers/withReservations/withReservations";
export default defineComponent({
    setup() {
        const availableDevices = withAvailableDevices();
        const registeredDevices = withRegisteredDevices();
        const layoutType = withLayoutType();
        const projects = withProjects(availableDevices.availableDevices);
        const projectActions = withProjectActions();
        const dialogPlugin = initializeDialogsPlugin();
        const binMode = withBinMode();
        const loggedInUser = withLoggedInUser();
        const usersWithinTenant = withUsersWithinTenant();
        const license = withLicense();
        const scanPlans = withScanPlans();
        const activeExperiments = withActiveExperiments();
        const reservations = withReservations();
        provideLayoutType(layoutType);
        provideProjects(projects);
        provideProjectActions(projectActions);
        provideAvailableDevices(availableDevices);
        provide(DialogInjectionKey, dialogPlugin);
        provideToastr(Vue.prototype.$toastr);
        provideBreadCrumb();
        provideBinMode(binMode);
        provideLoggedInUser(loggedInUser);
        provideUsersWithinTenant(usersWithinTenant);
        provideRegisteredDevices(registeredDevices);
        provideLicense(license);
        provideScanPlans(scanPlans);
        provideActiveExperiments(activeExperiments);
        provideReservations(reservations);
        const initDevices = async () => {
            if (router.currentRoute.name !== PROJECTS_BIN_ROUTE_NAME) {
                await Promise.all([
                    availableDevices.fetchAvailableDevices(),
                    registeredDevices.fetchDevices()
                ]);
            }
        };
        onBeforeMount(() => {
            initDevices();
        });
    }
});
