import { removeFromArray } from "@axisvue/utils";
export default class Group {
    constructor(shapes) {
        this.type = "group";
        this.id = "G" + ++Group.counter;
        this.shapes = [];
        this.leadingShape = Object();
        this.shapes = shapes;
    }
    moveShape(diff) {
        for (let i = 0; i < this.shapes.length; i++) {
            //affects the copy/paste func
            if (!this.shapes[i].processed) {
                this.shapes[i].moveShape(diff);
            }
        }
    }
    addShape(shape) {
        if (!shape.processed && !shape.offBounds) {
            this.leadingShape = shape;
        }
        // leading shape can only be unprocessed and with correct size
        if (!shape.offBounds) {
            this.shapes.push(shape);
        }
    }
    removeShape(shape) {
        this.shapes = removeFromArray(this.shapes, shape);
    }
    getCenter() {
        return this.leadingShape.center;
    }
    delete() {
        Group.counter--;
    }
    //to apply interface
    setConfluenceThreshold() {
        // remove this method?
    }
    getConfluenceThreshold() {
        return 0;
    }
    setColor(color) {
        for (let i = 0; i < this.shapes.length; i++) {
            this.shapes[i].setColor(color);
        }
    }
    clone(shapes) {
        const arr = [];
        let leading = Object();
        for (let i = 0; i < this.shapes.length; i++) {
            const shape = this.shapes[i].clone(shapes);
            if (!shape.processed) {
                arr.push(this.shapes[i].clone(shapes));
                shapes.ids.unshift(shape.id);
                if (this.shapes[i].id == this.leadingShape.id) {
                    leading = arr[arr.length - 1];
                }
            }
        }
        const g = new Group(arr);
        g.leadingShape = leading;
        return g;
    }
}
Group.counter = 0;
