import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import luxonPlugin from "@fullcalendar/luxon";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DateTime } from "luxon";
import { CalendarView } from "../enums/CalendarView";
import { dateStringFormatter, formatDateToTime } from "./dateTimeUtils";
export const calendarLocales = {
    enUS: {
        code: "en-US"
    },
    enGB: {
        code: "en-GB"
    }
};
export const defaultCalendarConfig = {
    plugins: [dayGridPlugin, timeGridPlugin, luxonPlugin, interactionPlugin],
    headerToolbar: {
        left: "prev,next now",
        center: "title",
        right: `${CalendarView.Monthly},${CalendarView.Weekly},${CalendarView.Daily}`
    },
    initialView: `${CalendarView.Weekly}`,
    views: {
        day: {
            titleFormat: {
                month: "long",
                year: "numeric",
                day: "numeric"
            }
        },
        week: {
            titleFormat: { month: "long", day: "2-digit", year: "numeric" }
        },
        month: {
            titleFormat: { month: "long", year: "numeric" }
        }
    },
    eventMinHeight: 0,
    eventOrder: "start",
    eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit"
    },
    displayEventEnd: false,
    slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false
    },
    locales: [calendarLocales.enGB, calendarLocales.enUS],
    firstDay: DateTime.now().weekday,
    slotMinTime: "00:00:00",
    lazyFetching: true,
    eventDurationEditable: false,
    selectable: false,
    selectAllow: () => false,
    forceEventDuration: false,
    progressiveEventRendering: false,
    eventOverlap: () => false,
    // On Vue 2.7.x for only build not serving, events will be clickable
    // and redirect app to the empty url. Below code prevents this behavior
    eventClick: info => {
        info.jsEvent.preventDefault();
    },
    height: "100%",
    navLinks: true,
    weekends: true,
    allDaySlot: false,
    dayMaxEvents: 3,
    weekNumbers: false,
    fixedWeekCount: true,
    showNonCurrentDates: true,
    nowIndicator: true,
    themeSystem: "standard",
    moreLinkClick: "week",
    selectOverlap: false,
    slotEventOverlap: false,
    timeZone: "local",
    buttonText: {
        day: "Day",
        week: "Week",
        month: "Month",
        today: "Today"
    },
    scrollTimeReset: false,
    editable: false,
    eventStartEditable: false,
    scrollTime: formatDateToTime(),
    slotDuration: "00:05:00",
    snapDuration: "00:05:00",
    validRange: {
        start: dateStringFormatter(DateTime.now().minus({ month: 1 })),
        end: dateStringFormatter(DateTime.now().plus({ month: 5, day: 1 }))
    },
    displayEventTime: true
};
