import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide } from "vue";
const ToastrInjectionKey = makeInjectionKey();
/**
 * Provides `Toastr` to all descendants of a component.
 *
 * Use in conjunction with `useToastr`.
 */
export function provideToastr(Toastr) {
    provide(ToastrInjectionKey, Toastr);
}
/**
 * Injects `Toastr` into a component.
 */
export function useToastr() {
    const Toastr = inject(ToastrInjectionKey);
    if (Toastr === undefined) {
        throw new Error("Toastr was not provided.");
    }
    return Toastr;
}
