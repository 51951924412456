import { ref } from "vue";
import { setupProvider } from "@/globals/setupProvider";
import { useReservations } from "@/shared/providers/withReservations/withReservations";
export function withModifyReservation() {
    const selectedReservation = ref(null);
    const { editReservation, cancelReservation } = useReservations();
    function selectReservation(reservation) {
        selectedReservation.value = reservation;
    }
    function deselectReservation() {
        selectedReservation.value = null;
    }
    // api call to edit reservation
    async function asyncEditReservation() {
        if (selectedReservation.value) {
            await editReservation(selectedReservation.value);
        }
    }
    // api call to cancel reservation
    async function asyncCancelReservation() {
        if (selectedReservation.value) {
            await cancelReservation(selectedReservation.value.reservationId);
        }
    }
    return {
        asyncCancelReservation,
        asyncEditReservation,
        selectReservation,
        deselectReservation,
        selectedReservation
    };
}
export const { provide: provideModifyReservation, use: useModifyReservation } = setupProvider();
