import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
export function withBreadCrumbs() {
    const initialBreadcrumbs = window.GLOBAL_BREADCRUMBS || [];
    const breadCrumbs = ref(initialBreadcrumbs);
    function truncateBreadCrumb(newCrumb) {
        const maxCharLength = 22;
        if (newCrumb.name.length < maxCharLength) {
            return;
        }
        newCrumb.name = newCrumb.name.substring(0, maxCharLength).concat("...");
    }
    function setBreadCrumbs(newCrumbs) {
        newCrumbs.forEach((newCrumb) => {
            truncateBreadCrumb(newCrumb);
        });
        breadCrumbs.value = newCrumbs;
    }
    function addBreadCrumb(newCrumb) {
        truncateBreadCrumb(newCrumb);
        breadCrumbs.value.push(newCrumb);
    }
    return { breadCrumbs, setBreadCrumbs, addBreadCrumb };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing. Use `provideBreadCrumb` and `useBreadCrumb` instead.
 */
export const BreadCrumbInjectionKey = makeInjectionKey();
/**
 * Provides `BreadCrumb` to all descendants of a component.
 *
 * Use in conjunction with `useBreadCrumb`.
 */
export function provideBreadCrumb() {
    provide(BreadCrumbInjectionKey, withBreadCrumbs());
}
/**
 * Injects `BreadCrumb` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideBreadCrumb`, otherwise an error will
 * be thrown
 */
export function useBreadCrumbs() {
    const breadCrumb = inject(BreadCrumbInjectionKey);
    if (breadCrumb === undefined)
        throw new Error("BreadCrumb was not provided.");
    return breadCrumb;
}
