import { makeInjectionKey } from "@axisvue/utils";
import { computed, inject, provide, ref } from "vue";
import { findGroupByAreasIds } from "@/pages/wellPlate/_providers/withGroups/helpers/findGroupByAreasIds";
import { useSelectActions } from "@/pages/wellPlate/_providers/withSelect/useSelectActions/useSelectActions";
export function withSelect(groups, areas, areasWithConfluence, areasWithObjectCount, areasWithOrganoid, areasWithClonogenic, areasWithPSC, isFullStitch, getWellsByROIs) {
    const selectedAreasIds = ref([]);
    const selectedGroupsIds = ref([]);
    const modifierKeyActive = ref(false); // on WINDOWS ctrl, on MAC command key
    const selectedItems = computed(() => {
        if (!isFullStitch.value) {
            const wells = getWellsByROIs(selectedAreasIds.value);
            return wells.map(well => well.id);
        }
        return selectedAreasIds.value;
    });
    const selectedGroups = computed(() => groups.value.filter(group => selectedGroupsIds.value.includes(group.Id.toString())));
    const selectedAreas = computed(() => {
        const selectedAreas = [];
        selectedAreasIds.value.forEach(id => {
            var _a;
            if ((_a = areas.value) === null || _a === void 0 ? void 0 : _a[id]) {
                selectedAreas.push(areas.value[id]);
            }
        });
        return selectedAreas;
    });
    const selectedAreasIdsWithoutGroup = computed(() => {
        return selectedAreasIds.value.filter(id => !findGroupByAreasIds(groups.value, [id]));
    });
    const selectedAreasWithoutSelectedGroup = computed(() => {
        return selectedAreas.value.filter(area => !findGroupByAreasIds(selectedGroups.value, [area.name]));
    });
    const selectedAreasWithConfluence = computed(() => areasWithConfluence.value.filter(area => selectedAreasIds.value.includes(area.name)));
    const selectedAreasWithObjectCount = computed(() => areasWithObjectCount.value.filter(area => selectedAreasIds.value.includes(area.name)));
    const selectedAreasWithClonogenic = computed(() => areasWithClonogenic.value.filter(area => selectedAreasIds.value.includes(area.name)));
    const selectedAreasWithPSC = computed(() => areasWithPSC.value.filter(area => selectedAreasIds.value.includes(area.name)));
    const selectedAreasWithOrganoid = computed(() => areasWithOrganoid.value.filter(area => selectedAreasIds.value.includes(area.name)));
    const { selectItems, deselectAll, initModifierEvents, removeModifierEvents } = useSelectActions(groups, selectedAreasIds, selectedGroupsIds, modifierKeyActive);
    return {
        selectedAreasIds,
        selectedGroupsIds,
        modifierKeyActive,
        selectedAreas,
        selectedGroups,
        selectedAreasIdsWithoutGroup,
        selectedAreasWithoutSelectedGroup,
        selectedAreasWithConfluence,
        selectedAreasWithObjectCount,
        selectedAreasWithClonogenic,
        selectedAreasWithOrganoid,
        selectedAreasWithPSC,
        selectedItems,
        deselectAll,
        initModifierEvents,
        removeModifierEvents,
        selectItems
    };
}
export const SelectInjectionKey = makeInjectionKey();
/**
 * Provides `Select` to all descendants of a component.
 *
 * Use in conjunction with `useSelect`.
 */
export function provideSelect(Select) {
    provide(SelectInjectionKey, Select);
}
/**
 * Injects `Select` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideSelect`, otherwise an error will
 * be thrown
 */
export function useSelect() {
    const Select = inject(SelectInjectionKey);
    if (Select === undefined)
        throw new Error("Select was not provided.");
    return Select;
}
