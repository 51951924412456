/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'margin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 4v5l-1.5-1.5-13 13-2-2 13-13L15 4h5m-3 16a3 3 0 01-3-3v-2a3 3 0 013-3 3 3 0 013 3v2a3 3 0 01-3 3m0-6a1 1 0 00-1 1v2a1 1 0 001 1 1 1 0 001-1v-2a1 1 0 00-1-1M7 12a3 3 0 01-3-3V7a3 3 0 013-3 3 3 0 013 3v2a3 3 0 01-3 3m0-6a1 1 0 00-1 1v2a1 1 0 001 1 1 1 0 001-1V7a1 1 0 00-1-1z"/>'
  }
})
