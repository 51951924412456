import Repository from "@/api/repository";
import { controllerRoutePrefix } from "../controllerRoutePrefix";
const resource = `${controllerRoutePrefix}/results`;
export default {
    getScratchResults(experimentId) {
        return Repository.get(`${resource}/${experimentId}/scratch`);
    },
    getAvailableAlgorithmsToReprocess(experimentId) {
        return Repository.get(`${resource}/${experimentId}/reprocessing/state`);
    },
    getConfluenceResults(experimentId) {
        return Repository.get(`${resource}/${experimentId}/confluenceresults`);
    },
    getObjectCountResults(experimentId) {
        return Repository.get(`${resource}/${experimentId}/ObjectCountResults`);
    }
};
