export var EventType;
(function (EventType) {
    EventType[EventType["Sample"] = 0] = "Sample";
    EventType[EventType["BusySlot"] = 1] = "BusySlot";
    EventType[EventType["Experiment"] = 2] = "Experiment";
    EventType[EventType["Reservation"] = 3] = "Reservation";
})(EventType || (EventType = {}));
export var EventState;
(function (EventState) {
    EventState["Completed"] = "Completed";
    EventState["Failed"] = "Failed";
    EventState["Waiting"] = "Waiting";
    EventState["Processing"] = "Processing";
    EventState["Skipped"] = "Skipped";
    EventState["No_Data"] = "No data";
})(EventState || (EventState = {}));
export var CalendarColourPallete;
(function (CalendarColourPallete) {
    CalendarColourPallete["Active"] = "rgba(0,131,148,0.3)";
    CalendarColourPallete["Failed"] = "#feb2b2";
    CalendarColourPallete["Waiting"] = "#008394";
    CalendarColourPallete["Any"] = "#DDDDDD";
    CalendarColourPallete["Reservation"] = "rgba(250,145,30,0.3)";
    CalendarColourPallete["BorderActive"] = "#008394";
    CalendarColourPallete["BorderReservation"] = "#FA911E";
})(CalendarColourPallete || (CalendarColourPallete = {}));
