import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Vue from "vue";
const darkenConst = 50;
const waveOptions = {
    color: "currentColor",
    initialOpacity: 0.5,
    finalOpacity: 0.5,
    duration: 0.1
};
export default defineComponent({
    props: {
        model: { type: [String, Boolean, Object, Number, Array], default: true },
        show: { type: Boolean, default: true }
    },
    setup(props) {
        const element = ref();
        const elementChild = computed(() => { var _a; return (_a = element.value) === null || _a === void 0 ? void 0 : _a.firstChild; });
        const childHasAbsolutePosition = computed(() => {
            if (!elementChild.value)
                return false;
            return getComputedStyle(elementChild.value).position === "absolute";
        });
        const root = ref();
        const waveOnOptions = ref({ ...waveOptions });
        const waveOffOptions = ref({ ...waveOptions });
        const waveStyles = computed(() => {
            if (!elementChild.value || !root.value)
                return {};
            const top = elementChild.value.offsetHeight / 2 +
                (childHasAbsolutePosition.value ? root.value.offsetTop : 0);
            return {
                height: `${elementChild.value.offsetHeight * 2}px`,
                width: `${elementChild.value.offsetWidth * 2}px`,
                top: `-${top}px`,
                left: `-${elementChild.value.offsetWidth / 2}px`
            };
        });
        function extractRGBAsArray(rgb) {
            return rgb
                .replace(/^(rgb|rgba)\(/, "")
                .replace(/\)$/, "")
                .replace(/\s/g, "")
                .split(",")
                .map(el => Number(el));
        }
        function getDarkenedColor(color) {
            const initial = color - darkenConst;
            return initial < 0 ? 0 : initial;
        }
        function getDarkenedRGB(rgb) {
            const r = getDarkenedColor(rgb[0]);
            const g = getDarkenedColor(rgb[1]);
            const b = getDarkenedColor(rgb[2]);
            return `rgb(${r},${g},${b})`;
        }
        const waveHoverStyles = ref({
            "--wave-on-background-color-hover": "",
            "--wave-off-background-color-hover": ""
        });
        function setWaveOnHoverStyles() {
            if (!elementChild.value)
                return;
            const color = getComputedStyle(elementChild.value).backgroundColor;
            waveHoverStyles.value["--wave-on-background-color-hover"] = color;
            const rgb = extractRGBAsArray(color);
            waveOnOptions.value.color = getDarkenedRGB(rgb);
        }
        function setWaveOffHoverStyles() {
            if (!elementChild.value)
                return;
            const color = getComputedStyle(elementChild.value).borderColor;
            waveHoverStyles.value["--wave-off-background-color-hover"] = color;
            const rgb = extractRGBAsArray(color);
            waveOffOptions.value.color = getDarkenedRGB(rgb);
        }
        function updateWaveHoverStyles() {
            Vue.nextTick(() => {
                if (props.model)
                    setWaveOnHoverStyles();
                else
                    setWaveOffHoverStyles();
            });
        }
        watch(() => props.model, () => {
            updateWaveHoverStyles();
        });
        onMounted(() => {
            updateWaveHoverStyles();
        });
        return {
            element,
            root,
            waveStyles,
            waveHoverStyles,
            waveOnOptions,
            waveOffOptions
        };
    }
});
