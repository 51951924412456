/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eject': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5L5.33 15h13.34M5 17h14v2H5v-2z"/>'
  }
})
