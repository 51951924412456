import { defineComponent, ref } from "vue";
import LoadingSpinner from "../shared/LoadingSpinner.vue";
export default defineComponent({
    name: "AxionButton",
    components: {
        LoadingSpinner
    },
    props: {
        buttonType: {
            type: String,
            required: true,
            validator: value => ["primary", "secondary", "icon", "primary-xs", "secondary-xs"].includes(value)
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        spinnerCustomClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    setup() {
        //to prevent spam clicks
        const forceDisabledAfterMounting = ref(false);
        window.setTimeout(() => {
            forceDisabledAfterMounting.value = true;
        }, 400);
        return {
            forceDisabledAfterMounting
        };
    }
});
