import { EventState } from "../shared/ICloudCalendarEvent";
// whenever the experiment  does not scan states
export default function generateSampleStates(occurrence) {
    const tempStates = [];
    for (let i = 0; i < occurrence.numberOfEvents; i++) {
        tempStates.push({
            id: i,
            scanState: EventState.No_Data
        });
    }
    return {
        states: tempStates,
        numberOfActiveScans: occurrence.numberOfEvents,
        startedAt: occurrence.startOffset,
        startedBy: {
            name: occurrence.owner.name,
            email: occurrence.owner.email
        }
    };
}
