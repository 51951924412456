import { CalendarColourPallete, EventState, EventType } from "../shared/ICloudCalendarEvent";
import { CalendarEventDisplay } from "@axisvue/calendar";
import { DeviceType } from "../shared/enums";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
// check withCalendarEvents.vue
// occurrence is general inteface(name) refering to experiment, reservation displayed on calendar
export function generateOccurrenceAsCloudCalendarEvent(device, occurrence) {
    var _a, _b, _c;
    const end = new Date(occurrence.startOffset).getTime() +
        occurrence.numberOfEvents * occurrence.eventsDuration * tms;
    return {
        title: occurrence.name,
        start: new Date(occurrence.startOffset).toISOString(),
        end: new Date(end).toISOString(),
        backgroundColor: CalendarColourPallete.Active,
        borderColor: CalendarColourPallete.Active,
        display: device.type === DeviceType.Lux
            ? CalendarEventDisplay.Auto
            : CalendarEventDisplay.None,
        extendedProps: {
            overlapped: false,
            deviceId: device.id,
            linkIsShared: (_b = (_a = occurrence.projectDetails) === null || _a === void 0 ? void 0 : _a.isShared) !== null && _b !== void 0 ? _b : false,
            relationId: occurrence.id,
            owner: occurrence.owner,
            eventType: EventType.Experiment,
            eventState: EventState.No_Data,
            hiddenUrl: `Project/${(_c = occurrence.projectDetails) === null || _c === void 0 ? void 0 : _c.projectId}/Experiments/${occurrence.id}`
        }
    };
}
