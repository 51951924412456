/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-expand-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 22h2V2h-2v9H5.83l5.5-5.5-1.41-1.42L2 12l7.92 7.92 1.41-1.42-5.5-5.5H20v9z"/>'
  }
})
