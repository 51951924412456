import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
const ProjectRepository = RepositoryFactory.projects;
export function withProjectActions() {
    const fetching = ref(false);
    async function editProject(project) {
        fetching.value = true;
        try {
            await ProjectRepository.postEditProject(project);
        }
        catch (error) {
            throw new Error("Something went wrong trying to edit project.");
        }
        finally {
            fetching.value = false;
        }
    }
    async function deleteProject(id) {
        fetching.value = true;
        try {
            await ProjectRepository.deleteProject(id);
        }
        catch (error) {
            throw new Error("Something went wrong trying to delete project.");
        }
        finally {
            fetching.value = false;
        }
    }
    async function inviteUsersToProject(data) {
        fetching.value = true;
        try {
            await ProjectRepository.postInviteUser(data);
        }
        catch (error) {
            throw new Error("Something went wrong trying to invite the user");
        }
        finally {
            fetching.value = false;
        }
    }
    async function followProject(id) {
        fetching.value = true;
        try {
            await ProjectRepository.postFollowProject(id);
        }
        catch (error) {
            throw new Error("Something went wrong trying to follow project.");
        }
        finally {
            fetching.value = false;
        }
    }
    async function unfollowProject(id) {
        fetching.value = true;
        try {
            await ProjectRepository.postUnfollowProject(id);
        }
        catch (error) {
            throw new Error("Something went wrong trying to unfollow project.");
        }
        finally {
            fetching.value = false;
        }
    }
    async function leaveProject(id) {
        var _a, _b;
        fetching.value = true;
        try {
            await ProjectRepository.postLeaveProject(id);
        }
        catch (error) {
            throw (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : null;
        }
        finally {
            fetching.value = false;
        }
    }
    async function removeUserFromProject(id, email) {
        fetching.value = true;
        try {
            await ProjectRepository.removeUserFromProject(id, email);
        }
        catch (error) {
            throw new Error("Something went wrong trying to remove the user from project.");
        }
        finally {
            fetching.value = false;
        }
    }
    async function restoreProject(id) {
        fetching.value = true;
        try {
            await ProjectRepository.restoreProject(id);
        }
        catch (error) {
            throw new Error(error);
        }
        finally {
            fetching.value = false;
        }
    }
    return {
        fetching,
        editProject,
        deleteProject,
        inviteUsersToProject,
        followProject,
        unfollowProject,
        leaveProject,
        removeUserFromProject,
        restoreProject
    };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing.
 * Use `provideProjectActions` and `useProjectActions` instead.
 */
export const ProjectActionsInjectionKey = makeInjectionKey();
/**
 * Provides `ProjectActions` to all descendants of a component.
 *
 * Use in conjunction with `useProjectActions`.
 */
export function provideProjectActions(projectActions) {
    provide(ProjectActionsInjectionKey, projectActions);
}
/**
 * Injects `ProjectActions` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideProjectActions`, otherwise an error will
 * be thrown
 */
export function useProjectActions() {
    const projectActions = inject(ProjectActionsInjectionKey);
    if (projectActions === undefined)
        throw new Error("ProjectActions was not provided.");
    return projectActions;
}
