import { LatLng, LatLngBounds } from "leaflet";
import { getAreaBounds } from "../../Mapview/canvasOverlay/helpers";
import ShapeEnum from "../types/enums/ShapeEnum";
function getRoisFromWell(shape, shapes) {
    const northEast = new LatLng(shape.center.lng + shape.radius, shape.center.lat + shape.radius);
    const southWest = new LatLng(shape.center.lng - shape.radius, shape.center.lat - shape.radius);
    return shapes
        .filter(shape => shape.type === ShapeEnum.ROI &&
        new LatLngBounds(northEast, southWest).contains(shape.center))
        .map(shape => shape.id);
}
export function fitBounds(bounds, shapes) {
    const selectedAreasIds = [];
    const areaBounds = new LatLngBounds([bounds[0][0], bounds[0][1]], [bounds[1][0], bounds[1][1]]);
    shapes.forEach(shape => {
        if (shape.type === ShapeEnum.CIRCLE) {
            const wellBounds = getAreaBounds(shape);
            if (wellBounds === undefined) {
                throw new Error("Well bounds are undefined");
            }
            const inverseAreaBounds = new LatLngBounds([bounds[0][1], bounds[0][0]], [bounds[1][1], bounds[1][0]]);
            if (inverseAreaBounds.intersects(wellBounds) && shape.visible) {
                selectedAreasIds.push(shape.id);
            }
        }
        else if (shape.type === ShapeEnum.RECTANGLE) {
            const rectBounds = window.L.latLngBounds([shape.bounds[0][0], shape.bounds[0][1]], [shape.bounds[1][0], shape.bounds[1][1]]);
            if (areaBounds.intersects(rectBounds) && shape.visible) {
                selectedAreasIds.push(shape.id);
            }
        }
        else if (shape.type === ShapeEnum.POLYGON) {
            const polygonBounds = getAreaBounds(shape);
            if (polygonBounds === undefined) {
                throw new Error("Polygon bounds are undefined");
            }
            if (areaBounds.intersects(polygonBounds)) {
                selectedAreasIds.push(shape.id);
            }
        }
        else if (shape.type === ShapeEnum.WELL) {
            const wellBounds = getAreaBounds(shape);
            if (wellBounds === undefined) {
                throw new Error("Well bounds are undefined");
            }
            const inverseAreaBounds = new LatLngBounds([bounds[0][1], bounds[0][0]], [bounds[1][1], bounds[1][0]]);
            const roiIds = getRoisFromWell(shape, shapes);
            if (inverseAreaBounds.intersects(wellBounds) && shape.visible) {
                selectedAreasIds.push(...roiIds);
            }
        }
    });
    return selectedAreasIds;
}
