/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compare': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 3h-5v2h5v13l-5-6v9h5a2 2 0 002-2V5a2 2 0 00-2-2m-9 15H5l5-6m0-9H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h5v2h2V1h-2v2z"/>'
  }
})
