/* eslint-disable @typescript-eslint/no-var-requires */
const saveAs = require("file-saver");
const SvgSaver = require("svgsaver");
/* eslint-enable @typescript-eslint/no-var-requires */
export default function () {
    return {
        asSvg: (svgNode, name) => {
            const svgSaver = new SvgSaver();
            svgSaver.asSvg(svgNode, `${name}.svg`);
        },
        asPng: (svgNode, name) => {
            const isIE11 = !!window.MSInputMethodContext &&
                !!document.documentMode;
            if (isIE11) {
                saveAs(svgNode, `${name}.png`);
            }
            else {
                const svgSaver = new SvgSaver();
                svgSaver.asPng(svgNode, `${name}.png`);
            }
        }
    };
}
