export default function drawPolygons(ctx, data, color) {
    ctx.strokeStyle = color;
    data.forEach(d => {
        ctx.beginPath();
        d.forEach(([x, y]) => {
            ctx.lineTo(x, y);
        });
        ctx.closePath();
        ctx.stroke();
    });
}
