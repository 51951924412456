/**
 * Gets all properties of an object which have all their keys values defined.
 * @param object Object to get the properties for which all keys are defined.
 * @param excludedKeys
 * @returns An array with all properties of the object which have all their keys values defined.
 */
export default function getPropertiesForWhichAllKeysExist(object, excludedKeys) {
    const values = Object.values(object);
    const propertiesForWhichAllKeysExist = values.filter(value => {
        const keys = Object.keys(value).filter(key => !excludedKeys.includes(key));
        return keys.every(key => value[key] !== null && value[key] !== undefined);
    });
    return propertiesForWhichAllKeysExist;
}
