export var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["Omni"] = 3] = "Omni";
    DeviceType[DeviceType["Lux"] = 1] = "Lux";
})(DeviceType || (DeviceType = {}));
export var DeviceOptions;
(function (DeviceOptions) {
    DeviceOptions["Active"] = "Active devices";
    DeviceOptions["All"] = "All devices";
    DeviceOptions["Omni"] = "Omni family";
    DeviceOptions["Lux"] = "Lux family";
})(DeviceOptions || (DeviceOptions = {}));
