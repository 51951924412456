import { AlgorithmType } from "./enums/OverlayType";
export var ChannelTypeDisplayName;
(function (ChannelTypeDisplayName) {
    ChannelTypeDisplayName["BRIGHTFIELD"] = "Brightfield";
    ChannelTypeDisplayName["GREEN"] = "Green";
    ChannelTypeDisplayName["RED"] = "Red";
    ChannelTypeDisplayName["RED_CONFLUENCE"] = "RedConf";
    ChannelTypeDisplayName["GREEN_CONFLUENCE"] = "GreenConf";
    ChannelTypeDisplayName["RED_MEAN_AVERAGE_INTENSITY_CONTOURS"] = "RedMeanAverageIntensityContours";
    ChannelTypeDisplayName["GREEN_MEAN_AVERAGE_INTENSITY_CONTOURS"] = "GreenMeanAverageIntensityContours";
    ChannelTypeDisplayName["RED_AVERAGE_INTENSITY_CONTOURS"] = "RedAverageIntensityContours";
    ChannelTypeDisplayName["GREEN_AVERAGE_INTENSITY_CONTOURS"] = "GreenAverageIntensityContours";
    ChannelTypeDisplayName["RED_TOTAL_AREA_CONTOURS"] = "RedTotalAreaContours";
    ChannelTypeDisplayName["GREEN_TOTAL_AREA_CONTOURS"] = "GreenTotalAreaContours";
    ChannelTypeDisplayName["RED_AVERAGE_INTENSITY_IMAGE"] = "RedAverageIntensityImage";
    ChannelTypeDisplayName["GREEN_AVERAGE_INTENSITY_IMAGE"] = "GreenAverageIntensityImage";
})(ChannelTypeDisplayName || (ChannelTypeDisplayName = {}));
export function ChannelTypeDisplayNameToUser(value) {
    let name = "";
    let unit = "";
    switch (value) {
        case ChannelTypeDisplayName.BRIGHTFIELD:
            name = "Brightfield";
            break;
        case ChannelTypeDisplayName.RED:
        case ChannelTypeDisplayName.GREEN:
            name = "Object Count";
            break;
        case ChannelTypeDisplayName.RED_CONFLUENCE:
            name = "RedConf";
            break;
        case ChannelTypeDisplayName.GREEN_CONFLUENCE:
            name = "GreenConf";
            break;
        case ChannelTypeDisplayName.RED_MEAN_AVERAGE_INTENSITY_CONTOURS:
        case ChannelTypeDisplayName.GREEN_MEAN_AVERAGE_INTENSITY_CONTOURS:
            name = "Average Object Intensity";
            break;
        case ChannelTypeDisplayName.RED_AVERAGE_INTENSITY_CONTOURS:
        case ChannelTypeDisplayName.GREEN_AVERAGE_INTENSITY_CONTOURS:
            name = "Object Intensity";
            break;
        case ChannelTypeDisplayName.RED_TOTAL_AREA_CONTOURS:
        case ChannelTypeDisplayName.GREEN_TOTAL_AREA_CONTOURS:
            name = "Object area";
            unit = "μm²";
            break;
        case ChannelTypeDisplayName.RED_AVERAGE_INTENSITY_IMAGE:
        case ChannelTypeDisplayName.GREEN_AVERAGE_INTENSITY_IMAGE:
            name = "Intensity";
            break;
        default:
            name = "Unknown";
            break;
    }
    return { name, unit };
}
export var ChannelType;
(function (ChannelType) {
    ChannelType[ChannelType["BRIGHTFIELD"] = 0] = "BRIGHTFIELD";
    ChannelType[ChannelType["RED"] = 1] = "RED";
    ChannelType[ChannelType["GREEN"] = 2] = "GREEN";
    ChannelType[ChannelType["RED_CONFLUENCE"] = 3] = "RED_CONFLUENCE";
    ChannelType[ChannelType["GREEN_CONFLUENCE"] = 4] = "GREEN_CONFLUENCE";
    ChannelType[ChannelType["RED_INTENSITY"] = 5] = "RED_INTENSITY";
    ChannelType[ChannelType["GREEN_INTENSITY"] = 6] = "GREEN_INTENSITY";
})(ChannelType || (ChannelType = {}));
