import { ProjectActions } from "@/pages/projects/_providers/types/enums/ProjectEnums";
/**
 * Helper function which is responsible to get action name, description and icon name for experiment options
 */
export function useProjectOptionActions() {
    function getProjectOptionAction(action, isFollowing) {
        switch (action) {
            case ProjectActions.SHARE:
                return "share";
            case ProjectActions.EDIT:
                return "edit";
            case ProjectActions.BIN:
                return "moveToBin";
            case ProjectActions.DELETE:
                return "delete";
            case ProjectActions.RESTORE:
                return "restore";
            case ProjectActions.LEAVE:
                return "leave";
            case ProjectActions.NOTIFICATIONS:
                if (isFollowing) {
                    return "unfollow";
                }
                else {
                    return "follow";
                }
            default:
                return "";
        }
    }
    function getProjectOptionActionDescription(action, isFollowing, isProject) {
        switch (action) {
            case ProjectActions.SHARE:
                return "Share project";
            case ProjectActions.EDIT:
                return "Edit project";
            case ProjectActions.BIN:
                if (isProject) {
                    return "Move all to bin";
                }
                else {
                    return "Move to bin";
                }
            case ProjectActions.DELETE:
                return "Delete project";
            case ProjectActions.RESTORE:
                return "Restore project";
            case ProjectActions.LEAVE:
                return "Leave project";
            case ProjectActions.NOTIFICATIONS:
                if (isFollowing) {
                    return "Disable notifications";
                }
                else {
                    return "Enable notifications";
                }
            default:
                return "";
        }
    }
    function getProjectOptionActionIcon(action, isFollowing) {
        switch (action) {
            case ProjectActions.SHARE:
                return "share";
            case ProjectActions.EDIT:
                return "pencil";
            case ProjectActions.BIN:
            case ProjectActions.DELETE:
                return "delete-outline";
            case ProjectActions.RESTORE:
                return "history";
            case ProjectActions.LEAVE:
                return "eye-off-outline";
            case ProjectActions.NOTIFICATIONS:
                if (isFollowing) {
                    return "bell-off";
                }
                else {
                    return "bell";
                }
            default:
                return "";
        }
    }
    return {
        getProjectOptionAction,
        getProjectOptionActionIcon,
        getProjectOptionActionDescription
    };
}
