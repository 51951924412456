export const offset = {
    /** Vertical top and bot offset for the document. */
    v: 12,
    /** Horizontal left and right offset for the document. */
    h: 10
};
export const margins = {
    /** Vertical margin between elements */
    v: 5,
    /** Horizontal margin between elements */
    h: 10
};
export const axionUrl = "https://www.axionbio.com/";
export const imageSize = {
    height: 90,
    width: 90
};
export const histogramSize = {
    height: 80,
    width: 80
};
export const chartSize = {
    height: 80,
    width: 80
};
export const rawPosition = {
    x: offset.h,
    y: offset.v + margins.v * 2
};
export const processedPostioion = {
    x: offset.h + imageSize.width + margins.h,
    y: offset.v + margins.v * 2
};
export const countHistPostition = {
    x: offset.h,
    y: offset.v + 4 * margins.v + imageSize.height
};
export const countConcentrationPostition = {
    x: offset.h + 100,
    y: offset.v + 4 * margins.v + imageSize.height
};
export const countCellSizeFluoHistogramPosition = {
    x: offset.h + imageSize.width + margins.h,
    y: offset.v + 4 * margins.v + imageSize.height
};
export const averageCellSizeHistogramPosition = {
    x: 10,
    y: 100
};
export const averageCellSizeFluoHistogramPosition = {
    x: 110,
    y: 100
};
export const averageCellIntensityHistogramPosition = {
    x: 110,
    y: 200
};
const bottomRightPosition = {
    x: offset.h + imageSize.width + margins.h,
    y: offset.v + 22 * margins.v + imageSize.height
};
export const bottomLeftPosition = {
    x: offset.h,
    y: offset.v + 22 * margins.v + imageSize.height
};
export const intensityHistPostioion = {
    ...bottomRightPosition
};
export const concentrationHistPostioion = {
    ...bottomLeftPosition
};
export const countConcentrationBarChartPosition = (isFluo) => {
    if (isFluo)
        return concentrationHistPostioion;
    return countConcentrationPostition;
};
export const countCellIntensityHistogramPosition = {
    x: offset.h + imageSize.width + margins.h,
    y: offset.v + 22 * margins.v + imageSize.height
};
export const averageCountStatisticsTableStartY = (isFluo) => {
    if (isFluo)
        return 5;
    else
        return 200;
};
export const averageConcentrationHistogramPosition = (isFluo) => {
    if (isFluo)
        return { x: 10, y: 200 };
    return { x: 110, y: 100 };
};
