<template>
  <div class="flex items-center">
    <axion-button
      button-type="icon"
      :disabled="page === 1 || pages.length === 0"
      class="h-10 !rounded-r-none !border-control-base"
      @click="setPageNumber(1)"
    >
      <b-icon name="chevron-double-left" class="h-4" />
    </axion-button>

    <axion-button
      button-type="icon"
      :disabled="page === 1 || pages.length === 0"
      class="mr-2 h-10 !rounded-l-none !border-control-base"
      @click="page !== 1 ? setPageNumber(page - 1) : setPageNumber(page)"
    >
      Previous
    </axion-button>

    <span v-if="page > 3 && pages.length > 4" class="relative ml-2 block">
      ...
    </span>

    <a
      v-for="(pageNumber, i) in pages.slice(
        page - offsetLeft,
        page + offsetRight
      )"
      :key="i"
      :class="{
        'cursor-auto font-bold !text-control-high hover:no-underline':
          pageNumber === page
      }"
      class="mx-2 cursor-pointer text-sm text-signal-base hover:underline"
      @click="setPageNumber(pageNumber)"
    >
      {{ pageNumber }}
    </a>

    <span
      v-if="page < pages.length - 2 && pages.length > 4"
      class="relative mr-2 block"
    >
      ...
    </span>
    <axion-button
      button-type="icon"
      :disabled="page === pages.length || pages.length === 0"
      class="!ml-2 h-10 !rounded-r-none !border-control-base"
      @click="
        page < pages.length ? setPageNumber(page + 1) : setPageNumber(page)
      "
    >
      Next
    </axion-button>
    <axion-button
      button-type="icon"
      class="h-10 !rounded-l-none !border-control-base"
      :disabled="page === pages.length || pages.length === 0"
      @click="setPageNumber(pages.length)"
    >
      <b-icon name="chevron-double-right" class="h-4" />
    </axion-button>
  </div>
</template>
<script>
import { range } from "d3-array";
import { AxionButton } from "@axisvue/buttons";

export default {
  components: { AxionButton },
  props: {
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true }
  },
  data() {
    return { page: this.currentPage };
  },
  computed: {
    pages() {
      return range(1, this.totalPages + 1, 1);
    },
    offsetLeft() {
      if (this.page > 3 && this.page <= this.pages.length - 2) {
        return 2;
      } else if (this.page === 1) {
        return 1;
      } else if (this.page === 2) {
        return 2;
      } else if (this.page === 3 || this.page === this.pages.length - 1) {
        return 3;
      } else if (this.page === this.pages.length) {
        return 4;
      } else return 0;
    },
    offsetRight() {
      if (this.page >= 3 && this.page < this.pages.length - 2) {
        return 1;
      } else if (this.page === 2 || this.page === this.pages.length - 2) {
        return 2;
      } else if (this.page === 1) {
        return 3;
      } else if (this.page === this.pages.length - 1) {
        return 3;
      } else if (this.page === this.pages.length) {
        return 0;
      } else return 0;
    }
  },
  watch: {
    currentPage(value) {
      if (value !== this.page) this.page = value;
    }
  },
  beforeMount() {
    this.page = this.currentPage;
  },
  methods: {
    setPageNumber(currentPage) {
      this.page = currentPage;
      this.$emit("update:currentPage", currentPage);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.active {
  z-index: 1;
  color: #fff !important;
  background-color: #1961a4;
  border-color: #1961a4;
}

.active:hover {
  background-color: #1961a4 !important;
  border-color: #1961a4 !important;
}

.disabled {
  color: #212529;
}

.enabled {
  color: #1961a4;
}

button {
  background-color: #fff;
  border: 1px solid #dee2e6;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
}

button:focus {
  outline: 0;
}

span {
  color: #212529;
}

.enabled:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
</style>
