import { defineComponent, onMounted, ref } from "vue";
import { AxionInputSwitch } from "@axisvue/forms";
export default defineComponent({
    components: {
        AxionInputSwitch
    },
    setup(_, { emit }) {
        /* The Intl.DateTimeFormat method returns an object that enables language-sensitive date and time formatting */
        /* Access the timeZone property to get the name of the time zone. */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const isTimeFormat12 = ref(timezone ? timezone.match(/(America|Pacific)/g) !== null : false);
        function selectTimeFormat(value) {
            isTimeFormat12.value = value;
            emit("changeToTimeFormat12", isTimeFormat12.value);
        }
        onMounted(() => emit("changeToTimeFormat12", isTimeFormat12.value));
        return {
            isTimeFormat12,
            selectTimeFormat
        };
    }
});
