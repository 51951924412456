import { reactive } from "vue";
import { ChannelTypeDisplayName } from "./types/IChannelConfigurations";
import { setupProvider } from "@/globals/setupProvider";
export function withExperimentConfigurations() {
    const channels = reactive({
        [ChannelTypeDisplayName.BRIGHTFIELD]: false,
        [ChannelTypeDisplayName.RED]: false,
        [ChannelTypeDisplayName.GREEN]: false,
        [ChannelTypeDisplayName.RED_MEAN_AVERAGE_INTENSITY_CONTOURS]: false,
        [ChannelTypeDisplayName.GREEN_MEAN_AVERAGE_INTENSITY_CONTOURS]: false,
        [ChannelTypeDisplayName.RED_AVERAGE_INTENSITY_CONTOURS]: false,
        [ChannelTypeDisplayName.GREEN_AVERAGE_INTENSITY_CONTOURS]: false,
        [ChannelTypeDisplayName.RED_TOTAL_AREA_CONTOURS]: false,
        [ChannelTypeDisplayName.GREEN_TOTAL_AREA_CONTOURS]: false,
        [ChannelTypeDisplayName.RED_AVERAGE_INTENSITY_IMAGE]: false,
        [ChannelTypeDisplayName.GREEN_AVERAGE_INTENSITY_IMAGE]: false,
        [ChannelTypeDisplayName.RED_CONFLUENCE]: false,
        [ChannelTypeDisplayName.GREEN_CONFLUENCE]: false
    });
    const algorithms = reactive({
        Confluence: false,
        ObjectCount: false,
        Organoid: false,
        Scratch: false,
        Colony: false,
        PSC: false
    });
    const areaAlgorithms = reactive({
        Confluence: false,
        ObjectCount: false,
        Organoid: false,
        Scratch: false,
        Colony: false,
        PSC: false
    });
    function setChannels(initialChannels) {
        initialChannels.forEach(channel => {
            const key = Object.keys(channels)[channel];
            channels[key] = true;
        });
    }
    const setAlgorithms = (initialAlgorithms) => {
        initialAlgorithms.forEach(algorithm => {
            algorithms[algorithm] = true;
        });
    };
    const setAreaAlgorithms = (areas) => {
        const areaAlgos = [];
        Object.values(areas.value).forEach(area => {
            area.selectedAlgorithms.forEach(algo => {
                const algoName = algo.DisplayName;
                areaAlgos.includes(algoName) ? "" : areaAlgos.push(algoName);
            });
        });
        areaAlgos.forEach((algorithm) => {
            areaAlgorithms[algorithm] = true;
        });
    };
    return {
        channels,
        algorithms,
        areaAlgorithms,
        setChannels,
        setAlgorithms,
        setAreaAlgorithms
    };
}
export const { provide: provideExperimentConfigurations, use: useExperimentConfigurations } = setupProvider();
