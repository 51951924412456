/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'doctor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21l2.84-4.74M8 8h8v2a4 4 0 01-4 4 4 4 0 01-4-4V8m0-1l.41-4.1a1 1 0 011-.9h5.19c.51 0 .94.39.99.9L16 7H8m4-4h-1v1h-1v1h1v1h1V5h1V4h-1V3z"/>'
  }
})
