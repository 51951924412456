import { removeFromArray } from "@axisvue/utils";
export default class CommandPolygonCreate {
    constructor(polygon) {
        this.polygon = polygon;
    }
    do(shapes) {
        shapes.byId[this.polygon.id] = this.polygon;
        shapes.ids = removeFromArray(shapes.ids, this.polygon.id);
        //triggers an update - current scan is changed
        this.polygon.processed
            ? shapes.ids.push(this.polygon.id)
            : shapes.ids.unshift(this.polygon.id);
    }
    undo(shapes) {
        delete shapes.byId[this.polygon.id];
        shapes.ids = removeFromArray(shapes.ids, this.polygon.id);
    }
}
