import Repository from "@/api/repository";
const resource = `/scanplan`;
const userResource = `/userselectedplans`;
export default {
    createScanPlan(model) {
        return Repository.post(`${resource}/create`, model);
    },
    addScanPlan(model) {
        return Repository.post(`${userResource}/create`, model);
    },
    updateScanPlan(value, id) {
        return Repository.post(`${resource}/${id}/update`, { isAvailable: value });
    },
    getScanPlan(id) {
        return Repository.get(`${resource}/${id}`);
    },
    getScanPlans(tier, type) {
        return Repository.post(`${resource}/all`, { type, tier });
    },
    upgradePlan(model) {
        return Repository.post(`${userResource}/upgrade`, model);
    },
    getDeviceHistory(deviceId) {
        return Repository.get(`${userResource}/history?deviceId=${deviceId}`);
    },
    getTenantHistory(tenantId) {
        return Repository.get(`${userResource}/history?tenantId=${tenantId}`);
    },
    deleteDataPlan(dataPlanId) {
        return Repository.delete(`${userResource}/${dataPlanId}`);
    }
};
