import { CalendarEventDisplay } from "@axisvue/calendar";
import { CalendarColourPallete, EventState, EventType } from "../shared/ICloudCalendarEvent";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
// check withCalendarEvents.vue
// generated busy slot in between two samples(scans) - for omni device (not omni pro) -- busy slot means
// that another experiment cannot run in between.
export function generateBusySlotForOccurrence(deviceId, index, occurrence, startTime) {
    var _a, _b;
    const start = new Date(startTime.getTime() + occurrence.eventsDuration * tms);
    const end = new Date(start.getTime() + occurrence.eventsInterval * tms);
    return {
        id: occurrence.id + "-busySlot-" + index,
        start: start.toISOString(),
        end: end.toISOString(),
        backgroundColor: CalendarColourPallete.Active,
        borderColor: CalendarColourPallete.Active,
        display: CalendarEventDisplay.Background,
        extendedProps: {
            deviceId: deviceId,
            overlapped: false,
            relationId: occurrence.id,
            linkIsShared: (_b = (_a = occurrence.projectDetails) === null || _a === void 0 ? void 0 : _a.isShared) !== null && _b !== void 0 ? _b : false,
            owner: occurrence.owner,
            eventState: EventState.No_Data,
            eventType: EventType.BusySlot
        }
    };
}
