import Repository from "@/api/repository";
const resource = `/Projects/v1`;
export default {
    getProjects({ page, pageSize, deviceType, search, sortBy, resourceFilter, bin, cancelToken }) {
        return Repository.get(`${resource}/all`, {
            cancelToken: cancelToken,
            params: {
                page,
                pageSize,
                search,
                resourceFilter,
                sortBy,
                deviceType,
                bin
            }
        });
    },
    postEditProject(model) {
        return Repository.post(`${resource}/edit`, model);
    },
    postInviteUser(model) {
        return Repository.post(`${resource}/inviteUser`, model);
    },
    removeUserFromProject(projectId, email) {
        return Repository.post(`${resource}/removeUser?projectId=${projectId}&email=${email}`);
    },
    deleteProject(id) {
        return Repository.post(`${resource}/${id}/delete?permanentDelete=true`);
    },
    moveProjectToBin(id) {
        return Repository.post(`${resource}/${id}/delete`);
    },
    postFollowProject(id) {
        return Repository.post(`${resource}/${id}/follow`);
    },
    postUnfollowProject(id) {
        return Repository.post(`${resource}/${id}/unfollow`);
    },
    postLeaveProject(id) {
        return Repository.post(`${resource}/${id}/leave`);
    },
    postCreateProject(model) {
        return Repository.post(`${resource}/create`, model);
    },
    restoreProject(id) {
        return Repository.post(`${resource}/${id}/restore`);
    },
    getProjectDetails(id) {
        return Repository.get(`${resource}/details`, {
            params: {
                projectId: id
            }
        });
    },
    getAvailableDeviceTypes() {
        return Repository.get(`${resource}/getAvailableDeviceFamily`);
    },
    getLocalExcel(projectId) {
        return Repository.get(`${resource}/${projectId}/localExcel`, {
            responseType: "blob"
        });
    },
    sendExcelFileToEmail(projectId, email) {
        return Repository.get(`${resource}/${projectId}/excel`, {
            params: {
                projectId,
                email
            }
        });
    },
    getProjectsForMoving(projectId) {
        return Repository.get(`${resource}/getUserProjectsForMoving`, {
            params: {
                projectId
            }
        });
    }
};
