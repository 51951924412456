/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-thick': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 10v4h9l-3.5 3.5 2.42 2.42L19.84 12l-7.92-7.92L9.5 6.5 13 10H4z"/>'
  }
})
