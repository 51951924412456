import { CalendarEventDisplay, CalendarEventType } from "@axisvue/calendar";
import { CalendarEventColor, CalendarEventState } from "../_providers/types/ICloudCalendarEvent";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
/**
 * Generates a scan event that can be passed to and visualized by the fullcalendar.
 * @param {number} scNumber - a number used to create unique id for the scan event.
 * @param {IRunningExperiment} experiment - the experiment object - with all properties.
 * @param {IDevice} device - the device object - with all properties.
 * @param {Date} startTime - start time of the scan event (when does it start).
 * @param {number} scanEstimate - estimated scan time - based on slot duration setting for the calendar.
 * @returns a scan event.
 */
export function generateScanForExperiment(props) {
    const end = new Date(props.eventEstimateStartTime.getTime() + props.eventEstimateDuration * tms);
    const isScanCompleted = end.toISOString() < new Date().toISOString();
    const color = isScanCompleted
        ? CalendarEventColor.ActiveCompleted
        : props.eventState === CalendarEventState.FAILED
            ? CalendarEventColor.ActiveFailed
            : CalendarEventColor.ActiveWaiting;
    return {
        id: props.experimentId + "-scan-" + props.eventId,
        start: props.eventEstimateStartTime.toISOString(),
        end: end.toISOString(),
        display: CalendarEventDisplay.Auto,
        backgroundColor: color,
        borderColor: color,
        extendedProps: {
            deviceId: props.deviceId,
            experimentId: props.experimentId,
            experimentIsShared: props.projectIsShared,
            experimentOwner: props.experimentOwner,
            eventType: CalendarEventType.Scan,
            eventState: props.eventState,
            hiddenEventTitle: props.eventId.toString(),
            hiddenUrl: `Project/${props.projectId}/Experiments/${props.experimentId}`
        }
    };
}
