/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-box-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m0 2v14H5V5h14m-9 12l-4-4 1.41-1.42L10 14.17l6.59-6.59L18 9"/>'
  }
})
