/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause-circle-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 16V8h2v8h-2m-4 0V8h2v8H9m3-14a10 10 0 0110 10 10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2m0 2a8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8 8 8 0 00-8-8z"/>'
  }
})
