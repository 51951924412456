/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery-alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 14h-2V9h2m0 9h-2v-2h2m3.67-12H15V2H9v2H7.33A1.33 1.33 0 006 5.33v15.34C6 21.4 6.6 22 7.33 22h9.34A1.33 1.33 0 0018 20.67V5.33C18 4.6 17.4 4 16.67 4z"/>'
  }
})
