export function debounce(func, waitMilliseconds = 50, isImmediate = false) {
    let timeoutId;
    return function (...args) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        const doLater = function () {
            timeoutId = undefined;
            if (!isImmediate) {
                func.apply(context, args);
            }
        };
        const shouldCallNow = isImmediate && timeoutId === undefined;
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(doLater, waitMilliseconds);
        if (shouldCallNow) {
            func.apply(context, args);
        }
    };
}
export default debounce;
