/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'anchor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a3 3 0 00-3 3c0 1.27.8 2.4 2 2.83V10H8v2h3v6.92A6.494 6.494 0 016.53 16H8v-2H3v5h2v-1.7c1.58 2.31 4.2 3.7 7 3.7s5.42-1.39 7-3.69V19h2v-5h-5v2h1.46c-1 1.56-2.63 2.63-4.46 2.92V12h3v-2h-3V7.82c1.2-.42 2-1.55 2-2.82a3 3 0 00-3-3m0 2a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1z"/>'
  }
})
