/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "device-omni": {
    width: 16,
    height: 16,
    viewBox: "0 0 394 207",
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M333 30v-9a21 21 0 00-21-21H16A16 16 0 000 16v122a19 19 0 0019 19h17.07A52.6 52.6 0 0068 191.6v10.2a5.2 5.2 0 005.2 5.2h281.6a5.2 5.2 0 005.2-5.2v-10.2a52.6 52.6 0 0034-49.2V84H52V30h281z" fill="#000"/>'
  }
});
