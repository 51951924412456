import { defineComponent, ref } from "vue";
import BModal from "@/shared/components/layout/Modal.vue";
import BLabel from "@/shared/components/forms/BLabel.vue";
import BText from "@/shared/components/forms/BText.vue";
import BTextarea from "@/shared/components/forms/BTextarea.vue";
import BErrors from "@/shared/components/forms/BErrors.vue";
import { WithValidation } from "@axisvue/forms";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: {
        BModal,
        BLabel,
        BText,
        BTextarea,
        BErrors,
        WithValidation,
        AxionButton
    },
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    emits: ["update", "close"],
    setup(props, { emit }) {
        const data = ref({ ...props.project });
        const validators = {
            name: ["required"],
            description: []
        };
        const update = () => {
            emit("update", data.value);
        };
        const close = () => {
            emit("close");
        };
        return { data, validators, update, close };
    }
});
