import { defineComponent } from "vue";
import { CalendarEventState } from "../_providers/types/ICloudCalendarEvent";
export default defineComponent({
    props: {
        selectedEvent: {
            type: Object,
            required: true
        }
    },
    setup() {
        return {
            CalendarEventState
        };
    }
});
