import { exact3Repository } from "./exact3/exact3Repository";
import { exact3Repository as exact3V2Repository } from "./exact3/v2/exact3Repository";
import lux3v1Repository from "./lux3/v1/lux3v1Repository";
import notesRepository from "./notes/notesRepository";
import notificationSettingsRepository from "./notificationSettings/notificationSettingsRepository";
import omni3v1Repository from "./omni3/v1/omni3v1Repository";
import projectsRepository from "./projects/projectsRepository";
import { featureKeysRepository } from "./featurekeys/featureKeysRepository";
import distributorsRepository from "./distributors/distributorsRepository";
import lux3v2Repository from "./lux3/v2/lux3v2Repository";
import deviceRepository from "./shared/types/device/IDeviceRepository";
import experimentRepository from "@/api/experiments/experimentsRepository";
import scansStorageRepository from "@/api/scansStorage/scansStorageRepository";
import RNotificationRepository from "./rNotifications/RNotificationRepository";
import RNotificationTestRepository from "./rNotifications/RNotificationTestRepository";
import RNotificationCustomFieldsRepository from "./rNotifications/RNotificationCustomFieldsRepository";
import RNotificationUserPreferencesRepository from "./rNotifications/RNotificationUserPreferencesRepository";
import authorizationRepository from "@/api/shared/types/auth/authorizationRepository";
import accountRepository from "@/api/shared/types/account/IAccountRepository";
import settingsRepository from "@/api/settings/settingsRepository";
import settingsUsers from "@/api/settings/settingsUsers";
import licenseRepository from "@/api/license/licenseRepository";
import usersRepository from "./users/usersRepository";
import scanPlansRepository from "./scanPlans/scanPlansRepository";
import barcodeRepository from "@/api/barcodes/experiments/experimentsRepository";
import reservationsRepository from "./reservations/reservationsRepository";
import alertsRepository from "@/api/alerts/alertsRepository";
const RepositoryFactory = {
    rNotification: RNotificationRepository,
    rNotificationTest: RNotificationTestRepository,
    rNotificationCustomFields: RNotificationCustomFieldsRepository,
    rNotificationUserPreferences: RNotificationUserPreferencesRepository,
    lux3v1: lux3v1Repository,
    authorization: authorizationRepository,
    notes: notesRepository,
    omni3v1: omni3v1Repository,
    notificationSettings: notificationSettingsRepository,
    projects: projectsRepository,
    exact3: exact3Repository,
    exact3V2: exact3V2Repository,
    featureKeys: featureKeysRepository,
    users: usersRepository,
    lux3v2: lux3v2Repository,
    distributors: distributorsRepository,
    license: licenseRepository,
    device: deviceRepository,
    experiments: experimentRepository,
    scansStorage: scansStorageRepository,
    account: accountRepository,
    settings: settingsRepository,
    userSettings: settingsUsers,
    scanPlans: scanPlansRepository,
    barcode: barcodeRepository,
    reservations: reservationsRepository,
    alerts: alertsRepository
};
export default RepositoryFactory;
