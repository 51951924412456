/**
 * Returns if the keycode of a KeyboardEvent is a Number.
 * @param event
 * @returns {boolean}
 */
export default function isKeycodeANumber(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46)
        return false;
    else
        return true;
}
