import Repository from "@/api/repository";
const resourceNotifications = `/notifications`;
export default {
    getNotificationsFilteredOrderedByGroup(start, length) {
        return Repository.get(`${resourceNotifications}/${start}/${length}`);
    },
    getNotificationsByGroupId(groupId) {
        return Repository.get(`${resourceNotifications}/group/${groupId}`);
    },
    getNotificationById(id) {
        return Repository.get(`${resourceNotifications}/${id}`);
    },
    updateNotificationSettings(id, emailPreference, isEnforced) {
        return Repository.post(`${resourceNotifications}/settings/${id}`, {
            emailPreference,
            isEnforced
        });
    },
    updateAllNotificationSettings(notification) {
        return Repository.post(`${resourceNotifications}/${notification.id}`, {
            typeGroupId: notification.typeGroupId,
            title: notification.title,
            description: notification.description,
            emailPreference: notification.emailPreference,
            isEnforced: notification.isEnforced,
            groupId: notification.groupId
        });
    }
};
