import Repository from "@/api/repository";
const resource = `/NotificationSettings`;
export default {
    getNotificationSettings(experimentId) {
        return Repository.get(`${resource}/${experimentId}`);
    },
    postNotificationSetting(model) {
        return Repository.post(`${resource}`, model);
    },
    deleteNotificationSetting(id) {
        return Repository.delete(`${resource}/${id}`);
    }
};
