import { inject, ref } from "vue";
import { DialogRoot } from "./components/DialogRoot";
import { DialogInjectionKey } from "./types/DialogInjectionKey";
import { DialogType } from "./types/DialogType";
export function initializeDialogsPlugin() {
    const dialogMounted = ref(false);
    const buildDialog = (propsData) => {
        if (dialogMounted.value)
            throw new Error("A dialog is already in use!");
        else
            dialogMounted.value = true;
        const dialog = new DialogRoot({
            propsData: { dialogProps: propsData }
        });
        return dialog
            .$mount()
            .show()
            .finally(() => {
            dialog.$destroy();
            dialogMounted.value = false;
        });
    };
    return {
        alert: (data) => {
            return buildDialog({
                ...data,
                icon: data.icon ? data.icon : "information-variant",
                type: DialogType.Alert
            });
        },
        confirm: (data) => {
            return buildDialog({
                ...data,
                icon: data.icon ? data.icon : "help",
                type: DialogType.Confirm
            });
        },
        prompt: (data) => {
            return buildDialog({
                ...data,
                icon: data.icon ? data.icon : "draw",
                type: DialogType.Prompt
            });
        }
    };
}
export function useDialogPlugin() {
    const dialog = inject(DialogInjectionKey);
    if (!dialog) {
        throw new Error("A plugin of type Dialog should be provided to the component. See App.vue.");
    }
    return dialog;
}
