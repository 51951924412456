/**
 * The type of display for event on the calendar.
 * None - not shown.
 * Auto - normal event (scan/snapshot/reservations/busy slot).
 * Background - background event on calendar - check https://fullcalendar.io
 */
export var CalendarEventDisplay;
(function (CalendarEventDisplay) {
    CalendarEventDisplay["None"] = "none";
    CalendarEventDisplay["Auto"] = "auto";
    CalendarEventDisplay["Background"] = "background";
})(CalendarEventDisplay || (CalendarEventDisplay = {}));
