import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const devicesRepository = RepositoryFactory.device;
/**
 * @returns all registered devices within tenant (only lux and omni).
 */
export function withRegisteredDevices() {
    const registeredDevices = ref([]);
    const fetchingDevices = ref(false);
    async function fetchDevices() {
        try {
            fetchingDevices.value = true;
            const { data } = await devicesRepository.getAllDevicesWithinTenant();
            registeredDevices.value = transformObjectKeysPascalToCamelCase(data);
            fetchingDevices.value = false;
        }
        catch (error) {
            console.warn("Error while fetching registered devices within tenant: ", error);
        }
    }
    async function fetchDeviceTimezoneOffset(deviceId) {
        try {
            const index = registeredDevices.value.findIndex(d => d.id === deviceId);
            if (index == -1) {
                return;
            }
            const { data } = await devicesRepository.getDeviceTimezoneOffset(deviceId);
            return data;
        }
        catch (error) {
            console.warn("Error while fetching timezone for device: ", error);
        }
    }
    return {
        fetchDevices,
        fetchDeviceTimezoneOffset,
        registeredDevices,
        fetchingDevices
    };
}
// PROVIDER SETUP
export const { provide: provideRegisteredDevices, use: useRegisteredDevices } = setupProvider();
