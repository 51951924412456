/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'square-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3h18v18H3V3m2 2v14h14V5H5z"/>'
  }
})
