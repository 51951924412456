/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-replace': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 12h5.5L14 6.5V12M8 5h7l6 6v10a2 2 0 01-2 2H8a2 2 0 01-2-2v-3h5v2l4-3-4-3v2H6V7a2 2 0 012-2m5.5-2H4v13h2v2H4a2 2 0 01-2-2V3a2 2 0 012-2h7.5l2 2z"/>'
  }
})
