/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'less-than-or-equal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path d="M18.5,2.27L5,10.14L18.5,18L19.5,16.27L8.97,10.14L19.5,4L18.5,2.27M5,20V22H20V20H5Z" />'
  }
})
