import { computed, defineComponent, ref } from "vue";
import { useDetectTruncatedDom } from "@/shared/providers/useDetectTruncatedDom/useDetectTruncatedDom";
export default defineComponent({
    props: {
        project: {
            type: Object,
            required: true
        },
        projectLink: {
            type: String,
            default: "",
            required: true
        }
    },
    setup(props) {
        const getDeviceType = computed(() => {
            switch (props.project.projectDeviceType) {
                case 1:
                    return "Lux";
                case 2:
                    return "CellCounter";
                case 3:
                    return "Omni";
                default:
                    return "Unknown";
            }
        });
        const projectNameDOM = ref(null);
        const { truncated } = useDetectTruncatedDom(projectNameDOM);
        return {
            projectNameDOM,
            getDeviceType,
            truncated
        };
    }
});
