export default function currentDate() {
    const curdate = new Date();
    const currentdate = curdate.toString();
    const datestring = currentdate.split("GMT");
    const datefinal = datestring[0].split(" ");
    function MonthToNumber(month) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        for (let i = 0; i < months.length; i++) {
            if (month === months[i]) {
                return i + 1;
            }
        }
        return null;
    }
    const datetostore = datefinal[3] +
        "/" +
        MonthToNumber(datefinal[1]) +
        "/" +
        datefinal[2] +
        " " +
        datefinal[4];
    return datetostore;
}
