//GLOBALS
import "./globals/directives";
import "./globals/filters";
import "./globals/endpoints";
//MAIN STYLE
import "@/shared/css/main.scss";
// Leaflet styles
import "leaflet/dist/leaflet.css";
import "@livelybone/vue-datepicker/lib/css/index.css";
import { setupVueFormulate } from "@axisvue/forms";
import { registerIcons } from "@axisvue/svg-icons";
import { Datepicker, DateRangePicker, DatetimePicker, Timepicker } from "@livelybone/vue-datepicker";
import Axios from "axios";
import PortalVue from "portal-vue";
import VWave from "v-wave";
import Vue from "vue";
import VueSVGIcon from "vue-svgicon";
import VueTour from "vue-tour";
import { IN_DEVELOPMENT } from "./constants";
import ToastrPlugin from "./globals/plugins/toastr";
// Global register
Vue.component("DatePicker", Datepicker);
Vue.component("TimePicker", Timepicker);
Vue.component("DateTimePicker", DatetimePicker);
Vue.component("DateRangePicker", DateRangePicker);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Tooltip = require("vue-directive-tooltip");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Fragment = require("vue-fragment");
Vue.config.productionTip = false;
//Vue tour
Vue.use(VueTour);
Vue.use(Fragment.Plugin);
Vue.use(VWave);
//Forms
setupVueFormulate();
//Icons
registerIcons();
Vue.use(VueSVGIcon, {
    tagName: "b-icon",
    classPrefix: "mdi"
});
// eslint-disable-next-line @typescript-eslint/no-var-requires
const endpoints = require("./globals/endpoints");
Vue.use(PortalVue);
Vue.use(ToastrPlugin);
Vue.use(Tooltip);
if (IN_DEVELOPMENT) {
    require("./_dev");
    Object.defineProperty(Vue.prototype, "$console", { value: console });
    //For development only
    window.setScenario = async (s) => Axios.get("/e2e/" + s);
}
Object.defineProperty(Vue.prototype, "$publicPath", {
    value: IN_DEVELOPMENT ? "" : "/vue/dist"
});
Object.defineProperty(Vue.prototype, "$endpoint", {
    value: endpoints.default
});
