function makeExtendedPromise(promise) {
    // Set initial state
    let isCancelled = false;
    let isPending = true;
    let isRejected = false;
    let isFulfilled = false;
    let rejectFn;
    const extendedPromise = new Promise((resolve, reject) => {
        rejectFn = reject;
        Promise.resolve(promise).then(function (v) {
            isFulfilled = true;
            isPending = false;
            resolve(v);
        }, function (e) {
            isRejected = true;
            isPending = false;
            reject(e);
        });
    });
    extendedPromise.cancel = () => {
        isCancelled = true;
        rejectFn({ isCancelled: true });
    };
    extendedPromise.isCancelled = function () {
        return isCancelled;
    };
    extendedPromise.isFulfilled = function () {
        return isFulfilled;
    };
    extendedPromise.isPending = function () {
        return isPending;
    };
    extendedPromise.isRejected = function () {
        return isRejected;
    };
    return extendedPromise;
}
export default makeExtendedPromise;
