/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-bold': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 15V9h8V4.16L19.84 12 12 19.84V15H4z"/>'
  }
})
