/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 18H6a4 4 0 01-4-4 4 4 0 014-4h.71C7.37 7.69 9.5 6 12 6a5.5 5.5 0 015.5 5.5v.5H19a3 3 0 013 3 3 3 0 01-3 3m.35-7.97A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.03A6.004 6.004 0 000 14a6 6 0 006 6h13a5 5 0 005-5c0-2.64-2.05-4.78-4.65-4.97z"/>'
  }
})
