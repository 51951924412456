import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { transformObjectKeysCamelToPascalCase } from "./transformObjectKeysCamelToPascalCase";
const reservationsRepository = RepositoryFactory.reservations;
/**
 * @returns to do
 */
export function withReservations() {
    const reservations = ref([]);
    function removeItemFromReservationsList(reservationId) {
        reservations.value = reservations.value.filter(item => item.reservationId !== reservationId);
    }
    async function fetchReservations(deviceId) {
        var _a;
        try {
            const { data } = await reservationsRepository.getReservations(deviceId);
            reservations.value = transformObjectKeysPascalToCamelCase(data);
            return (_a = reservations.value) !== null && _a !== void 0 ? _a : [];
        }
        catch (error) {
            console.warn("Could not fetch reservations: ", error);
        }
    }
    async function createReservation(reservation) {
        try {
            const { data } = await reservationsRepository.createReservation(transformObjectKeysCamelToPascalCase(reservation));
            reservations.value.push({ ...reservation, reservationId: data });
            return data;
        }
        catch (error) {
            console.warn("Could not create a reservation: ", error);
            return -1;
        }
    }
    async function editReservation(reservation) {
        try {
            await reservationsRepository.editReservation(transformObjectKeysCamelToPascalCase(reservation));
            const tempIndex = reservations.value.findIndex(r => r.reservationId === reservation.reservationId);
            if (tempIndex > -1) {
                reservations.value[tempIndex] = { ...reservation };
            }
        }
        catch (error) {
            console.warn("Could not edit reservation: ", error);
        }
    }
    async function cancelReservation(reservationId) {
        try {
            const tempIndex = reservations.value.findIndex(r => r.reservationId === reservationId);
            if (tempIndex > -1) {
                removeItemFromReservationsList(reservationId);
                await reservationsRepository.cancelReservation(reservationId);
            }
        }
        catch (error) {
            console.warn("Could not remove reservation: ", error);
        }
    }
    return {
        fetchReservations,
        editReservation,
        cancelReservation,
        createReservation,
        reservations
    };
}
// PROVIDER SETUP
export const { provide: provideReservations, use: useReservations } = setupProvider();
