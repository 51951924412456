import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const experimentsRepository = RepositoryFactory.experiments;
/**
 * @returns all experiments to be displayed on calendar view.
 */
export function withActiveExperiments() {
    const activeExperiments = ref([]);
    async function fetchActiveExperiments(deviceId, days, deviceType) {
        var _a;
        try {
            const { data } = await experimentsRepository.getExperimentsWithinDaysRange(deviceId, days, deviceType);
            activeExperiments.value = transformObjectKeysPascalToCamelCase(data);
            return (_a = activeExperiments.value) !== null && _a !== void 0 ? _a : [];
        }
        catch (error) {
            console.warn("Could not fetch active experiments: ", error);
        }
    }
    return {
        fetchActiveExperiments,
        activeExperiments
    };
}
// PROVIDER SETUP
export const { provide: provideActiveExperiments, use: useActiveExperiments } = setupProvider();
