import { LatLng, LatLngBounds } from "leaflet";
import ShapeEnum from "../../types/enums/ShapeEnum";
import { _getShapeById } from "./GetShapeById";
export function _getAllROIsIdsFromData(data) {
    let rois = data.shapes.ids.filter(id => data.shapes.byId[id].type === ShapeEnum.ROI);
    if (!rois.length) {
        rois = data.shapes.ids.filter(id => data.shapes.byId[id].type === ShapeEnum.RECTANGLE);
    }
    return rois;
}
export function _getAllROIsFromData(data) {
    return _getAllROIsIdsFromData(data).map(id => data.shapes.byId[id]);
}
export function _getAllROIsInWell(data, wellId) {
    const well = _getShapeById(data, wellId);
    const northEast = new LatLng(well.center.lng + well.radius, well.center.lat + well.radius);
    const southWest = new LatLng(well.center.lng - well.radius, well.center.lat - well.radius);
    return _getAllROIsFromData(data).filter((roi) => new LatLngBounds(southWest, northEast).contains(roi.center));
}
