import Repository from "@/api/repository";
import { controllerRoutePrefix } from "../controllerRoutePrefix";
const resource = `${controllerRoutePrefix}/settings`;
export default {
    postChannelSettings(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/channelSettings`, model);
    },
    postAllChannelSettings(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/channelSettings/all`, model);
    },
    getChannelSettings(experimentId) {
        return Repository.get(`${resource}/${experimentId}/channelSettings/all`);
    }
};
