/*
If you do not use Dates, functions, undefined, Infinity, RegExps,
Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays
or other complex types within your object, a very simple one liner to
deep clone an object.
*/
function parseStringifyCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export default parseStringifyCopy;
