/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'selection-off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.5 3.77L1.78 2.5 21.5 22.22l-1.27 1.28-1.5-1.5H17v-1.73L3.73 7H2V5.27L.5 3.77M4 2h3v2H5.82L3.83 2H4m18 2v3h-2V4h-3V2h3a2 2 0 012 2m-2 13h2v3.17l-2-1.99V17M2 20v-3h2v3h3v2H4a2 2 0 01-2-2m8-18h4v2h-4V2m0 18h4v2h-4v-2m10-10h2v4h-2v-4M2 10h2v4H2v-4z"/>'
  }
})
