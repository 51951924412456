import { provideCalendarEvents, withCalendarEvents } from "./withCalendarEvents";
import { provideCreateReservation, withCreateReservation } from "./withCreateReservation";
import { provideModifyReservation, withModifyReservation } from "./withModifyReservation";
import { provideOccurrences, withOccurrences } from "./withOccurrences";
import { provideScansEstimate, withScansEstimate } from "./withScansEstimate";
import { ref } from "vue";
import { provideDeviceSelection, withDeviceSelection } from "./withDeviceSelection";
import { CalendarEventType } from "@axisvue/calendar";
export function setupAndProvide() {
    const deviceSelection = withDeviceSelection();
    const modifyReservation = withModifyReservation();
    const createReservation = withCreateReservation();
    const calendarEvents = withCalendarEvents();
    const occurrences = withOccurrences();
    const scansEstimate = withScansEstimate();
    provideDeviceSelection(deviceSelection);
    provideModifyReservation(modifyReservation);
    provideCalendarEvents(calendarEvents);
    provideOccurrences(occurrences);
    provideScansEstimate(scansEstimate);
    provideCreateReservation(createReservation);
    const fetchedDataAndCalculatedCalendarEvents = ref(false);
    /**
     * This function is called every 60000 ms from Calendar.vue - continuous polling information from back-end to keep data updated.
     * Moreover, it is called every time whenever you select a new device from the App.vue.
     * @param device - selected device coming from App.vue
     */
    async function fetchDataAndCalculateCalendarEvents(device) {
        if (device) {
            fetchedDataAndCalculatedCalendarEvents.value = false;
            await occurrences.fetchAllOccurrences(device.id, device.type);
            if (occurrences.occurrences.value.length) {
                // for all experiments, if any is omni3 then fetch scan estimate - state values
                await Promise.all(occurrences.occurrences.value.map(async (occurrence) => {
                    let scanStates;
                    if (occurrence.type === CalendarEventType.Experiment &&
                        occurrence.isOmni3) {
                        scanStates = await scansEstimate.fetchScansEstimateOnExperimentId(occurrence.id);
                    }
                    calendarEvents.calculateNonEditableCalendarEvents(device, occurrence, scanStates);
                }));
            }
            fetchedDataAndCalculatedCalendarEvents.value = true;
        }
    }
    return {
        modifyReservation,
        createReservation,
        calendarEvents,
        occurrences,
        scansEstimate,
        deviceSelection,
        fetchDataAndCalculateCalendarEvents,
        fetchedDataAndCalculatedCalendarEvents
    };
}
