import Repository from "@/api/repository";
const resource = "/licenses/v1";
export default {
    editLicenseFeatures(model, licenseId) {
        return Repository.post(`${resource}/${licenseId}/features`, model);
    },
    getLicenseFeatures(licenseId) {
        return Repository.get(`${resource}/${licenseId}/features`);
    },
    editLicense(licenseCode, license) {
        return Repository.post(`${resource}/${licenseCode}/Edit/`, license);
    },
    cancelLicenseReservation(licenseCode) {
        return Repository.post(`${resource}/${licenseCode}/cancelReservation/`);
    },
    blockLicense(licenseCode) {
        return Repository.post(`${resource}/${licenseCode}/block/`);
    },
    unblockLicense(licenseCode) {
        return Repository.post(`${resource}/${licenseCode}/unblock/`);
    }
};
