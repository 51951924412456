/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 3a2 2 0 012 2v10a2 2 0 01-2 2h-4v2h1a1 1 0 011 1h7v2h-7a1 1 0 01-1 1h-4a1 1 0 01-1-1H2v-2h7a1 1 0 011-1h1v-2H7a2 2 0 01-2-2V5a2 2 0 012-2h10z"/>'
  }
})
