/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 13.5a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5 1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5m-5 0a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5 1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5m-5 0A1.5 1.5 0 017.5 12 1.5 1.5 0 019 10.5a1.5 1.5 0 011.5 1.5A1.5 1.5 0 019 13.5M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.96.89 1.65.89H22a2 2 0 002-2V5a2 2 0 00-2-2z"/>'
  }
})
