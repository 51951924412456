import { defineComponent, computed } from "vue";
import { DialogType } from "../types/DialogType";
import DialogMessage from "./DialogMessage.vue";
import DialogInput from "./DialogInput.vue";
export default defineComponent({
    components: { DialogInput, DialogMessage },
    props: {
        inputAttributes: {
            type: Object,
            default: undefined
        },
        inputPlaceholder: {
            type: String,
            default: undefined
        },
        inputValue: {
            type: String,
            default: undefined
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    setup(props) {
        return {
            IsPromptDialog: computed(() => props.type === DialogType.Prompt)
        };
    }
});
