import { defineComponent, onMounted, onUnmounted, ref } from "vue";
export default defineComponent({
    setup() {
        const onHoverSection = ref();
        function followMousePosition(e) {
            var _a, _b;
            if (onHoverSection.value) {
                onHoverSection.value.style.visibility = "visible";
                if (e.pageX < 300) {
                    onHoverSection.value.style.left = e.pageX + 10 + "px";
                }
                else {
                    onHoverSection.value.style.left =
                        e.pageX - ((_a = onHoverSection.value) === null || _a === void 0 ? void 0 : _a.offsetWidth) - 10 + "px";
                }
                onHoverSection.value.style.top =
                    e.pageY - ((_b = onHoverSection.value) === null || _b === void 0 ? void 0 : _b.offsetHeight) + "px";
            }
        }
        onMounted(() => {
            document.addEventListener("mousemove", followMousePosition);
        });
        onUnmounted(() => {
            document.removeEventListener("mousemove", followMousePosition);
        });
        return {
            onHoverSection
        };
    }
});
