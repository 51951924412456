/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.42z"/>'
  }
})
