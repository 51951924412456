<template functional>
  <p
    :title="props.title"
    class="truncate whitespace-nowrap text-xs text-gray-600"
  >
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>
