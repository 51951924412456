/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-double-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.59 7.41L7 6l6 6-6 6-1.41-1.41L10.17 12 5.59 7.41m6 0L13 6l6 6-6 6-1.41-1.41L16.17 12l-4.58-4.59z"/>'
  }
})
