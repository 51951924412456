import "@fullcalendar/core/vdom.cjs";
import { DateTime } from "luxon";
import { reactive, ref } from "vue";
import { calendarLocales, defaultCalendarConfig } from "./defaultCalendarConfiguration";
/**
 * @param options for passing specific calendar options from business logic.
 * @param use12HourFormat
 * @returns a calendar instance and a default configuration.
 */
export function withCalendarConfiguration(options, use12HourFormat) {
    const calendarInstance = ref(null);
    let calendarEventSource;
    const calendarEvents = [];
    /**
     * Follows the red line in the calendar, scrolling 20 minutes in the past so that it is visible
     * in case the scrolling would cause calendar to go back to previous day i.e.: t < 00:20,
     * scrolls calendar to current time
     */
    const scrollCalendarToRedLine = () => {
        var _a, _b;
        if (DateTime.local() > DateTime.local().set({ hour: 0, minute: 20 })) {
            (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.scrollToTime(DateTime.local().minus({ minute: 20 }).toISOTime());
        }
        else {
            (_b = calendarInstance.value) === null || _b === void 0 ? void 0 : _b.scrollToTime(DateTime.local().toISOTime());
        }
    };
    /**
     * Custom button that redirects to 15 min before now
     * so the red line is clearly visible.
     */
    const nowButton = {
        text: "Now",
        hint: "Now",
        click: () => {
            var _a;
            (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.gotoDate(DateTime.local().toISO());
            scrollCalendarToRedLine();
        }
    };
    const configuration = reactive({
        ...defaultCalendarConfig,
        locale: use12HourFormat ? calendarLocales.enUS : calendarLocales.enGB,
        customButtons: { now: nowButton },
        ...options,
        events: calendarEvents,
        viewDidMount: (loadedView) => {
            calendarInstance.value = loadedView.view.calendar;
            calendarEventSource = calendarInstance.value.getEventSources()[0];
            scrollCalendarToRedLine();
            if (typeof options.viewDidMount === "function") {
                options.viewDidMount(loadedView);
            }
        }
    });
    /**
     * It changes time format to h12 | h23 for both slotLabel & eventTime
     * @param use12HourFormat {boolean} true means h12, false means h23 time format
     */
    const setRegionalOptions = (use12HourFormat) => {
        calendarInstance.value.setOption("locale", use12HourFormat ? calendarLocales.enUS : calendarLocales.enGB);
    };
    const updateAllEvents = (events) => {
        calendarEvents.splice(0, calendarEvents.length, ...events);
        calendarEventSource.refetch();
    };
    const updateEvents = (events) => {
        events.forEach(newEvent => {
            const oldEvent = calendarEvents.find(e => e.id === newEvent.id);
            if (oldEvent) {
                Object.entries(newEvent).forEach(([k, v]) => {
                    oldEvent[k] = v;
                });
            }
        });
        calendarEventSource.refetch();
    };
    const updateEvent = (newEvent) => {
        const oldEvent = calendarEvents.find(e => e.id === newEvent.id);
        if (oldEvent) {
            Object.entries(newEvent).forEach(([k, v]) => {
                oldEvent[k] = v;
            });
        }
        calendarEventSource.refetch();
    };
    /**
     * It auto assign calendar zoom level regarding to the event with min duration
     * @param { CalendarEvent[] } calendarEvents calendar events array
     */
    const setSlotDuration = (duration) => {
        var _a, _b;
        const newSlotDuration = `00:${duration.toString().padStart(2, "0")}:00`;
        if (((_a = calendarInstance === null || calendarInstance === void 0 ? void 0 : calendarInstance.value) === null || _a === void 0 ? void 0 : _a.getOption("slotDuration")) !== newSlotDuration) {
            (_b = calendarInstance === null || calendarInstance === void 0 ? void 0 : calendarInstance.value) === null || _b === void 0 ? void 0 : _b.setOption("slotDuration", newSlotDuration);
            scrollCalendarToRedLine();
        }
    };
    return {
        calendarInstance,
        configuration,
        setRegionalOptions,
        updateAllEvents,
        setSlotDuration,
        updateEvents,
        updateEvent,
        scrollCalendarToRedLine
    };
}
