// @ts-expect-error
import VueFormulate from "@braid/vue-formulate";
import Vue from "vue";
import PasswordEye from "./components/PasswordEye.vue";
import FormulateEmailSearchSelect from "./components/FormulateEmailSearchSelect.vue";
import formValidators from "./utils/formValidators";
export const setupVueFormulate = () => {
    Vue.component("PasswordEye", PasswordEye);
    Vue.component("FormulateEmailSearchSelect", FormulateEmailSearchSelect);
    Vue.use(VueFormulate, {
        classes: {
            outer(context, classes) {
                if (context.classification !== "group" &&
                    (context.type === "checkbox" ||
                        context.type === "radio" ||
                        context.type === "submit"))
                    return classes.concat("my-1");
                return classes.concat("mb-3");
            },
            input(context, classes) {
                // console.log(context.type);
                if (context.classification === "group") {
                    return classes;
                }
                switch (context.type) {
                    case "checkbox":
                        return classes.concat(["form-checkbox", "text-xl"]);
                    case "select":
                        return classes.concat(["form-select", "w-full"]);
                    case "radio":
                        return classes.concat(["form-radio", "text-xl"]);
                    case "submit":
                        return classes.concat([
                            "b-btn-solid",
                            "b-btn-solid-blue",
                            "rounded-full",
                            "bg-blue-600",
                            "hover:bg-blue-700",
                            "px-8",
                            "py-3"
                        ]);
                    case "button":
                        return classes.concat(["b-btn-solid"]);
                    case "textarea":
                        return classes.concat(["form-textarea", "w-full"]);
                    default:
                        return classes.concat(["form-input", "w-full"]);
                }
            },
            inputDisabled: "!text-control-low",
            inputHasErrors: "!bg-surface-danger !border !border-signal-warning focus:!outline-none focus:!border-signal-warning focus:!ring focus:!ring-1 focus:!ring-signal-warning",
            label(context, classes) {
                var _a;
                const base = classes.concat(["select-none"]);
                if (context.classification === "box" &&
                    (context.type === "checkbox" || context.type === "radio")) {
                    if ((_a = context === null || context === void 0 ? void 0 : context.attrs) === null || _a === void 0 ? void 0 : _a.disabled) {
                        return base.concat(["mx-2 text-control-low"]);
                    }
                    else {
                        return base.concat(["mx-2 text-control-high"]);
                    }
                }
                else {
                    return base.concat(["mb-1"]);
                }
            },
            help: "text-xs mb-1 text-gray-600",
            error: "text-red-700 text-xs mt-1",
            wrapper(context, classes) {
                if (context.classification === "box" &&
                    (context.type === "checkbox" || context.type === "radio"))
                    return classes.concat(["flex", "items-center"]);
                else
                    return classes;
            }
        },
        library: {
            password_eye: {
                classification: "text",
                component: "PasswordEye"
            },
            emailSearchSelect: {
                classification: "text",
                component: "FormulateEmailSearchSelect"
            }
        },
        rules: {
            phone: (context) => !formValidators.isPhoneNumber(context.value),
            hasNumbers: (context, length) => {
                const reg = new RegExp(new Array(parseInt(length)).fill("[0-9]").join(".*"));
                return context.value.match(reg);
            },
            hasCapitals: (context, length) => {
                const reg = new RegExp(new Array(parseInt(length)).fill("[A-Z]").join(".*"));
                return context.value.match(reg);
            },
            hasLowerCase: (context, length) => {
                const reg = new RegExp(new Array(parseInt(length)).fill("[a-z]").join(".*"));
                return context.value.match(reg);
            },
            hasNoSpecialCharacters: (context) => {
                const reg = new RegExp('[\\\\:*?"<>|/]');
                return !context.value.match(reg);
            }
        },
        locales: {
            en: {
                hasNoSpecialCharacters({ name }) {
                    return `${name} must not contain special characters \\/:*?"<>|`;
                },
                hasNumbers({ name, args }) {
                    if (args[0] > 1)
                        return `${name} must have at least ${args[0]} numbers.`;
                    else
                        return `${name} must have at least ${args[0]} number.`;
                },
                hasCapitals({ name, args }) {
                    if (args[0] > 1)
                        return `${name} must have at least ${args[0]} capital characters.`;
                    else
                        return `${name} must have at least ${args[0]} capital character.`;
                },
                hasLowerCase({ name, args }) {
                    if (args[0] > 1)
                        return `${name} must have at least ${args[0]} lower case characters.`;
                    else
                        return `${name} must have at least ${args[0]} lower case character.`;
                },
                phone() {
                    return "Phone number is not valid";
                }
            }
        }
    });
};
