<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <portal v-if="show" :to="destination">
    <transition name="fade">
      <div
        class="modal"
        role="dialog"
        :style="overlayStyling"
        @mousedown.self="closeOnClick"
      >
        <div class="modal-dialog" :class="classes">
          <div class="rounded-t-lg border border-transparent">
            <div v-if="showHeader" class="my-2 mx-4 flex justify-between">
              <div class="truncate">
                <div class="text-grey-700 self-center text-2xl font-light">
                  {{ heading }}
                </div>
                <div
                  class="text-grey-700 self-center text-2xl truncate font-light"
                >
                  {{ subHeading }}
                </div>
              </div>
              <span
                class="cursor-pointer text-4xl text-gray-700"
                @click="close"
              >
                <svg
                  viewBox="0 0 566.93 566.93"
                  fill="currentColor"
                  class="h-8 w-8"
                >
                  <path
                    d="M427.29 395.47l-112-112 112-112a22.5 22.5 0 0 0-31.82-31.82l-112 112-112-112a22.5 22.5 0 0 0-31.82 31.82l112 112-112 112a22.5 22.5 0 0 0 31.82 31.82l112-112 112 112a22.5 22.5 0 0 0 31.82-31.82z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body p-2">
            <slot />
          </div>
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
/**
 * Panel component that can collapse and expand content
 * @vue-prop {String} [heading = ""] - Text to put in the modal header
 * @vue-prop {String} [width = "max-w-2xl"] - Classes to apply to modal body
 */
export default {
  props: {
    heading: {
      default: "",
      type: String
    },
    subHeading: {
      default: "",
      type: String
    },
    classes: {
      default: "max-w-5xl",
      type: String
    },
    overlayStyling: {
      default: "",
      type: String
    },
    destination: {
      default: "modals",
      type: String,
      require: false
    },
    show: Boolean,
    closeWithButtonOnly: {
      default: true,
      type: Boolean
    },
    showHeader: {
      default: true,
      type: Boolean
    }
  },
  created() {
    const escapeHandler = e => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    closeOnClick() {
      if (this.closeWithButtonOnly) this.$emit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  @apply fixed top-0 left-0 h-screen w-screen cursor-pointer overflow-y-auto;
}

.modal-dialog {
  @apply mx-auto my-10 cursor-default rounded rounded-t-lg bg-white shadow-lg;
}

.modal-body {
  :deep(img) {
    max-height: 100%;
    width: auto !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.15s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
