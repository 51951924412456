/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'export': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 12l-4-4v3h-9v2h9v3M1 18V6a2 2 0 012-2h12a2 2 0 012 2v3h-2V6H3v12h12v-3h2v3a2 2 0 01-2 2H3a2 2 0 01-2-2z"/>'
  }
})
