import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const UsersRepository = RepositoryFactory.users;
/**
 * This Provider is responsible for handling global state for users within tenant
 */
export function withUsersWithinTenant() {
    const usersWithinTenant = ref([]);
    const fetchUsersWithinTenant = async () => {
        try {
            const { data } = await UsersRepository.getEmailsInTenant();
            usersWithinTenant.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error(error);
        }
    };
    return {
        fetchUsersWithinTenant,
        usersWithinTenant
    };
}
// PROVIDER SETUP
export const { provide: provideUsersWithinTenant, use: useUsersWithinTenant } = setupProvider();
