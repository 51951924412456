function loadImage(src) {
    let img;
    const promise = new Promise((res, rej) => {
        try {
            // It could be the case that src = "" (useful to cancel requests)
            if (src === null || src === undefined)
                rej("src is null or undefined");
            img = document.createElement("img");
            img.crossOrigin = "Anonymous";
            img.onload = () => {
                res(img);
                img.remove();
            };
            img.src = src;
        }
        catch (err) {
            rej(err);
        }
    });
    const cancelDownload = () => new Promise(res => {
        img.addEventListener("error", () => {
            res();
        });
        img.src = "";
    });
    return { promise, cancelDownload };
}
export default loadImage;
