import { defineComponent } from "vue";
import { useLayoutType } from "@/shared/providers/withLayoutType/withLayoutType";
import { LayoutTypes } from "@/shared/providers/withLayoutType/types/LayoutTypes";
import BIconGrid from "@/shared/components/icons/BIconGrid.vue";
import BIconList from "@/shared/components/icons/BIconList.vue";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: { BIconGrid, BIconList, AxionButton },
    setup() {
        const { layoutType, setLayoutType } = useLayoutType();
        const toggleLayoutType = () => {
            if (layoutType.value === LayoutTypes.GRID) {
                setLayoutType(LayoutTypes.LIST);
            }
            else {
                setLayoutType(LayoutTypes.GRID);
            }
        };
        return { layoutType, LayoutTypes, setLayoutType, toggleLayoutType };
    }
});
