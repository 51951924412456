/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "fa-sort-asc": {
    width: 16,
    height: 16,
    viewBox: "0 0 334 430",
    data: '<path pid="0" d="M153.9 5.08a19 19 0 0125.86 0l147.8 137.31c12.64 11.76 4.33 32.93-12.94 32.93H19.04c-17.27 0-25.58-21.17-12.93-32.92L153.9 5.08z" fill="#000"/>'
  }
});
