import Repository from "@/api/repository";
const resource = `/barcodes`;
export default {
    getExperiments(barcode, unixTimestamp) {
        return Repository.get(`${resource}/experiments`, {
            params: {
                barcode,
                unixTimestamp
            }
        });
    }
};
