import { removeFromArray } from "@axisvue/utils";
export default class CommandCircleCreate {
    constructor(circle) {
        this.circle = circle;
    }
    do(shapes) {
        shapes.byId[this.circle.id] = this.circle;
        shapes.ids = removeFromArray(shapes.ids, this.circle.id);
        //triggers an update - current scan is changed
        this.circle.processed
            ? shapes.ids.push(this.circle.id)
            : shapes.ids.unshift(this.circle.id);
    }
    undo(shapes) {
        delete shapes.byId[this.circle.id];
        shapes.ids = removeFromArray(shapes.ids, this.circle.id);
    }
}
