/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "fa-sort-desc": {
    width: 16,
    height: 16,
    viewBox: "0 0 334 430",
    data: '<path pid="0" d="M153.9 424.05a19 19 0 0025.86 0l147.8-137.32c12.64-11.75 4.33-32.92-12.94-32.92H19.04c-17.27 0-25.58 21.17-12.93 32.92L153.9 424.05z" fill="#000"/>'
  }
});
