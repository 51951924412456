// Experiment event types are used for determining experiment following characteristics:
import { CalendarEventType } from "./CalendarEventType";
const eventColorPairings = {
    background: "#686e6b",
    scan: "#4d86d2",
    "busy-slot": "",
    [CalendarEventType.Experiment]: "",
    [CalendarEventType.Reservation]: ""
};
// Map ExperimentEventType to equivalent color
export const eventToExperimentColor = (eventType) => eventColorPairings[eventType];
export const MAXIMUM_NUMBER_OF_SCANS = 120;
