// to do
import { capitalizeFirstLetter } from "@axisvue/utils";
/**
 * Transform all object keys found in provided data from Pascal Case to Camel Case.
 *
 * @param data - Can be an array of objects, object or nested object.
 *
 * @returns data with transformed object keys
 */
export function transformObjectKeysCamelToPascalCase(data) {
    if (Array.isArray(data)) {
        return data.map(transformObjectKeysCamelToPascalCase);
    }
    if (data && typeof data === "object") {
        return Object.entries(data).reduce((result, [key, value]) => ({
            ...result,
            [capitalizeFirstLetter(key)]: transformObjectKeysCamelToPascalCase(value)
        }), {});
    }
    return data;
}
