import { CalendarEventType, CalendarEventDisplay } from "@axisvue/calendar";
import { CalendarEventColor } from "../_providers/types/ICloudCalendarEvent";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
export function generateBusySlotForExperiment(props) {
    const start = new Date(props.eventEstimateStartTime.getTime() + props.eventEstimateDuration * tms);
    const end = new Date(start.getTime() + props.eventsBetweenInterval * tms);
    return {
        id: props.experimentId + "-busySlot-" + props.eventId,
        start: start.toISOString(),
        end: end.toISOString(),
        backgroundColor: CalendarEventColor.ActiveCompleted,
        borderColor: CalendarEventColor.ActiveCompleted,
        display: CalendarEventDisplay.Background,
        extendedProps: {
            deviceId: props.deviceId,
            experimentId: props.experimentId,
            experimentIsShared: props.projectIsShared,
            experimentOwner: props.experimentOwner,
            eventType: CalendarEventType.BusySlot
        }
    };
}
