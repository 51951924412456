/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contain': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 3h6v2H4v14h4v2H2V3m5 14v-2h2v2H7m4 0v-2h2v2h-2m4 0v-2h2v2h-2m7-14v18h-6v-2h4V5h-4V3h6z"/>'
  }
})
