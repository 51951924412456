import BLabel from "@/shared/components/forms/BLabel.vue";
import axios from "axios";
import { AxionButton } from "@axisvue/buttons";
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
    components: {
        AxionButton,
        BLabel
    },
    props: {
        availableTenants: {
            type: Array,
            required: true
        },
        defaultTenant: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const selectedTenantId = ref(null);
        const showAlert = ref(false);
        const alertMessage = ref("");
        const switchOrganization = (tenantId) => {
            if (tenantId == null) {
                showAlert.value = true;
                alertMessage.value = "First, select an organization!";
            }
            else {
                const params = new URLSearchParams({
                    __RequestVerificationToken: window === null || window === void 0 ? void 0 : window.GLOBAL_ANTI_FORGERY
                });
                axios({
                    method: "post",
                    url: `/api/manage/v1/user/switchOrganization?tenantId=${tenantId}`,
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    data: params
                })
                    .then(() => {
                    window.location.href = window.location.origin;
                    localStorage.setItem("tenantId", tenantId);
                })
                    .catch(e => {
                    console.error(e);
                    showAlert.value = true;
                    alertMessage.value = "Something went wrong!";
                });
                selectedTenantId.value = null;
            }
        };
        onMounted(() => {
            const tenantId = localStorage.getItem("tenantId");
            if (tenantId !== null) {
                selectedTenantId.value = +tenantId;
            }
            else {
                selectedTenantId.value = props.defaultTenant;
            }
        });
        const closeDrawer = () => {
            showAlert.value = false;
            selectedTenantId.value = null;
            emit("close");
        };
        return {
            showAlert,
            selectedTenantId,
            alertMessage,
            switchOrganization,
            closeDrawer
        };
    }
});
