import { computed, reactive, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { useAlgorithmPolling } from "@/shared/providers/useAlgorithmPolling/useAlgorithmPolling";
import { AlgorithmValue } from "./types/enums/OverlayType";
import { ChannelTypeDisplayName } from "./types/IChannelConfigurations";
import { setupProvider } from "@/globals/setupProvider";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withConfluence(experimentId, areasWithConfluence, scanTimepoints) {
    const confluence = ref({});
    const selectedChannelConfluence = ref(ChannelTypeDisplayName.BRIGHTFIELD);
    const initialConfluenceValues = ref({});
    const channelsWithResultsConfluence = ref([]);
    const fetchingConfluence = reactive({
        AreaProcessedPercent: 0,
        IsFetched: false
    });
    const fetched = computed(() => fetchingConfluence.IsFetched ||
        fetchingConfluence.AreaProcessedPercent === 100);
    function setFetchedPercentage(data) {
        const total = areasWithConfluence.value.length * scanTimepoints.value.length;
        let scansWithResults = 0;
        areasWithConfluence.value.forEach(area => {
            if (data[area.name] && data[area.name].Brightfield) {
                const areaScan = Object.values(data[area.name].Brightfield.Data);
                areaScan.forEach(areaScanValue => {
                    if (areaScanValue != null)
                        scansWithResults++;
                });
            }
        });
        const nrOfAreasWithNullResults = total - scansWithResults;
        fetchingConfluence.AreaProcessedPercent =
            (100 - (nrOfAreasWithNullResults * 100) / total) | 0;
        if (fetchingConfluence.AreaProcessedPercent >= 100) {
            fetchingConfluence.IsFetched = true;
        }
    }
    function getConfluenceChannels(areaIndex) {
        var _a, _b, _c, _d;
        const selectedAlgorithms = (_c = (_b = (_a = areasWithConfluence.value) === null || _a === void 0 ? void 0 : _a[areaIndex]) === null || _b === void 0 ? void 0 : _b.selectedAlgorithms) !== null && _c !== void 0 ? _c : [];
        const confluenceAlgorithm = selectedAlgorithms.find(algo => algo.Value === AlgorithmValue.CONFLUENCE);
        return (_d = confluenceAlgorithm === null || confluenceAlgorithm === void 0 ? void 0 : confluenceAlgorithm.SelectedChannelsPerAlgorithm) !== null && _d !== void 0 ? _d : [];
    }
    function getChannelsWithResults() {
        if (!areasWithConfluence.value) {
            return;
        }
        for (let i = 0; i < areasWithConfluence.value.length; i++) {
            const areaChannels = getConfluenceChannels(i);
            if (areaChannels) {
                for (let j = 0; j < areaChannels.length; j++) {
                    if (channelsWithResultsConfluence.value.indexOf(areaChannels[j]) == -1) {
                        channelsWithResultsConfluence.value.push(areaChannels[j]);
                    }
                }
            }
        }
    }
    function setSelectedChannelConfluence(channel) {
        selectedChannelConfluence.value = channel;
    }
    async function reprocessAreasConfluence(areasToReprocess) {
        return Omni3v1ExperimentsRepository.postAreasReprocess(experimentId, areasToReprocess);
    }
    const getConfluenceData = async () => {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getConfluence(experimentId);
            confluence.value = data;
            initialConfluenceValues.value = data;
            setFetchedPercentage(data);
            getChannelsWithResults();
        }
        catch (error) {
            console.warn("Error while fetching confluence data: ", error);
        }
    };
    const algorithmPolling = useAlgorithmPolling(getConfluenceData, fetched);
    const fetchConfluenceData = async () => {
        fetchingConfluence.IsFetched = false;
        fetchingConfluence.AreaProcessedPercent = 0;
        await algorithmPolling.trigger();
    };
    return {
        confluence,
        initialConfluenceValues,
        fetchingConfluence,
        selectedChannelConfluence,
        channelsWithResultsConfluence,
        fetchConfluenceData,
        reprocessAreasConfluence,
        setSelectedChannelConfluence,
        getChannelsWithResults
    };
}
export const { provide: provideConfluence, use: useConfluence } = setupProvider();
