/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rectangle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 6v13h16V6H4z"/>'
  }
})
