import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { sortAreasInGroupsData } from "@/pages/wellPlate/_providers/helpers";
import { findGroupByAreasIds } from "@/pages/wellPlate/_providers/withGroups/helpers/findGroupByAreasIds";
import { useGroupActions } from "@/pages/wellPlate/_providers/withGroups/useGroupActions/useGroupActions";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withGroups(experimentId) {
    const groups = ref([]);
    const fetchGroups = async (omni3ExperimentId = experimentId) => {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getGroups(omni3ExperimentId);
            groups.value = sortAreasInGroupsData(data);
        }
        catch (error) {
            throw new Error("Groups could not be loaded\nTry again");
        }
    };
    const removeItemFromGroupsList = (id) => {
        const index = groups.value.findIndex(g => g.Id === id);
        if (index > -1) {
            groups.value.splice(index, 1);
        }
    };
    const getGroupByAreasIds = (areasIds) => {
        return findGroupByAreasIds(groups.value, areasIds);
    };
    const getGroupColor = (groupId) => {
        var _a;
        const group = groups.value.find(group => group.Id === groupId);
        return (_a = group === null || group === void 0 ? void 0 : group.color) !== null && _a !== void 0 ? _a : "fff";
    };
    const { createGroup, deleteGroup, editGroup } = useGroupActions(experimentId);
    return {
        groups,
        fetchGroups,
        removeItemFromGroupsList,
        createGroup,
        deleteGroup,
        editGroup,
        getGroupByAreasIds,
        getGroupColor
    };
}
export const GroupsInjectionKey = makeInjectionKey();
/**
 * Provides `Groups` to all descendants of a component.
 *
 * Use in conjunction with `useGroups`.
 */
export function provideGroups(Groups) {
    provide(GroupsInjectionKey, Groups);
}
/**
 * Injects `Groups` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideGroups`, otherwise an error will
 * be thrown
 */
export function useGroups() {
    const Groups = inject(GroupsInjectionKey);
    if (Groups === undefined)
        throw new Error("Groups was not provided.");
    return Groups;
}
