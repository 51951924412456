/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'feature-search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 20a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h5.5c-.3.8-.5 1.6-.5 2.5 0 3.6 2.9 6.5 6.5 6.5.8 0 1.5-.1 2.1-.4L20 15v5m-.7-11.1c.4-.7.7-1.5.7-2.4C20 4 18 2 15.5 2S11 4 11 6.5s2 4.5 4.5 4.5c.9 0 1.7-.3 2.4-.7l3.1 3.1 1.4-1.4-3.1-3.1m-3.8.1C14.1 9 13 7.9 13 6.5S14.1 4 15.5 4 18 5.1 18 6.5 16.9 9 15.5 9z"/>'
  }
})
