import BModal from "@/shared/components/layout/Modal.vue";
import { defineComponent, ref } from "vue";
import { withBinMode } from "@/shared/providers/withBinMode/withBinMode";
import { useToastr } from "@/globals/plugins/toastr/useToastr";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: { BModal, AxionButton },
    props: {
        deletedExperiments: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    setup(_, { emit }) {
        const { emptyBin } = withBinMode();
        const toastr = useToastr();
        const loading = ref(false);
        const padTo2Digits = (num) => num.toString().padStart(2, "0");
        const formatDate = (date) => {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join("/");
        };
        const newExperimentDate = (date) => new Date(date);
        const closeHandler = () => emit("close");
        const onSuccessCloseHandler = () => emit("onSuccessCloseHandler");
        const deleteBin = async () => {
            try {
                loading.value = true;
                await emptyBin();
                onSuccessCloseHandler();
                toastr.success("Your bin has been emptied");
            }
            catch (error) {
                toastr.error("Something went wrong while emptying bin");
            }
            finally {
                loading.value = false;
            }
        };
        return {
            loading,
            closeHandler,
            deleteBin,
            formatDate,
            newExperimentDate
        };
    }
});
