/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M4 4h3l2-2h6l2 2h3a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2m8 3a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5m0 2a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3z"/>'
  }
})
