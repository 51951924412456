/**
 * Receives an object with promise containing properties and returns a promise that resolves to an object
 * with the same properties containing the resolved values
 * @param  {PromisesMap<T>} promisesMap  the input object with a promise in each property
 * @return {Promise<T>}  a promise that resolved to an object with the same properties containing the resolved values
 */
export default async function promiseAllProperties(promisesMap) {
    if (promisesMap === null || typeof promisesMap !== "object") {
        return Promise.reject(new TypeError("The input argument must be of type Object"));
    }
    const keys = Object.keys(promisesMap);
    const promises = keys.map(key => {
        return promisesMap[key];
    });
    const results = await Promise.all(promises);
    return results.reduce((resolved, result, index) => {
        resolved[keys[index]] = result;
        return resolved;
    }, {});
}
