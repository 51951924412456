/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redo-variant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 7A6.5 6.5 0 004 13.5a6.5 6.5 0 006.5 6.5H14v-2h-3.5C8 18 6 16 6 13.5S8 9 10.5 9h5.67l-3.08 3.09 1.41 1.41L20 8l-5.5-5.5-1.42 1.41L16.17 7H10.5M18 18h-2v2h2v-2z"/>'
  }
})
