import { defineComponent } from "vue";
import TimeFormatSwitch from "./TimeFormatSwitch.vue";
export default defineComponent({
    components: {
        TimeFormatSwitch
    },
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    setup(_, { emit }) {
        function changeToTimeFormat12(value) {
            emit("changeToTimeFormat12", value);
        }
        return {
            changeToTimeFormat12
        };
    }
});
