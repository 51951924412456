import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1f661e43&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1f661e43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18_vyrjir3tafa6d7xmeapelwsujq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f661e43",
  null
  
)

export default component.exports