import { ref } from "vue";
import { setupProvider } from "@/globals/setupProvider";
import { useRegisteredDevices } from "@/shared/providers/withRegisteredDevices/withRegisteredDevices";
export function withDeviceSelection() {
    // select device constructor, either has value or is null
    // depending on whether device is actually selected
    const selectedDevice = ref({
        id: 0,
        deviceDisplayName: "",
        firmwareNumber: "",
        serialNumber: "",
        type: 0,
        hasRobotArmAttached: false,
        lastExperimentOwnerEmail: "",
        lastExperimentIsFinished: false
    });
    // timezone of selected device
    const timezoneOffsetInMinutes = ref();
    const { registeredDevices, fetchDeviceTimezoneOffset } = useRegisteredDevices();
    //every time a new device is being selected, timezone is fetched
    async function selectDevice(serialNumber) {
        var _a;
        const tempDevice = registeredDevices.value.find(rd => rd.serialNumber === serialNumber);
        if (tempDevice) {
            selectedDevice.value = tempDevice;
            timezoneOffsetInMinutes.value =
                (_a = (await fetchDeviceTimezoneOffset(tempDevice.id))) !== null && _a !== void 0 ? _a : 0;
        }
    }
    return {
        selectDevice,
        selectedDevice,
        timezoneOffsetInMinutes
    };
}
export const { provide: provideDeviceSelection, use: useDeviceSelection } = setupProvider();
