/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label-off-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 4.27L3.28 3 20 19.72 18.73 21l-2.1-2.1c-.2.06-.41.1-.63.1H5a2 2 0 01-2-2V7c0-.5.17-.93.46-1.27L2 4.27M5 17h9.73L5 7.27V17m14.55-5L16 7H9.82L7.83 5H16c.67 0 1.27.33 1.63.84L22 12l-3 4.2-1.41-1.44L19.55 12z"/>'
  }
})
