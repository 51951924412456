export function createCurrentDateInENGB() {
    return new Date().toLocaleString("en-GB", {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    });
}
export function convertDateInENGB(d) {
    return new Date(d).toLocaleString("en-GB", {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    });
}
export function getDateFormat(date, useAmPmFormat) {
    const localeSelected = useAmPmFormat ? "en-US" : "en-GB";
    return date.toLocaleString(localeSelected, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: `${useAmPmFormat ? "h12" : "h23"}`
    });
}
