import { AxionInputSearch, AxionInputSelect } from "@axisvue/forms";
import { debounce } from "@axisvue/utils";
import { computed, defineComponent } from "vue";
import LayoutTypeToggle from "@/shared/components/layout/LayoutTypeToggle.vue";
import { AvailableDevicesValueTypes } from "@/shared/providers/withAvailableDevices/types/AvailableDevicesValueTypes";
import { useBinMode } from "@/shared/providers/withBinMode/withBinMode";
import { PROJECTS_DEVICES_FILTER_DEFAULT_OPTION } from "@/shared/providers/withProjects/useProjectsFilters/constants/ProjectsDevicesFilterDefaultOption";
import { useProjects } from "@/shared/providers/withProjects/withProjects";
export default defineComponent({
    components: { AxionInputSearch, LayoutTypeToggle, AxionInputSelect },
    props: {
        enableDevicesFilter: {
            type: Boolean,
            default: true
        }
    },
    emits: ["update"],
    setup(props, { emit }) {
        const { fetching, search, resourceFilterOptions, selectedResourceFilter, devicesFilterOptions, selectedDevicesFilter, setSearch, setSelectedResourceFilter, setSelectedDevicesFilter } = useProjects();
        const { isBinMode } = useBinMode();
        const showDevicesFilter = computed(() => devicesFilterOptions.value.length > 0 && props.enableDevicesFilter);
        const selectedDeviceIsOmni = computed(() => selectedDevicesFilter.value === AvailableDevicesValueTypes.OMNI);
        const triggerSearchProjects = debounce(async () => {
            emit("update");
        }, 500);
        const searchHandler = (value) => {
            setSearch(value);
            triggerSearchProjects();
        };
        const clearSearch = () => {
            setSearch("");
            emit("update");
        };
        const selectResourceHandler = (event) => {
            setSelectedResourceFilter(parseInt(event.target.value));
            emit("update");
        };
        const selectDeviceHandler = (event) => {
            const value = event.target.value;
            const selectedDevice = value
                ? parseInt(value)
                : PROJECTS_DEVICES_FILTER_DEFAULT_OPTION.value;
            setSelectedDevicesFilter(selectedDevice);
            emit("update");
        };
        return {
            fetching,
            isBinMode,
            search,
            resourceFilterOptions,
            selectedResourceFilter,
            devicesFilterOptions,
            selectedDevicesFilter,
            showDevicesFilter,
            selectedDeviceIsOmni,
            searchHandler,
            clearSearch,
            selectResourceHandler,
            selectDeviceHandler
        };
    }
});
