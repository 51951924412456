import { computed, defineComponent, onBeforeUpdate, onMounted, ref, watch } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { useToastr } from "@/globals/plugins/toastr/useToastr";
import router from "@/router";
import WellplateNavigationPanel from "./WellplateNavigationPanel.vue";
import { useBreadCrumbs } from "./withBreadCrumbs";
import { AxionButton } from "@axisvue/buttons";
import { AxionInputSelect } from "@axisvue/forms";
const NavigationRepository = RepositoryFactory.experiments;
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export default defineComponent({
    components: {
        AxionInputSelect,
        AxionButton,
        WellplateNavigationPanel
    },
    props: {
        isOmniWell: {
            type: Boolean,
            required: false,
            default: false
        },
        experimentId: { type: String, required: true },
        omniExperimentId: { type: String, required: false, default: null },
        showExperimentsNavigation: {
            type: Boolean,
            required: false,
            default: true
        },
        isLoggedInUserExperimentOwner: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props) {
        const omni3WellRoute = "omni3-v1-well";
        const toastr = useToastr();
        const { breadCrumbs } = useBreadCrumbs();
        const experimentLink = ref("");
        const experiments = ref([]);
        const isWellplatePanelVisible = ref(false);
        const selectedExperimentName = ref("");
        const experimentRoute = (projectId, experimentId) => `/project/${projectId}/experiments/${experimentId}`;
        const experimentsNavOptions = computed(() => {
            return experiments.value.map(item => ({
                ...item,
                disabled: item.Current,
                label: item.Name,
                value: item.Name
            }));
        });
        const previousExperiment = computed(() => {
            const current = experiments.value.findIndex(e => e.Current);
            if (current > 0)
                return experimentRoute(router.currentRoute.params.projectId, experiments.value[current - 1].Id);
            return "";
        });
        const nextExperiment = computed(() => {
            const current = experiments.value.findIndex(e => e.Current);
            if (current < experiments.value.length - 1)
                return experimentRoute(router.currentRoute.params.projectId, experiments.value[current + 1].Id);
            return "";
        });
        const isExactOrOmni2Exp = computed(() => {
            var _a;
            return ((_a = router.currentRoute) === null || _a === void 0 ? void 0 : _a.path.toLowerCase().includes("exact")) ||
                router.currentRoute.name === "well-plate-react";
        });
        const updateExperiment = () => {
            if (props.experimentId && !window.IS_APP) {
                experimentLink.value = `/project/${router.currentRoute.params.projectId}/experiments`;
            }
            else {
                experimentLink.value = "";
            }
        };
        const getPrevAndNextExperiment = async () => {
            try {
                if (router.currentRoute.name === omni3WellRoute &&
                    props.omniExperimentId) {
                    const { data } = await Omni3v1ExperimentsRepository.getPrevAndNextWellExperiments(props.omniExperimentId, props.experimentId);
                    experiments.value = data;
                }
                else {
                    const { data } = (await NavigationRepository.getExperiments(props.experimentId));
                    experiments.value = data;
                }
                const current = experiments.value.find(item => item.Id === props.experimentId);
                if (current)
                    selectedExperimentName.value = current === null || current === void 0 ? void 0 : current.Name;
            }
            catch (error) {
                toastr.error("Something went wrong with fetching experiment list");
            }
        };
        const select = (name) => {
            const current = experiments.value.find(item => item.Name === name);
            if (current) {
                window.location.href = experimentRoute(router.currentRoute.params.projectId, current.Id);
            }
        };
        const togglePanel = () => {
            isWellplatePanelVisible.value = !isWellplatePanelVisible.value;
        };
        const closePanel = (e) => {
            if (e.target.id !== "toggleButton" &&
                e.target.parentNode.id !== "toggleButton") {
                isWellplatePanelVisible.value = false;
            }
        };
        watch(() => props.experimentId, () => {
            if (props.experimentId && router.currentRoute.name !== omni3WellRoute) {
                getPrevAndNextExperiment();
            }
        });
        watch(() => props.omniExperimentId, () => {
            if (props.omniExperimentId && props.experimentId) {
                getPrevAndNextExperiment();
            }
        });
        onBeforeUpdate(() => updateExperiment());
        onMounted(async () => {
            updateExperiment();
            if (props.experimentId && router.currentRoute.name !== omni3WellRoute) {
                await getPrevAndNextExperiment();
            }
        });
        return {
            breadCrumbs,
            experiments,
            experimentLink,
            experimentsNavOptions,
            previousExperiment,
            nextExperiment,
            selectedExperimentName,
            isExactOrOmni2Exp,
            isWellplatePanelVisible,
            select,
            closePanel,
            togglePanel
        };
    }
});
