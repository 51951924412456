//* This file defines the base configuration for tailwindcss that is reused throught the codebase for each project

/* eslint-disable @typescript-eslint/no-var-requires */
//default config https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
const { fontFamily, spacing } = require("tailwindcss/defaultTheme");

module.exports = {
  mode: "jit",
  purge: {
    // Change enabled to false for faster build time
    enabled: true,
    // enable only in production
    // enabled: process.env.NODE_ENV === "production",
    content: [
      //! The relative paths are evaluated from the actual tailwind.config.json which imports this file
      // Ignore purging classes from files containing the "TailwindIgnore" substring
      "./src/**/!(*TailwindIgnore*).{vue,html,js,ts}",
      "./node_modules/@axisvue/**/src/**/!(*TailwindIgnore*).{vue,ts}",
      "./index.html",
      "./public/index.html"
    ],
    // Ignore purging classes which we override, which come from libraries
    safelist: [
      "leaflet-tile",
      "mdi-icon",
      "mdi-fill",
      "mdi-up",
      "mdi-right",
      "mdi-down",
      "mdi-left",
      "vue-tooltip",
      "vue-tooltip.vue-tooltip-hidden",
      "vue-tooltip .vue-tooltip-content",
      "vue-tooltip .tooltip-arrow",
      "vue-tooltip[x-out-of-boundaries]",
      'vue-tooltip[x-placement^="bottom"]',
      'vue-tooltip[x-placement^="bottom"] .tooltip-arrow',
      'vue-tooltip[x-placement^="top"]',
      'vue-tooltip[x-placement^="top"] .tooltip-arrow',
      'vue-tooltip[x-placement^="right"]',
      'vue-tooltip[x-placement^="right"] .tooltip-arrow',
      'vue-tooltip[x-placement^="left"]',
      'vue-tooltip[x-placement^="left"] .tooltip-arrow'
    ]
  },
  theme: {
    extend: {
      boxShadow: {
        "3xl": "0px 1px 10px 2px rgba(0,0,0,0.20)",
        outline: '0 0 0 1px rgba(theme("colors.signal.base"),1)',
        "signal-base": '0 0 0 3px rgba(theme("colors.signal.base"), 0.5)'
      },
      colors: {
        transparent: "transparent",
        current: "currentColor",
        black: "#000",
        white: "#fff",
        gray: {
          100: "#f7fafc",
          200: "#edf2f7",
          300: "#e2e8f0",
          400: "#cbd5e0",
          500: "#a0aec0",
          600: "#718096",
          700: "#4a5568",
          800: "#2d3748",
          900: "#1a202c"
        },
        red: {
          100: "#fff5f5",
          200: "#fed7d7",
          300: "#feb2b2",
          400: "#fc8181",
          500: "#f56565",
          600: "#e53e3e",
          700: "#c53030",
          800: "#9b2c2c",
          900: "#742a2a"
        },
        yellow: {
          100: "#fffff0",
          200: "#fefcbf",
          300: "#faf089",
          400: "#f6e05e",
          500: "#ecc94b",
          600: "#d69e2e",
          700: "#b7791f",
          800: "#975a16",
          900: "#744210"
        },
        green: {
          100: "#f0fff4",
          200: "#c6f6d5",
          300: "#9ae6b4",
          400: "#68d391",
          500: "#48bb78",
          600: "#38a169",
          700: "#2f855a",
          800: "#276749",
          900: "#22543d"
        },
        teal: {
          100: "#e6fffa",
          200: "#b2f5ea",
          300: "#81e6d9",
          400: "#4fd1c5",
          500: "#38b2ac",
          600: "#319795",
          700: "#2c7a7b",
          800: "#285e61",
          900: "#234e52"
        },
        indigo: {
          100: "#ebf4ff",
          200: "#c3dafe",
          300: "#a3bffa",
          400: "#7f9cf5",
          500: "#667eea",
          600: "#5a67d8",
          700: "#4c51bf",
          800: "#434190",
          900: "#3c366b"
        },
        purple: {
          100: "#faf5ff",
          200: "#e9d8fd",
          300: "#d6bcfa",
          400: "#b794f4",
          500: "#9f7aea",
          600: "#805ad5",
          700: "#6b46c1",
          800: "#553c9a",
          900: "#44337a"
        },
        pink: {
          100: "#fff5f7",
          200: "#fed7e2",
          300: "#fbb6ce",
          400: "#f687b3",
          500: "#ed64a6",
          600: "#d53f8c",
          700: "#b83280",
          800: "#97266d",
          900: "#702459"
        },
        orange: {
          100: "#FBEEDC",
          200: "#F8D9AE",
          300: "#F8C47B",
          400: "#F1B560",
          500: "#EDA949",
          600: "#D5881B",
          700: "#A86506",
          800: "#744400",
          900: "#5B2C00"
        },
        blue: {
          100: "#EDF7FE",
          200: "#C5E0F7",
          300: "#9CCBF2",
          400: "#6CABEA",
          500: "#4A8FDE",
          600: "#277DCE",
          700: "#236AAF",
          800: "#2C4F89",
          900: "#27416E"
        },
        brand: {
          orange: "#FA911E",
          "bright-teal": "#0096AA",
          navy: "#071624"
        },
        signal: {
          low: "#8D9DA0",
          base: "#008394",
          high: "#005F6B",
          warning: "#A72020"
        },
        "signal-alt": {
          low: "#B2A99F",
          base: "#FA911E",
          high: "#D86E05"
        },
        control: {
          low: "#A1A1A1",
          base: "#757575",
          high: "#1C1C1C"
        },
        surface: {
          high: "#FFFFFF",
          info: "#F4F4F4",
          low: "#DDDDDD",
          danger: "#FFEBEB",
          warning: "#FFF7EB",
          modal: "#071624b3"
        },
        channel: {
          brightfield: "#4A8FDE",
          green: "#48BB78",
          red: "#F56565"
        },
        token: {
          red: "#F56565",
          orange: "#EDA949",
          yellow: "#ECC94B",
          green: "#68D391",
          teal: "#81E6D9",
          blue: "#4A8FDE",
          "dark-blue": "#236AAF",
          purple: "#9F7AEA",
          pink: "#ED64A6",
          neutral: "#C8C8C8"
        }
      },
      spacing: {
        1: "0.25rem",
        1.5: "0.375rem",
        2: "0.5rem",
        2.5: "0.625rem",
        3.5: "0.875rem",
        4: "1rem",
        10: "2.5rem",
        12: "3rem",
        26: "6.5rem",
        80: "20rem",
        96: "24rem"
      },
      padding: {
        0.5: "0.125rem",
        2.5: "0.625rem"
      },
      width: {
        14: "3.5rem",
        15: "3.75rem",
        23: "5.75rem",
        fit: "fit-content",
        max: "max-content"
      },
      maxWidth: {
        "7xl": "80rem",
        "8xl": "100rem"
      },
      height: {
        7: "1.75rem",
        "4/5": "80%",
        "4.5/5": "90%",
        max: "max-content"
      },
      maxHeight: {
        ...spacing
      },
      borderRadius: {
        xl: "1rem",
        "2xl": "2rem",
        "3xl": "4rem"
      },
      fontFamily: {
        sans: ["IBM Plex", ...fontFamily.sans]
      },
      fontWeight: {
        medium: 600
      },
      fontSize: {
        xs: "0.75rem",
        sm: "0.875rem",
        base: "1rem",
        "base-lg": "1.0625rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
        "2xs": "0.65rem",
        "3xs": "0.5rem"
      },
      zIndex: {
        1: "1",
        2: "2"
      },
      inset: {
        "1/2": "50%",
        full: "100%"
      },
      opacity: {
        38: "0.38"
      },
      cursor: {
        grab: "grab",
        grabbing: "grabbing"
      }
    },
    container: {
      center: true
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1537px"
    },
    minWidth: {
      0: "0",
      "1/4": "25%",
      "1/2": "50%",
      "3/4": "75%",
      full: "100%"
    }
  },
  variants: {
    boxShadow: ["responsive", "hover", "focus", "group-hover"]
  },
  corePlugins: {},
  plugins: [
    require("@tailwindcss/forms")({
      /**
       * Use `strategy: "class"` to stick with  only class-based styles, without global styles.
       * More information: https://github.com/tailwindlabs/tailwindcss-forms#using-only-global-styles-or-only-classes
       */
      strategy: "class"
    }),
    require("@tailwindcss/line-clamp")
  ]
};
