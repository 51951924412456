import { defineComponent, ref, watch } from "vue";
export default defineComponent({
    name: "AxionInputBase",
    props: {
        value: {
            required: true,
            type: null,
            validator: (value) => typeof value === "string" ||
                typeof value === "object" ||
                typeof value === "number" ||
                typeof value === "boolean" ||
                value === null ||
                value === undefined
        },
        inputClass: {
            type: Array,
            required: false,
            default: () => []
        },
        inputWrapperClass: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    setup(props) {
        /**
         * VueFormulate package has a limitation that when the `value` prop is updated from the parent component, using `:value="props.value"` will not work.
         * The only way it works correctly is when value is bind with v-model.
         * Since using `v-model="props.value"` is not recommended as it will result in a Vue error indicating that the prop cannot be directly mutated,
         * we use a local variable `localValue` and bind it to the v-model directive.
         */
        const localValue = ref(props.value);
        watch(() => props.value, value => {
            localValue.value = value;
        });
        return { localValue };
    }
});
