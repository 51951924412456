import BaseLayout from "@/shared/components/layout/BaseLayout.vue";
import { computed, defineComponent, onMounted, provide, ref, watch } from "vue";
import CalendarSettings from "./settings/CalendarSettings.vue";
import setup from "./_providers/setup";
import StateInjection from "./_providers/types/StateInjection";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import CloudCalendar from "./CloudCalendar.vue";
import { useRegisteredDevices } from "@/shared/providers/withRegisteredDevices/withRegisteredDevices";
import { AxionInputSelect } from "@axisvue/forms";
import { DeviceOptions } from "./shared/enums";
import DeviceType from "@/types/DeviceType";
import EventOverviewDialog from "./components/EventOverviewDialog.vue";
export default defineComponent({
    components: {
        BaseLayout,
        WrapperLoading,
        CloudCalendar,
        CalendarSettings,
        AxionInputSelect,
        EventOverviewDialog
    },
    setup() {
        const state = setup();
        const eventsFetching = ref(true); // on page load
        const initInProgress = ref(false);
        const calendarTitle = ref(new Date().toString());
        /* triggers open/close of event info dialog */
        const showExperimentOverviewDialog = ref(false);
        /* template ref to cloud calendar to enable size updates */
        const cloudCalendarRef = ref(null);
        /* contains the ICloudCalendarEvent.ts structure after click on event (selection) */
        const experimentInfo = ref();
        const timezoneOffsetInMinutes = ref(0);
        const { registeredDevices, fetchDeviceTimezoneOffset, fetchingDevices } = useRegisteredDevices();
        const loading = computed(() => initInProgress.value || fetchingDevices.value);
        const selectedDevice = ref({
            id: 0,
            deviceDisplayName: "",
            firmwareNumber: "",
            serialNumber: "",
            type: 0,
            hasRobotArmAttached: false,
            lastExperimentOwnerEmail: "",
            lastExperimentIsFinished: false
        });
        const selectedDeviceOption = ref(DeviceOptions.All);
        const isTimeFormat12 = ref(false);
        const filteredDeviceOptions = computed(() => {
            const options = [DeviceOptions.All, DeviceOptions.Active];
            const availableLuxDevices = registeredDevices.value.some(r => r.type === DeviceType.Lux);
            const availableOmniDevices = registeredDevices.value.some(r => r.type === DeviceType.Omni);
            if (availableOmniDevices) {
                options.push(DeviceOptions.Omni);
            }
            if (availableLuxDevices) {
                options.push(DeviceOptions.Lux);
            }
            return options;
        });
        const filteredDevices = computed(() => {
            switch (selectedDeviceOption.value) {
                case DeviceOptions.Active:
                    return registeredDevices.value.filter(r => !r.lastExperimentIsFinished);
                case DeviceOptions.Omni:
                    return registeredDevices.value.filter(r => r.type === DeviceType.Omni);
                case DeviceOptions.Lux:
                    return registeredDevices.value.filter(r => r.type === DeviceType.Lux);
                default:
                    return registeredDevices.value;
            }
        });
        function openExperimentOverviewDialog(e) {
            var _a;
            showExperimentOverviewDialog.value = true;
            experimentInfo.value = e;
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarSize();
        }
        function closeExperimentOverviewDialog() {
            var _a;
            showExperimentOverviewDialog.value = false;
            experimentInfo.value = null;
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarSize();
        }
        async function selectDevice(serialNumber) {
            var _a;
            eventsFetching.value = true;
            closeExperimentOverviewDialog();
            const tempDevice = registeredDevices.value.find(d => d.serialNumber === serialNumber);
            if (tempDevice) {
                selectedDevice.value = tempDevice;
                await state.selectDevice(tempDevice);
                timezoneOffsetInMinutes.value =
                    (_a = (await fetchDeviceTimezoneOffset(tempDevice.id))) !== null && _a !== void 0 ? _a : 0;
            }
            eventsFetching.value = false;
        }
        function selectDeviceOption(option) {
            selectedDeviceOption.value = option;
        }
        async function initSelectDevice() {
            // experiment should be running.
            let initTempDevice = filteredDevices.value.find(rd => rd.lastExperimentOwnerEmail === window.GLOBAL_CURRENT_USER_EMAIL &&
                !rd.lastExperimentIsFinished);
            if (!initTempDevice) {
                initTempDevice = filteredDevices.value[0];
            }
            await selectDevice(initTempDevice.serialNumber);
        }
        async function calendarInit() {
            initInProgress.value = true;
            if (registeredDevices.value.length) {
                await initSelectDevice();
            }
            initInProgress.value = false;
        }
        watch(() => registeredDevices, () => calendarInit(), { deep: true });
        watch(() => filteredDevices, async () => {
            // select init device after change in device family dropdown.
            if (registeredDevices.value.length) {
                await initSelectDevice();
            }
        }, { deep: true });
        onMounted(() => calendarInit());
        provide(StateInjection, state);
        return {
            calendarTitle,
            state,
            showExperimentOverviewDialog,
            closeExperimentOverviewDialog,
            experimentInfo,
            selectDevice,
            filteredDeviceOptions,
            selectDeviceOption,
            DeviceOptions,
            openExperimentOverviewDialog,
            loading,
            selectedDevice,
            registeredDevices,
            isTimeFormat12,
            eventsFetching,
            timezoneOffsetInMinutes,
            selectedDeviceOption,
            filteredDevices,
            cloudCalendarRef
        };
    }
});
