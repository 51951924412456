import { ref } from "vue";
import { setupProvider } from "@/globals/setupProvider";
import { useActiveExperiments } from "@/shared/providers/withActiveExperiments/withActiveExperiments";
import { useReservations } from "@/shared/providers/withReservations/withReservations";
import { CalendarEventType } from "@axisvue/calendar";
export function withOccurrences() {
    const occurrences = ref([]);
    const { fetchActiveExperiments, activeExperiments } = useActiveExperiments();
    const { fetchReservations, reservations } = useReservations();
    // transform reservation/experiment to occurrence readable to withCalendarEvents.ts
    // check App.vue for usages
    function transformObjectToOccurrence(object) {
        var _a, _b;
        const objectIsExperiment = object.experimentId ? true : false;
        return {
            id: objectIsExperiment ? object.experimentId : object.reservationId,
            type: objectIsExperiment
                ? CalendarEventType.Experiment
                : CalendarEventType.Reservation,
            name: object.name,
            startOffset: object.startOffset,
            eventsInterval: object.sampleInterval,
            eventsDuration: object.estimateSampleDuration,
            numberOfEvents: object.estimateNumberOfSamples,
            numberOfCompletedEvents: (_a = object.previouslyCompletedScans) !== null && _a !== void 0 ? _a : 0,
            isOmni3: objectIsExperiment ? object.isOmni3 : undefined,
            owner: object.owner,
            projectDetails: (_b = object.projectDetails) !== null && _b !== void 0 ? _b : undefined
        };
    }
    function addOccurence(newOccurrence) {
        const index = occurrences.value.findIndex(o => o.id === newOccurrence.id);
        if (index > -1) {
            occurrences.value[index] = { ...newOccurrence };
        }
        else {
            occurrences.value.push(newOccurrence);
        }
    }
    async function removeOccurence(id) {
        const index = occurrences.value.findIndex(o => o.id === id);
        if (index > -1) {
            occurrences.value.splice(index, 1);
        }
    }
    // api call to get all reservations/experiments from DB
    async function fetchAllOccurrences(deviceId, deviceType) {
        const fetchedOccurrences = [];
        await Promise.all([
            fetchActiveExperiments(deviceId, 28, deviceType).then(experiments => experiments === null || experiments === void 0 ? void 0 : experiments.forEach(experiment => {
                const temp = transformObjectToOccurrence(experiment);
                if (temp) {
                    fetchedOccurrences.push(temp);
                }
            })),
            fetchReservations(deviceId).then(reservations => {
                reservations === null || reservations === void 0 ? void 0 : reservations.forEach(reservation => {
                    const temp = transformObjectToOccurrence(reservation);
                    if (temp) {
                        fetchedOccurrences.push(temp);
                    }
                });
            })
        ]);
        occurrences.value = fetchedOccurrences;
    }
    return {
        occurrences,
        activeExperiments,
        reservations,
        fetchAllOccurrences,
        removeOccurence,
        addOccurence,
        transformObjectToOccurrence
    };
}
export const { provide: provideOccurrences, use: useOccurrences } = setupProvider();
