/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-box-multiple-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11h-3v3h-2v-3h-3V9h3V6h2v3h3m2-5v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12c1.11 0 2-.89 2-2V4a2 2 0 00-2-2M4 6H2v14a2 2 0 002 2h14v-2H4V6z"/>'
  }
})
