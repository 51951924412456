/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'locker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H8a2 2 0 01-2-2V4a2 2 0 012-2m0 2v16h8V4H8m2 9h2v4h-2v-4m0-7h4v1.5h-4V6m0 3h4v1.5h-4V9z"/>'
  }
})
