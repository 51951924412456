<template>
  <router-link to="/Project/Create">
    <button
      class="btn-solid my-2 flex items-center rounded border border-gray-500 text-sm text-gray-700 hover:bg-gray-100"
    >
      <b-icon name="plus" class="mr-1 w-5 text-gray-700" /> New project
    </button>
  </router-link>
</template>
<script>
export default {};
</script>
