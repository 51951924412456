/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exclamation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 4h2v11h-2V4zm2 14v2h-2v-2h2z"/>'
  }
})
