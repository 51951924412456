/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rectangle-mapview-vector': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 4h6v2h8V4h6v6h-2v4h2v6h-6v-2H8v2H2v-6h2v-4H2V4m14 6V8H8v2H6v4h2v2h8v-2h2v-4h-2M4 6v2h2V6H4m14 0v2h2V6h-2M4 16v2h2v-2H4m14 0v2h2v-2h-2z"/>'
  }
})
