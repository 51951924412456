/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-bold-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 12A10 10 0 0112 2a10 10 0 0110 10 10 10 0 01-10 10A10 10 0 012 12m15 0l-5-5v3H8v4h4v3l5-5z"/>'
  }
})
