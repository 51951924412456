<template>
  <b-icon
    name="view-list"
    :class="[
      'cursor-pointer hover:text-gray-600',
      active ? 'text-control-high' : 'text-gray-500'
    ]"
    aria-hidden="true"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>
