/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loading': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 4V2A10 10 0 002 12h2a8 8 0 018-8z"/>'
  }
})
