/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-bold-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 12a10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2a10 10 0 0110 10M7 12l5 5v-3h4v-4h-4V7l-5 5z"/>'
  }
})
