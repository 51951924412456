const endPoints = {
    projects: "/api/projects/v1",
    devices: "/api/devices/v1",
    experiments: "/api/experiments",
    licenses: "/api/licenses/v1",
    manage: "/api/manage/v1",
    tenant: "/api/tenant/v1",
    user: "/api/user/v1",
    dataPlans: "/api/userselectedplans",
    scanPlan: "/api/scanPlan"
};
export default endPoints;
