/**
 * Pluralizes english words (adds "s" at the end)
 * @example pluralize("item", 1) // returns "1 item"
 * @example pluralize("item", 2) // returns "2 items"
 * @param label Text to get pluralized
 * @param value Amount
 */
function pluralize(label, value) {
    const str = `${value} ${label}`;
    if (value === 1)
        return str;
    return str + "s";
}
export default pluralize;
