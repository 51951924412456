import blobStorageRepository from "../shared/blobStorage/blobStorageRepository";
import settingsRepository from "./settings/settingsRepository";
import resultsRepository from "./results/resultsRepository";
import exportRepository from "./export/exportRepository";
import experimentsRepository from "./experiments/experimentsRepository";
export default {
    export: exportRepository,
    settings: settingsRepository,
    blobStorage: blobStorageRepository,
    results: resultsRepository,
    experiments: experimentsRepository
};
