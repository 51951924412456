import { getExponent } from "@axisvue/utils";
export function formatConcentration(c) {
    if (c === -1)
        return "-";
    if (c === 0)
        return c;
    const e = c.toExponential(2);
    const exp = e.split("+")[1];
    const no = e.split("e")[0];
    if (exp === undefined)
        return no;
    const concentration = no + " * 10" + getExponent(exp);
    return concentration;
}
export default formatConcentration;
