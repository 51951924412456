/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27 19.72 19.73-1.27 1.27M19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 00-2-2 2 2 0 00-2 2v.29c-.61.18-1.2.45-1.74.8L19 15.8M12 23a2 2 0 002-2h-4a2 2 0 002 2z"/>'
  }
})
