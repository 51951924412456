import Repository from "@/api/repository";
const resource = `/reservations`;
export default {
    getReservations(deviceId) {
        return Repository.get(`${resource}/get/all`, {
            params: {
                deviceId
            }
        });
    },
    createReservation(reservation) {
        return Repository.post(`${resource}/create`, {
            params: {
                reservation
            }
        });
    },
    editReservation(reservation) {
        return Repository.put(`${resource}/edit/${reservation.ReservationId}`, {
            params: {
                ...reservation
            }
        });
    },
    cancelReservation(reservationId) {
        return Repository.delete(`${resource}/delete/${reservationId}`);
    }
};
