import "@fullcalendar/core/vdom";
import { CalendarEventType, CalendarView, withCalendarConfiguration } from "@axisvue/calendar";
import FullCalendar from "@fullcalendar/vue";
import { defineComponent, nextTick, onUnmounted, ref, watch } from "vue";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import { setVisibilityOfCalendarEventsBasedOnSelection } from "./utils/setVisibilityOfCalendarEventsBasedOnSelection";
import { useCreateReservation } from "./_providers/withCreateReservation";
import { useCalendarEvents } from "./_providers/withCalendarEvents";
import { EventType } from "./shared/ICloudCalendarEvent";
import { setupAndProvide } from "./_providers/setupAndProvide";
export default defineComponent({
    components: {
        FullCalendar,
        WrapperLoading
    },
    props: {
        editingMode: {
            type: Boolean,
            required: true
        },
        selectedDevice: {
            type: Object,
            required: true
        },
        isTimeFormat12: {
            type: Boolean,
            required: true
        },
        eventsFetched: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const { occurrenceEvents, sampleEvents, busySlotEvents } = useCalendarEvents();
        const { newReservation } = useCreateReservation();
        const { fetchDataAndCalculateCalendarEvents } = setupAndProvide();
        const calendarEventInfo = ref({});
        const slotDuration = ref(15);
        const { configuration, calendarInstance, updateAllEvents } = withCalendarConfiguration({
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            viewDidMount,
            headerToolbar: {
                left: "",
                center: "",
                right: `now prev ${CalendarView.Monthly},${CalendarView.Weekly},${CalendarView.Daily} next`
            },
            editable: true,
            eventOverlap: function (stillEvent, _) {
                // for when scheduling new experiment/reservation
                return (stillEvent.extendedProps.eventType !== CalendarEventType.BusySlot);
            },
            eventContent: function (info) {
                const eventTitle = info.event.title;
                const eventTime = info.event.start;
                let displayTime;
                if (!props.isTimeFormat12) {
                    displayTime = eventTime === null || eventTime === void 0 ? void 0 : eventTime.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    });
                }
                else {
                    displayTime = eventTime === null || eventTime === void 0 ? void 0 : eventTime.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit"
                    });
                }
                // custom HTML as to what comes in the body of event
                if (props.editingMode ||
                    info.event.extendedProps.eventType === EventType.BusySlot) {
                    return {
                        html: '<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><b>' +
                            eventTitle +
                            "</div>"
                    };
                }
                else {
                    return {
                        html: '<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><b>' +
                            displayTime +
                            "</b> " +
                            eventTitle +
                            "</div>"
                    };
                }
            },
            slotDuration: "00:15:00",
            validRange: {
                // the calendar shows data for 2 months ahead, and 2 months back
                start: new Date(new Date().getFullYear(), new Date().getMonth() - 2, // 2 month prev
                new Date().getDate()),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 2, // 2 months ahead
                new Date().getDate())
            },
            eventDrop: info => {
                // when to allow/not allow to drag and drop event
                if (info.event.start) {
                    if (info.event.start.toISOString() >= new Date().toISOString()) {
                        emit("updateReservationStartTime", info.event.start.toISOString());
                    }
                    else {
                        info.revert();
                        // if reservation to be scheduled in the past -> revert!
                    }
                }
            },
            dateClick: info => {
                var _a;
                // click changed start time of editing reservation
                // do not allow click in the past
                if (props.editingMode) {
                    if (new Date(info.dateStr).toISOString() >= new Date().toISOString()) {
                        emit("updateReservationStartTime", info.dateStr);
                    }
                }
                const events = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.getEvents();
                const isOverlapping = events === null || events === void 0 ? void 0 : events.filter(e => e.start &&
                    e.end &&
                    e.start <= new Date(info.dateStr) &&
                    e.end > new Date(info.dateStr) &&
                    e.extendedProps.eventType === CalendarEventType.BusySlot);
                const beforePast = new Date(info.dateStr).toISOString() >= new Date().toISOString();
                if (!(isOverlapping === null || isOverlapping === void 0 ? void 0 : isOverlapping.length) && beforePast) {
                    emit("openReservationOverviewDialog", { start: info.dateStr });
                }
            },
            eventClick: function (info) {
                if (props.editingMode) {
                    return;
                }
                // clicking on specific event of calendar
                if (info.event.extendedProps.hiddenUrl === undefined) {
                    emit("editReservation", info.event.extendedProps.relationId);
                }
                else {
                    emit("openExperimentOverviewDialog", info.event);
                }
                calendarEventInfo.value = {};
            },
            // This method is required in the unified calendars
            eventDataTransform: (e) => {
                return { ...e };
            }
        });
        /**
         * The calendar adjustes its size whenever this function is called - show/hide event dilog.
         * The timeout is needed as the inner size of the calendar also has to be adjusted.
         */
        function updateCalendarSize() {
            if (calendarInstance) {
                nextTick(() => {
                    var _a;
                    (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.updateSize();
                });
            }
        }
        /**
         * Interval for every 60 seconds, refetch data from backend,
         * thus the calendar will update when experiment stopped, continued, finished or started.
         */
        const interval = setInterval(async () => {
            if (calendarInstance.value) {
                await fetchDataAndCalculateCalendarEvents(props.selectedDevice);
                calendarInstance.value.refetchEvents();
            }
        }, 60000);
        /**
         * Sets the slot duration of the calendar whenever new events come.
         */
        function updateSlotDuration() {
            var _a, _b, _c;
            const scrollTime = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.getOption("scrollTime");
            const scansOnDevice = sampleEvents.value.filter(sc => { var _a; return ((_a = sc.extendedProps) === null || _a === void 0 ? void 0 : _a.deviceId) === props.selectedDevice.id; });
            if (scansOnDevice && scansOnDevice.length) {
                const scansDurations = scansOnDevice.map(sc => Math.round((new Date(sc.end).getTime() -
                    new Date(sc.start).getTime()) /
                    60000));
                let min = Math.min(...scansDurations);
                switch (true) {
                    case min <= 12: {
                        min = 5;
                        break;
                    }
                    case min <= 17: {
                        min = 15;
                        break;
                    }
                    default: {
                        min = 30;
                        break;
                    }
                }
                if (min < slotDuration.value || min > slotDuration.value) {
                    slotDuration.value = min;
                    (_b = calendarInstance.value) === null || _b === void 0 ? void 0 : _b.setOption("slotDuration", "00:" + ("0" + slotDuration.value).slice(-2) + ":00");
                    const timeReservation = new Date(newReservation.value.startOffset);
                    const targetTime = {
                        hours: timeReservation.getHours(),
                        minutes: timeReservation.getMinutes()
                    };
                    (_c = calendarInstance.value) === null || _c === void 0 ? void 0 : _c.scrollToTime(scrollTime !== null && scrollTime !== void 0 ? scrollTime : targetTime);
                }
            }
        }
        /**
         * Based on selection of device, the calendar will show/hide
         * linked events (to device id)
         */
        function updateCalendarView() {
            var _a, _b;
            if (calendarInstance.value) {
                const calendarEvents = setVisibilityOfCalendarEventsBasedOnSelection(props.selectedDevice, sampleEvents.value
                    .concat(busySlotEvents.value)
                    .concat(occurrenceEvents.value), (_b = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view.type) !== null && _b !== void 0 ? _b : "");
                if (calendarInstance.value)
                    updateAllEvents(calendarEvents);
                updateSlotDuration();
            }
        }
        /**
         * Sets the slot label format of calendar when time format value is changed from provider.
         * For more info - check withTimeFormat.ts
         */
        function setOptionSlotLabelFormat() {
            var _a, _b, _c;
            (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.setOption("slotLabelFormat", {
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                // AM/PM/24
                hour12: props.isTimeFormat12
            });
            // locale - for 12am - 00 (else shows 24)
            if (props.isTimeFormat12) {
                (_b = calendarInstance.value) === null || _b === void 0 ? void 0 : _b.setOption("locale", "en-us");
            }
            else {
                (_c = calendarInstance.value) === null || _c === void 0 ? void 0 : _c.setOption("locale", "en-gb");
            }
        }
        /**
         * Every time calendar view type changes - montly/weekly/daily
         */
        function viewDidMount() {
            updateCalendarView();
        }
        watch(() => props.eventsFetched, () => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            updateSlotDuration();
            updateCalendarView();
        });
        watch(() => props.isTimeFormat12, 
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        () => {
            setOptionSlotLabelFormat();
            updateCalendarView();
        });
        watch(() => { var _a, _b; return (_b = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view) === null || _b === void 0 ? void 0 : _b.title; }, () => { var _a; return emit("updateCalendarTitle", (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view.title); });
        watch(() => calendarInstance.value, () => updateCalendarView());
        onUnmounted(() => {
            clearInterval(interval);
        });
        return {
            configuration,
            updateCalendarView,
            updateCalendarSize
        };
    }
});
