/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cheetah': {
    width: 24,
    height: 12,
    viewBox: '0 0 24 12',
    data: '<path pid="0" d="M23.558 2.078c-.451-.374-.156-.919-.902-1.075C22.118.89 22.015.941 22 .977c-.008-.125-.068-.66-.608-.3 0 0-.295-.077-.25.295 0 0-.606.187-1.089.545-.482.357-2.352 1.152-5.013.482-2.664-.67-4.983-1.572-6.96-.201 0 0-2.024.996-2.96 3.098-.933 2.102-2.133 2.74-3.816 2.71-1.682-.032-1.137 1.682 0 1.027 1.138-.655 2.57.077 3.956-2.086C6.645 4.382 6.554 3.713 7.425 3.23c0 0 .125.84 1.652 1.697 0 0-.778.483-.624.747.156.264.607.902.607.902s-.389.264-.219.5c.17.232 1.323 1.386 1.433 1.588.108.202.608 1.152.934.965 0 0 .326.405.545.25 0 0 .576-.077.108-.67-.468-.593-.576-.684-.576-.684s.45-.545-.747-1.728c0 0 1.963-1.263 1.76-1.728-.202-.468 1.852 2.133 2.848 1.416 0 0-.84 1.37-.933 1.915-.094.545-.646 1.344-1.479 1.385 0 0-.809-.017-.607 1.464 0 0 .264.374.406.093.139-.28.312-.684.7-.856.39-.17 1.308-.437 1.745-1.416.437-.982 1.479-1.776 1.479-1.776s.902 1.759.902 1.915c0 .156-.746.655-.809.996 0 0-.746.576-.513.763 0 0 .093.468.513.14.42-.327.795-.625 1.044-.764.25-.14.281-.343.576-.67.295-.326.108-.67-.125-1.137-.232-.468-.343-1.589-.343-1.589s1.464-.346 1.714-.95c.25-.608 1.058-1.666 1.54-2.055.483-.389 1.448-.499 1.635-.7 0 0 .53.374.746-.14l-.093-.108s.312-.093.312-.312c.002.003.456-.233.002-.605z"/>'
  }
})
