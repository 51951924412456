import Repository from "@/api/repository";
const resourceNotifications = `/notification/test`;
export default {
    sendTestNotification(notificationType, email) {
        const params = {
            notificationType: notificationType,
            email: email || "" // Provide an empty string if email is null
        };
        return Repository.get(`${resourceNotifications}`, {
            params
        });
    }
};
