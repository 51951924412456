import { computed, defineComponent } from "vue";
import { PROJECT_TYPES_COLORS } from "@/shared/providers/withProjects/constants/ProjectTypesColors";
export default defineComponent({
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const colors = computed(() => {
            return PROJECT_TYPES_COLORS[props.project.projectType];
        });
        return { colors };
    }
});
