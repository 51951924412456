import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const LicenseRepository = RepositoryFactory.license;
/**
 * Provider that handles global state for licenses.
 */
export function withLicense() {
    const loading = ref(false);
    const license = ref({
        demo: false,
        features: {
            scratch: false,
            colony: false,
            psc: false,
            confluence: false,
            organoids: false,
            fluoModule: false,
            belowHourIntervalSnapshotsOmni: false,
            belowMinuteIntervalSnapshots: false,
            allowImageStabilization: false
        }
    });
    const editLicense = async (licenseCode, license) => {
        try {
            await LicenseRepository.editLicense(licenseCode, license);
        }
        catch (error) {
            throw new Error("Something went wrong while editing the license");
        }
    };
    const cancelLicenseReservation = async (licenseCode) => {
        try {
            await LicenseRepository.cancelLicenseReservation(licenseCode);
        }
        catch (error) {
            throw new Error("Something went wrong while canceling the license");
        }
    };
    const blockLicense = async (licenseCode) => {
        try {
            await LicenseRepository.blockLicense(licenseCode);
        }
        catch (error) {
            throw new Error("Something went wrong while blocking the license");
        }
    };
    const unblockLicense = async (licenseCode) => {
        try {
            await LicenseRepository.unblockLicense(licenseCode);
        }
        catch (error) {
            throw new Error(error);
        }
    };
    const editLicenseFeature = async (license, licenseCode) => {
        loading.value = true;
        try {
            await LicenseRepository.editLicenseFeatures(license, licenseCode);
        }
        catch (error) {
            throw new Error("Something went wrong while editing the license features.");
        }
        finally {
            loading.value = false;
        }
    };
    const getLicenseFeatures = async (licenseCode) => {
        loading.value = true;
        try {
            const { data } = await LicenseRepository.getLicenseFeatures(licenseCode);
            license.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error("Something went wrong while fetching the license.");
        }
        finally {
            loading.value = false;
        }
    };
    return {
        license,
        loading,
        blockLicense,
        cancelLicenseReservation,
        editLicense,
        editLicenseFeature,
        getLicenseFeatures,
        unblockLicense
    };
}
// PROVIDER SETUP
export const { provide: provideLicense, use: useLicense } = setupProvider();
