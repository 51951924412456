import { reactive, ref, watch } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withSliceProperties(experimentId, currentScan) {
    const areSlicesFetched = ref(false);
    const isOmniNewCamera = ref(false);
    const sliceLevelProperties = reactive({
        ScanSlices: {},
        AreaSlices: {}
    });
    function setCameraType(slices) {
        const scans = Object.values(slices);
        const highContrast = 2;
        scans.forEach(scan => {
            if (scan) {
                Object.values(scan.LevelProperties).forEach((levelProp) => {
                    if (levelProp.contrastType === highContrast) {
                        isOmniNewCamera.value = true;
                    }
                });
            }
        });
    }
    async function fetchSliceProperties(scan) {
        areSlicesFetched.value = false;
        try {
            const { data } = await Omni3v1ExperimentsRepository.getSliceProperties(experimentId, scan);
            sliceLevelProperties.ScanSlices = data.ScanSlices;
            sliceLevelProperties.AreaSlices = data.AreaSlices;
        }
        catch (error) {
            console.warn("Error while fetching slices: ", error);
        }
        areSlicesFetched.value = true;
        setCameraType(sliceLevelProperties.ScanSlices);
    }
    watch(currentScan, currentScan => {
        if (experimentId !== "") {
            fetchSliceProperties(currentScan);
        }
    });
    return {
        sliceLevelProperties,
        fetchSliceProperties,
        areSlicesFetched,
        isOmniNewCamera
    };
}
export const { provide: provideSliceProperties, use: useSliceProperties } = setupProvider();
