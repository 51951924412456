/**
 * Type of the event that can be present in the calendar.
 */
export var CalendarEventType;
(function (CalendarEventType) {
    CalendarEventType["Background"] = "background";
    CalendarEventType["Scan"] = "scan";
    CalendarEventType["Experiment"] = "experiment";
    CalendarEventType["BusySlot"] = "busy-slot";
    CalendarEventType["Reservation"] = "reservation";
})(CalendarEventType || (CalendarEventType = {}));
