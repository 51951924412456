/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'white-balance-sunny': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.55 18.54l1.41 1.41 1.8-1.79-1.42-1.42M11 22.45h2V19.5h-2m1-14a6 6 0 00-6 6 6 6 0 006 6 6 6 0 006-6c0-3.32-2.69-6-6-6m8 7h3v-2h-3m-2.76 7.66l1.8 1.79 1.41-1.41-1.79-1.8m1.79-12.28l-1.41-1.41-1.8 1.79 1.42 1.42M13 .55h-2V3.5h2m-9 7H1v2h3m2.76-7.66l-1.8-1.79-1.41 1.41 1.79 1.8 1.42-1.42z"/>'
  }
})
