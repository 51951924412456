import { makeInjectionKey } from "@axisvue/utils";
import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
const DeviceRepository = RepositoryFactory.device;
/**
 * Provider that handles global state for available devices .
 */
export function withAvailableDevices() {
    const availableDevices = ref([]);
    const fetching = ref(false);
    async function fetchAvailableDevices() {
        fetching.value = true;
        try {
            const { data } = await DeviceRepository.getAvailableDevices();
            availableDevices.value = transformObjectKeysPascalToCamelCase(data);
            // Set global beamer config filter used for "What's new?" section.
            availableDevices.value.forEach(item => {
                if (item === null || item === void 0 ? void 0 : item.displayName) {
                    window.beamer_config.filter += `${item.displayName};`;
                }
            });
        }
        catch (error) {
            console.warn("Error while fetching available devices: ", error);
        }
        finally {
            fetching.value = false;
        }
    }
    return {
        availableDevices,
        fetching,
        fetchAvailableDevices
    };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing.
 * Use `provideAvailableDevices` and `useAvailableDevices` instead.
 */
export const AvailableDevicesInjectionKey = makeInjectionKey();
/**
 * Provides `AvailableDevices` to all descendants of a component.
 *
 * Use in conjunction with `useAvailableDevices`.
 */
export function provideAvailableDevices(availableDevices) {
    provide(AvailableDevicesInjectionKey, availableDevices);
}
/**
 * Injects `AvailableDevices` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideAvailableDevices`, otherwise an error will
 * be thrown
 */
export function useAvailableDevices() {
    const availableDevices = inject(AvailableDevicesInjectionKey);
    if (availableDevices === undefined)
        throw new Error("Available devices was not provided.");
    return availableDevices;
}
