/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-open': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 20H4a2 2 0 01-2-2V6c0-1.11.89-2 2-2h6l2 2h7a2 2 0 012 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z"/>'
  }
})
