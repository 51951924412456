import { LatLng, LatLngBounds } from "leaflet";
import ShapeEnum from "../../types/enums/ShapeEnum";
import { _getShapeById } from "./GetShapeById";
import { _getAllROIsInWell } from "@/pages/wellPlate/_providers/VirtualWellPlateShapeProvider/WellPlateShapesOperations/ROIOperations";
export function _getAllWellsIdsFromData(data) {
    return data.shapes.ids.filter(id => data.shapes.byId[id].type === ShapeEnum.WELL).length === 0
        ? data.shapes.ids.filter(id => data.shapes.byId[id].type === ShapeEnum.CIRCLE)
        : data.shapes.ids.filter(id => data.shapes.byId[id].type === ShapeEnum.WELL);
}
export function _getAllWellsFromData(data) {
    return _getAllWellsIdsFromData(data).map(id => data.shapes.byId[id]);
}
export function _wellContainsPoint(well, point) {
    const northEast = new LatLng(well.center.lng + well.radius, well.center.lat + well.radius);
    const southWest = new LatLng(well.center.lng - well.radius, well.center.lat - well.radius);
    return new LatLngBounds(southWest, northEast).contains(point);
}
export function _getWellByROI(data, roiId) {
    const roi = _getShapeById(data, roiId);
    return _getAllWellsFromData(data).find(well => _wellContainsPoint(well, roi.center));
}
export const _getWellsByROIs = (data, roiIds) => {
    const wells = [];
    roiIds.forEach(id => {
        const well = _getWellByROI(data, id);
        if (well && !wells.includes(well)) {
            wells.push(well);
        }
    });
    return wells;
};
export const _getPatternScanWells = (data, areas) => {
    const areasByWell = {};
    for (const id in areas) {
        const shape = _getShapeById(data, id);
        if ((shape === null || shape === void 0 ? void 0 : shape.type) === ShapeEnum.ROI) {
            const well = _getWellByROI(data, shape.id);
            if (well) {
                const areasInWell = _getAllROIsInWell(data, well.id).map(r => r.id);
                if (!areasByWell[well.id]) {
                    areasByWell[well.id] = {
                        ...areas[areasInWell[0]],
                        name: well.id,
                        rois: areasInWell
                    };
                }
            }
        }
    }
    return areasByWell;
};
