import Repository from "@/api/repository";
import { usersBase } from "./base";
const resource = `manage/v1/user`;
export default {
    getEmailsInTenant() {
        return Repository.get(`${usersBase}/GetEmailsInTenant`);
    },
    getLoggedInUser() {
        return Repository.get(`${resource}`);
    },
    editLoggedInUser(params) {
        const data = new URLSearchParams(params);
        return Repository.put(`${resource}`, data, {
            headers: { "content-type": "application/x-www-form-urlencoded" }
        });
    },
    getAvailableTenants() {
        return Repository.get(`${resource}/availableTenants`);
    },
    changeDefaultTenant(params) {
        const data = new URLSearchParams({
            __RequestVerificationToken: params.__RequestVerificationToken
        });
        return Repository.put(`${resource}/changeDefaultTenant?defaultTenantId=${params.tenantId}`, data, {
            headers: { "content-type": "application/x-www-form-urlencoded" }
        });
    },
    leaveOrganization(params) {
        const data = new URLSearchParams({
            __RequestVerificationToken: params.__RequestVerificationToken
        });
        return Repository.post(`${resource}/leaveOrganization?tenantId=${params.tenantId}`, data, {
            headers: { "content-type": "application/x-www-form-urlencoded" }
        });
    }
};
