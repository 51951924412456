/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 13v-2h8v2h-8m0 6v-2h4v2h-4m0-12V5h12v2H10M6 17h2.5L5 20.5 1.5 17H4V7H1.5L5 3.5 8.5 7H6v10z"/>'
  }
})
