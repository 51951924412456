/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'publish': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 4v2h14V4H5m0 10h4v6h6v-6h4l-7-7-7 7z"/>'
  }
})
