import { computed, defineComponent } from "vue";
export default defineComponent({
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const bgColor = computed(() => {
            const color = {
                Blue: "blue-400",
                BrightRed: "red-400",
                Brown: "red-600",
                DarkBlue: "blue-600",
                DarkGreen: "green-600",
                Green: "green-500",
                Magenta: "pink-600",
                Orange: "orange-400",
                Pink: "pink-400",
                Red: "red-400",
                Yellow: "yellow-400",
                LightGreen: "green-400",
                Purple: "purple-400"
            }[props.project.projectType];
            return "bg-" + color;
        });
        return { bgColor };
    }
});
