export function debouncePromise(func, waitMilliseconds = 50, isImmediate = false) {
    let timeoutId;
    return function (...args) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        return new Promise((resolve, reject) => {
            const doLater = function () {
                timeoutId = undefined;
                if (!isImmediate) {
                    try {
                        const result = func.apply(context, args);
                        resolve(result);
                    }
                    catch (error) {
                        reject(error);
                    }
                }
            };
            const shouldCallNow = isImmediate && timeoutId === undefined;
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(doLater, waitMilliseconds);
            if (shouldCallNow) {
                try {
                    const result = func.apply(context, args);
                    resolve(result);
                }
                catch (error) {
                    reject(error);
                }
            }
        });
    };
}
export default debouncePromise;
