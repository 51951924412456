<template>
  <ul class="flex">
    <button
      type="button"
      class="relative block w-10"
      :class="{ enabled: page != 1 }"
      :disabled="page == 1 || pages.length == 0"
      @click="setPageNumber(1)"
    >
      <span class="text-xl">&laquo;</span>
    </button>
    <button
      type="button"
      class="relative block w-10"
      :class="{ enabled: page != 1 }"
      :disabled="page == 1 || pages.length == 0"
      @click="page != 1 ? setPageNumber(page - 1) : setPageNumber(page)"
    >
      <span class="text-xl">&lsaquo;</span>
    </button>
    <button
      v-if="page > 3 && pages.length > 4"
      type="button"
      class="relative block w-10"
    >
      ...
    </button>
    <button
      v-for="(pageNumber, i) in pages.slice(
        page - offsetLeft,
        page + offsetRight
      )"
      :key="i"
      type="button"
      class="enabled"
      :class="{ '!bg-control-high !text-white': pageNumber === page }"
      @click="setPageNumber(pageNumber)"
    >
      {{ pageNumber }}
    </button>
    <button
      v-if="page < pages.length - 2 && pages.length > 4"
      type="button"
      class="relative block w-10"
    >
      ...
    </button>
    <button
      type="button"
      class="relative block w-10 border"
      :class="{ enabled: currentPage != pages.length && pages.length != 0 }"
      :disabled="page == pages.length || pages.length == 0"
      @click="
        page < pages.length ? setPageNumber(page + 1) : setPageNumber(page)
      "
    >
      <span class="text-xl">&rsaquo;</span>
    </button>
    <button
      type="button"
      class="relative block w-10 border"
      :class="{ enabled: page != pages.length && pages.length != 0 }"
      :disabled="page == pages.length || pages.length == 0"
      @click="setPageNumber(pages.length)"
    >
      <span class="text-xl">&raquo;</span>
    </button>
  </ul>
</template>
<script>
import { range } from "d3-array";
export default {
  props: {
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true }
  },
  data() {
    return { page: this.currentPage };
  },
  computed: {
    pages() {
      return range(1, this.totalPages + 1, 1);
    },
    offsetLeft() {
      if (this.page > 3 && this.page <= this.pages.length - 2) {
        return 2;
      } else if (this.page == 1) {
        return 1;
      } else if (this.page == 2) {
        return 2;
      } else if (this.page == 3 || this.page == this.pages.length - 1) {
        return 3;
      } else if (this.page == this.pages.length) {
        return 4;
      } else return 0;
    },
    offsetRight() {
      if (this.page >= 3 && this.page < this.pages.length - 2) {
        return 1;
      } else if (this.page == 2 || this.page == this.pages.length - 2) {
        return 2;
      } else if (this.page == 1) {
        return 3;
      } else if (this.page == this.pages.length - 1) {
        return 3;
      } else if (this.page == this.pages.length) {
        return 0;
      } else return 0;
    }
  },
  watch: {
    currentPage(value) {
      if (value !== this.page) this.page = value;
    }
  },
  beforeMount() {
    this.page = this.currentPage;
  },
  methods: {
    setPageNumber(currentPage) {
      this.page = currentPage;
      this.$emit("update:currentPage", currentPage);
      this.$emit("setCurrentPage", currentPage);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.active {
  z-index: 1;
  color: #fff !important;
  color: #fff;
}

button:active {
  box-shadow: none !important;
}

.disabled {
  color: #212529;
}

.enabled {
  @apply text-gray-700;
}

button {
  background-color: #fff;
  border: 1px solid #dee2e6;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
}

button:focus {
  outline: 0;
}

span {
  color: #212529;
}

.enabled:hover {
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
</style>
