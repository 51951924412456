<script>
import LoadingDNA from "./LoadingComponent.vue";
export default {
  components: { LoadingDNA },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "h-screen"
    }
  },
  render(h) {
    return this.loading
      ? h("div", { class: `${this.height} flex justify-center items-center` }, [
          h("LoadingDNA")
        ])
      : this.$slots.default;
  }
};
</script>
