import jsPDF from "jspdf";
import { axionUrl, chartSize, intensityHistPostioion, countCellSizeFluoHistogramPosition, averageCellSizeFluoHistogramPosition, averageCellIntensityHistogramPosition, countHistPostition, histogramSize, imageSize, margins, offset, processedPostioion, rawPosition, averageCellSizeHistogramPosition, countConcentrationBarChartPosition, averageConcentrationHistogramPosition } from "./utils/constants";
import { intensityHistogramTitle, concentrationTitle, histogramTitle } from "./utils/titles";
import addHeader from "./utils/addHeader";
import addFigure from "./utils/addFigure";
import addFooter from "./utils/addFooter";
import { experimentDataTable, averageDataTable, notesTable, countDataTable } from "./utils/tables";
/**
 * Function that sets properties of PDF document.
 * @param doc The PDF document.
 * @param logo AxionBio logo.
 * @param title The title of the document.
 * @param createdOn The date of generation for the document.
 */
function titlePageSummaryProperties(doc, logo, title, createdOn) {
    doc.setFontSize(22);
    doc.text(title, 10, 20);
    doc.addImage(logo, "jpeg", 175, 5, 25, 25, undefined, "FAST");
    doc.link(175, 10, 25, 16, { url: axionUrl });
    doc.setFontSize(12);
    doc.text(`Report date: ${createdOn}`, 10, 27);
}
/**
 * Function that generates title page of PDF document.
 * Title page contains some experiment data, average charts
 * and average count statistics.
 * @param doc The PDF document.
 * @param logo CytoSMART logo.
 * @param title The title of the document.
 * @param createdOn The date of creation of the document.
 * @param isMultiCount Check if experiment has multiple counts.
 * @param experimentData The data for the experiment.
 * @param averageHistogram The average area histogram.
 * @param averageConcetration The average concentration barchart.
 * @param averageData The average count statistics data.
 */
export function createTitlePage(doc, logo, title, createdOn, isMultiCount, experimentData, averageHistogram, averageConcetration, includedCountsIds, averageData, hasFluo, averageHistogramFluo, averageIntensity) {
    const nrOfCounts = includedCountsIds.length;
    const countId = includedCountsIds[0] + 1;
    // page properties
    titlePageSummaryProperties(doc, logo, title, createdOn);
    // experiment data
    experimentDataTable(doc, experimentData);
    if (!isMultiCount)
        return; // do not add average data to title page if there is only 1 count exported
    // average histogram
    addFigure(doc, averageHistogram, averageCellSizeHistogramPosition.x, averageCellSizeHistogramPosition.y, histogramTitle(isMultiCount, nrOfCounts, countId, averageIntensity), imageSize);
    // average cell size (fluo)
    if (hasFluo && averageHistogramFluo) {
        addFigure(doc, averageHistogramFluo, averageCellSizeFluoHistogramPosition.x, averageCellSizeFluoHistogramPosition.y, histogramTitle(isMultiCount, nrOfCounts, countId, averageIntensity), imageSize);
    }
    // average bar chart
    addFigure(doc, averageConcetration, averageConcentrationHistogramPosition(hasFluo).x, averageConcentrationHistogramPosition(hasFluo).y, concentrationTitle(isMultiCount, nrOfCounts, countId), imageSize);
    // average cell intensity (fluo)
    if (hasFluo && averageIntensity) {
        addFigure(doc, averageIntensity, averageCellIntensityHistogramPosition.x, averageCellIntensityHistogramPosition.y, intensityHistogramTitle(isMultiCount, nrOfCounts, countId), imageSize);
        doc.addPage();
    }
    // average data
    averageDataTable(doc, isMultiCount, nrOfCounts, countId, averageData, hasFluo ? rawPosition.y : undefined);
}
/**
 * Function that generates notes page of PDF document.
 * Notes page contains the notes associated with this
 * experiment
 * @param doc The PDF document.
 * @param logo CytoSMART logo.
 * @param notes The notes associated with this experiment
 */
export function addNotes(doc, logo, notes) {
    if (!notes.length)
        return;
    doc.addPage();
    addHeader(doc, logo, axionUrl, margins, offset);
    const formattedNotes = notes.reduce((accumulator, note) => {
        const formattedNote = doc.splitTextToSize(`${note.text}`, doc.internal.pageSize.width - 2 * offset.h);
        accumulator.push([formattedNote, ""], [new Date(note.date).toLocaleString(), ""]);
        return accumulator;
    }, []);
    notesTable(doc, offset, formattedNotes);
}
/**
 * Function that generates count page for individual counts.
 * The page contains raw and processed count images, area and concentration charts
 * and statistics data.
 * @param doc The PDF document.
 * @param logo CytoSMART logo.
 * @param index The current count index.
 * @param images The raw and processed images for the count.
 * @param data The statistics data of the count.
 * @param histogram The area histogram of the count.
 * @param concentration The concentration barchart of the count.
 */
export function createCountPage(doc, logo, index, experimentName, images, data, histogram, concentration, hasFluo, includedCountsIds, fluoHistogram, intensity) {
    const nrOfCounts = (includedCountsIds === null || includedCountsIds === void 0 ? void 0 : includedCountsIds.length) || 1;
    // Adding new page.
    doc.addPage();
    addHeader(doc, logo, axionUrl, margins, offset);
    // Raw image of count.
    addFigure(doc, images.raw, rawPosition.x, rawPosition.y, `Raw input image of count ${index + 1}`, imageSize);
    // Processed image of count.
    addFigure(doc, images.processed, processedPostioion.x, processedPostioion.y, `Processed image of count ${index + 1}`, imageSize);
    addFigure(doc, histogram, countHistPostition.x, countHistPostition.y, histogramTitle(false, nrOfCounts, index + 1, intensity), histogramSize);
    if (hasFluo && nrOfCounts && fluoHistogram) {
        addFigure(doc, fluoHistogram, countCellSizeFluoHistogramPosition.x, countCellSizeFluoHistogramPosition.y, histogramTitle(false, nrOfCounts, index + 1, intensity), chartSize);
    }
    // Concentration barchart of count.
    addFigure(doc, concentration, countConcentrationBarChartPosition(hasFluo).x, countConcentrationBarChartPosition(hasFluo).y, `Concentration of count ${index + 1}`, histogramSize);
    if (hasFluo && intensity) {
        addFigure(doc, intensity, intensityHistPostioion.x, intensityHistPostioion.y, intensityHistogramTitle(false, nrOfCounts, index + 1), chartSize);
        doc.addPage();
    }
    // Count statistics.
    countDataTable(doc, index + 1, data, hasFluo ? rawPosition.y : undefined);
    addFooter(doc, offset, margins, experimentName);
    doc.page++;
}
/**
 * Function that generates jsPDF document and sets some properties.
 * @returns jsPDF document.
 */
export function createDocument(experimentName, userName, currentUserEmail, owner) {
    const doc = new jsPDF("p", "mm", "a4");
    doc.setPage(1);
    doc.setProperties({
        title: experimentName,
        subject: "Cell counting",
        author: `${userName}, ${currentUserEmail}`,
        keywords: "cell, counting",
        creator: `${owner.name}, ${owner.email}`
    });
    return doc;
}
