import { useActiveExperiments } from "@/shared/providers/withActiveExperiments/withActiveExperiments";
import { useRegisteredDevices } from "@/shared/providers/withRegisteredDevices/withRegisteredDevices";
import withCalendarEvents from "./withCalendarEvents";
import withScansEstimate from "./withScansEstimate";
export default function () {
    const { fetchScansEstimateOnExperimentId } = withScansEstimate();
    const { scans, busySlots, experimentEvents, generateCalendarEvents } = withCalendarEvents();
    const { registeredDevices } = useRegisteredDevices();
    const { fetchActiveExperiments, activeExperiments } = useActiveExperiments();
    async function selectDevice(device) {
        if (device) {
            // fetch all experiments linked to selected device
            await fetchActiveExperiments(device.id, 28, device.type);
            if (activeExperiments) {
                // for all experiments, if any is omni3 then fetch scan estimate - state values
                await Promise.all(activeExperiments.value.map(async (exp) => {
                    let scanStates;
                    if (exp.isOmni3) {
                        scanStates = await fetchScansEstimateOnExperimentId(exp.experimentId);
                    }
                    // event sources for calendar
                    generateCalendarEvents(device, exp, scanStates);
                }));
            }
        }
    }
    return {
        scans,
        busySlots,
        activeExperiments,
        experimentEvents,
        registeredDevices,
        selectDevice
    };
}
