/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock-open-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 20V10H6v10h12m0-12a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V10a2 2 0 012-2h9V6a3 3 0 00-3-3 3 3 0 00-3 3H7a5 5 0 015-5 5 5 0 015 5v2h1m-6 9a2 2 0 01-2-2 2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2z"/>'
  }
})
