/**
 * @returns Counting chamber volume in miliLiters (mL)
 */
export function computeCountingSlideVolume(pixelsPerMM, imageWidth, imaegHeight, imageDepth) {
    if (pixelsPerMM === 0)
        return 0;
    return (imageDepth *
        (imageWidth / pixelsPerMM) *
        (imaegHeight / pixelsPerMM) *
        0.001 // to convert: 1 mm³ = 0.001 mL
    );
}
