var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('axion-button',{staticClass:"h-10 !rounded-r-none !border-control-base",attrs:{"button-type":"icon","disabled":_vm.page === 1 || _vm.pages.length === 0},on:{"click":function($event){return _vm.setPageNumber(1)}}},[_c('b-icon',{staticClass:"h-4",attrs:{"name":"chevron-double-left"}})],1),_c('axion-button',{staticClass:"mr-2 h-10 !rounded-l-none !border-control-base",attrs:{"button-type":"icon","disabled":_vm.page === 1 || _vm.pages.length === 0},on:{"click":function($event){_vm.page !== 1 ? _vm.setPageNumber(_vm.page - 1) : _vm.setPageNumber(_vm.page)}}},[_vm._v(" Previous ")]),(_vm.page > 3 && _vm.pages.length > 4)?_c('span',{staticClass:"relative ml-2 block"},[_vm._v(" ... ")]):_vm._e(),_vm._l((_vm.pages.slice(
      _vm.page - _vm.offsetLeft,
      _vm.page + _vm.offsetRight
    )),function(pageNumber,i){return _c('a',{key:i,staticClass:"mx-2 cursor-pointer text-sm text-signal-base hover:underline",class:{
      'cursor-auto font-bold !text-control-high hover:no-underline':
        pageNumber === _vm.page
    },on:{"click":function($event){return _vm.setPageNumber(pageNumber)}}},[_vm._v(" "+_vm._s(pageNumber)+" ")])}),(_vm.page < _vm.pages.length - 2 && _vm.pages.length > 4)?_c('span',{staticClass:"relative mr-2 block"},[_vm._v(" ... ")]):_vm._e(),_c('axion-button',{staticClass:"!ml-2 h-10 !rounded-r-none !border-control-base",attrs:{"button-type":"icon","disabled":_vm.page === _vm.pages.length || _vm.pages.length === 0},on:{"click":function($event){_vm.page < _vm.pages.length ? _vm.setPageNumber(_vm.page + 1) : _vm.setPageNumber(_vm.page)}}},[_vm._v(" Next ")]),_c('axion-button',{staticClass:"h-10 !rounded-l-none !border-control-base",attrs:{"button-type":"icon","disabled":_vm.page === _vm.pages.length || _vm.pages.length === 0},on:{"click":function($event){return _vm.setPageNumber(_vm.pages.length)}}},[_c('b-icon',{staticClass:"h-4",attrs:{"name":"chevron-double-right"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }