/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'turtle': {
    width: 20,
    height: 14,
    viewBox: '0 0 20 14',
    data: '<path pid="0" d="M17.31.6c-1.22-.04-2.43.9-2.81 2.4C14 5 14 5 13 6c-2 2-5 3-11 4-1 .16-1.5.5-2 1 2 0 4 0 2.5 1.5L1 14h3l2-2c2 1 3.33 1 5.33 0l.67 2h3l-1-3s1-4 2-5 1 0 2 0 2-1 2-2.5C20 3 20 2 18.5 1c-.35-.24-.76-.38-1.19-.4zM7 1a6 6 0 00-6 6c0 .6.13 1.08.23 1.6 5.92-.98 9.06-2.01 10.7-3.66l.5-.5A6.007 6.007 0 007 1z"/>'
  }
})
