/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gesture-two-double-tap': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 15.14v6.36c-.03.82-.68 1.47-1.5 1.5H11c-.38 0-.74-.15-1-.43l-4.9-4.2.74-.77c.19-.21.46-.32.74-.32h.22L10 19V9a1 1 0 011-1 1 1 0 011 1V7a1 1 0 011-1 1 1 0 011 1v5l4.15 1.84c.51.23.85.74.85 1.3M13 3a4 4 0 014 4c0 1.5-.8 2.77-2 3.46V9.24c.61-.55 1-1.35 1-2.24a3 3 0 00-3-3c-1.35 0-2.5.9-2.87 2.13A3.003 3.003 0 009 11.24v1.22C7.8 11.77 7 10.5 7 9c0-1.62.97-3 2.35-3.65C10 3.97 11.38 3 13 3m0-2a6 6 0 016 6c0 2.06-1.04 3.88-2.62 4.96l-1.12-.5A4.982 4.982 0 0018 7a5 5 0 00-5-5c-1.89 0-3.54 1.05-4.39 2.61C7.05 5.46 6 7.11 6 9c0 2.05 1.23 3.81 3 4.58v1.08C6.67 13.83 5 11.61 5 9c0-2.17 1.15-4.07 2.88-5.12A5.977 5.977 0 0113 1z"/>'
  }
})
