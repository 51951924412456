/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery-charging': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.67 4H15V2H9v2H7.33A1.33 1.33 0 006 5.33v15.33C6 21.4 6.6 22 7.33 22h9.33c.74 0 1.34-.6 1.34-1.33V5.33C18 4.6 17.4 4 16.67 4M11 20v-5.5H9L13 7v5.5h2"/>'
  }
})
