/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layers-off-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 4.53l-1.17.91L9.41 4 12 2l9 7-3.72 2.89-1.43-1.42L17.74 9 12 4.53m9 9.54l-.87.68-1.43-1.43.67-.52L21 14.07M3.41.86l18.73 18.73L20.73 21l-3.78-3.78L12 21.07l-9-7 1.62-1.26L12 18.54l3.5-2.75-1.4-1.42L12 16 3 9l3.22-2.5L2 2.27 3.41.86M12 13.47l.67-.53-5.02-5.02L6.26 9 12 13.47z"/>'
  }
})
