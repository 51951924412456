<template>
  <input
    class="old-form-input"
    :class="{
      readonly: $attrs.readonly,
      'border-red-500 bg-red-100': !isValid
    }"
    v-bind="$attrs"
    v-on="listeners"
  />
</template>

<script>
export default {
  props: {
    isValid: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit("input", event.target.value);
        }
      };
    }
  }
};
</script>
