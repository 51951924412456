/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "view-list": {
    width: 16,
    height: 16,
    viewBox: "0 0 420 330",
    data: '<rect pid="0" width="120" height="90" rx="12" fill="#000"/><rect pid="1" y="120" width="120" height="90" rx="12" fill="#000"/><rect pid="2" x="150" width="270" height="90" rx="12" fill="#000"/><rect pid="3" x="150" y="120" width="270" height="90" rx="12" fill="#000"/><rect pid="4" x="150" y="240" width="270" height="90" rx="12" fill="#000"/><rect pid="5" y="240" width="120" height="90" rx="12" fill="#000"/>'
  }
});
