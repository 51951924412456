/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account-box-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 19H5V5h14m0-2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m-2.5 13.25c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9M12 12.25A2.25 2.25 0 0014.25 10 2.25 2.25 0 0012 7.75 2.25 2.25 0 009.75 10 2.25 2.25 0 0012 12.25z"/>'
  }
})
