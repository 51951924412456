/**
 * Filters raw areas data from the api and returns only those with confluence algorithm.
 *
 * @param data - raw areas data from api
 * @param algorithm - AlgorithmValue enum
 *
 * @returns filtered areas data with confluence
 */
export function getAreasWithAlgorithm(data, algorithm) {
    return (data === null || data === void 0 ? void 0 : data.length)
        ? data.filter(item => item.selectedAlgorithms &&
            item.selectedAlgorithms.find(item => (item === null || item === void 0 ? void 0 : item.Type) === algorithm || (item === null || item === void 0 ? void 0 : item.Value) === algorithm))
        : [];
}
