import ShapeEnum from "../../_providers/types/enums/ShapeEnum";
import { ChannelTypeDisplayName } from "@/pages/wellPlate/_providers/types/IChannelConfigurations";

// Gets areas that need to be drawn to the mapview and areas that need to be removed from the mapview
export function getAreasToDrawAndRemove(areasInSight, previousAreas) {
  const areasToDraw = {};
  const areasToRemove = previousAreas;
  for (const area in areasInSight) {
    let areaFound = false;
    for (const previousArea in previousAreas) {
      if (area === previousArea) {
        delete areasToRemove[previousArea];
        areaFound = true;
        break;
      }
    }
    if (!areaFound) {
      areasToDraw[area] = areasInSight[area];
    }
  }
  return [areasToDraw, areasToRemove];
}

// Gets bounding box of a circle
function getCircleBounds(circle) {
  return window.L.latLngBounds(
    [circle.center.lat + circle.radius, circle.center.lng + circle.radius],
    [circle.center.lat - circle.radius, circle.center.lng - circle.radius]
  );
}

// Gets bounding box of a rectangle
function getRectangleBounds(rectangle) {
  return window.L.latLngBounds(
    [rectangle.bounds[0][0], rectangle.bounds[0][1]],
    [rectangle.bounds[1][0], rectangle.bounds[1][1]]
  );
}

// Gets bounding box of a polygon
function getPolygonBounds(polygon) {
  const latitudes = [];
  const longitudes = [];

  for (let i = 0; i < polygon.points.length; i++) {
    latitudes.push(polygon.points[i][0]);
    longitudes.push(polygon.points[i][1]);
  }

  const minlat = Math.min.apply(null, latitudes),
    maxlat = Math.max.apply(null, latitudes);
  const minlng = Math.min.apply(null, longitudes),
    maxlng = Math.max.apply(null, longitudes);

  return window.L.latLngBounds([minlat, minlng], [maxlat, maxlng]);
}

// Gets LatLng bounds of an area
export function getAreaBounds(area) {
  switch (area.type) {
    case ShapeEnum.WELL:
    case ShapeEnum.CIRCLE:
      return getCircleBounds(area);
    case ShapeEnum.RECTANGLE:
      return getRectangleBounds(area);
    case ShapeEnum.POLYGON:
      return getPolygonBounds(area);
  }
}

// Gets all areas in the current mapview sight
export function getAreasInSight(inputAreas, shapes, mapBounds) {
  const areasInSight = {};
  Object.keys(inputAreas).forEach(area => {
    const shape = shapes[area];
    const bounds = getAreaBounds(shape);
    if (mapBounds.intersects(bounds)) {
      areasInSight[area] = inputAreas[area];
    }
  });
  return areasInSight;
}

// Updates all selected areas with new options and returns the updated ones
export function getUpdatedAreas(areasToUpdate, areas, confluenceData, options) {
  const updatedAreas = {};
  areasToUpdate.forEach(areaToUpdate => {
    if (confluenceData[areaToUpdate]?.Brightfield?.Threshold) {
      confluenceData[areaToUpdate].Brightfield.Threshold =
        options.brightfieldConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
    if (confluenceData[areaToUpdate]?.Red?.Threshold) {
      confluenceData[areaToUpdate].Red.Threshold =
        options.redConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
    if (confluenceData[areaToUpdate]?.Green?.Threshold) {
      confluenceData[areaToUpdate].Green.Threshold =
        options.greenConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
  });
  return updatedAreas;
}

export function getUpdatedAreasPartialScan(
  areasToUpdate,
  areas,
  confluenceData,
  options,
  channel
) {
  const updatedAreas = {};
  areasToUpdate.forEach(areaToUpdate => {
    if (
      confluenceData[areaToUpdate].Brightfield.Threshold &&
      (channel === "all" || channel === ChannelTypeDisplayName.BRIGHTFIELD)
    ) {
      confluenceData[areaToUpdate].Brightfield.Threshold =
        options.brightfieldConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
    if (
      confluenceData[areaToUpdate]?.Red?.Threshold &&
      (channel === "all" || channel === ChannelTypeDisplayName.RED_CONFLUENCE)
    ) {
      confluenceData[areaToUpdate].Red.Threshold =
        options.redConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
    if (
      confluenceData[areaToUpdate]?.Green?.Threshold &&
      (channel === "all" || channel === ChannelTypeDisplayName.GREEN_CONFLUENCE)
    ) {
      confluenceData[areaToUpdate].Green.Threshold =
        options.greenConfluenceOptions.threshold;
      updatedAreas[areaToUpdate] = areas[areaToUpdate];
    }
  });
  return updatedAreas;
}

// Check if area is already in the reprocess array
export function addAreaToReprocess(
  thresholdAreas,
  areasToReprocess,
  confluenceData,
  isSnapshot = false
) {
  thresholdAreas.forEach(area => {
    let areaName = area;

    if (isSnapshot) {
      areaName = area.split("-")[0];
    }
    const existingArea = areasToReprocess.filter(e => e.Name === areaName);
    // if area doesn't exist in the reprocess array - add it, otherwise update its threshold value
    if (existingArea.length > 0) {
      existingArea[0].Threshold = String(
        confluenceData[areaName]?.Brightfield?.Threshold ||
          confluenceData[areaName]?.Red?.Threshold ||
          confluenceData[areaName]?.Green?.Threshold
      );
      return;
    }
    const areaToAdd = {
      Name: area,
      Threshold: String(
        confluenceData[areaName]?.Brightfield?.Threshold ||
          confluenceData[areaName]?.Red?.Threshold ||
          confluenceData[areaName]?.Green?.Threshold
      )
    };
    areasToReprocess.push(areaToAdd);
  });
}
