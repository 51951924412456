import { useSelectingItemsActions } from "@/pages/wellPlate/_providers/withSelect/useSelectActions/useSelectingItemsActions";
import { useModifierKeyActions } from "@/pages/wellPlate/_providers/withSelect/useSelectActions/useModifierKeyActions";
export function useSelectActions(groups, selectedAreasIds, selectedGroupsIds, modifierKeyActive) {
    const { selectItems, deselectAll } = useSelectingItemsActions(groups, selectedAreasIds, selectedGroupsIds, modifierKeyActive);
    const { initModifierEvents, removeModifierEvents } = useModifierKeyActions(modifierKeyActive);
    return {
        selectItems,
        deselectAll,
        initModifierEvents,
        removeModifierEvents
    };
}
