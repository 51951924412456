/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select-off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1 4.27L2.28 3 21 21.72 19.73 23 17 20.27V21h-2v-2h.73L5 8.27V9H3V7h.73L1 4.27M20 3a1 1 0 011 1v1h-2V3h1m-5 2V3h2v2h-2m-4 0V3h2v2h-2M7 5V3h2v2H7m4 16v-2h2v2h-2m-4 0v-2h2v2H7m-3 0a1 1 0 01-1-1v-1h2v2H4m-1-6h2v2H3v-2m18 0v2h-2v-2h2M3 11h2v2H3v-2m18 0v2h-2v-2h2m0-4v2h-2V7h2z"/>'
  }
})
