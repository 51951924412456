import randomColor from "randomcolor";
/**
 * Converts areas data from array to object.
 * For object keys, area name is used.
 * Also, adds to each data object new color field.
 *
 * @param data - raw areas data from api.
 *
 * @returns converted areas data
 */
export function convertAreasDataToObject(data) {
    return (data === null || data === void 0 ? void 0 : data.length)
        ? data.reduce((previousValue, currentValue) => {
            // ignore object that are empty or name field doesn't exist.
            return (currentValue === null || currentValue === void 0 ? void 0 : currentValue.name)
                ? {
                    ...previousValue,
                    [currentValue.name]: { ...currentValue, color: randomColor() }
                }
                : { ...previousValue };
        }, {})
        : {};
}
