import { removeFromArray } from "@axisvue/utils";
export default class CommandRectCreate {
    constructor(rect) {
        this.rect = rect;
    }
    do(shapes) {
        shapes.byId[this.rect.id] = this.rect;
        shapes.ids = removeFromArray(shapes.ids, this.rect.id);
        //triggers an update - current scan is changed
        this.rect.processed
            ? shapes.ids.push(this.rect.id)
            : shapes.ids.unshift(this.rect.id);
    }
    undo(shapes) {
        delete shapes.byId[this.rect.id];
        shapes.ids = removeFromArray(shapes.ids, this.rect.id);
    }
}
