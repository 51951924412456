export function histogramTitle(isMultiCount, nrOfCounts, id = -1, intensity) {
    let histHeader = "";
    if (intensity) {
        histHeader = isMultiCount
            ? `Average cell size of (${nrOfCounts}) counts`
            : `Cell sizes of count ${id}`;
    }
    else {
        histHeader = isMultiCount
            ? `Average organoid area of (${nrOfCounts}) counts`
            : `Organoid areas of count ${id}`;
    }
    return histHeader;
}
export function statisticsTableTitle(isMultiCount, nrOfCounts, id = -1) {
    const label = isMultiCount
        ? `Average of ${nrOfCounts} counts`
        : `Count ${id}`;
    return label;
}
export function concentrationTitle(isMultiCount, nrOfCounts, id = -1) {
    const concHeader = isMultiCount
        ? `Average concentration of (${nrOfCounts}) counts`
        : `Concentration of count ${id}`;
    return concHeader;
}
export function intensityHistogramTitle(isMultiCount, nrOfCounts, id = -1) {
    const intenHeader = isMultiCount
        ? `Average intensity of (${nrOfCounts}) counts`
        : `Intensity of count ${id}`;
    return intenHeader;
}
export function getRawImageTitle(id = -1) {
    return `Raw input image of count ${id}`;
}
export function getProcessedImageTitle(id = -1) {
    return `Processed image of count ${id}`;
}
