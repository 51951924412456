export default {
    name: "BProjectsListItemOwner",
    props: {
        title: {
            type: String,
            required: false,
            default: ""
        }
    }
};
