import { createPopper } from "@popperjs/core";
import { TimelineLite } from "gsap";
import { defineComponent, nextTick, onMounted, onUnmounted, provide, ref } from "vue";
const ANIMATION_TIME = 0.1;
const ANIMATION_OPTIONS = {
    opacity: 0.2
};
export default defineComponent({
    props: {
        /**
         * Placement of the dropdown
         * @values "auto", "auto-start", "auto-end", "top", "bottom", "right", "left", "top-start", "top-end", "bottom-start", "bottom-end", "right-start", "right-end", "left-start", "left-end", undefined
         */
        position: {
            type: String,
            default: "bottom"
        },
        dropdownClass: {
            type: String,
            default: ""
        }
    },
    emits: ["open", "close"],
    setup(props, { emit }) {
        const tl = new TimelineLite();
        const show = ref(false);
        const container = ref(null);
        let popper = null;
        const getToggler = () => { var _a; return (_a = container.value) === null || _a === void 0 ? void 0 : _a.children[0]; };
        const getDropdown = () => { var _a; return (_a = container.value) === null || _a === void 0 ? void 0 : _a.children[1]; };
        function updatePopper(element) {
            if (popper) {
                popper.destroy();
            }
            const toggler = getToggler();
            if (toggler) {
                popper = createPopper(toggler, element, {
                    placement: props.position
                });
            }
        }
        function close() {
            const dropdown = getDropdown();
            if (dropdown) {
                tl.to(dropdown, ANIMATION_TIME, { ...ANIMATION_OPTIONS });
                setTimeout(() => {
                    show.value = false;
                    emit("close", false);
                }, ANIMATION_TIME * 1000);
            }
        }
        function open() {
            show.value = true;
            emit("open", true);
            nextTick(() => {
                const dropdown = getDropdown();
                if (dropdown) {
                    updatePopper(dropdown);
                    tl.from(dropdown, ANIMATION_TIME, { ...ANIMATION_OPTIONS });
                }
            });
        }
        provide("dropdownClose", close);
        function toggle() {
            if (show.value)
                close();
            else
                open();
        }
        onMounted(() => {
            const toggler = getToggler();
            if (toggler) {
                toggler.addEventListener("click", toggle);
            }
        });
        onUnmounted(() => {
            const toggler = getToggler();
            if (toggler) {
                toggler.removeEventListener("click", toggle);
            }
        });
        return { container, show, close };
    }
});
