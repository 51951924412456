import Repository from "@/api/repository";
const resource = "manage/v1";
export default {
    getTenant() {
        return Repository.get(`${resource}/tenant`);
    },
    editTenant(params) {
        const data = new URLSearchParams({
            __RequestVerificationToken: params.__RequestVerificationToken
        });
        return Repository.put(`${resource}/tenant?name=${params.tenantName}`, data, {
            headers: { "content-type": "application/x-www-form-urlencoded" }
        });
    },
    getTenantExists() {
        return Repository.get(`${resource}/tenantHasExact`);
    },
    changeUserPassword(params) {
        const data = new URLSearchParams({
            __RequestVerificationToken: params.__RequestVerificationToken,
            oldPassword: params.oldPassword,
            newPassword: params.newPassword,
            confirmPassword: params.confirmPassword
        });
        return Repository.put(`${resource}/password`, data, {
            headers: { "content-type": "application/x-www-form-urlencoded" }
        });
    },
    getOrganizationPrivacy() {
        return Repository.get(`${resource}/privacy`);
    },
    editOrganizationPrivacy(params) {
        return Repository.post(`${resource}/privacy`, params);
    }
};
