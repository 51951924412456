/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "mass-export-circles": {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.273 5.045a1.773 1.773 0 100-3.545 1.773 1.773 0 000 3.545zm0 1.5a3.273 3.273 0 100-6.545 3.273 3.273 0 000 6.545zm0 7.228a1.773 1.773 0 100-3.546 1.773 1.773 0 000 3.546zm0 1.5a3.273 3.273 0 100-6.546 3.273 3.273 0 000 6.546zm1.772 5.454a1.773 1.773 0 11-3.545 0 1.773 1.773 0 013.545 0zm1.5 0a3.273 3.273 0 11-6.545 0 3.273 3.273 0 016.545 0zM12 5.045A1.773 1.773 0 1012 1.5a1.773 1.773 0 000 3.545zm0 1.5A3.273 3.273 0 1012 0a3.273 3.273 0 000 6.545zM13.773 12a1.773 1.773 0 11-3.546 0 1.773 1.773 0 013.546 0zm1.5 0a3.273 3.273 0 11-6.546 0 3.273 3.273 0 016.546 0zM12 22.5a1.773 1.773 0 100-3.545 1.773 1.773 0 000 3.545zm0 1.5a3.273 3.273 0 100-6.545A3.273 3.273 0 0012 24zM22.5 3.273a1.773 1.773 0 11-3.545 0 1.773 1.773 0 013.545 0zm1.5 0a3.273 3.273 0 11-6.546 0 3.273 3.273 0 016.546 0zm-3.273 10.5a1.773 1.773 0 100-3.546 1.773 1.773 0 000 3.546zm0 1.5a3.273 3.273 0 100-6.546 3.273 3.273 0 000 6.546zm1.773 5.454a1.773 1.773 0 11-3.545 0 1.773 1.773 0 013.545 0zm1.5 0a3.273 3.273 0 11-6.545 0 3.273 3.273 0 016.545 0z"/>'
  }
});
