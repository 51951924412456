/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-multiple-blank-circle-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 2a8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8 8 8 0 00-8-8m0 2c3.32 0 6 2.69 6 6 0 3.32-2.68 6-6 6a6 6 0 01-6-6 6 6 0 016-6M4.93 5.82A8.01 8.01 0 002 12a8 8 0 008 8c.64 0 1.27-.08 1.88-.23-1.76-.39-3.38-1.27-4.71-2.48A6.001 6.001 0 014 12c0-.3.03-.59.07-.89C4.03 10.74 4 10.37 4 10c0-1.44.32-2.87.93-4.18z"/>'
  }
})
