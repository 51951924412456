import { CalendarEventDisplay } from "@axisvue/calendar";
import { CalendarColourPallete, EventState, EventType } from "../shared/ICloudCalendarEvent";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
/**
 * Generates sample for Occurrence.
 * @param {number} deviceId - device id.
 * @param {number} index - index.
 * @param {EventState} sampleState - state of the occurance for example: "Completed"
 * @param {Date} startTime - start time of the scan event (when does it start).
 * @param {IOccurrence} occurrence - object that has to be present on the calendar.
 * @param {boolean} editable - boolean that says if occurance is editable.
 * @returns a scan event.
 */
export function generateSampleForOccurrence(deviceId, index, sampleState, occurrence, startTime, editable) {
    var _a, _b, _c, _d, _e;
    const end = new Date(startTime.getTime() + occurrence.eventsDuration * tms);
    const isScanCompleted = end.toISOString() < new Date().toISOString();
    const color = isScanCompleted
        ? CalendarColourPallete.Active
        : sampleState === EventState.Failed
            ? CalendarColourPallete.Failed
            : CalendarColourPallete.Waiting;
    return {
        title: occurrence.name,
        id: occurrence.id + "-sample-" + index,
        start: startTime.toISOString(),
        end: end.toISOString(),
        display: CalendarEventDisplay.Auto,
        backgroundColor: color,
        editable: editable,
        durationEditable: false,
        groupId: editable ? "reservation" : undefined,
        borderColor: color,
        extendedProps: {
            deviceId: deviceId,
            relationId: (_a = occurrence.id) !== null && _a !== void 0 ? _a : "",
            linkIsShared: (_c = (_b = occurrence.projectDetails) === null || _b === void 0 ? void 0 : _b.isShared) !== null && _c !== void 0 ? _c : false,
            owner: occurrence.owner,
            eventType: EventType.Sample,
            eventState: sampleState,
            overlapped: false,
            hiddenUrl: ((_d = occurrence.projectDetails) === null || _d === void 0 ? void 0 : _d.projectId) !== undefined
                ? `Project/${(_e = occurrence.projectDetails) === null || _e === void 0 ? void 0 : _e.projectId}/Experiments/${occurrence.id}`
                : undefined
        }
    };
}
