import { ref } from "vue";
export function withScansStorage() {
    const scansStorageUser = ref({
        total: 0,
        used: 0,
        flagged: 0
    });
    return {
        scansStorageUser
    };
}
