/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.58 19h8v3h-8v-3m13.16-7.4c-.19-.2-.45-.32-.74-.32l-.22.03-3.2 1.69v-1.17l.51-8.93c.03-.55-.39-1.03-.94-1.06-.55-.03-1.03.39-1.06.94l-.27 4.69h-.24l-1.04.11V2a1 1 0 00-1-1c-.54 0-1 .45-1 1v6.41l-.82.37-.69-5.46c-.07-.55-.57-.94-1.12-.87-.55.05-.94.55-.87 1.12l.77 6.06-.38.17c-.13.05-.25.13-.36.2l-1.1-3.89c-.16-.57-.72-.91-1.26-.77-.53.16-.83.74-.67 1.31l2.57 9.12c0 .03.02.07.03.1l.03.13h.01c.22.57.79 1 1.4 1h6.5c.39 0 .74-.16 1-.43l4.92-4.2-.76-.77z"/>'
  }
})
