import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const ScanPlansRepository = RepositoryFactory.scanPlans;
/**
 * Provider that handles global state for scan plans.
 */
export function withScanPlans() {
    const tenantHistoryData = ref([
        {
            scanPlanId: 0,
            scanPlanName: "",
            startDate: null,
            scans: 0,
            isAvailable: false,
            type: 0,
            tier: 0,
            category: "",
            deviceSerialNumber: "",
            licenseCode: "",
            tenantName: "",
            firmwareNumber: ""
        }
    ]);
    const scanPlan = ref({
        id: 0,
        name: "",
        createdAt: 0,
        updatedAt: 0,
        scans: 0,
        deviceType: 0,
        isAvailable: false,
        type: "",
        tier: 0,
        category: ""
    });
    const deviceHistory = ref({
        deviceInfo: {
            deviceSerialNumber: "",
            licenseCode: "",
            tenantName: "",
            firmwareNumber: ""
        },
        scanPlans: [
            {
                scanPlanId: 0,
                scanPlanName: "",
                startDate: null,
                scans: 0,
                isAvailable: false,
                type: 0,
                tier: 0,
                category: "",
                deviceSerialNumber: "",
                licenseCode: "",
                tenantName: "",
                firmwareNumber: ""
            }
        ]
    });
    const loading = ref(false);
    const getScanPlan = async (id) => {
        loading.value = true;
        try {
            const { data } = await ScanPlansRepository.getScanPlan(id);
            scanPlan.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error("Something went wrong while fetching scan plan.");
        }
        finally {
            loading.value = false;
        }
    };
    const upgradeScanPlan = async (plan) => {
        loading.value = true;
        try {
            await ScanPlansRepository.upgradePlan(plan);
        }
        catch (error) {
            throw new Error("Something went wrong while upgrading the data plan.");
        }
        finally {
            loading.value = false;
        }
    };
    const createNewScanPlan = async (scanPlan) => {
        loading.value = true;
        try {
            await ScanPlansRepository.createScanPlan(scanPlan);
        }
        catch (error) {
            throw new Error("Something went wrong while creating a scan plan.");
        }
        finally {
            loading.value = false;
        }
    };
    const getScanPlansHistory = async (tenantId) => {
        loading.value = true;
        try {
            const { data } = await ScanPlansRepository.getTenantHistory(tenantId);
            tenantHistoryData.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error("Something went wrong while fetching scan plans history.");
        }
        finally {
            loading.value = false;
        }
    };
    const getDeviceHistory = async (tenantId) => {
        loading.value = true;
        try {
            const { data } = await ScanPlansRepository.getDeviceHistory(tenantId);
            deviceHistory.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error("Something went wrong while fetching device scan plans history.");
        }
        finally {
            loading.value = false;
        }
    };
    const deleteDataPlan = async (dataPlanId) => {
        try {
            await ScanPlansRepository.deleteDataPlan(dataPlanId);
        }
        catch (error) {
            throw new Error(error);
        }
    };
    return {
        deviceHistory,
        tenantHistoryData,
        loading,
        scanPlan,
        createNewScanPlan,
        deleteDataPlan,
        getDeviceHistory,
        getScanPlan,
        getScanPlansHistory,
        upgradeScanPlan
    };
}
// PROVIDER SETUP
export const { provide: provideScanPlans, use: useScanPlans } = setupProvider();
