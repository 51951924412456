import Repository from "@/api/repository";
import { exact3Base } from "../base";
const resource = (experimentId) => `${exact3Base}/experiments/${experimentId}`;
export const experimentRepository = {
    getDetails(experimentId) {
        return Repository.get(`${resource(experimentId)}/details`);
    },
    getGating(experimentId) {
        return Repository.get(`${resource(experimentId)}/gating`);
    },
    postGating(experimentId, gatingModel) {
        return Repository.post(`${resource(experimentId)}/gating`, gatingModel);
    },
    getExcel(experimentId, GatingModel, AdditionalFluoCountStatisticsIncluded) {
        return Repository.post(`${resource(experimentId)}/export/excel`, { GatingModel, AdditionalFluoCountStatisticsIncluded }, {
            responseType: "arraybuffer"
        });
    },
    getChannelSettings(experimentId) {
        return Repository.get(`${resource(experimentId)}/settings`);
    },
    setChannelSettings(experimentId, channel, model) {
        return Repository.post(`${resource(experimentId)}/settings/${channel}`, model);
    }
};
