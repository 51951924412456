import { determineNewAreaName } from "@/pages/wellPlate/Mapview/shared/shapeHelper";
import ShapeEnum from "./types/enums/ShapeEnum";
function averageCalculation(channel, data, selectedItems, showScan, scan, average, averageData, standardDeviationData) {
    selectedItems.forEach(well => {
        if (!showScan)
            return;
        if (!(well in data &&
            channel in data[well] &&
            scan in data[well][channel].Data)) {
            showScan = false;
            return;
        }
        average +=
            data[well][channel].Data[scan];
    });
    if (!showScan)
        return;
    let sq = 0;
    average = parseFloat((average / selectedItems.length).toPrecision(3));
    selectedItems.forEach(well => {
        sq += Math.pow(data[well][channel].Data[scan] -
            average, 2);
    });
    sq = Math.sqrt(sq / (selectedItems.length - 1));
    if (!isNaN(average)) {
        averageData.push({ x: parseInt(scan, 10), y: average });
    }
    selectedItems.length > 1
        ? standardDeviationData.push({
            x: parseInt(scan, 10),
            y: parseFloat((average + sq).toPrecision(3)),
            z: parseFloat((average - sq).toPrecision(3)),
            sd: sq
        })
        : standardDeviationData.push({
            x: parseInt(scan, 10),
            y: 0,
            z: 0,
            sd: sq
        });
}
/**
 *@param channel currently selected channel for the results
 *@param data algorithmData data results
 *@param scans exp scans for which data needs to be set
 *@param selectedItems the ids of the selected shapes
 *@param groups the existing groups of the experiment
 *@param group group whose average will be calculated
 **/
export function computedAverageData(channel, data, selectedItems, scans, groups, group) {
    const averageData = [];
    const standardDeviationData = [];
    const temp = {};
    scans.forEach(scan => {
        const showScan = true;
        const average = 0;
        if (!group) {
            if (groups) {
                groups.forEach(group => {
                    const groupAreas = selectedItems.filter(item => group.areas.includes(item));
                    if (groupAreas.length)
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        setROIsData(channel, data, group, temp, groupAreas, scans);
                });
            }
            selectedItems = selectedItems.filter(item => !(groups === null || groups === void 0 ? void 0 : groups.find(g => g.areas.includes(item))));
        }
        averageCalculation(channel, data, selectedItems, showScan, scan, average, averageData, standardDeviationData);
    });
    return {
        data: averageData,
        color: "#0076b8",
        sd: standardDeviationData,
        ...temp
    };
}
export function setROIsData(channel, algorithmData, group, temp, selectedGroupROIs, scans) {
    temp[group.name] = {
        data: computedAverageData(channel, algorithmData, selectedGroupROIs, scans, undefined, group).data,
        color: group.color,
        sd: computedAverageData(channel, algorithmData, selectedGroupROIs, scans, undefined, group).sd,
        groupAreas: {
            // get data for each group area
            ...group.areas.reduce((acc, area) => {
                var _a;
                return ({
                    ...acc,
                    [area]: (_a = computedAverageData(channel, algorithmData, [area], scans, undefined, group)) === null || _a === void 0 ? void 0 : _a.data
                });
            }, {})
        }
    };
    temp[group.name].groupAreas = {
        ...Object.keys(temp[group.name].groupAreas).reduce((acc, area) => {
            var _a;
            return ({
                ...acc,
                [area.split("-")[0]]: (_a = computedAverageData(channel, algorithmData, group.areas.filter(a => a.split("-")[0] === area.split("-")[0]), scans, undefined, group)) === null || _a === void 0 ? void 0 : _a.data
            });
        }, {})
    };
}
export function defineScanCoordinates(channel, algorithmData, keys, id, data) {
    keys.forEach(scan => {
        const current = algorithmData[id][channel]
            .Data[scan];
        if (current !== undefined && current != null) {
            data.push({
                x: parseInt(scan),
                y: current
            });
        }
    });
}
export function preparedData(channel, algorithmData, selectedItems, areas, scans, virtualWellPlateProvider) {
    var _a;
    const temp = {};
    for (const id of selectedItems) {
        if (!algorithmData[id] ||
            !algorithmData[id][channel] ||
            !areas[id].color) {
            continue;
        }
        const keys = Object.keys(algorithmData[id][channel].Data);
        const data = [];
        defineScanCoordinates(channel, algorithmData, keys, id, data);
        if (virtualWellPlateProvider.getShapeById(id).type === ShapeEnum.ROI) {
            const well = virtualWellPlateProvider.getWellByROI(id);
            if (well) {
                const areasInWell = virtualWellPlateProvider
                    .getAllROIsInWell(well.id)
                    .map(roi => roi.id);
                const averagedData = computedAverageData(channel, algorithmData, areasInWell, scans);
                if (averagedData.data.length > 0) {
                    temp[well.id] = {
                        data: averagedData.data,
                        sd: averagedData.sd,
                        color: areas[areasInWell[0]].color,
                        rois: {
                            ...(_a = temp[well.id]) === null || _a === void 0 ? void 0 : _a.rois,
                            [id]: {
                                data,
                                color: areas[id].color
                            }
                        }
                    };
                }
            }
        }
        else {
            temp[id] = {
                data,
                color: areas[id].color
            };
        }
    }
    return temp;
}
// Checks if param is a number
const isNumber = (v) => {
    return (+v).toString() === v;
};
const sort = (a, b) => {
    let i = 0;
    const l = Math.min(a.value.length, b.value.length);
    while (i < l && a.value[i] === b.value[i]) {
        i++;
    }
    if (i === l) {
        return a.value.length - b.value.length;
    }
    if (isNumber(a.value[i]) && isNumber(b.value[i])) {
        return a.value[i] - b.value[i];
    }
    return a.value[i].localeCompare(b.value[i]);
};
// Sorts [A1-1, A1-2, A2-1, A1-14] to [A1-1, A1-2, A1-14, A2-1]
export function customSort(data) {
    let mapped = [];
    if (data === null || data === void 0 ? void 0 : data.length) {
        mapped = data.map(function (el, i) {
            const string = el ? el.replace(/\d(?=[a-z])|[a-z](?=\.)/gi, "$&. .") : "";
            const regex = /(\d+)|([^0-9.]+)/g;
            const parts = [];
            let m;
            while ((m = regex.exec(string)) !== null) {
                parts.push(m[0]);
            }
            return { index: i, value: parts, o: el, string: string };
        });
    }
    mapped.sort(sort);
    return mapped.map(function (el) {
        return data[el.index];
    });
}
export function sortAreasInGroupsData(data) {
    if (data === null || data === void 0 ? void 0 : data.length) {
        data = data.map(item => ({
            ...item,
            areas: customSort(item.areas)
        }));
    }
    return data;
}
export function sortAreasInSingleGroupData(data) {
    if (data === null || data === void 0 ? void 0 : data.areas) {
        return {
            ...data,
            areas: customSort(data.areas)
        };
    }
    return data;
}
export function generateNewGroupName(groupNames) {
    return determineNewAreaName("Group", groupNames)
        .replace(/(.{5})/g, "$1 ")
        .trim();
}
/**
 * Generates object with average data for each channel
 */
export function prepareChannelsAverageData(data, selectedItems, scans, selectedChannels, groups) {
    const temp = {};
    selectedChannels.forEach(channel => {
        const averageData = computedAverageData(channel, data, selectedItems, scans, groups);
        switch (channel) {
            case "Green":
                averageData.color = "green";
                break;
            case "Red":
                averageData.color = "red";
                break;
            default:
                break;
        }
        temp[channel] = averageData;
    });
    return temp;
}
