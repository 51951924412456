import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import CalendarSettingsOptions from "./CalendarSettingsOptions.vue";
export default defineComponent({
    components: {
        CalendarSettingsOptions
    },
    setup(_, { emit }) {
        /* providers */
        /* Whether to show the settings menu - settings btn clicked */
        const showSettings = ref(false);
        /* handle click outside settings menu - to close it */
        function handleClickOutside(event) {
            const settings = document.getElementById("settings");
            // @ts-expect-error - Argument of type 'EventTarget' is not assignable to parameter of type 'Node'.
            if (settings && event.target && !settings.contains(event.target)) {
                showSettings.value = false;
            }
        }
        function changeToTimeFormat12(value) {
            emit("changeToTimeFormat12", value);
        }
        onMounted(() => window.addEventListener("mouseup", handleClickOutside, true));
        onUnmounted(() => window.removeEventListener("mouseup", handleClickOutside, true));
        return {
            showSettings,
            changeToTimeFormat12
        };
    }
});
