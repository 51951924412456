<template>
  <with-pagination v-slot="{ pagination }" :options="options">
    <div>
      <div :class="overlayClass">
        <slot v-bind="{ pagination }"></slot>
        <div
          class="flex flex-col items-center justify-between md:flex-row md:flex-wrap"
        >
          <div class="flex items-center justify-between py-3 md:py-2">
            <span class="mr-3 text-left text-sm text-control-high">Show</span>
            <axion-input-select
              v-model.number="pagination.itemsPerPage"
              :options="options.itemsPerPage"
              name="perPage"
              class="w-14"
              @change="$emit('updated')"
            />
            <span class="ml-3 text-left text-sm text-control-high">items</span>
          </div>
          <div class="flex items-center">
            <base-remaining-items
              :per-page="pagination.itemsPerPage"
              :current-page="pagination.currentPage"
              :number-of-items="pagination.totalItems"
              class="mr-3"
            />
            <div class="py-3 md:py-2">
              <pagination-button
                :current-page.sync="pagination.currentPage"
                :total-pages="pagination.totalPages"
                @update:currentPage="$emit('updated')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </with-pagination>
</template>

<script>
import WithPagination from "./WithPagination.vue";
import PaginationButton from "./PaginationButton.vue";
import BaseRemainingItems from "./BaseRemainingItems.vue";
import { AxionInputSelect } from "@axisvue/forms";

export default {
  components: {
    AxionInputSelect,
    WithPagination,
    PaginationButton,
    BaseRemainingItems
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        itemsPerPage: [10, 20, 40, 60]
      })
    },
    overlayClass: {
      type: String,
      default: "mt-3"
    }
  }
};
</script>
