/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cookie': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9c0-.5-.04-1-.13-1.5C20.6 10 20 10 20 10h-2V9c0-1-1-1-1-1h-2V7c0-1-1-1-1-1h-1V4c0-1-1-1-1-1M9.5 6A1.5 1.5 0 0111 7.5 1.5 1.5 0 019.5 9 1.5 1.5 0 018 7.5 1.5 1.5 0 019.5 6m-3 4A1.5 1.5 0 018 11.5 1.5 1.5 0 016.5 13 1.5 1.5 0 015 11.5 1.5 1.5 0 016.5 10m5 1a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5 1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5m5 2a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5 1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 011.5-1.5M11 16a1.5 1.5 0 011.5 1.5A1.5 1.5 0 0111 19a1.5 1.5 0 01-1.5-1.5A1.5 1.5 0 0111 16z"/>'
  }
})
