<template>
  <base-layout :is-app="isApp">
    <div
      class="flex h-screen items-center justify-center"
      :class="isApp ? 'bg-black text-white' : ''"
    >
      <div class="flex w-1/2 items-center justify-center">
        <b-icon
          name="lock"
          class="outline-none mb-6 h-64 text-gray-500 hover:text-gray-500"
          :class="isApp ? 'text-gray-300  hover:text-gray-300' : ''"
        />
        <div class="flex flex-col items-center justify-center">
          <div class="flex w-full items-end justify-between">
            <span
              class="text-xl font-bold text-gray-700"
              :class="isApp ? 'text-gray-500' : ''"
            >
              {{ "Experiment Unavailable" }}
            </span>
          </div>
          <div class="text-md mt-6 w-full">
            <span>
              User
              <a class="link">
                {{ userEmail }}
              </a>
              does not have access to view this experiment.
            </span>
            <span class="block">
              Please contact the project owner to gain access to the project.
            </span>
          </div>
        </div>
      </div>
    </div></base-layout
  >
</template>
<script>
import BaseLayout from "@/shared/components/layout/BaseLayout.vue";
import { ref } from "vue";
import router from "@/router";

export default {
  components: {
    BaseLayout
  },
  setup() {
    const userEmail = ref(window.GLOBAL_CURRENT_USER_EMAIL);
    const isApp = ref(
      (router.currentRoute.query?.inAppMode + "").toLowerCase() === "true" ||
        false
    );
    return { userEmail, isApp };
  }
};
</script>
