import BaseLayout from "@/shared/components/layout/BaseLayout.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CalendarSettings from "./settings/CalendarSettings.vue";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import CloudCalendar from "./CloudCalendar.vue";
import { AxionInputSelect } from "@axisvue/forms";
import { DeviceOptions } from "./shared/enums";
import EventOverviewDialog from "./components/EventOverviewDialog.vue";
import { useRegisteredDevices } from "../../shared/providers/withRegisteredDevices/withRegisteredDevices";
import DeviceType from "../../types/DeviceType";
import { setupAndProvide } from "./_providers/setupAndProvide";
import EventReservationDialog from "./components/EventReservationDialog.vue";
export default defineComponent({
    components: {
        BaseLayout,
        WrapperLoading,
        CloudCalendar,
        CalendarSettings,
        AxionInputSelect,
        EventOverviewDialog,
        EventReservationDialog
    },
    setup() {
        const { modifyReservation, calendarEvents, occurrences, createReservation, deviceSelection, fetchDataAndCalculateCalendarEvents, fetchedDataAndCalculatedCalendarEvents } = setupAndProvide();
        const { registeredDevices, fetchingDevices } = useRegisteredDevices();
        const onPageLoadInProgress = ref(false);
        const timezoneOffsetInMinutes = computed(() => deviceSelection.timezoneOffsetInMinutes.value);
        const editingMode = computed(() => modifyReservation.selectedReservation.value !== null);
        const selectedDevice = computed(() => deviceSelection.selectedDevice.value);
        const calendarTitle = ref(new Date().toString());
        /* triggers open/close of event info dialog */
        const showExperimentOverviewDialog = ref(false);
        /* triggers open/close of reservation edit dialog */
        const showReservationDialog = ref(false);
        /* template ref to cloud calendar to enable size updates */
        const cloudCalendarRef = ref(null);
        /* contains the ICloudCalendarEvent.ts structure after click on event (selection) */
        const experimentInfo = ref();
        const selectedDeviceOption = ref(DeviceOptions.All);
        const isTimeFormat12 = ref(false);
        const loading = computed(() => {
            return onPageLoadInProgress.value || fetchingDevices.value;
        });
        //initial data for selected device - to be overwritten by data from api
        // filtering lux, omni devices
        const filteredDeviceOptions = computed(() => {
            const options = [DeviceOptions.All, DeviceOptions.Active];
            const availableLuxDevices = registeredDevices.value.some(r => r.type === DeviceType.Lux);
            const availableOmniDevices = registeredDevices.value.some(r => r.type === DeviceType.Omni);
            if (availableOmniDevices) {
                options.push(DeviceOptions.Omni);
            }
            if (availableLuxDevices) {
                options.push(DeviceOptions.Lux);
            }
            return options;
        });
        // filtered devices are displayed based selectedDeviceOption
        const filteredDevices = computed(() => {
            switch (selectedDeviceOption.value) {
                case DeviceOptions.Active:
                    return registeredDevices.value.filter(r => !r.lastExperimentIsFinished);
                case DeviceOptions.Omni:
                    return registeredDevices.value.filter(r => r.type === DeviceType.Omni);
                case DeviceOptions.Lux:
                    return registeredDevices.value.filter(r => r.type === DeviceType.Lux);
                default:
                    return registeredDevices.value;
            }
        });
        function openExperimentOverviewDialog(event) {
            var _a;
            showExperimentOverviewDialog.value = true;
            showReservationDialog.value = false; // close any other dalog if open
            experimentInfo.value = event;
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarSize();
        }
        function selectDeviceOption(option) {
            selectedDeviceOption.value = option;
        }
        // initial selection of device
        // whenever page loades or device types dropdown selection changes
        async function initSelectDevice() {
            // experiment should be running.
            let initTempDevice = filteredDevices.value.find(rd => rd.lastExperimentOwnerEmail === window.GLOBAL_CURRENT_USER_EMAIL &&
                !rd.lastExperimentIsFinished);
            if (!initTempDevice) {
                initTempDevice = filteredDevices.value[0];
            }
            await deviceSelection.selectDevice(initTempDevice.serialNumber);
            await fetchDataAndCalculateCalendarEvents(selectedDevice.value);
        }
        // called on manual select (dropdown) of a device -- check html
        async function selectDevice(serialNumber) {
            await deviceSelection.selectDevice(serialNumber);
            await fetchDataAndCalculateCalendarEvents(selectedDevice.value);
        }
        /**
         * This function is called onMounted or whenever there is data for registered devices
         * it is used to initialize selected device and generate calendar events
         */
        async function calendarInit() {
            onPageLoadInProgress.value = true;
            if (registeredDevices.value.length) {
                await initSelectDevice();
            }
            onPageLoadInProgress.value = false;
        }
        function openReservationOverviewDialog(event) {
            var _a, _b;
            showReservationDialog.value = true;
            createReservation.newReservationStartOffset.value = event.start;
            modifyReservation.selectReservation(createReservation.newReservation.value);
            // we need to transform experiment/reservation to an occurrence
            // as this is used to generate calendar events
            const occurrence = occurrences.transformObjectToOccurrence(createReservation.newReservation.value);
            if (occurrence) {
                calendarEvents.calculateEditableCalendarEvents(deviceSelection.selectedDevice.value, occurrence);
            }
            // from CloudCalendar.vue -> we need to refresh calendar
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
        }
        // update reservation preview -> whatever the users does before clicking the Apply button
        // any change to number of scans, duration etc.
        function updateReservationPreview() {
            var _a, _b;
            const occurrence = occurrences.transformObjectToOccurrence(modifyReservation.selectedReservation.value);
            if (occurrence) {
                calendarEvents.calculateEditableCalendarEvents(deviceSelection.selectedDevice.value, occurrence);
            }
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
        }
        async function closeReservationDialog() {
            var _a, _b;
            showReservationDialog.value = false;
            calendarEvents.removeEditableEvents();
            occurrences.occurrences.value.forEach(occurrence => calendarEvents.calculateNonEditableCalendarEvents(deviceSelection.selectedDevice.value, occurrence));
            modifyReservation.deselectReservation();
            createReservation.resetValues();
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
        }
        // save reservation to DB
        async function saveReservation() {
            var _a, _b, _c;
            if (((_a = modifyReservation.selectedReservation.value) === null || _a === void 0 ? void 0 : _a.reservationId) === 0) {
                await createReservation.asyncCreateReservation();
            }
            else {
                await modifyReservation.asyncEditReservation();
            }
            const occurrence = occurrences.transformObjectToOccurrence(modifyReservation.selectedReservation.value);
            if (occurrence) {
                occurrences.addOccurence(occurrence);
            }
            closeReservationDialog();
            modifyReservation.deselectReservation();
            createReservation.resetValues();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarView();
            (_c = cloudCalendarRef.value) === null || _c === void 0 ? void 0 : _c.updateCalendarSize();
        }
        // cancel - delete reservation from DB
        async function cancelReservation() {
            var _a, _b;
            if (modifyReservation.selectedReservation.value) {
                await modifyReservation.asyncCancelReservation();
                await occurrences.removeOccurence(modifyReservation.selectedReservation.value.reservationId);
                calendarEvents.removeEditableEvents();
                occurrences.occurrences.value.forEach(occurrence => calendarEvents.calculateNonEditableCalendarEvents(selectedDevice.value, occurrence));
                calendarEvents.removeNonEditableCalendarEventsForOccurrence(modifyReservation.selectedReservation.value);
                closeReservationDialog();
                (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
                (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
            }
        }
        // a (soon to be)reservation that is not saved in the DB - remove editable events
        function removeNewReservation() {
            var _a, _b;
            modifyReservation.deselectReservation();
            createReservation.resetValues();
            calendarEvents.removeEditableEvents();
            closeReservationDialog();
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
        }
        // api to change details of existing reservation
        function editReservation(id) {
            var _a, _b;
            const reservation = occurrences.reservations.value.find(o => o.reservationId === id);
            if (reservation) {
                modifyReservation.selectReservation(reservation);
                showReservationDialog.value = true;
                showExperimentOverviewDialog.value = false;
                updateReservationPreview();
            }
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarView();
            (_b = cloudCalendarRef.value) === null || _b === void 0 ? void 0 : _b.updateCalendarSize();
        }
        // from CloudCalendar.vue to update start time on calendar click
        function updateReservationStartTime(start) {
            if (modifyReservation.selectedReservation.value) {
                modifyReservation.selectedReservation.value.startOffset = start;
            }
            updateReservationPreview();
        }
        onMounted(() => calendarInit());
        function closeExperimentOverviewDialog() {
            var _a;
            showExperimentOverviewDialog.value = false;
            experimentInfo.value = null;
            (_a = cloudCalendarRef.value) === null || _a === void 0 ? void 0 : _a.updateCalendarSize();
        }
        watch(() => registeredDevices, () => calendarInit(), { deep: true });
        watch(() => filteredDevices, async () => {
            // select init device after change in device family dropdown.
            if (registeredDevices.value.length) {
                await initSelectDevice();
            }
        }, { deep: true });
        return {
            calendarTitle,
            openReservationOverviewDialog,
            showExperimentOverviewDialog,
            closeExperimentOverviewDialog,
            saveReservation,
            experimentInfo,
            deviceSelection,
            filteredDeviceOptions,
            selectDeviceOption,
            DeviceOptions,
            showReservationDialog,
            openExperimentOverviewDialog,
            loading,
            editingMode,
            closeReservationDialog,
            registeredDevices,
            isTimeFormat12,
            fetchedDataAndCalculatedCalendarEvents,
            timezoneOffsetInMinutes,
            selectedDeviceOption,
            selectedDevice,
            filteredDevices,
            updateReservationStartTime,
            cloudCalendarRef,
            updateReservationPreview,
            editReservation,
            removeNewReservation,
            cancelReservation,
            close,
            selectDevice
        };
    }
});
