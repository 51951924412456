import { defineComponent, ref } from "vue";
import BModal from "@/shared/components/layout/Modal.vue";
import BEmailSelect from "@/shared/components/forms/BEmailSelect.vue";
import { AxionInputTextarea } from "@axisvue/forms";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: {
        BModal,
        BEmailSelect,
        AxionButton,
        AxionInputTextarea
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        projectId: {
            type: String,
            required: true
        },
        excludedEmails: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    emits: ["invite", "close"],
    setup(props, { emit }) {
        const description = ref("I'm working on this project and wanted to share it with you!");
        const emails = ref([]);
        const buttonEnabled = ref(false);
        const invite = () => {
            const data = {
                projectId: props.projectId,
                description: description.value,
                emails: emails.value
            };
            buttonEnabled.value = false;
            emit("invite", data);
        };
        const closeHandler = () => {
            emit("close");
        };
        return { description, emails, buttonEnabled, invite, closeHandler };
    }
});
