/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 3h1v2H3V4a1 1 0 011-1m16 0a1 1 0 011 1v1h-2V3h1m-5 2V3h2v2h-2m-4 0V3h2v2h-2M7 5V3h2v2H7m14 15a1 1 0 01-1 1h-1v-2h2v1m-6 1v-2h2v2h-2m-4 0v-2h2v2h-2m-4 0v-2h2v2H7m-3 0a1 1 0 01-1-1v-1h2v2H4m-1-6h2v2H3v-2m18 0v2h-2v-2h2M3 11h2v2H3v-2m18 0v2h-2v-2h2M3 7h2v2H3V7m18 0v2h-2V7h2z"/>'
  }
})
