/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullseye-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.16-.21-2.31-.61-3.39l-1.6 1.6c.14.59.21 1.19.21 1.79 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c.6 0 1.2.07 1.79.21L15.4 2.6C14.31 2.21 13.16 2 12 2m7 0l-4 4v1.5l-2.55 2.55C12.3 10 12.15 10 12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2c0-.15 0-.3-.05-.45L16.5 9H18l4-4h-3V2m-7 4c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6h-2c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4V6z"/>'
  }
})
