import "@fullcalendar/core/vdom";
import { CalendarEventType, CalendarView, EventInfoPopper, withCalendarConfiguration } from "@axisvue/calendar";
import FullCalendar from "@fullcalendar/vue";
import { computed, defineComponent, inject, nextTick, onUnmounted, ref, watch } from "vue";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import StateInjection from "./_providers/types/StateInjection";
import EventInfoContentCloud from "./components/EventInfoContentCloud.vue";
import { setVisibilityOfCalendarEventsBasedOnSelection } from "./utils/setVisibilityOfCalendarEventsBasedOnSelection";
export default defineComponent({
    components: {
        FullCalendar,
        EventInfoPopper,
        EventInfoContentCloud,
        WrapperLoading
    },
    props: {
        selectedDevice: {
            type: Object,
            required: true
        },
        isTimeFormat12: {
            type: Boolean,
            required: true
        },
        eventsFetching: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const state = inject(StateInjection);
        const showCalendarEventInfo = ref(false);
        const calendarEventInfo = ref({});
        const experiments = computed(() => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.activeExperiments.value) !== null && _a !== void 0 ? _a : []; });
        const slotDuration = ref(5);
        function updateEventInfoOnHover(info) {
            var _a, _b;
            if (info.event.extendedProps.eventType !== CalendarEventType.BusySlot) {
                showCalendarEventInfo.value = true;
                const experiment = experiments.value.find(e => e.experimentId == info.event.extendedProps.experimentId);
                calendarEventInfo.value = {
                    title: info.event.extendedProps.hiddenEventTitle,
                    experimentName: (_a = experiment === null || experiment === void 0 ? void 0 : experiment.name) !== null && _a !== void 0 ? _a : "Unknown",
                    projectName: (_b = experiment === null || experiment === void 0 ? void 0 : experiment.projectDetails.projectName) !== null && _b !== void 0 ? _b : "Unknown",
                    experimentOwner: info.event.extendedProps.experimentOwner.name,
                    eventType: info.event.extendedProps.eventType,
                    estimatedNumberOfEventsForExperiment: experiment === null || experiment === void 0 ? void 0 : experiment.estimateNumberOfSamples,
                    state: info.event.extendedProps.eventState
                };
            }
        }
        const { configuration, calendarInstance, updateAllEvents } = withCalendarConfiguration({
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            headerToolbar: {
                left: "",
                center: "",
                right: `now prev ${CalendarView.Monthly},${CalendarView.Weekly},${CalendarView.Daily} next`
            },
            eventMouseEnter: updateEventInfoOnHover,
            eventMouseLeave: () => {
                showCalendarEventInfo.value = false;
            },
            editable: true,
            validRange: {
                start: new Date(new Date().getFullYear(), new Date().getMonth() - 2, // 2 month prev
                new Date().getDate()),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 2, // 2 months ahead
                new Date().getDate())
            },
            eventClick: function (info) {
                emit("openExperimentOverviewDialog", info.event);
            },
            // This method is required in the unified calendars
            eventDataTransform: (e) => {
                return { ...e };
            }
        });
        /**
         * The calendar adjustes its size whenever this function is called - show/hide event dilog.
         * The timeout is needed as the inner size of the calendar also has to be adjusted.
         */
        function updateCalendarSize() {
            if (calendarInstance) {
                nextTick(() => {
                    var _a;
                    (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.updateSize();
                });
            }
        }
        /**
         * Interval for every 60 seconds, refetch data from backend,
         * thus the calendar will update when experiment stopped, continued, finished or started.
         */
        const interval = setInterval(async () => {
            if (calendarInstance.value && state) {
                await state.selectDevice(props.selectedDevice);
                calendarInstance.value.refetchEvents();
            }
        }, 60000);
        /**
         * Based on selection of device, the calendar will show/hide
         * linked events (to device id)
         */
        function updateCalendarView() {
            var _a, _b;
            if (calendarInstance.value && state) {
                const calendarEvents = setVisibilityOfCalendarEventsBasedOnSelection(props.selectedDevice, state.scans.value
                    .concat(state.busySlots.value)
                    .concat(state.experimentEvents.value), (_b = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view.type) !== null && _b !== void 0 ? _b : "");
                if (calendarInstance.value)
                    updateAllEvents(calendarEvents);
            }
        }
        watch(() => props.eventsFetching, () => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            updateSlotDuration();
            updateCalendarView();
        });
        watch(() => props.isTimeFormat12, 
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        () => setOptionSlotLabelFormat());
        watch(() => { var _a, _b; return (_b = (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view) === null || _b === void 0 ? void 0 : _b.title; }, () => { var _a; return emit("updateCalendarTitle", (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.view.title); });
        watch(() => calendarInstance.value, () => updateCalendarView());
        /**
         * Sets the slot label format of calendar when time format value is changed from provider.
         * For more info - check withTimeFormat.ts
         */
        function setOptionSlotLabelFormat() {
            var _a, _b, _c;
            (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.setOption("slotLabelFormat", {
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                // AM/PM/24
                hour12: props.isTimeFormat12
            });
            // locale - for 12am - 00 (else shows 24)
            if (props.isTimeFormat12) {
                (_b = calendarInstance.value) === null || _b === void 0 ? void 0 : _b.setOption("locale", "en-us");
            }
            else {
                (_c = calendarInstance.value) === null || _c === void 0 ? void 0 : _c.setOption("locale", "en-gb");
            }
        }
        /**
         * Sets the slot duration of the calendar whenever new events come.
         */
        function updateSlotDuration() {
            var _a;
            if (props.eventsFetching === false && state) {
                const scansOnDevice = state.scans.value.filter(sc => { var _a; return ((_a = sc.extendedProps) === null || _a === void 0 ? void 0 : _a.deviceId) === props.selectedDevice.id; });
                if (scansOnDevice.length) {
                    const scansDurations = scansOnDevice.map(sc => Math.round(
                    // @ts-expect-error event on the calendar ALWAYS has an end
                    (new Date(sc.end).getTime() - new Date(sc.start).getTime()) /
                        60000));
                    let min = Math.min(...scansDurations);
                    while (min % 5 !== 0) {
                        min++;
                    }
                    if (min !== slotDuration.value) {
                        slotDuration.value = min;
                        (_a = calendarInstance.value) === null || _a === void 0 ? void 0 : _a.setOption("slotDuration", "00:" + ("0" + slotDuration.value).slice(-2) + ":00");
                    }
                }
            }
        }
        onUnmounted(() => {
            clearInterval(interval);
        });
        return {
            calendarEventInfo,
            showCalendarEventInfo,
            configuration,
            updateCalendarView,
            updateCalendarSize
        };
    }
});
