import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import { DEFAULT_LAYOUT_TYPE, LayoutTypes } from "@/shared/providers/withLayoutType/types/LayoutTypes";
/**
 * Provider that handles global state for pages layout type.
 */
export function withLayoutType() {
    const layoutType = ref(DEFAULT_LAYOUT_TYPE);
    const setLayoutType = (type) => {
        localStorage.setItem("layoutTypePreference", type);
        layoutType.value = type;
    };
    const initLayoutType = () => {
        if (localStorage.getItem("layoutTypePreference") === LayoutTypes.GRID ||
            localStorage.getItem("layoutTypePreference") === LayoutTypes.LIST) {
            setLayoutType(localStorage.getItem("layoutTypePreference") || DEFAULT_LAYOUT_TYPE);
        }
    };
    initLayoutType();
    return { layoutType, initLayoutType, setLayoutType };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing.
 * Use `provideLayoutType` and `useLayoutType` instead.
 */
export const LayoutTypeInjectionKey = makeInjectionKey();
/**
 * Provides `LayoutType` to all descendants of a component.
 *
 * Use in conjunction with `useLayoutType`.
 */
export function provideLayoutType(layoutType) {
    provide(LayoutTypeInjectionKey, layoutType);
}
/**
 * Injects `LayoutType` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideLayoutType`, otherwise an error will
 * be thrown
 */
export function useLayoutType() {
    const layoutType = inject(LayoutTypeInjectionKey);
    if (layoutType === undefined)
        throw new Error("LayoutType was not provided.");
    return layoutType;
}
