window.GLOBAL_ANTI_FORGERY =
  "liOThZd6E6mAOH_Q4RntnbMTmTkKkzFXxMBWV5Gi1u3lbidGu-CBTm0OzYh_QUsoNFIg-sdcJRQ94nFdqbwobnDHZkv2Y7VtNOG3_oVhbomX94sIzosmFFWdk5CBSS7QsMV89une8GmnSxfzcLWIaw2";
window.GLOBAL_IS_ADMIN = true;
window.GLOBAL_CURRENT_USER_ID = "655a6cc0-80f0-4d64-9823-923c54af20e9";
window.GLOBAL_CURRENT_USER_EMAIL = "test@cytosmart.com";
window.GLOBAL_VIEWBAG = "";
window.GLOBAL_DEVICE_START = "exact";
window.GLOBAL_IS_ENTERPRISE_ADMIN = false;
window.GLOBAL_NAVIGATION_URLS = [
  {
    name: "Notifications",
    link: "/Admin/Notifications",
    active: false
  },
  {
    name: "Customers",
    link: "/Admin/Customers",
    active: false
  },
  {
    name: "License",
    link: "/License",
    active: false
  },
  // { // temporarly disabled because it is on hold
  //   name: "Scan plans",
  //   link: "/Admin/ScanPlans",
  //   active: false
  // },
  {
    name: "Calendar",
    link: "/Calendar",
    active: false
  },
  {
    name: "Projects",
    link: "/",
    active: false
  }
];
