export default {
  props: {
    model: [String, Boolean, Object, Number, Array],
    value: {
      type: [String, Boolean, Object, Number],
      default: "on"
    },
    name: [String, Number],
    required: Boolean,
    disabled: Boolean,
    indeterminate: Boolean
  },
  model: {
    prop: "model",
    event: "change"
  },
  computed: {
    isSelected() {
      if (this.isModelArray) {
        return this.model.includes(this.value);
      }

      if (this.isModelBoolean && this.value === "on") {
        return this.model;
      }

      return this.model === this.value;
    },
    isModelArray() {
      return Array.isArray(this.model);
    },
    isModelBoolean() {
      return typeof this.model === "boolean";
    }
  },
  methods: {
    removeItemFromModel(newModel) {
      const index = newModel.indexOf(this.value);

      if (index !== -1) {
        newModel.splice(index, 1);
      }
    },
    handleArrayCheckbox() {
      const newModel = this.model;

      if (!this.isSelected) {
        newModel.push(this.value);
      } else {
        this.removeItemFromModel(newModel);
      }

      this.$emit("change", newModel);
    },
    handleStringCheckbox() {
      if (!this.isSelected) {
        this.$emit("change", this.value);
      } else {
        this.$emit("change", null);
      }
    },
    handleBooleanCheckbox() {
      this.$emit("change", !this.model);
    },
    toggleCheck() {
      if (!this.disabled) {
        if (this.isModelArray) {
          this.handleArrayCheckbox();
        } else if (this.isModelBoolean) {
          this.handleBooleanCheckbox();
        } else {
          this.handleStringCheckbox();
        }
      }
    }
  }
};
