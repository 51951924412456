export function formatDateString() {
  const curdate = new Date();
  const currentdate = curdate.toString();
  const datestring = currentdate.split("GMT");
  const datefinal = datestring[0].split(" ");
  const time = datefinal[4].split(":");
  function MonthToNumber(month) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    for (let i = 0; i < months.length; i++) {
      if (month == months[i]) return i + 1;
    }
    return null;
  }
  const datetostore =
    datefinal[3] +
    "/" +
    MonthToNumber(datefinal[1]) +
    "/" +
    datefinal[2] +
    " " +
    time[0] +
    ":" +
    time[1];
  return datetostore;
}

export function formatDateMilliseconds(milliseconds) {
  // totalSeconds = parseInt(Math.floor(milliseconds / 1000));
  const totalSeconds = parseInt(milliseconds);
  const totalMinutes = parseInt(Math.floor(milliseconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));
  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours % 24);

  return days + "d:" + hours + "h:" + minutes + "m:" + seconds + "s";
}
export function formatDateTime(datetime) {
  return new Date(+datetime * 1000).toLocaleString();
}
