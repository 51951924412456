/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "fa-sort": {
    width: 16,
    height: 16,
    viewBox: "0 0 334 430",
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M179.76 5.08a19 19 0 00-25.86 0L6.1 142.4c-12.64 11.76-4.33 32.93 12.94 32.93h295.58c17.27 0 25.58-21.17 12.93-32.92L179.76 5.08zm0 418.97a19 19 0 01-25.86 0L6.1 286.73c-12.64-11.75-4.33-32.92 12.94-32.92h295.58c17.27 0 25.58 21.17 12.93 32.92L179.76 424.05z" fill="#000"/>'
  }
});
