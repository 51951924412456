/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const router = new Router({
    mode: "history",
    base: "/",
    routes: require("./cloudRoutes").cloudRoutes
});
router.beforeEach((to, from, next) => {
    var _a;
    document.title = [(_a = to.meta) === null || _a === void 0 ? void 0 : _a.title, "AxIS Vue"].filter(s => !!s).join(" - ");
    //Fix because app sends double slash occasionally
    if (to.fullPath.includes("//")) {
        const path = to.fullPath.replace("//", "/");
        next(path);
    }
    else
        next();
});
// Make router accessible in Cypress tests via window
if (window.Cypress) {
    window.router = router;
}
export default router;
if (process.env.NODE_ENV === "development") {
    router.addRoutes([
        {
            name: "icons",
            path: "/icons",
            component: () => import("./shared/components/IconsViewer.vue")
        }
    ]);
}
