/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diameter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m5 13v-2H7v2l-3-3 3-3v2h10V9l3 3-3 3z"/>'
  }
})
