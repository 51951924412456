import { removeFromArray } from "@axisvue/utils";
export function _clearShapesFromData(data) {
    const local = { ...data };
    local.shapes.byId = {};
    local.shapes.ids = [];
    return local;
}
export function _deleteShapes(data, areaNames) {
    const local = { ...data };
    areaNames.forEach(areaName => {
        if (local.shapes.byId[areaName]) {
            delete local.shapes.byId[areaName];
            local.shapes.ids = removeFromArray(local.shapes.ids, areaName);
        }
    });
    return local;
}
