/**
 * Converts string value to boolean.
 * Example: "true" -> true
 * @param stringValue
 */
export default function stringToBoolean(stringValue) {
    var _a;
    switch ((_a = stringValue === null || stringValue === void 0 ? void 0 : stringValue.toLowerCase()) === null || _a === void 0 ? void 0 : _a.trim()) {
        case "true":
        case "yes":
        case "1":
            return true;
        case "false":
        case "no":
        case "0":
        case null:
        case undefined:
            return false;
        default:
            return JSON.parse(stringValue);
    }
}
