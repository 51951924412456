import { CalendarEventDisplay, CalendarEventType, CalendarView } from "@axisvue/calendar";
import { CalendarEventColor, CalendarEventState } from "../_providers/types/ICloudCalendarEvent";
import { DeviceType } from "../shared/enums";
function updateScanStates(events, selectedDevice) {
    events.forEach(event => {
        var _a, _b, _c, _d, _e, _f;
        if (selectedDevice.hasRobotArmAttached) {
            if (((_b = (_a = event.extendedProps) === null || _a === void 0 ? void 0 : _a.experimentOwner) === null || _b === void 0 ? void 0 : _b.email) !==
                window.GLOBAL_CURRENT_USER_EMAIL) {
                event.backgroundColor = CalendarEventColor.RandomAny;
                event.borderColor = CalendarEventColor.RandomAny;
                return;
            }
        }
        // busy slot always appear with color associated to a completed event/scan.
        if (((_c = event.extendedProps) === null || _c === void 0 ? void 0 : _c.eventType) === CalendarEventType.BusySlot) {
            event.borderColor = CalendarEventColor.ActiveCompleted;
            event.backgroundColor = CalendarEventColor.ActiveCompleted;
            return;
        }
        // for omni2/lux if event is pass current time, then it completed.
        if (event.end && event.end <= new Date().toISOString()) {
            event.borderColor = CalendarEventColor.ActiveCompleted;
            event.backgroundColor = CalendarEventColor.ActiveCompleted;
            return;
        }
        if (((_d = event.extendedProps) === null || _d === void 0 ? void 0 : _d.deviceId) === selectedDevice.id) {
            switch ((_e = event.extendedProps) === null || _e === void 0 ? void 0 : _e.eventState) {
                case CalendarEventState.COMPLETED:
                case CalendarEventState.PROCESSING:
                    event.borderColor = CalendarEventColor.ActiceProcessing;
                    event.backgroundColor = CalendarEventColor.ActiceProcessing;
                    break;
                case CalendarEventState.FAILED:
                    event.borderColor = CalendarEventColor.ActiveFailed;
                    event.backgroundColor = CalendarEventColor.ActiveFailed;
                    break;
                default:
                    event.borderColor = CalendarEventColor.ActiveWaiting;
                    event.backgroundColor = CalendarEventColor.ActiveWaiting;
            }
            if ((event.end && event.end < new Date().toISOString()) ||
                ((_f = event.extendedProps) === null || _f === void 0 ? void 0 : _f.eventType) === CalendarEventType.BusySlot) {
                event.borderColor = CalendarEventColor.ActiveCompleted;
                event.backgroundColor = CalendarEventColor.ActiveCompleted;
            }
        }
    });
}
/**
 * Sets the visibility (auto/none) of events on the calendar based on filtering
 * @param {IRegisteredDevice} selectedDevice - selected device for the calendar view
 * @param {ICloudCalendarEvent[]} events - calendar events
 * @param {string} calendarView - current calendar view - monthly/weekly
 */
export function setVisibilityOfCalendarEventsBasedOnSelection(selectedDevice, events, calendarView) {
    if (selectedDevice) {
        events.map(event => {
            var _a, _b, _c;
            if (((_a = event === null || event === void 0 ? void 0 : event.extendedProps) === null || _a === void 0 ? void 0 : _a.deviceId) !== selectedDevice.id) {
                // if event does not belong to selected device, remove it from the calendar view
                event.display = CalendarEventDisplay.None;
                return;
            }
            if (((_b = event.extendedProps) === null || _b === void 0 ? void 0 : _b.eventState) === CalendarEventState.SKIPPED) {
                // if event is skipped/conflicting then the calendar should not show it
                event.display = CalendarEventDisplay.None;
                return;
            }
            if (selectedDevice.type === DeviceType.Lux) {
                event.display = CalendarEventDisplay.Auto;
                return;
            }
            switch ((_c = event.extendedProps) === null || _c === void 0 ? void 0 : _c.eventType) {
                case CalendarEventType.Experiment:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.Auto
                            : CalendarEventDisplay.None;
                    break;
                case CalendarEventType.Scan:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.None
                            : CalendarEventDisplay.Auto;
                    break;
                case CalendarEventType.BusySlot:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.None
                            : CalendarEventDisplay.Background;
            }
        });
    }
    updateScanStates(events, selectedDevice);
    return events;
}
