/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 12l-4-4v3H2v2h8v3m10 2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v3h2V6h12v12H6v-3H4v3a2 2 0 002 2h12a2 2 0 002-2z"/>'
  }
})
