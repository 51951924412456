/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scatter-plot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 11a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3m4-8a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3m5.6 11.6c1.65 0 3 1.34 3 3a3 3 0 01-3 3c-1.66 0-3-1.35-3-3a3 3 0 013-3z"/>'
  }
})
