import RepositoryFactory from "@/api/repositoryFactory";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function useGroupActions(experimentId) {
    const createGroup = async (group) => {
        try {
            await Omni3v1ExperimentsRepository.createGroup(experimentId, group);
        }
        catch (error) {
            throw new Error("Group could not be created\nTry again");
        }
    };
    const deleteGroup = async (id) => {
        try {
            await Omni3v1ExperimentsRepository.deleteGroup(experimentId, id.toString());
        }
        catch (error) {
            throw new Error("Group couldn't be deleted\nTry again");
        }
    };
    const editGroup = async (group) => {
        try {
            await Omni3v1ExperimentsRepository.editGroup(experimentId, group);
        }
        catch (error) {
            throw new Error("Group couldn't be updated\nTry again");
        }
    };
    return {
        createGroup,
        deleteGroup,
        editGroup
    };
}
