import { computed, defineComponent, ref } from "vue";
import { LCircle } from "vue2-leaflet";
import ShapeEnum from "@/pages/wellPlate/_providers/types/enums/ShapeEnum";
import { useExperimentConfigurations } from "@/pages/wellPlate/_providers/withExperimentConfigurations";
import { useVirtualWellplate } from "@/pages/wellPlate/_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useExperiment } from "@/pages/wellPlate/_providers/withExperiment";
import { useGroups } from "@/pages/wellPlate/_providers/withGroups/withGroups";
import { useSelect } from "@/pages/wellPlate/_providers/withSelect/withSelect";
import { getVirtualStrokeColor, getStrokeWeight } from "./index";
import ROI from "./ROI.vue";
import { useConfluence } from "@/pages/wellPlate/_providers/withConfluence";
import { useScans } from "@/pages/wellPlate/_providers/withScans/withScans";
import { useAlerts } from "@/pages/wellPlate/_providers/withAlerts/withAlerts";
export default defineComponent({
    components: {
        LCircle,
        ROI
    },
    props: {
        shape: {
            type: Object,
            required: true
        },
        enabledGroupedAreasInteraction: {
            type: Boolean,
            required: false,
            default: true
        },
        ignoreGroups: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["openLux3Experiment"],
    setup(props, { emit }) {
        const onHover = ref(false);
        const virtualWellPlateProvider = useVirtualWellplate();
        const { isFullStitch, experiment } = useExperiment();
        const { getGroupByAreasIds } = useGroups();
        const selector = useSelect();
        const { algorithms } = useExperimentConfigurations();
        const confluenceProvider = useConfluence();
        const rois = computed(() => virtualWellPlateProvider.getAllROIsInWell(props.shape.id));
        const { alert, showAlert } = useAlerts();
        const { completedScanTimepoints } = useScans();
        const hasAlertConditionMet = computed(() => {
            if (!isFullStitch.value || !alert.value || !algorithms.Confluence) {
                return false;
            }
            const latestCompletedScanTimepoint = completedScanTimepoints.value[completedScanTimepoints.value.length - 1];
            const confluence = confluenceProvider.confluence.value[props.shape.id].Brightfield.Data[latestCompletedScanTimepoint];
            return alert.value.HasBeenTriggered && (alert.value.OverUnderConfluencyTarget ? confluence >= alert.value.ConfluencyTarget : confluence <= alert.value.ConfluencyTarget);
        });
        const selected = computed(() => {
            if (props.shape.type === ShapeEnum.WELL) {
                return rois.value.every(roi => selector.selectedAreasIds.value.includes(roi.id));
            }
            return selector.selectedAreasIds.value.includes(props.shape.id);
        });
        const grouped = computed(() => {
            if (isFullStitch.value) {
                return !!getGroupByAreasIds([props.shape.id]);
            }
            return !!getGroupByAreasIds(rois.value.map(roi => roi.id));
        });
        const interactive = computed(() => props.enabledGroupedAreasInteraction ||
            (!props.enabledGroupedAreasInteraction && !grouped.value));
        const color = computed(() => getVirtualStrokeColor(props.shape, onHover.value, selected.value));
        const weight = computed(() => getStrokeWeight(selected.value, onHover.value, experiment.vesselType));
        const sortAlphaNum = (data, keyName) => data.sort((a, b) => a[keyName].localeCompare(b[keyName], "en", { numeric: true }));
        const getLux3ExperimentId = () => {
            var _a, _b;
            let lux3ExperimentId = "";
            if (props.shape.type === ShapeEnum.CIRCLE) {
                lux3ExperimentId = props.shape.lux3ExperimentId;
            }
            else if (props.shape.type === ShapeEnum.WELL) {
                const roisInWell = sortAlphaNum(virtualWellPlateProvider.getAllROIsInWell(props.shape.id), "id");
                lux3ExperimentId = (_b = (_a = roisInWell[0]) === null || _a === void 0 ? void 0 : _a.lux3ExperimentId) !== null && _b !== void 0 ? _b : "";
            }
            return lux3ExperimentId;
        };
        const select = () => {
            if (isFullStitch.value) {
                selector.selectItems([props.shape.id]);
            }
            else {
                const items = rois.value.map(roi => roi.id);
                selector.selectItems(items, { ignoreGroups: props.ignoreGroups });
            }
            onHover.value = false;
            onHover.value = true;
        };
        const handleClick = function () {
            if (interactive.value) {
                select();
            }
        };
        const handleDoubleClick = function () {
            if (interactive.value && getLux3ExperimentId()) {
                emit("openLux3Experiment", getLux3ExperimentId());
            }
        };
        return {
            onHover,
            rois,
            virtualWellPlateProvider,
            ShapeEnum,
            selected,
            color,
            interactive,
            handleClick,
            handleDoubleClick,
            weight,
            hasAlertConditionMet,
            showAlert
        };
    }
});
