/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20a8 8 0 008-8 8 8 0 00-8-8 8 8 0 00-8 8 8 8 0 008 8m0-18a10 10 0 0110 10 10 10 0 01-10 10C6.47 22 2 17.5 2 12A10 10 0 0112 2m.5 5v5.25l4.5 2.67-.75 1.23L11 13V7h1.5z"/>'
  }
})
