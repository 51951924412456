import { checkModifierKeyPressed } from "@/pages/wellPlate/_providers/withSelect/helpers/checkModifierKeyPressed";
export function useModifierKeyActions(modifierKeyActive) {
    const onKeyPressed = (event) => {
        if (event.repeat) {
            return;
        }
        modifierKeyActive.value = checkModifierKeyPressed(event);
    };
    const onKeyUp = () => {
        if (modifierKeyActive.value) {
            modifierKeyActive.value = false;
        }
    };
    const initModifierEvents = () => {
        document.addEventListener("keydown", onKeyPressed);
        document.addEventListener("keyup", onKeyUp);
    };
    const removeModifierEvents = () => {
        document.removeEventListener("keydown", onKeyPressed);
        document.removeEventListener("keyup", onKeyUp);
    };
    return {
        initModifierEvents,
        removeModifierEvents
    };
}
