import Vue, { ref, watch } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { convertAreasDataToObject } from "@/pages/wellPlate/_providers/helpers/convertAreasDataToObject";
import { getAreaNamesFromData } from "@/pages/wellPlate/_providers/helpers/getAreaNamesFromData";
import { getAreasWithAlgorithm } from "@/pages/wellPlate/_providers/helpers/getAreasWithAlgorithm";
import { AlgorithmValue } from "@/pages/wellPlate/_providers/types/enums/OverlayType";
import OmniExperimentType from "../_providers/types/enums/OmniExperimentType";
import { setupProvider } from "@/globals/setupProvider";
import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
const Omni3v1ExperimentsRepository = RepositoryFactory.omni3v1.experiments;
export function withAreas(experimentId, currentScan, experiment) {
    const areas = ref({});
    const areasStatus = ref([]);
    const areasPositions = ref([]);
    const areaChannels = ref([]);
    const areaNames = ref([]);
    const areasWithOrganoid = ref([]);
    const areasWithConfluence = ref([]);
    const areasWithObjectCount = ref([]);
    const areasWithClonogenic = ref([]);
    const areasWithScratch = ref([]);
    const areasWithPSC = ref([]);
    const areAreasFetched = ref(false);
    function removeAreasLocally(areaNamesInput) {
        areaNamesInput.forEach(areaName => {
            Vue.delete(areas.value, areaName);
            const index = areaNames.value.indexOf(areaName, 0);
            if (index > -1) {
                areaNames.value.splice(index, 1);
            }
            const confluenceIndex = areasWithConfluence.value.findIndex(area => area.name === areaName);
            if (confluenceIndex > -1) {
                areasWithConfluence.value.splice(confluenceIndex, 1);
            }
            const organoidIndex = areasWithOrganoid.value.findIndex((element) => element.name === areaName);
            if (organoidIndex > -1) {
                areasWithOrganoid.value.splice(organoidIndex, 1);
            }
            const objectCountIndex = areasWithObjectCount.value.findIndex(area => area.name === areaName);
            if (objectCountIndex > -1) {
                areasWithObjectCount.value.splice(objectCountIndex, 1);
            }
            const scratchIndex = areasWithScratch.value.findIndex(area => area.name === areaName);
            if (scratchIndex > -1) {
                areasWithScratch.value.splice(scratchIndex, 1);
            }
            const clonogenicIndex = areasWithClonogenic.value.findIndex(area => area.name === areaName);
            if (clonogenicIndex > -1) {
                areasWithClonogenic.value.splice(clonogenicIndex, 1);
            }
            const pscIndex = areasWithPSC.value.findIndex(area => area.name === areaName);
            if (pscIndex > -1) {
                areasWithPSC.value.splice(pscIndex, 1);
            }
        });
    }
    async function deleteAreas(ids) {
        const areasToDelete = { data: { names: ids } };
        try {
            await Omni3v1ExperimentsRepository.deleteAreas(experimentId, areasToDelete);
            removeAreasLocally(ids);
        }
        catch (error) {
            console.warn("Error while deleting processed shapes: ", error);
        }
    }
    async function fetchAreasRelativePositions(scan) {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getAreasPositionsRelative(experimentId, scan);
            areasPositions.value = data;
        }
        catch (error) {
            console.warn("Error while fetching relative positions: ", error);
        }
    }
    async function fetchAreasAbsolutePositions() {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getAreasPositionsAbsolute(experimentId);
            areasPositions.value = data;
        }
        catch (error) {
            console.warn("Error while fetching absolute positions: ", error);
        }
    }
    async function fetchAreasPositions(scan) {
        if (experiment.experimentType === OmniExperimentType.FULL_SCAN) {
            await fetchAreasRelativePositions(scan);
        }
        else {
            await fetchAreasAbsolutePositions();
        }
    }
    async function fetchAreas(scan, omni3ExperimentId = experimentId) {
        areAreasFetched.value = false;
        try {
            experimentId = omni3ExperimentId;
            const { data } = await Omni3v1ExperimentsRepository.getAreas(experimentId);
            areas.value = convertAreasDataToObject(data);
            areaNames.value = getAreaNamesFromData(data);
            areaChannels.value = data[0].selectedChannels;
            areasWithConfluence.value = getAreasWithAlgorithm(data, AlgorithmValue.CONFLUENCE);
            areasWithObjectCount.value = getAreasWithAlgorithm(data, AlgorithmValue.OBJECT_COUNT);
            areasWithScratch.value = getAreasWithAlgorithm(data, AlgorithmValue.SCRATCH);
            areasWithOrganoid.value = getAreasWithAlgorithm(data, AlgorithmValue.ORGANOID);
            areasWithClonogenic.value = getAreasWithAlgorithm(data, AlgorithmValue.CLONOGENIC);
            areasWithPSC.value = getAreasWithAlgorithm(data, AlgorithmValue.PSC);
            await fetchAreasPositions(scan);
        }
        catch (error) {
            console.warn("Error while fetching areas: ", error);
        }
        areAreasFetched.value = true;
    }
    async function fetchAreasStatus(scan) {
        try {
            const { data } = await Omni3v1ExperimentsRepository.getAreasStatus(experimentId, scan);
            areasStatus.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            console.warn("Error while fetching areas status: ", error);
        }
    }
    async function processNewAreas(areasToProcess) {
        try {
            return await Omni3v1ExperimentsRepository.postCreateAreas(experimentId, areasToProcess);
        }
        catch (error) {
            console.warn("Error while processing new areas: ", error);
            throw new Error(error);
        }
    }
    async function processExistingAreas(existingAreasToProcess) {
        try {
            return await Omni3v1ExperimentsRepository.postExistingAreasProcess(experimentId, existingAreasToProcess);
        }
        catch (error) {
            console.warn("Error while processing existing areas: ", error);
            throw new Error(error);
        }
    }
    watch(currentScan, (currentScan) => {
        if (experiment.hasLoaded) {
            fetchAreas(currentScan);
            fetchAreasStatus(currentScan);
        }
    });
    return {
        areas,
        areaChannels,
        areasPositions,
        areasStatus,
        areaNames,
        areasWithConfluence,
        areasWithScratch,
        areasWithObjectCount,
        areasWithOrganoid,
        areasWithClonogenic,
        areasWithPSC,
        areAreasFetched,
        fetchAreas,
        fetchAreasStatus,
        processNewAreas,
        deleteAreas,
        processExistingAreas,
        removeAreasLocally
    };
}
export const { provide: provideAreas, use: useAreas } = setupProvider();
