import { computed, ref } from "vue";
import { setupProvider } from "@/globals/setupProvider";
import { useReservations } from "@/shared/providers/withReservations/withReservations";
export function withCreateReservation() {
    const { createReservation } = useReservations();
    // body of reservation, when the user clicks on the calendar
    const newReservation = ref({
        reservationId: 0,
        name: "Reservation",
        deviceId: -1,
        startOffset: new Date().toISOString(),
        owner: {
            name: window.GLOBAL_CURRENT_USER_FULL_NAME,
            email: window.GLOBAL_CURRENT_USER_EMAIL
        },
        sampleInterval: 45,
        estimateSampleDuration: 15,
        estimateNumberOfSamples: 2,
        redChannel: false,
        greenChannel: false,
        wells: 6,
        rois: 1
    });
    const newReservationId = computed({
        get: () => newReservation.value.reservationId,
        set: (value) => {
            newReservation.value.reservationId = value;
        }
    });
    const newReservationName = computed({
        get: () => newReservation.value.name,
        set: (value) => {
            newReservation.value.name = value;
        }
    });
    const newReservationDeviceId = computed({
        get: () => newReservation.value.deviceId,
        set: (value) => {
            newReservation.value.deviceId = value;
        }
    });
    const newReservationStartOffset = computed({
        get: () => newReservation.value.startOffset,
        set: (value) => {
            newReservation.value.startOffset = value;
        }
    });
    const newReservationSampleInterval = computed({
        get: () => newReservation.value.sampleInterval,
        set: (value) => {
            newReservation.value.sampleInterval = value;
        }
    });
    const newReservationSampleDuration = computed({
        get: () => newReservation.value.estimateSampleDuration,
        set: (value) => {
            newReservation.value.estimateSampleDuration = value;
        }
    });
    const newReservationEstimateNumberOfSamples = computed({
        get: () => newReservation.value.estimateNumberOfSamples,
        set: (value) => {
            newReservation.value.estimateNumberOfSamples = value;
        }
    });
    const newReservationRedChannel = computed({
        get: () => newReservation.value.redChannel,
        set: (value) => {
            newReservation.value.redChannel = value;
        }
    });
    const newReservationGreenChannel = computed({
        get: () => newReservation.value.greenChannel,
        set: (value) => {
            newReservation.value.greenChannel = value;
        }
    });
    const newReservationWellplate = computed({
        get: () => newReservation.value.wells,
        set: (value) => {
            newReservation.value.wells = value;
        }
    });
    const newReservationRois = computed({
        get: () => newReservation.value.rois,
        set: (value) => {
            newReservation.value.rois = value;
        }
    });
    // after a reservation has been created successfuly (or "Remove") then the values for the constructor of new reservation
    // are reset --> check App.vue
    function resetValues() {
        newReservationName.value = "Reservation";
        newReservationId.value = 0;
        newReservationDeviceId.value = -1;
        newReservationStartOffset.value = new Date().toISOString();
        newReservationSampleDuration.value = 15;
        newReservationEstimateNumberOfSamples.value = 2;
        newReservationGreenChannel.value = false;
        newReservationRedChannel.value = false;
        newReservationSampleInterval.value = 45;
        newReservationWellplate.value = 6;
        newReservationRois.value = 1;
    }
    // Calls the api to actually create reservation
    async function asyncCreateReservation() {
        const reservationId = await createReservation(newReservation.value);
        newReservationId.value = reservationId;
    }
    return {
        resetValues,
        asyncCreateReservation,
        newReservation,
        newReservationId,
        newReservationDeviceId,
        newReservationEstimateNumberOfSamples,
        newReservationGreenChannel,
        newReservationRedChannel,
        newReservationName,
        newReservationSampleDuration,
        newReservationSampleInterval,
        newReservationStartOffset,
        newReservationWellplate,
        newReservationRois
    };
}
export const { provide: provideCreateReservation, use: useCreateReservation } = setupProvider();
