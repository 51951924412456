/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud-check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 17l-3.5-3.5 1.41-1.42L10 14.17 15.18 9l1.41 1.41m2.76-.38A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.03A6.004 6.004 0 000 14a6 6 0 006 6h13a5 5 0 005-5c0-2.64-2.05-4.78-4.65-4.97z"/>'
  }
})
