/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 0110 10 10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2m0 2a8 8 0 00-8 8c0 1.85.63 3.55 1.68 4.91L16.91 5.68A8.005 8.005 0 0012 4m0 16a8 8 0 008-8c0-1.85-.63-3.55-1.68-4.91L7.09 18.32A8.005 8.005 0 0012 20z"/>'
  }
})
