import Vue from "vue";

import axis from "./axis";
import visible from "./visible";
import { directive as vueClickaway } from "./vueClickaway";

Vue.directive("axis", axis);

// Register a global custom directive called `v-focus`
Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  }
});
Vue.directive("visible", visible);
Vue.directive("onClickaway", vueClickaway);
