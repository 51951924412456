import { computed, defineComponent } from "vue";
import AppDropdown from "@/shared/ui/dropdown/AppDropdown.vue";
import AppDropdownMenu from "@/shared/ui/dropdown/AppDropdownMenu.vue";
import AppDropdownItem from "@/shared/ui/dropdown/AppDropdownItem.vue";
import { useProjectOptionActions } from "@/shared/providers/withProjects/withProjectExperiments/useProjectOptionActions/useProjectOptionActions";
export default defineComponent({
    components: { AppDropdown, AppDropdownMenu, AppDropdownItem },
    props: {
        project: {
            type: Object,
            required: true
        },
        isProjectFlagged: {
            type: Boolean,
            default: false
        },
        isOmni: {
            type: Boolean,
            required: true
        },
        numberOfExperiments: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit }) {
        const projectActions = computed(() => props.project.availableActions.filter(action => action.isAvailable));
        const { getProjectOptionAction, getProjectOptionActionDescription, getProjectOptionActionIcon } = useProjectOptionActions();
        const actionHandler = async (action) => {
            emit(getProjectOptionAction(action, props.project.isFollowing));
        };
        const actionDescription = (action) => getProjectOptionActionDescription(action, props.project.isFollowing);
        const iconName = (action) => getProjectOptionActionIcon(action, props.project.isFollowing);
        return {
            projectActions,
            actionDescription,
            actionHandler,
            iconName
        };
    }
});
