/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share-off-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.1 21.5L2.4 1.7 1.1 3l7.8 7.8C5.4 12.9 3.7 16.4 3 20c2.3-3.3 5.6-4.9 10-5.1l1 1V19l1.6-1.6 5.2 5.3M8.3 13.8c.64-.58 1.35-1.09 2.1-1.5l.9.8m2.9-2.1l-1.7-1.7L14 9V5l7 7-2.9 2.9-1.4-1.4 1.5-1.5L16 9.8v.9"/>'
  }
})
