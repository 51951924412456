/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-multiple-blank': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 16a2 2 0 01-2 2H8a2 2 0 01-2-2V4c0-1.11.89-2 2-2h12a2 2 0 012 2v12m-6 4v2H4a2 2 0 01-2-2V7h2v13h12z"/>'
  }
})
