/**
 * Given a list of functions and a value, returns a list of the function results when called with that value
 * @param {(value: T) => R} functions - List of functions that will be called
 * @param {T} value - Value to use as argument
 * @returns {R[]}
 * @example
 * const double = (nr: number) => nr*2;
 * const square = (nr: number) => nr*nr;
 *
 * mapValueToFunctions([double, square], 3) //returns [6, 9]
 */
function mapValueToFunctions(functions, value) {
    return functions.map(fn => fn(value));
}
export default mapValueToFunctions;
