import { onMounted, onUnmounted, ref, unref } from "vue";
export function useDetectTruncatedDom(domNode) {
    let element = unref(domNode);
    const truncated = ref(false);
    const checkIsElementTruncated = () => {
        if (element) {
            truncated.value = element.offsetWidth < element.scrollWidth;
        }
        else {
            truncated.value = false;
        }
    };
    onMounted(() => {
        element = unref(domNode); // in case when domNode is ref, we must set it again onMounted since only then it actually contains value
        checkIsElementTruncated();
        window.addEventListener("resize", checkIsElementTruncated);
    });
    onUnmounted(() => {
        window.removeEventListener("resize", checkIsElementTruncated);
    });
    return {
        truncated,
        checkIsElementTruncated
    };
}
