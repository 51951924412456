/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'note': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 10V4.5l5.5 5.5M5 3c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V9l-6-6H5z"/>'
  }
})
