/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'license': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 10a3.04 3.04 0 013-3 3.04 3.04 0 013 3 3.04 3.04 0 01-3 3 3.04 3.04 0 01-3-3m3 9l4 1v-3.08A7.54 7.54 0 0112 18a7.54 7.54 0 01-4-1.08V20m4-16a5.78 5.78 0 00-4.24 1.74A5.78 5.78 0 006 10a5.78 5.78 0 001.76 4.23A5.78 5.78 0 0012 16a5.78 5.78 0 004.24-1.77A5.78 5.78 0 0018 10a5.78 5.78 0 00-1.76-4.26A5.78 5.78 0 0012 4m8 6a8.04 8.04 0 01-.57 2.8A7.84 7.84 0 0118 15.28V23l-6-2-6 2v-7.72A7.9 7.9 0 014 10a7.68 7.68 0 012.33-5.64A7.73 7.73 0 0112 2a7.73 7.73 0 015.67 2.36A7.68 7.68 0 0120 10z"/>'
  }
})
