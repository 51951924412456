import Repository from "@/api/repository";
import { controllerRoutePrefix } from "../controllerRoutePrefix";
const resource = `${controllerRoutePrefix}/experiments`;
export default {
    async getSnapshots(experimentId) {
        const allData = await Promise.all([
            this.getOriginal(experimentId),
            this.getScratch(experimentId),
            this.getConfluence(experimentId),
            this.getScratchData(experimentId),
            this.getTemperatureData(experimentId),
            this.getMetadata(experimentId),
            this.getMaskShape(experimentId)
        ]);
        return {
            Original: allData[0].data,
            Scratch: allData[1].data,
            Confluence: allData[2].data,
            ScratchData: allData[3].data,
            TemperatureData: allData[4].data,
            Metadata: allData[5].data,
            MaskShape: allData[6].data
        };
    },
    /**
     * Gets the original overlays per timestamp for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns All snapshots for the selected channels of an experiment per timestamp.
     */
    getOriginal(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/original`);
    },
    /**
     * Gets the scratch overlays per timestamp for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns All brightfield scratch overlays of an experiment per timestamp.
     */
    getScratch(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/scratch`);
    },
    /**
     * Gets the confluence overlays per timestamp for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns All brightfield confluence overlays of an experiment per timestamp.
     */
    getConfluence(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/confluence`);
    },
    /**
     * Gets the scratch data per timestamp for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns All scratch data of an experiment per timestamp.
     */
    getScratchData(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/scratchData`);
    },
    /**
     * Gets the temperature data per timestamp for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns All temperature data of an experiment per timestamp.
     */
    getTemperatureData(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/temperatureData`);
    },
    /**
     * Gets the metadata for an experiment. Provides information such as PPMM and
     * image dimentions.
     * @param experimentId The unique identifier of the experiment.
     * @returns The metadata of the experiment.
     */
    getMetadata(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/metadata`);
    },
    /**
     * Only applicable for omni devices. Gets the mask shape for an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns The mask shape for the experiment if applicable, else null.
     */
    getMaskShape(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots/maskShape`);
    },
    /**
     * Gets the details about an experiment.
     * @param experimentId The unique identifier of the experiment.
     * @returns Details about the experiment.
     */
    getDetails(experimentId) {
        return Repository.get(`${resource}/${experimentId}/details`);
    }
};
