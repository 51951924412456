import { defineComponent } from "vue";
import NavBar from "@/shared/components/navigation/NavBar/index.vue";
import BreadCrumbs from "@/shared/components/navigation/BreadCrumbs/index.vue";
export default defineComponent({
    components: { NavBar, BreadCrumbs },
    props: {
        isApp: { type: Boolean, default: false },
        classes: {
            type: String,
            default: "mb-16"
        },
        isOmniWell: {
            type: Boolean,
            required: false,
            default: false
        },
        showBreadCrumbs: {
            type: Boolean,
            required: false,
            default: true
        },
        experimentId: {
            type: String,
            default: ""
        },
        experimentReadOnly: {
            type: Boolean,
            default: false
        },
        omniExperimentId: {
            type: String,
            default: ""
        },
        showExperimentsNavigation: {
            type: Boolean,
            required: false,
            default: true
        },
        isLoggedInUserExperimentOwner: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup() {
        const app = window.GLOBAL_IS_APP || false;
        return {
            app
        };
    }
});
