/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "window-maximize": {
    width: 16,
    height: 16,
    viewBox: "0 0 24 24",
    data: '<path pid="0" fill="currentColor" d="M4 4h16v16H4V4m2 4v10h12V8H6z"/>'
  }
});
