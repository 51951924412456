/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-bold-circle-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 12l5-5v3h4v4h-4v3l-5-5m15 0a10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2a10 10 0 0110 10m-2 0a8 8 0 00-8-8 8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8z"/>'
  }
})
