<template>
  <div>
    <div
      v-for="(error, i) in errors"
      :key="'error-' + i"
      class="mt-1 text-xs text-red-600"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
};
</script>
