export default {
  install: function (Vue) {
    const toastrContainer = document.createElement("div");
    toastrContainer.className = "fixed mt-4 mr-4 top-0 right-0 toastr";
    document.body.appendChild(toastrContainer);

    function shouldCreateToastie(message, type) {
      const htmlArray = Array.from(
        toastrContainer.getElementsByClassName(type)
      );

      return htmlArray.filter(div => div.innerText === message).length < 1;
    }

    function addToastie(message, type, time = 4000) {
      if (shouldCreateToastie(message, type)) {
        const node = document.createElement("div");
        node.setAttribute("data-test-id", "toastr");
        node.className = `toastie ${type}`;

        const messageNode = document.createElement("span");
        messageNode.className = "whitespace-pre-wrap";
        messageNode.innerText = message;

        node.appendChild(messageNode);

        toastrContainer.appendChild(node);

        node.addEventListener("click", function () {
          node.remove();
        });

        window.setTimeout(function () {
          node.remove();
        }, time);
      }
    }

    Vue.prototype.$toastr = {
      success(message, time = 4000) {
        addToastie(message, "toastie-green w-auto", time);
      },
      error(message, time = 4000) {
        addToastie(message, "toastie-red w-auto", time);
      },
      warning(message, time = 4000) {
        addToastie(message, "toastie-yellow w-auto", time);
      }
    };
  }
};
