import { ProjectsFilterByOwnerLabels } from "@/shared/providers/withProjects/useProjectsFilters/types/ProjectsFilterByOwnerTypes";
import { IFilterByOwnerValues } from "@/shared/providers/withProjects/types/IFilterByOwnerValues";
export const PROJECTS_FILTER_BY_OWNER_OPTIONS = [
    {
        value: IFilterByOwnerValues.ALL,
        label: ProjectsFilterByOwnerLabels.ALL_PROJECTS
    },
    {
        value: IFilterByOwnerValues.OWNED,
        label: ProjectsFilterByOwnerLabels.MY_PROJECTS
    }
];
export const DEFAULT_PROJECTS_FILTER_BY_OWNER_OPTION = IFilterByOwnerValues.ALL;
