/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "window-minimize": {
    width: 16,
    height: 16,
    viewBox: "0 0 24 24",
    data: '<path pid="0" fill="currentColor" d="M20 14H4v-4h16"/>'
  }
});
