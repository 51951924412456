/**
 * Returns true if number in range, false otherwise.
 * Includes the min and max of the range.
 * @param value
 * @param range
 * @returns
 */
export function rangeFilter(value, range) {
    return value >= range[0] && value <= range[1];
}
