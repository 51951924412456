/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots-horizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 12a2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2m-6 0a2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2m-6 0a2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2z"/>'
  }
})
