import Repository from "@/api/repository";
import { baseUrl } from "../base";
const resource = `${baseUrl}/experiments`;
export default {
    getSnapshots(experimentId) {
        return Repository.get(`${resource}/${experimentId}/snapshots`);
    },
    getDetails(experimentId) {
        return Repository.get(`${resource}/${experimentId}/details`);
    },
    getConfluenceResults(experimentId) {
        return Repository.get(`${resource}/${experimentId}/confluenceresults`);
    },
    getObjectCountResults(experimentId) {
        return Repository.get(`${resource}/${experimentId}/ObjectCountResults`);
    },
    postChannelSettings(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/overlaysetting`, model);
    },
    postChannelSettingsNew(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/multipleoverlaysettings`, model);
    }
};
