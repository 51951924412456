<template>
  <on-click-outside :do="close">
    <div class="relative" :class="{ 'is-active': isOpen }">
      <div class="border-box flex w-full items-center">
        <input
          v-show="!isOpen"
          data-test-id="placeholder"
          :value="searchPlaceholder"
          :placeholder="placeholder"
          class="search-select-search rounded rounded-r-none border border-r-0 border-gray-400 bg-white px-3 py-2"
          :class="[disabled ? 'cursor-not-allowed' : '', inputClasses]"
          :disabled="disabled"
          @focus="open"
        />
        <input
          v-show="isOpen"
          ref="search"
          v-model="search"
          data-test-id="search"
          class="search-select-search rounded rounded-r-none border border-r-0 border-gray-400 bg-white px-3 py-2"
          :class="inputClasses"
          :disabled="disabled"
          @keydown.esc="close"
          @keydown.up="highlightPrev"
          @keydown.down="highlightNext"
          @keydown.enter.prevent="selectHighlighted"
          @keydown.tab.prevent
          @keydown="$emit('keydown', $event)"
          @input="searched = true"
        />
        <div
          :class="inputClasses"
          class="rounded-r border-r border-t border-b border-gray-400 text-gray-500"
        >
          <i
            v-visible="filteredOptsArray.length > 0"
            class="fa fa-chevron-down cursor-pointer p-3"
            aria-hidden="true"
            data-test-id="chevron"
            :class="{
              up: isOpen,
              down: !isOpen
            }"
            @click="toggle"
          />
        </div>
      </div>
      <div class="search-select-dropdown rounded bg-white">
        <ul
          v-if="isOpen && filteredOptsArray.length > 0"
          ref="options"
          data-test-id="options"
          class="search-select-options"
        >
          <li
            v-for="(option, i) in filteredOptsArray"
            :key="i"
            class="search-select-option w-32 truncate rounded px-3 py-2"
            :class="{ 'is-active': i === highlightedIndex }"
            @click="select(option, i)"
            @mouseover="$emit('mouseoveroption', reduce(option))"
            @mouseleave="$emit('mouseoveroption', reduce(createOption('')))"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="menuView(option)"></span>
          </li>
        </ul>
        <div
          v-if="isOpen && filteredOptsArray.length === 0 && !taggable"
          class="search-select-empty px-3 py-2"
        >
          No results found for "{{ search }}"
        </div>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import { OnClickOutside } from "../OnClickOutside";

const filterDefault = (search, options, optionLabelKey) => {
  return options.filter(option => {
    if (!search) return true;
    if (typeof option !== "object") {
      return option.toLowerCase().includes(search.toLowerCase());
    }
    // if key for option label is not specified, look in every option's object property for search value.
    if (optionLabelKey) {
      return option[optionLabelKey]
        .toLowerCase()
        .includes(search.toLowerCase());
    } else {
      for (const property in option) {
        if (option[property].toLowerCase().includes(search.toLowerCase()))
          return true;
      }
    }
  });
};

const reduceDefault = option => option;

export default {
  components: {
    OnClickOutside
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    default: {
      type: String,
      required: false,
      default: ""
    },
    filterFunctions: {
      type: Array,
      required: false,
      default: () => [filterDefault]
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    options: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    optionLabelKey: {
      type: String,
      required: false,
      default: () => null
    },
    inputClasses: {
      type: String,
      required: false,
      default: ""
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false
    },
    reduce: {
      type: Function,
      default: reduceDefault
    },
    createOption: {
      type: Function,
      default: reduceDefault
    },
    searchView: {
      type: Function,
      default: reduceDefault
    },
    menuView: {
      type: Function,
      default: reduceDefault
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      search: "",
      searchPlaceholder: "",
      searched: false,
      highlightedIndex: 0,
      initial: true
    };
  },
  computed: {
    searchOption() {
      return this.createOption(this.search);
    },
    foundOption() {
      return this.searched
        ? this.findOption(this.searchOption)
        : this.searchOption;
    },
    filteredOptsArray() {
      const opts = this.options;
      if (this.filterFunctions.length === 0 || !this.searched) return opts;

      let filteredOpts =
        this.taggable && this.search && !this.foundOption
          ? [this.searchOption]
          : [];

      const remaining = this.filterFunctions.reduce((result, func) => {
        result = func(this.search, opts, this.optionLabelKey);
        return result;
      }, this.search);
      filteredOpts = filteredOpts.concat(remaining);
      return filteredOpts;
    }
  },
  watch: {
    value: {
      handler(value) {
        const option = this.initial
          ? this.createOption(this.default)
          : this.createOption(value);
        value = this.findOption(option) || option;

        this.search = this.searchView(value);
        this.searchPlaceholder = this.searchView(value);

        this.highlightCurrentValue();

        this.initial = false;
      },
      immediate: true
    },
    search: {
      handler() {
        this.$emit(
          "update:search",
          this.reduce(this.findOption(this.searchOption) || this.searchOption)
        );
      },
      immediate: true
    }
  },
  methods: {
    findOption(option) {
      const array = this.options;
      const keys = Object.keys(option);
      return array.find(opt => {
        for (const key of keys) {
          if (opt[key] && option[key] && opt[key] === option[key]) {
            return true;
          }
        }
      });
    },
    select(option, index) {
      this.$emit("input", this.reduce(option));
      this.highlightedIndex = index;
      this.close();
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    open() {
      this.searched = false;
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.search.focus();
        this.scrollToHighlighted();
      });
    },
    close() {
      this.searched = false;
      this.isOpen = false;
    },
    selectHighlighted() {
      if (
        this.filteredOptsArray.length > 0 &&
        this.filteredOptsArray[this.highlightedIndex]
      ) {
        this.select(
          this.filteredOptsArray[this.highlightedIndex],
          this.highlightedIndex
        );
      }
    },
    scrollToHighlighted() {
      const element = this.$refs.options?.children[this.highlightedIndex];
      if (!element) return;

      element.scrollIntoView({
        block: "nearest"
      });
    },
    highlight(index) {
      this.highlightedIndex = index;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.filteredOptsArray.length - 1;
      } else if (this.highlightedIndex > this.filteredOptsArray.length - 1) {
        this.highlightedIndex = 0;
      }

      this.scrollToHighlighted();
    },
    highlightPrev() {
      this.highlight(this.highlightedIndex - 1);
    },
    highlightNext() {
      this.highlight(this.highlightedIndex + 1);
    },
    highlightCurrentValue() {
      const selectedValueIndex = this.options.findIndex(
        item => item === this.value
      );

      if (selectedValueIndex > -1) {
        this.highlightedIndex = selectedValueIndex;
      }
    }
  }
};
</script>
<style scoped>
.up {
  transform: rotateZ(+180deg);
  transition: transform 0.5s ease, visibility 0.5s linear, opacity 0.5s linear;
}

.down {
  transition: transform 0.5s ease, visibility 0.5s linear, opacity 0.5s linear;
}

.search-select-placeholder {
  color: #8795a1;
}

.search-select.is-active .search-select-input {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
}

.search-select-dropdown {
  position: absolute;
  overflow: auto;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.search-select-search {
  display: block;
  width: 100%;
}

.search-select-search:focus {
  outline: 0;
}

.search-select-options {
  display: inline-block;
  float: left;
  min-width: 100%;
  list-style: none;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 14rem;
}

.search-select-option {
  min-width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.search-select-option:hover {
  background-color: #c1c1c1;
}

.search-select-option.is-active,
.search-select-option.is-active:hover {
  background-color: #c1c1c1;
}

.search-select-empty {
  color: #b8c2cc;
}
</style>
