import { CalendarEventDisplay, CalendarEventType } from "@axisvue/calendar";
import DeviceType from "@/types/DeviceType";
import { CalendarEventColor } from "../_providers/types/ICloudCalendarEvent";
/**
 * Value to convert minutes to milliseconds.
 */
const tms = 60000;
export function generateExperiment(device, experiment) {
    const end = new Date(experiment.startOffset).getTime() +
        experiment.estimateNumberOfSamples *
            experiment.estimateSampleDuration *
            tms;
    return {
        id: experiment.name + "-experiment",
        title: experiment.name,
        start: new Date(experiment.startOffset).toISOString(),
        end: new Date(end).toISOString(),
        backgroundColor: CalendarEventColor.ActiceProcessing,
        borderColor: CalendarEventColor.ActiceProcessing,
        display: device.type === DeviceType.Lux
            ? CalendarEventDisplay.Auto
            : CalendarEventDisplay.None,
        extendedProps: {
            deviceId: device.id,
            experimentIsShared: experiment.projectDetails.isShared,
            experimentId: experiment.experimentId,
            experimentOwner: experiment.owner,
            eventType: CalendarEventType.Experiment,
            hiddenUrl: `Project/${experiment.projectDetails.projectId}/Experiments/${experiment.experimentId}`
        }
    };
}
