import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import VirtualWellPlateCloud from "@/pages/wellPlate/VirtualWellPlate/VirtualWellPlateCloud.vue";
import { VirtualWellPlateMode } from "@/pages/wellPlate/_providers/types/enums/VirtualWellPlateMode";
import { useVirtualWellplate } from "@/pages/wellPlate/_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useSelect } from "@/pages/wellPlate/_providers/withSelect/withSelect";
import { useExperiment } from "@/pages/wellPlate/_providers/withExperiment";
import { useAreas } from "@/pages/wellPlate/_providers/withAreas";
export default defineComponent({
    components: {
        VirtualWellPlateCloud,
        WrapperLoading
    },
    props: {
        experimentId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const virtualWellPlateProvider = useVirtualWellplate();
        const selector = useSelect();
        const { experiment } = useExperiment();
        const { areas, areasPositions } = useAreas();
        const areasWells = ref({});
        const modalIsOpened = ref(false);
        // adjust zoom based on width
        const getInitialZoom = () => {
            if (window.innerWidth < 1400) {
                return 2;
            }
            else if (window.innerWidth < 1800) {
                return 2.3;
            }
            else if (window.innerWidth < 2200) {
                return 2.7;
            }
            else {
                return 3;
            }
        };
        const options = reactive({
            mode: "fluorescence",
            initialZoom: getInitialZoom(),
            minZoom: 2,
            maxZoom: 5,
            wellWeight: 1,
            wellOpacity: 0.7,
            dragging: false,
            zoomControl: true,
            scrollWheelZoom: true,
            center: [40, 60]
        });
        virtualWellPlateProvider.data.mode = VirtualWellPlateMode.NAVIGATION;
        // Set current shape as selected
        const onVirtualWellPlateMounted = () => {
            const currentSnapshot = virtualWellPlateProvider
                .getAllROIs()
                .find(roi => roi.lux3ExperimentId === props.experimentId);
            const currentShape = currentSnapshot
                ? virtualWellPlateProvider.getWellByROI(currentSnapshot === null || currentSnapshot === void 0 ? void 0 : currentSnapshot.id)
                : null;
            if (currentShape) {
                const selectedAreaIds = virtualWellPlateProvider
                    .getAllROIsInWell(currentShape === null || currentShape === void 0 ? void 0 : currentShape.id)
                    .map(roi => roi.id);
                selector.selectItems(selectedAreaIds, {
                    ignoreModifierKey: true,
                    ignoreGroups: true
                });
            }
        };
        const onResize = () => {
            options.initialZoom = getInitialZoom();
        };
        onMounted(() => {
            window.addEventListener("resize", onResize);
            // Imitate fetching the experiment as leaflet need some time to render
            setTimeout(() => {
                modalIsOpened.value = true;
            }, 1000);
        });
        onUnmounted(() => {
            window.removeEventListener("resize", onResize);
        });
        return {
            experiment,
            areasWells,
            areasPositions,
            areas,
            options,
            virtualWellPlateProvider,
            modalIsOpened,
            onVirtualWellPlateMounted
        };
    }
});
