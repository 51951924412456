<template>
  <portal v-if="show" to="dialogs">
    <transition name="fade">
      <div class="modal" role="dialog" @mousedown.self="closeOnClick">
        <div class="modal-dialog" :class="classes">
          <div class="relative">
            <span
              style="left: 45%"
              class="absolute mx-auto -mt-8 rounded-full bg-white p-2 shadow-lg"
            >
              <b-icon :name="icon" class="h-12 w-12 text-gray-800" />
            </span>
            <div class="mx-4 flex items-center justify-end py-2">
              <button
                class="focus:outline-none transform cursor-pointer rounded-full p-1 text-gray-700 hover:scale-95 hover:bg-gray-200 hover:shadow-md focus:border focus:bg-gray-300 focus:shadow-none"
                @click="close"
              >
                <b-icon name="close" class="h-8 w-8 fill-current" />
              </button>
            </div>
            <div class="whitespace-pre-wrap py-2 px-4 text-center">
              <span class="text-md font-bold text-gray-800">
                {{ heading }}
              </span>

              <slot />
            </div>
          </div>
          <div class="mx-3 border-t pb-2">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
/**
 * Panel component that can collapse and expand content
 * @vue-prop {String} [icon = "information-variant"] - An icon regarding the message of the modal
 * @vue-prop {String} [heading = ""] - Text to put in the modal header
 * @vue-prop {String} [classes = "max-w-2xl"] - Classes to apply to modal body
 * @vue-prop {Boolean} [show] - Information whether the modal should be visible
 */
export default {
  props: {
    icon: {
      type: String,
      default: "information-variant"
    },
    heading: {
      default: "",
      type: String
    },
    classes: {
      default: "max-w-xl",
      type: String
    },
    show: {
      type: Boolean,
      required: true
    },
    closeWithButtonOnly: {
      default: true,
      type: Boolean
    }
  },
  created() {
    const escapeHandler = e => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    closeOnClick() {
      if (this.closeWithButtonOnly) {
        this.$emit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  z-index: 500;
  background-color: rgba(111, 111, 111, 0.79);
  @apply fixed top-0 left-0 h-screen w-screen cursor-pointer  overflow-y-auto;
}

.modal-dialog {
  -webkit-box-shadow: 0px 6px 32px -4px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 6px 32px -4px rgba(0, 0, 0, 0.41);
  @apply mx-auto my-12 cursor-default rounded bg-white;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
