import { defineComponent } from "vue";
export default defineComponent({
    render(createElement) {
        if (!this.$slots.default) {
            throw new Error("AppDropdownMenu expects to have at least one child element.");
        }
        return createElement("div", {
            staticClass: "dropdown-menu",
            on: this.$listeners
        }, this.$slots.default);
    }
});
