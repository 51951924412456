import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import RepositoryFactory from "@/api/repositoryFactory";
const experimentsRepository = RepositoryFactory.omni3v1.experiments;
export default function withScansEstimate() {
    async function fetchScansEstimateOnExperimentId(experimentId) {
        try {
            const { data } = await experimentsRepository.getScanStates(experimentId);
            data.States = data.States.splice(data.States.length - data.NumberOfActiveScans);
            return transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            console.warn("[Calendar] Error while fetching scans estimates.", error);
        }
    }
    return {
        fetchScansEstimateOnExperimentId
    };
}
