import { makeInjectionKey } from "@axisvue/utils";
import { inject as vueInjectAPI, provide as vueProvideAPI } from "vue";
const createProvider = (key, value) => {
    vueProvideAPI(key, value);
};
const useProvider = (key) => {
    const provider = vueInjectAPI(key);
    if (!provider)
        throw new Error(`Provider was not created.`);
    return provider;
};
export const setupProvider = () => {
    const ProviderInjectionKey = makeInjectionKey();
    const provide = (value) => createProvider(ProviderInjectionKey, value);
    const use = () => useProvider(ProviderInjectionKey);
    return {
        provide,
        use
    };
};
