<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    initial: {
      type: Array,
      required: false,
      default: () => []
    },
    validate: {
      type: Function,
      required: false,
      default: option => option
    },
    validators: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      input: null,
      values: []
    };
  },
  computed: {
    remaining() {
      return this.getDifference(
        this.data.concat(this.initial),
        this.values,
        this.validate
      );
    },
    isValid() {
      for (const validator of this.validators) {
        if (validator(this.validate(this.input))) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    value: {
      handler(value) {
        this.input = value;
      },
      immediate: true
    },
    initial: {
      handler() {
        this.values = [...this.initial];
      },
      immediate: true
    },
    values: {
      handler() {
        this.$emit("change", this.values);
      },
      immediate: true
    }
  },
  methods: {
    getDifference(arr1, arr2, comparer) {
      return arr1.filter(elem1 => {
        return (
          arr2.findIndex(elem2 => comparer(elem1) === comparer(elem2)) === -1
        );
      });
    },
    remove(value) {
      this.values = this.values.filter(t => t !== value);
    },
    reset() {
      this.values = [];
    },
    add() {
      if (this.values.includes(this.input)) {
        return;
      }

      if (!this.isValid) {
        return;
      }

      this.values.push(this.input);
      this.input = "";
    },
    pop() {
      if (!this.input || !this.validate(this.input)) {
        this.values.pop();
      }
    }
  },
  render() {
    return this.$scopedSlots.default({
      values: this.values,
      remaining: this.remaining,
      remove: this.remove,
      reset: this.reset,
      pop: this.pop,
      add: this.add,
      removeButtonEvents: value => ({
        click: () => {
          this.remove(value);
        }
      }),
      inputProps: {
        value: this.input
      },
      inputEvents: {
        inputValue: value => {
          this.input = value;
        },
        input: e => {
          this.input = e.target.value;
        }
      }
    });
  }
};
</script>
