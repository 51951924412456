import { computed, defineComponent, ref, watch } from "vue";
/**
 * This component is custom input type that is not part of the built-in fields of vueFormulate.
 * It acts as autocomplete field for emails. When an users starts typing, dropdown of email options will appear to search from.
 * For reference: https://vueformulate.com/guide/inputs/custom-inputs/#custom-props
 */
export default defineComponent({
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const selectedIndex = ref(0);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        /**
         * The variable is used for any new value the user enters, that is not part of the options.
         * The new value is also then displayed in the dropdown.
         */
        const tempModel = ref("");
        const filteredOptions = computed(() => {
            if (Array.isArray(props.context.options) && tempModel.value) {
                const isAlreadySelected = props.context.options.find(option => option.label === tempModel.value);
                if (!isAlreadySelected) {
                    return props.context.options.filter(option => option.label.toLowerCase().includes(tempModel.value.toLowerCase()));
                }
            }
            return [];
        });
        const showTempModel = computed(() => filteredOptions.value.length === 0 && !!tempModel.value);
        const recentSelection = computed(() => { var _a, _b; return (_b = (_a = filteredOptions.value[selectedIndex.value]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : tempModel.value; });
        const emailIsIncorrectFormat = computed(() => !emailRegex.test(recentSelection.value) && !!recentSelection.value);
        /**
         * Increment index of selection when using buttons up/down.
         */
        function increment() {
            if (selectedIndex.value + 1 < filteredOptions.value.length) {
                selectedIndex.value++;
            }
            else {
                selectedIndex.value = 0;
            }
        }
        /**
         * Decrement index of selection when using buttons up/down.
         */
        function decrement() {
            if (selectedIndex.value - 1 >= 0) {
                selectedIndex.value--;
            }
            else {
                selectedIndex.value = filteredOptions.value.length - 1;
            }
        }
        /**
         * Whenever the user makes a selection, via click or button enter.
         */
        function enter() {
            if (!emailIsIncorrectFormat.value) {
                // eslint-disable-next-line vue/no-mutating-props
                props.context.model = recentSelection.value;
                tempModel.value = "";
            }
        }
        watch(() => selectedIndex.value, () => {
            const liElement = document.getElementById(selectedIndex.value.toString());
            if (!liElement)
                return;
            liElement.scrollIntoView({
                block: "nearest"
            });
        });
        return {
            increment,
            decrement,
            enter,
            emailIsIncorrectFormat,
            selectedIndex,
            recentSelection,
            filteredOptions,
            tempModel,
            showTempModel
        };
    }
});
