import { defineComponent } from "vue";
export default defineComponent({
    inject: ["dropdownClose"],
    render(createElement) {
        const listeners = {
            ...this.$listeners,
            click: (e) => {
                const close = this.dropdownClose;
                close();
                if (this.$listeners.click) {
                    if (Array.isArray(this.$listeners.click)) {
                        this.$listeners.click.forEach(l => l(e));
                    }
                    else
                        this.$listeners.click(e);
                }
            }
        };
        const data = {
            staticClass: "dropdown-menu-item",
            class: this.$attrs.class,
            on: listeners
        };
        return createElement("button", data, this.$slots.default);
    }
});
