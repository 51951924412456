/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-circle-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-18A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m-2 14.5l6-4.5-6-4.5v9z"/>'
  }
})
