/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'matrix': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 2h4v2H4v16h2v2H2V2m18 2h-2V2h4v20h-4v-2h2V4M9 5h1v5h1v1H8v-1h1V6l-1 .5v-1L9 5m6 8h1v5h1v1h-3v-1h1v-4l-1 .5v-1l1-.5m-6 0c1.1 0 2 1.34 2 3s-.9 3-2 3-2-1.34-2-3 .9-3 2-3m0 1c-.55 0-1 .9-1 2s.45 2 1 2 1-.9 1-2-.45-2-1-2m6-9c1.1 0 2 1.34 2 3s-.9 3-2 3-2-1.34-2-3 .9-3 2-3m0 1c-.55 0-1 .9-1 2s.45 2 1 2 1-.9 1-2-.45-2-1-2z"/>'
  }
})
