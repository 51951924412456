export var AlgorithmType;
(function (AlgorithmType) {
    AlgorithmType["CONFLUENCE"] = "Confluence";
    AlgorithmType["OBJECT_COUNT"] = "ObjectCount";
    AlgorithmType["ORGANOID"] = "Organoid";
    AlgorithmType["SCRATCH"] = "Scratch";
    AlgorithmType["CLONOGENIC"] = "Colony";
    AlgorithmType["PSC"] = "PSC";
})(AlgorithmType || (AlgorithmType = {}));
export var AlgorithmValue;
(function (AlgorithmValue) {
    AlgorithmValue[AlgorithmValue["NONE"] = 0] = "NONE";
    AlgorithmValue[AlgorithmValue["CONFLUENCE"] = 1] = "CONFLUENCE";
    AlgorithmValue[AlgorithmValue["CLONOGENIC"] = 2] = "CLONOGENIC";
    AlgorithmValue[AlgorithmValue["SCRATCH"] = 3] = "SCRATCH";
    AlgorithmValue[AlgorithmValue["OBJECT_COUNT"] = 4] = "OBJECT_COUNT";
    AlgorithmValue[AlgorithmValue["ORGANOID"] = 6] = "ORGANOID";
    AlgorithmValue[AlgorithmValue["PSC"] = 7] = "PSC";
})(AlgorithmValue || (AlgorithmValue = {}));
