import BModal from "@/shared/components/layout/Modal.vue";
import { computed, defineComponent } from "vue";
import router from "@/router";
import { useToastr } from "@/globals/plugins/toastr/useToastr";
import { useProjectActions } from "@/shared/providers/withProjects/useProjectActions/useProjectActions";
import { ExperimentModalType } from "@/pages/experiments/_providers/types/enums/ExperimentsModalEnums";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: { BModal, AxionButton },
    props: {
        heading: {
            type: String,
            required: true,
            default: ""
        },
        message: {
            type: String,
            required: true,
            default: ""
        },
        project: {
            type: Object,
            required: true
        },
        modalType: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const { leaveProject, deleteProject, restoreProject } = useProjectActions();
        const toastr = useToastr();
        const closeHandler = () => emit("close");
        const leaveProjectHandler = async () => {
            try {
                await leaveProject(props.project.id);
                toastr.success("Successfully left project");
                await router.push("/");
                emit("close");
            }
            catch (error) {
                if (error === 469) {
                    emit("unableLeave");
                }
                else {
                    toastr.error("Something went wrong when leaving this project.");
                }
            }
        };
        const deleteProjectHandler = async () => {
            try {
                await deleteProject(props.project.id);
                toastr.success("Successfully deleted project");
                emit("close");
                await router.push("/");
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const restoreProjectHandler = async () => {
            try {
                await restoreProject(props.project.id);
                toastr.success("Successfully restored project");
                emit("close");
                await router.push("/");
            }
            catch (error) {
                toastr.error(error);
            }
        };
        const closeLabel = computed(() => props.modalType === ExperimentModalType.UnableLeaveSharedProject
            ? "Close"
            : "Cancel");
        return {
            closeLabel,
            ExperimentModalType,
            restoreProjectHandler,
            deleteProjectHandler,
            leaveProjectHandler,
            closeHandler
        };
    }
});
