import { CalendarColourPallete, EventState, EventType } from "../shared/ICloudCalendarEvent";
import { CalendarEventDisplay, CalendarEventType, CalendarView } from "@axisvue/calendar";
import { DeviceType } from "../shared/enums";
// whether an event is overlapping with any events
function isOverlapping(event, events) {
    return events.some(p => {
        var _a;
        // check for experiment - to ignore the monthly view
        if (p.id === event.id ||
            ((_a = p.extendedProps) === null || _a === void 0 ? void 0 : _a.eventType) === CalendarEventType.Experiment) {
            return false;
        }
        else if (p.end && event.end) {
            return (p.start < event.end &&
                event.start < p.end &&
                p.display !== CalendarEventDisplay.None);
        }
    });
}
// update the visual side of events depending on filtering (colors)
function updateScanStates(events, selectedDevice) {
    events.forEach(event => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = event.groupId) === null || _a === void 0 ? void 0 : _a.toString()) === "reservation" && event.extendedProps) {
            if (isOverlapping(event, events)) {
                event.extendedProps.overlapped = true;
                event.borderColor = CalendarColourPallete.Failed;
                event.backgroundColor = CalendarColourPallete.Failed;
                return;
            }
            else {
                event.extendedProps.overlapped = false;
                event.borderColor = CalendarColourPallete.BorderReservation;
                event.backgroundColor = CalendarColourPallete.Reservation;
                return;
            }
        }
        else if (((_c = (_b = event.extendedProps) === null || _b === void 0 ? void 0 : _b.owner) === null || _c === void 0 ? void 0 : _c.email) !== window.GLOBAL_CURRENT_USER_EMAIL &&
            selectedDevice.hasRobotArmAttached) {
            event.backgroundColor = CalendarColourPallete.Any;
            event.borderColor = CalendarColourPallete.Any;
            return;
        }
        // busy slot always appear with color associated to a completed event/scan.
        if (((_d = event.extendedProps) === null || _d === void 0 ? void 0 : _d.eventType) === CalendarEventType.BusySlot) {
            event.borderColor = CalendarColourPallete.Active;
            event.backgroundColor = CalendarColourPallete.Active;
            return;
        }
        // for omni2/lux if event is pass current time, then it completed.
        if (event.end && event.end <= new Date().toISOString()) {
            event.borderColor = CalendarColourPallete.Active;
            event.backgroundColor = CalendarColourPallete.Active;
            return;
        }
        if (((_e = event.extendedProps) === null || _e === void 0 ? void 0 : _e.deviceId) === selectedDevice.id ||
            ((_f = event.groupId) === null || _f === void 0 ? void 0 : _f.toString()) === "reservation") {
            switch ((_g = event.extendedProps) === null || _g === void 0 ? void 0 : _g.eventState) {
                case EventState.Completed:
                case EventState.Processing:
                    event.borderColor = CalendarColourPallete.Active;
                    event.backgroundColor = CalendarColourPallete.Active;
                    break;
                case EventState.Failed:
                    event.borderColor = CalendarColourPallete.Failed;
                    event.backgroundColor = CalendarColourPallete.Failed;
                    break;
                default:
                    event.borderColor = CalendarColourPallete.Waiting;
                    event.backgroundColor = CalendarColourPallete.Waiting;
            }
            if ((event.end && event.end < new Date().toISOString()) ||
                ((_h = event.extendedProps) === null || _h === void 0 ? void 0 : _h.eventType) === CalendarEventType.BusySlot) {
                event.borderColor = CalendarColourPallete.Active;
                event.backgroundColor = CalendarColourPallete.Active;
            }
        }
    });
}
/**
 * Sets the visibility (auto/none) of events on the calendar based on filtering
 * @param {IRegisteredDevice} selectedDevice - selected device for the calendar view
 * @param {ICloudCalendarEvent[]} events - calendar events
 * @param {string} calendarView - current calendar view - monthly/weekly
 */
export function setVisibilityOfCalendarEventsBasedOnSelection(selectedDevice, events, calendarView) {
    if (selectedDevice) {
        events.map(event => {
            var _a, _b, _c;
            if (((_a = event.groupId) === null || _a === void 0 ? void 0 : _a.toString()) === "reservation") {
                event.display = CalendarEventDisplay.Auto;
            }
            else if (((_b = event === null || event === void 0 ? void 0 : event.extendedProps) === null || _b === void 0 ? void 0 : _b.deviceId) !== selectedDevice.id) {
                // if event does not belong to selected device, remove it from the calendar view
                event.display = CalendarEventDisplay.None;
                return;
            }
            if (selectedDevice.type === DeviceType.Lux) {
                event.display = CalendarEventDisplay.Auto;
                return;
            }
            switch ((_c = event.extendedProps) === null || _c === void 0 ? void 0 : _c.eventType) {
                case EventType.Experiment:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.Auto
                            : CalendarEventDisplay.None;
                    break;
                case EventType.Sample:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.None
                            : CalendarEventDisplay.Auto;
                    break;
                case EventType.BusySlot:
                    event.display =
                        calendarView === CalendarView.Monthly
                            ? CalendarEventDisplay.None
                            : CalendarEventDisplay.Background;
            }
        });
    }
    updateScanStates(events, selectedDevice);
    return events;
}
