import { defineComponent, computed, reactive, watch, ref, readonly, onMounted } from "vue";
import { useModifyReservation } from "../_providers/withModifyReservation";
import { useCalendarEvents } from "../_providers/withCalendarEvents";
import { useCreateReservation } from "../_providers/withCreateReservation";
import { useToastr } from "@/globals/plugins/toastr/useToastr";
import { debounce } from "@axisvue/utils";
import { AxionInputCheckbox, AxionInputNumber, AxionInputSelect, AxionInputText } from "@axisvue/forms";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: {
        AxionButton,
        AxionInputText,
        AxionInputCheckbox,
        AxionInputNumber,
        AxionInputSelect
    },
    props: {},
    setup(props, { emit }) {
        // IMPORTANT: The electron application has the (almost) same component. Every calculation for
        // scan duration, interval - whether to have minutes or so - MUST be the same as electron
        const toastr = useToastr();
        const { newReservation } = useCreateReservation();
        const { selectedReservation } = useModifyReservation();
        const reservation = computed(() => { var _a; return (_a = selectedReservation.value) !== null && _a !== void 0 ? _a : newReservation.value; });
        const reservationBelongsToMe = computed(() => reservation.value.owner.email === window.GLOBAL_CURRENT_USER_EMAIL);
        const { sampleEvents } = useCalendarEvents();
        const reservationName = ref(reservation.value.name);
        const overlapping = computed(() => sampleEvents.value.filter(s => { var _a; return (_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.overlapped; }).length > 0);
        const realReservation = computed(() => reservation.value.reservationId !== 0);
        const redChannel = ref(reservation.value.redChannel);
        const greenChannel = ref(reservation.value.greenChannel);
        const selectedWellplateType = ref(reservation.value.wells);
        const selectedSnapshotPerWell = ref(reservation.value.rois);
        const defaultIntervalHours = ref((reservation.value.sampleInterval % 60) +
            reservation.value.estimateSampleDuration);
        const defaultIntervalMinutes = ref(reservation.value.sampleInterval);
        const scanEstimatation = ref(reservation.value.estimateSampleDuration);
        const totalScanIntervalInMinutes = ref(reservation.value.sampleInterval +
            reservation.value.estimateSampleDuration);
        const totalNumberOfScans = ref(reservation.value.estimateNumberOfSamples);
        const scanType = ref(reservation.value.redChannel || reservation.value.greenChannel ? 1 : 0);
        const isFullScan = computed(() => scanType.value === 0);
        const dayInSeconds = readonly({ value: 24 * 60 * 60 });
        const wellplateTypes = ref([
            {
                label: "6 wells",
                value: 6
            },
            {
                label: "12 wells",
                value: 12
            },
            {
                label: "24 wells",
                value: 24
            },
            {
                label: "48 wells",
                value: 48
            },
            {
                label: "96 wells",
                value: 96
            },
            {
                label: "384 wells",
                value: 384
            }
        ]);
        const scanTypes = ref([
            {
                label: "Full scan",
                value: 0
            },
            {
                label: "Pattern scan",
                value: 1
            }
        ]);
        const minuteOptions = ref([0, 10, 15, 30, 45]);
        const maxIntervalMinutes = ref(45);
        const getMinimumHourOption = computed(() => {
            if (isFullScan.value) {
                return 0;
            }
            let minutesOverflow = scanEstimatation.value % 60;
            if (minutesOverflow > maxIntervalMinutes.value) {
                minutesOverflow = 1;
            }
            else {
                minutesOverflow = 0;
            }
            return Math.floor(scanEstimatation.value / 60) + minutesOverflow;
        });
        const minimumMinuteOption = computed(() => {
            const minutes = scanEstimatation.value % 60;
            for (let i = 0; i < minuteOptions.value.length; i++) {
                if (minuteOptions.value[i] - minutes >= 0) {
                    return minuteOptions.value[i];
                }
            }
            return 0;
        });
        const model = reactive({
            intervalHoursOptions: [],
            intervalHours: defaultIntervalHours,
            intervalMinutesOptions: [],
            intervalMinutes: defaultIntervalMinutes,
            durationDays: 2,
            durationHours: 0,
            durationMinutes: 0,
            numberOfScans: 48,
            minScans: 2,
            maxScans: 120
        });
        const oldIntervalHours = ref(model.intervalHours);
        const oldIntervalMinutes = ref(model.intervalMinutes);
        const oldDurationDays = ref(model.durationDays);
        const oldDurationHours = ref(model.durationHours);
        const oldDurationMinutes = ref(model.durationMinutes);
        const oldNumberOfScans = ref(model.numberOfScans);
        function getSnapshotsPerWell(wellplateType) {
            switch (wellplateType) {
                case 6:
                    return [1, 4, 9, 16].map(e => ({ label: e, value: e }));
                case 12:
                    return [1, 2, 4, 9].map(e => ({ label: e, value: e }));
                case 24:
                    return [1, 2, 3, 4].map(e => ({ label: e, value: e }));
                case 48:
                    return [1, 2, 3, 4].map(e => ({ label: e, value: e }));
                case 96:
                    return [1].map(e => ({ label: e, value: e }));
                default:
                    return [1].map(e => ({ label: e, value: e }));
            }
        }
        function calculateSampleScanningTime() {
            if (isFullScan.value)
                return 15;
            const timeDeviceToMove = 13 + 8; // 8 sec for channel
            let time = (timeDeviceToMove *
                selectedWellplateType.value *
                selectedSnapshotPerWell.value) /
                60;
            if (time < 5) {
                time = 5;
            }
            return time;
        }
        const disableIntervalMinutes = computed(() => {
            if (isFullScan.value) {
                return true;
            }
            else if ((model.intervalHours === 0 && model.intervalMinutes === 0) ||
                model.intervalHours === 24) {
                return true;
            }
            else if (!isFullScan.value && model.intervalHours > 0) {
                return true;
            }
            return false;
        });
        function updateDuration() {
            const durationInSeconds = (model.intervalHours * 3600 + model.intervalMinutes * 60) *
                (model.numberOfScans - 1);
            if (durationInSeconds / dayInSeconds.value >= 1) {
                model.durationDays = Math.floor(durationInSeconds / (24 * 3600));
                model.durationHours = Math.floor((durationInSeconds - model.durationDays * dayInSeconds.value) / 3600);
                model.durationMinutes = Math.ceil((durationInSeconds -
                    model.durationDays * 24 * 3600 -
                    model.durationHours * 3600) /
                    60);
            }
            else {
                model.durationDays = 0;
                model.durationHours = Math.floor(durationInSeconds / 3600);
                model.durationMinutes = Math.ceil((durationInSeconds - model.durationHours * 3600) / 60);
            }
            // These two if statements prevent the duration numbers from becoming negative if you manually input 0 number of scans.
            if (model.durationHours < 0)
                model.durationHours = 0;
            if (model.durationMinutes < 0)
                model.durationMinutes = 0;
            oldDurationDays.value = model.durationDays;
            oldDurationHours.value = model.durationHours;
            oldDurationMinutes.value = model.durationMinutes;
        }
        const newScanNumberDiffersByRounding = (rawNumber) => model.numberOfScans !== rawNumber;
        function clampNumberOfScans() {
            if (model.numberOfScans > model.maxScans) {
                model.numberOfScans = model.maxScans;
                oldNumberOfScans.value = model.numberOfScans;
            }
            else if (model.numberOfScans < model.minScans) {
                model.numberOfScans = model.minScans;
                oldNumberOfScans.value = model.numberOfScans;
            }
            updateDuration();
        }
        /**
         * @param isDescending If a change of the other parameters was ascending or descending.
         *
         * For example, consider an experiment with interval 1 hour 15 minutes and duration 3 hours 45 minutes.
         * Number of scans raw will be 3. If you increase duration minutes by 1, number of scans raw will be 3.04.
         * For convenience, this should be rounded to 4, duration hours increased to 5 and duration minutes set to 0.
         *
         * Similarly, if you decrease minutes by 1, the rounding should be done the other way: number of scans becomes 2,
         * duration hours set to 2, and duration  minutes set to 30.
         */
        function updateScans(isDescending = false) {
            const numberOfScansRaw = (model.durationDays * 24 * 3600 +
                model.durationHours * 3600 +
                model.durationMinutes * 60) /
                (model.intervalHours * 3600 + model.intervalMinutes * 60);
            model.numberOfScans = isDescending
                ? Math.floor(numberOfScansRaw)
                : Math.ceil(numberOfScansRaw);
            // Bring the number of scans in line with the app scientists' expectations. https://cytosmart.visualstudio.com/CytoOverview/_workitems/edit/24977
            model.numberOfScans++;
            oldNumberOfScans.value = model.numberOfScans;
            if (newScanNumberDiffersByRounding(numberOfScansRaw)) {
                updateDuration();
            }
            clampNumberOfScans();
        }
        /**
         * @param forceUpdate Whether to update the interval in minutes regardless of the current experiment type.
         * Useful when first loading this component, as the interval hour is updated, yet the default experiment type
         * is single scan.
         */
        function updateLocalExperiment(forceUpdate = false) {
            totalNumberOfScans.value = model.numberOfScans;
            if (forceUpdate)
                totalScanIntervalInMinutes.value =
                    model.intervalHours * 60 + model.intervalMinutes;
        }
        function populateIntervalMinutesOptions() {
            const possibleMinutes = [0, 10, 15, 30, 45];
            let currentMinutes = model.intervalMinutes;
            if (!isFullScan.value && model.intervalHours > 0) {
                model.intervalMinutesOptions = new Array(1).fill(0).map((a, i) => {
                    return {
                        label: i,
                        value: i
                    };
                });
                currentMinutes = 0;
            }
            else {
                const availableMinutes = isFullScan.value && model.intervalHours > 0
                    ? possibleMinutes
                    : possibleMinutes.filter(i => i >= minimumMinuteOption.value);
                model.intervalMinutesOptions = new Array(availableMinutes.length)
                    .fill(0)
                    .map((a, i) => {
                    return {
                        label: availableMinutes[i],
                        value: availableMinutes[i],
                        disabled: false
                    };
                });
                if (model.intervalMinutesOptions.every(({ value }) => value !== currentMinutes)) {
                    currentMinutes = model.intervalMinutesOptions[0].value;
                }
            }
            model.intervalMinutes = currentMinutes;
        }
        function changeIntervalHours() {
            if (model.intervalHours === 0 && model.intervalMinutes === 0) {
                model.intervalMinutes = minimumMinuteOption.value;
            }
            else if ((!isFullScan.value && model.intervalHours > 0) ||
                model.intervalHours === 24) {
                model.intervalMinutes = 0;
            }
            populateIntervalMinutesOptions();
            updateScans(model.intervalHours < oldIntervalHours.value);
            updateLocalExperiment();
            oldIntervalHours.value = model.intervalHours;
        }
        function changeIntervalMinutes() {
            updateScans(model.intervalMinutes < oldIntervalMinutes.value);
            updateLocalExperiment();
            oldIntervalMinutes.value = model.intervalMinutes;
        }
        function changeDurationDays() {
            updateScans(model.durationDays < oldDurationDays.value);
            updateLocalExperiment();
            oldDurationDays.value = model.durationDays;
        }
        function changeDurationHours() {
            updateScans(model.durationHours < oldDurationHours.value);
            updateLocalExperiment();
            oldDurationHours.value = model.durationHours;
        }
        function changeDurationMinutes() {
            updateScans(model.durationMinutes < oldDurationMinutes.value);
            updateLocalExperiment();
            oldDurationMinutes.value = model.durationMinutes;
        }
        // Debounce this to allow typing numbers like 10 and 100 without instantly triggering the error toaster.
        const changeNumberOfScans = debounce(() => {
            if (model.numberOfScans < model.minScans) {
                toastr.error(`The minimum number of scans is ${model.minScans}`);
            }
            else if (model.numberOfScans > model.maxScans) {
                toastr.error(`The maximum number of scans is ${model.maxScans}`);
            }
            else {
                oldNumberOfScans.value = model.numberOfScans;
                updateDuration();
                updateLocalExperiment();
            }
        }, 400);
        function checkNumberOfScans() {
            if (model.numberOfScans < model.minScans ||
                (model.numberOfScans === model.minScans &&
                    oldNumberOfScans.value === model.numberOfScans)) {
                toastr.error(`The minimum number of scans is ${model.minScans}`);
            }
            if (model.numberOfScans > model.maxScans ||
                (model.numberOfScans === model.maxScans &&
                    oldNumberOfScans.value === model.numberOfScans)) {
                toastr.error(`The maximum number of scans is ${model.maxScans}`);
            }
        }
        model.intervalHoursOptions = new Array(25 - getMinimumHourOption.value)
            .fill(0)
            .map((a, i) => {
            return {
                label: i + getMinimumHourOption.value,
                value: i + getMinimumHourOption.value
            };
        });
        model.intervalHours = model.intervalHoursOptions[0].value;
        updateScans();
        if (scanEstimatation.value > totalScanIntervalInMinutes.value) {
            // Switched from a shorter estimate to a longer one, reset to default to prevent overlapping scans.
            model.intervalHours = defaultIntervalHours.value;
            model.intervalMinutes = defaultIntervalMinutes.value;
        }
        else {
            model.intervalHours = Math.floor(totalScanIntervalInMinutes.value / 60);
            model.intervalMinutes = Math.floor(totalScanIntervalInMinutes.value % 60);
            model.numberOfScans = totalNumberOfScans.value;
            oldNumberOfScans.value = model.numberOfScans;
            oldIntervalHours.value = model.intervalHours;
            oldIntervalMinutes.value = model.intervalMinutes;
        }
        if (!isFullScan.value && model.intervalHours > 0) {
            model.intervalMinutes = 0;
        }
        async function Apply() {
            scanEstimatation.value = calculateSampleScanningTime();
            reservation.value.estimateSampleDuration = scanEstimatation.value;
            emit("saveReservation");
        }
        watch(() => [
            model.durationDays,
            model.durationHours,
            model.durationMinutes,
            model.intervalHours,
            model.intervalMinutes,
            model.numberOfScans,
            reservationName.value,
            selectedWellplateType.value,
            selectedSnapshotPerWell.value,
            isFullScan.value,
            greenChannel.value,
            redChannel.value
        ], () => {
            scanEstimatation.value = calculateSampleScanningTime();
            reservation.value.estimateNumberOfSamples = model.numberOfScans;
            reservation.value.estimateSampleDuration = scanEstimatation.value;
            reservation.value.sampleInterval =
                model.intervalMinutes +
                    model.intervalHours * 60 -
                    scanEstimatation.value;
            reservation.value.redChannel = redChannel.value;
            reservation.value.greenChannel = greenChannel.value;
            reservation.value.name = reservationName.value;
            reservation.value.wells = selectedWellplateType.value;
            reservation.value.rois = selectedSnapshotPerWell.value;
            emit("updateReservationPreview");
        }, { deep: true });
        onMounted(() => {
            if (reservation.value.reservationId === 0) {
                if (scanEstimatation.value < 5) {
                    scanEstimatation.value = 5;
                }
                emit("updateReservationPreview");
            }
        });
        watch(() => selectedWellplateType.value, () => (selectedSnapshotPerWell.value = 1));
        updateDuration();
        updateLocalExperiment();
        updateDuration();
        populateIntervalMinutesOptions();
        return {
            reservationBelongsToMe,
            minimumMinuteOption,
            disableIntervalMinutes,
            model,
            overlapping,
            Apply,
            reservationName,
            selectedSnapshotPerWell,
            changeIntervalHours,
            changeIntervalMinutes,
            changeDurationDays,
            changeDurationHours,
            changeDurationMinutes,
            changeNumberOfScans,
            checkNumberOfScans,
            redChannel,
            greenChannel,
            scanTypes,
            selectedWellplateType,
            wellplateTypes,
            scanType,
            realReservation,
            calculateSampleScanningTime,
            getSnapshotsPerWell,
            isFullScan
        };
    }
});
