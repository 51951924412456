import axios from "axios";
import Repository from "@/api/repository";
const baseUrl = "/account";
export default {
    login(params) {
        return Repository.post(`${baseUrl}/logIn`, params);
    },
    registerAccount(params) {
        return Repository.post(`${baseUrl}/activate`, params);
    },
    getNotificationSettings() {
        return Repository.get(`${baseUrl}/NotificationsSettings`);
    },
    updateNotificationSettings(params) {
        return Repository.post(`${baseUrl}/UpdateNotificationSettings`, params);
    },
    activateAccount(params) {
        return axios.post(`${baseUrl}/activate`, params);
    },
    resendActivation(params) {
        return Repository.post(`${baseUrl}/ResendActivation`, params);
    }
};
