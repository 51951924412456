export var ProjectState;
(function (ProjectState) {
    ProjectState[ProjectState["ACTIVE"] = 0] = "ACTIVE";
    ProjectState[ProjectState["FLAGGED"] = 1] = "FLAGGED";
    ProjectState[ProjectState["DELETED"] = 2] = "DELETED";
    ProjectState[ProjectState["RESTORED"] = 3] = "RESTORED";
    // in the future might change
    ProjectState[ProjectState["PARTIALLY_DELETED"] = 4] = "PARTIALLY_DELETED";
})(ProjectState || (ProjectState = {}));
export var ProjectActions;
(function (ProjectActions) {
    ProjectActions[ProjectActions["SHARE"] = 1] = "SHARE";
    ProjectActions[ProjectActions["EDIT"] = 2] = "EDIT";
    ProjectActions[ProjectActions["BIN"] = 3] = "BIN";
    ProjectActions[ProjectActions["DELETE"] = 4] = "DELETE";
    ProjectActions[ProjectActions["RESTORE"] = 5] = "RESTORE";
    ProjectActions[ProjectActions["LEAVE"] = 6] = "LEAVE";
    ProjectActions[ProjectActions["NOTIFICATIONS"] = 7] = "NOTIFICATIONS";
})(ProjectActions || (ProjectActions = {}));
