import { defineComponent } from "vue";
import { AxionInputBase } from "../AxionInputBase";
export default defineComponent({
    name: "AxionInputEmailSearchSelect",
    components: { AxionInputBase },
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        options: {
            type: [Array, Object],
            required: true
        }
    },
    emits: ["clear"]
});
