import { computed, ref } from "vue";
import { PROJECTS_DEVICES_FILTER_DEFAULT_OPTION } from "@/shared/providers/withProjects/useProjectsFilters/constants/ProjectsDevicesFilterDefaultOption";
import { DEFAULT_PROJECTS_FILTER_BY_OWNER_OPTION, PROJECTS_FILTER_BY_OWNER_OPTIONS } from "@/shared/providers/withProjects/useProjectsFilters/constants/ProjectsFilterByOwnerOptions";
import { DEFAULT_PROJECTS_SORTING_OPTION } from "@/shared/providers/withProjects/useProjectsFilters/constants/ProjectsSortingOptions";
/**
 * Provider that stores and handles state for projects filters.
 */
export function withProjectsFilters(availableDevices) {
    const search = ref("");
    // values for availability (owners of the projects) filter
    const resourceFilterOptions = PROJECTS_FILTER_BY_OWNER_OPTIONS;
    const selectedResourceFilter = ref(DEFAULT_PROJECTS_FILTER_BY_OWNER_OPTION);
    const selectedSorting = ref(DEFAULT_PROJECTS_SORTING_OPTION);
    // values for available devices filter
    const devicesFilterOptions = computed(() => {
        const options = [PROJECTS_DEVICES_FILTER_DEFAULT_OPTION];
        if (availableDevices === null || availableDevices === void 0 ? void 0 : availableDevices.value) {
            options.push(...availableDevices.value);
        }
        return options.map(({ displayName: label, ...rest }) => ({
            label,
            ...rest
        }));
    });
    const selectedDevicesFilter = ref(PROJECTS_DEVICES_FILTER_DEFAULT_OPTION.value);
    function setSearch(value) {
        search.value = value;
    }
    function setSelectedResourceFilter(value) {
        selectedResourceFilter.value = value;
    }
    function setSelectedDevicesFilter(value) {
        selectedDevicesFilter.value = value;
    }
    return {
        search,
        resourceFilterOptions,
        selectedResourceFilter,
        selectedSorting,
        devicesFilterOptions,
        selectedDevicesFilter,
        setSearch,
        setSelectedResourceFilter,
        setSelectedDevicesFilter
    };
}
