import { computed, defineComponent } from "vue";
import BProjectsListItemImage from "./BProjectsListItemImage.vue";
import BProjectsListItemName from "./BProjectsListItemName.vue";
import BProjectsListItemDate from "./BProjectsListItemDate.vue";
import BProjectsListItemDevices from "./BProjectsListItemDevices.vue";
import BProjectsListItemDescription from "./BProjectsListItemDescription.vue";
import BProjectsListItemOptions from "./BProjectsListItemOptions.vue";
import BProjectsListItemOwner from "@/pages/projects/BProjectsListItemOwner.vue";
import { ProjectState } from "@/pages/projects/_providers/types/enums/ProjectEnums";
import { useBinMode } from "@/shared/providers/withBinMode/withBinMode";
import DeviceType from "@/types/DeviceType";
export default defineComponent({
    components: {
        BProjectsListItemImage,
        BProjectsListItemName,
        BProjectsListItemDevices,
        BProjectsListItemDescription,
        BProjectsListItemDate,
        BProjectsListItemOptions,
        BProjectsListItemOwner
    },
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    emits: [
        "follow",
        "unfollow",
        "leave",
        "delete",
        "edit",
        "share",
        "restore",
        "move"
    ],
    setup(props, { emit }) {
        const { isBinMode } = useBinMode();
        const followHandler = () => emit("follow", props.project);
        const unfollowHandler = () => emit("unfollow", props.project);
        const leaveHandler = () => emit("leave", props.project);
        const deleteHandler = () => emit("delete", props.project);
        const editHandler = () => emit("edit", props.project);
        const shareHandler = () => emit("share", props.project);
        const binHandler = () => emit("move", props.project);
        const restoreHandler = () => emit("restore", props.project);
        const getDeviceType = computed(() => {
            switch (props.project.projectDeviceType) {
                case 1:
                    return "Lux";
                case 2:
                    return "CellCounter";
                case 3:
                    return "Omni";
                default:
                    return "Unknown";
            }
        });
        const projectLink = computed(() => {
            if (isBinMode.value) {
                return `/trash/project/${props.project.id}/Experiments`;
            }
            else {
                return `/project/${props.project.id}/Experiments?device=${getDeviceType.value}`;
            }
        });
        const totalExperiments = computed(() => props.project.nrOfExperimentsExact +
            props.project.nrOfExperimentsLux +
            props.project.nrOfExperimentsOmni);
        const isProjectFlagged = computed(() => props.project.activeState === ProjectState.FLAGGED);
        const isProjectPartiallyDeleted = computed(() => props.project.activeState === ProjectState.PARTIALLY_DELETED);
        const badgeColor = computed(() => props.project.projectType.toLowerCase());
        const isOmniProject = computed(() => props.project.projectDeviceType === DeviceType.Omni);
        return {
            isBinMode,
            projectLink,
            badgeColor,
            totalExperiments,
            getDeviceType,
            isProjectFlagged,
            isProjectPartiallyDeleted,
            isOmniProject,
            binHandler,
            deleteHandler,
            editHandler,
            followHandler,
            shareHandler,
            leaveHandler,
            unfollowHandler,
            restoreHandler
        };
    }
});
