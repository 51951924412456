/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-mapview-vector': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 9h-2.03c-1.27-3.59-4.66-6-8.47-6a9 9 0 00-9 9c0 5 4.03 9 9 9 3.81 0 7.2-2.4 8.5-6h2m-2-4v2h-2v-2m-.18 4c-1.16 2.44-3.62 4-6.32 4-3.86 0-7-3.13-7-7 0-3.86 3.14-7 7-7 2.7 0 5.16 1.57 6.31 4H16v6"/>'
  }
})
