/**
 * @enum CalendarEventState
 * @member COMPLETED
 * @member FAILED
 * @member WAITING
 * @member PROCESSING
 * @member NO_DATA - Omni2 & Lux
 */
export var CalendarEventState;
(function (CalendarEventState) {
    CalendarEventState["COMPLETED"] = "Completed";
    CalendarEventState["FAILED"] = "Failed";
    CalendarEventState["WAITING"] = "Waiting";
    CalendarEventState["PROCESSING"] = "Processing";
    CalendarEventState["SKIPPED"] = "Skipped";
    CalendarEventState["NO_DATA"] = "No data";
})(CalendarEventState || (CalendarEventState = {}));
/**
 * @enum CalendarEventColor
 * @member ActiveCompleted
 * @member ActiveFailed
 * @member ActiveWaiting
 * @member ActiceProcessing
 * @member RandomAny - omni pro, experiments that do not belong to user (from slots).
 */
export var CalendarEventColor;
(function (CalendarEventColor) {
    CalendarEventColor["ActiveCompleted"] = "rgba(0,131,148,0.3)";
    CalendarEventColor["ActiveFailed"] = "#feb2b2";
    CalendarEventColor["ActiveWaiting"] = "#008394";
    CalendarEventColor["ActiceProcessing"] = "rgba(0,131,148,0.3)";
    CalendarEventColor["RandomAny"] = "#DDDDDD";
})(CalendarEventColor || (CalendarEventColor = {}));
