/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"/>'
  }
})
