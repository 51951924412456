import { defineComponent } from "vue";
import { LControl } from "vue2-leaflet";
export default defineComponent({
    components: {
        LControl
    },
    props: {
        minZoom: {
            type: Number,
            required: true
        },
        maxZoom: {
            type: Number,
            required: true
        },
        currentZoom: {
            type: Number,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        zoomStep: {
            type: Number,
            required: false,
            default: 1
        }
    },
    setup(props) {
        const zoomIn = () => {
            props.map.zoomIn(props.zoomStep);
        };
        const zoomOut = () => {
            props.map.zoomOut(props.zoomStep);
        };
        return {
            zoomIn,
            zoomOut
        };
    }
});
