import { useToastr } from "@/globals/plugins/toastr/useToastr";
import BProjectsFilters from "@/pages/projects/BProjectsFilters.vue";
import router from "@/router";
import BLabel from "@/shared/components/forms/BLabel.vue";
import BaseLayout from "@/shared/components/layout/BaseLayout.vue";
import WrapperLoading from "@/shared/components/loading/WrapperLoading.vue";
import { useBreadCrumbs } from "@/shared/components/navigation/BreadCrumbs/withBreadCrumbs";
import { WithPagination, BPaginationItems, BPaginationButtons } from "@axisvue/pagination";
import BButtonEmptyBin from "./BButtonEmptyBin.vue";
import BinModeModal from "@/pages/projects/binMode/BinModeModal.vue";
import BBinButton from "@/pages/projects/BBinButton.vue";
import { AvailableDevicesValueTypes } from "@/shared/providers/withAvailableDevices/types/AvailableDevicesValueTypes";
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";
import { useProjects } from "@/shared/providers/withProjects/withProjects";
import { useProjectActions } from "@/shared/providers/withProjects/useProjectActions/useProjectActions";
import { INITIAL_PAGE } from "@/shared/providers/withProjects/constants/ProjectsInitialPage";
import { DEFAULT_ITEMS_PER_PAGE } from "@/shared/providers/withProjects/constants/DefaultItemsPerPage";
import { PROJECTS_FETCHING_ERROR_LABEL } from "@/shared/providers/withProjects/constants/ProjectsFetchingErrorLabel";
import BButtonNewProject from "./BButtonNewProject.vue";
import BProjectsList from "./BProjectsList.vue";
import { useBinMode } from "@/shared/providers/withBinMode/withBinMode";
import { PROJECTS_DEVICES_FILTER_DEFAULT_OPTION } from "@/shared/providers/withProjects/useProjectsFilters/constants/ProjectsDevicesFilterDefaultOption";
import { withScansStorage } from "@/shared/providers/withScansStorage/withScansStorage";
import { useAvailableDevices } from "@/shared/providers/withAvailableDevices/withAvailableDevices";
import { IFilterByOwnerValues } from "@/shared/providers/withProjects/types/IFilterByOwnerValues";
import axios from "axios";
import { PROJECTS_BIN_ROUTE_NAME } from "@/pages/projects/_providers/constants";
import { useLoggedInUser } from "@/shared/providers/withLoggedInUser/withLoggedInUser";
import { AxionInputSelect } from "@axisvue/forms";
export default defineComponent({
    components: {
        AxionInputSelect,
        BaseLayout,
        WrapperLoading,
        WithPagination,
        BProjectsFilters,
        BProjectsList,
        BPaginationItems,
        BButtonNewProject,
        BButtonEmptyBin,
        BPaginationButtons,
        BLabel,
        BinModeModal,
        BBinButton
    },
    props: {
        isBinMode: {
            type: Boolean || undefined,
            default: false
        }
    },
    setup(props) {
        // currently, withPagination provider is created as component,
        // so pagination and other values can be accessed only as scope of the withPagination.vue component.
        // We create here withPagination as template ref, that will have the value of the withPagination component instance, once it's mounted.
        const withPagination = ref(null);
        const filters = ref(null);
        const { loggedInUser } = useLoggedInUser();
        const { projects, numberOfProjects, search, selectedDevicesFilter, fetching: projectsFetching, setSelectedDevicesFilter, setSelectedResourceFilter, devicesFilterOptions, fetchProjects } = useProjects();
        const { fetchDeletedExperiments, setBinMode, deletedExperiments } = useBinMode();
        const { scansStorageUser } = withScansStorage();
        const { fetching: projectActionsFetching } = useProjectActions();
        const toastr = useToastr();
        const instance = getCurrentInstance();
        const { availableDevices, fetchAvailableDevices } = useAvailableDevices();
        const admin = ref(window.GLOBAL_IS_ADMIN);
        const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
        const isOmniAvailable = computed(() => {
            const omniDevice = availableDevices.value.find(device => device.value === AvailableDevicesValueTypes.OMNI);
            return omniDevice === null || omniDevice === void 0 ? void 0 : omniDevice.isAvailable;
        });
        const page = computed(() => {
            var _a, _b, _c;
            return (_c = (_b = (_a = withPagination.value) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.currentPage) !== null && _c !== void 0 ? _c : INITIAL_PAGE;
        });
        const pageSize = computed(() => {
            var _a, _b, _c;
            return (_c = (_b = (_a = withPagination.value) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.itemsPerPage) !== null && _c !== void 0 ? _c : itemsPerPage[0];
        });
        const loading = computed(() => projectsFetching.value || projectActionsFetching.value);
        const isSelectedDevicesFilterOmniOrDefault = computed(() => selectedDevicesFilter.value === null ||
            selectedDevicesFilter.value === AvailableDevicesValueTypes.OMNI);
        const isBinButtonVisible = computed(() => !props.isBinMode &&
            isOmniAvailable &&
            isSelectedDevicesFilterOmniOrDefault);
        const showFetchingProjectsError = () => {
            toastr.error(PROJECTS_FETCHING_ERROR_LABEL);
        };
        const { setBreadCrumbs } = useBreadCrumbs();
        setBreadCrumbs([
            {
                name: "Projects",
                url: null
            }
        ]);
        const omniDeviceValue = computed(() => {
            var _a;
            return (_a = devicesFilterOptions.value.find(device => device.value === AvailableDevicesValueTypes.OMNI)) === null || _a === void 0 ? void 0 : _a.value;
        });
        const getDeletedExperiments = async () => {
            try {
                await fetchDeletedExperiments();
            }
            catch (error) {
                toastr.error("Something went wrong with fetching deleted experiment list");
            }
        };
        const cancelToken = axios.CancelToken;
        let axiosSource = cancelToken.source();
        const refreshToken = () => (axiosSource = cancelToken.source());
        const cancelGetProjectRequests = () => axiosSource.cancel();
        const getProjects = async () => {
            var _a, _b;
            try {
                await fetchProjects({
                    page: page.value,
                    pageSize: pageSize.value,
                    bin: props.isBinMode,
                    cancelToken: axiosSource.token
                });
                if (typeof ((_b = (_a = withPagination.value) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.totalItems) !== "undefined") {
                    withPagination.value.pagination.totalItems = numberOfProjects.value;
                }
            }
            catch (error) {
                showFetchingProjectsError();
            }
        };
        const returnToInitialPage = async () => {
            if (router.currentRoute.query.page != String(INITIAL_PAGE)) {
                await router.replace({
                    name: router.currentRoute.name || "",
                    query: { page: String(INITIAL_PAGE) }
                });
            }
        };
        // waits for template to update -> browser to re-render -> get new projects.
        const updateProjectsList = async () => {
            await nextTick();
            await cancelGetProjectRequests();
            await refreshToken();
            await getProjects();
        };
        const reInitProjects = async () => {
            await returnToInitialPage();
            await updateProjectsList();
        };
        const isBinModal = ref(false);
        const showBinModal = () => (isBinModal.value = !isBinModal.value);
        const closeHandler = () => (isBinModal.value = false);
        const onSuccessCloseHandler = () => {
            isBinModal.value = false;
            router.push("/");
            getDeletedExperiments();
        };
        const projectsFlaggedNumber = computed(() => { var _a; return (_a = deletedExperiments.value) === null || _a === void 0 ? void 0 : _a.length; });
        const goToBin = () => router.push({ name: "binProjects" });
        const routeName = computed(() => { var _a, _b; return (_b = (_a = instance === null || instance === void 0 ? void 0 : instance.proxy) === null || _a === void 0 ? void 0 : _a.$root) === null || _b === void 0 ? void 0 : _b.$route.name; });
        watch(routeName, async (_, oldVal) => {
            var _a;
            setBinMode(props === null || props === void 0 ? void 0 : props.isBinMode);
            cancelGetProjectRequests();
            refreshToken();
            (_a = filters.value) === null || _a === void 0 ? void 0 : _a.clearSearch();
            if (props.isBinMode && omniDeviceValue.value) {
                setSelectedDevicesFilter(omniDeviceValue.value);
                await getDeletedExperiments();
            }
            else if (oldVal === "binProjects") {
                setSelectedResourceFilter(IFilterByOwnerValues.ALL);
                setSelectedDevicesFilter(PROJECTS_DEVICES_FILTER_DEFAULT_OPTION.value);
            }
        });
        onMounted(async () => {
            projectsFetching.value = true;
            if (router.currentRoute.name === PROJECTS_BIN_ROUTE_NAME) {
                await fetchAvailableDevices();
            }
            setBinMode(props === null || props === void 0 ? void 0 : props.isBinMode);
            if (props.isBinMode && omniDeviceValue.value) {
                setSelectedDevicesFilter(omniDeviceValue.value);
            }
            try {
                getProjects();
                getDeletedExperiments();
                window.GLOBAL_BREADCRUMBS = [];
                window.beamer_config.user_firstname = loggedInUser.value.firstName;
                window.beamer_config.user_lastname = loggedInUser.value.lastName;
                window.beamer_config.user_email = loggedInUser.value.email;
                window.beamer_config.user_id = loggedInUser.value.email;
            }
            catch (error) {
                console.warn(error);
            }
        });
        return {
            isBinButtonVisible,
            isBinModal,
            projectsFlaggedNumber,
            deletedExperiments,
            projects,
            loading,
            admin,
            itemsPerPage,
            search,
            withPagination,
            filters,
            scansStorageUser,
            reInitProjects,
            updateProjectsList,
            showBinModal,
            closeHandler,
            goToBin,
            onSuccessCloseHandler
        };
    }
});
