/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z"/>'
  }
})
