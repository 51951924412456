/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'magnify-plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 2a7 7 0 017 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6-2 2-6-6v-.59l-.81-.8A6.916 6.916 0 019 16a7 7 0 01-7-7 7 7 0 017-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5H8z"/>'
  }
})
