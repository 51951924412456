import { computed, defineComponent, ref } from "vue";
import { AxionInputBase } from "../AxionInputBase";
export default defineComponent({
    name: "AxionInputPassword",
    components: { AxionInputBase },
    props: {
        value: {
            required: true,
            type: null,
            validator: (value) => typeof value === "string" || value === null
        }
    },
    setup() {
        const visible = ref(false);
        const togglePasswordVisibility = () => {
            visible.value = !visible.value;
        };
        const inputType = computed(() => (visible.value ? "text" : "password"));
        return {
            visible,
            inputType,
            togglePasswordVisibility
        };
    }
});
