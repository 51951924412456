/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "color-wheel": {
    width: 64,
    height: 64,
    viewBox: "0 0 64 64",
    data: '<path pid="0" d="M32 0C14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0zm0 48.969c-9.372 0-16.969-7.597-16.969-16.969S22.628 15.031 32 15.031 48.969 22.628 48.969 32 41.372 48.969 32 48.969z" fill="#D0E8FF"/><path pid="1" d="M32 15.031V0C23.163 0 15.163 3.582 9.373 9.373L20 20a16.915 16.915 0 0112-4.97z" fill="#00CB71"/><path pid="2" d="M43.999 20.001L54.627 9.372C48.837 3.583 40.837 0 32 0v15.031c4.686 0 8.928 1.9 11.999 4.97z" fill="#FFD92E"/><path pid="3" d="M15.031 32c0-4.686 1.9-8.928 4.97-11.998L9.373 9.373C3.582 15.163 0 23.163 0 32h15.031z" fill="#7EE780"/><path pid="4" d="M48.969 32H64c0-8.836-3.582-16.836-9.373-22.627L44 20.002A16.915 16.915 0 0148.969 32z" fill="#FF9000"/><path pid="5" d="M32 48.968V64c8.837 0 16.837-3.582 22.627-9.372L44 43.998A16.916 16.916 0 0132 48.968z" fill="#AD0551"/><path pid="6" d="M32 48.968c-4.686 0-8.928-1.9-11.999-4.97L9.373 54.627c5.79 5.79 13.79 9.372 22.627 9.372V48.968z" fill="#554CCE"/><path pid="7" d="M15.031 32H0c0 8.837 3.582 16.837 9.373 22.627L20 44A16.915 16.915 0 0115.031 32z" fill="#3E34C2"/><path pid="8" d="M64 32H48.969c0 4.686-1.9 8.928-4.97 11.999l10.628 10.628C60.418 48.837 64 40.837 64 32z" fill="#FF3980"/>'
  }
});
