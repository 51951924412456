/**
 * Gives you the concentration of cells or organoids given a volume.
 * Takes into account dilution through trypan and other dyes.
 * @param countingChamberVolume volume in miliLiters (mL)
 * @returns concentration in cells/mL
 */
export function computeConcentration(numberOfCells, trypan, dilution, countingChamberVolume) {
    if (countingChamberVolume === 0)
        return 0;
    return ((numberOfCells * (1 + trypan) * (1 + dilution)) / countingChamberVolume);
}
