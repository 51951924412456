/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 21h3v-3H3m5 3h3v-7H8m5 7h3V9h-3m5 12h3V3h-3v18z"/>'
  }
})
