import { select } from "d3-selection";

const axis = {
  bind(el, binding) {
    select(el).call(binding.value);
  },
  componentUpdated(el, binding) {
    select(el).call(binding.value);
  }
};

export default axis;
