/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select-place': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 3h1v2H3V4c0-.5.5-1 1-1m16 0c.5 0 1 .5 1 1v1h-2V3h1m-5 2V3h2v2h-2m-4 0V3h2v2h-2M7 5V3h2v2H7m14 7c0 .5-.5 1-1 1h-1v-2h2v1m-6 1v-2h2v2h-2m-4 0v-2h2v2h-2m-4 0v-2h2v2H7m-3 0c-.5 0-1-.5-1-1v-1h2v2H4M3 7h2v2H3V7m18 0v2h-2V7h2M7 16l5 5 5-5H7z"/>'
  }
})
