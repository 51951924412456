import { defineComponent } from "vue";
import { AxionInputBase } from "../AxionInputBase";
export default defineComponent({
    name: "AxionInputSelect",
    components: { AxionInputBase },
    props: {
        value: {
            required: true,
            type: null,
            validator: (value) => typeof value === "string" || typeof value === "number" || value === null
        },
        options: {
            type: [Array, Object],
            required: true
        },
        inputClass: {
            type: Array,
            required: false,
            default: () => []
        }
    }
});
