import { transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
import { setupProvider } from "@/globals/setupProvider";
const UsersRepository = RepositoryFactory.users;
/**
 * This Provider is responsible for handling global state for logged in user
 */
export function withLoggedInUser() {
    const loggedInUser = ref({
        firstName: "",
        lastName: "",
        email: "",
        defaultTenantId: 0,
        function: "",
        phone: "",
        title: 0,
        city: "",
        id: ""
    });
    const fetching = ref(false);
    const isAdminOrganization = ref(false);
    const isGlobalAdmin = ref(false);
    const fetchLoggedInUser = async () => {
        try {
            fetching.value = true;
            const { data } = await UsersRepository.getLoggedInUser();
            loggedInUser.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error(error);
        }
        finally {
            fetching.value = false;
        }
    };
    const editLoggedInUser = async (params) => {
        try {
            fetching.value = true;
            const { data } = await UsersRepository.editLoggedInUser(params);
            loggedInUser.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error(error);
        }
        finally {
            fetching.value = false;
        }
    };
    return {
        loggedInUser,
        isAdminOrganization,
        isGlobalAdmin,
        fetching,
        fetchLoggedInUser,
        editLoggedInUser
    };
}
// PROVIDER SETUP
export const { provide: provideLoggedInUser, use: useLoggedInUser } = setupProvider();
