/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exclamation-thick': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 3h4v11h-4V3m0 18v-4h4v4h-4z"/>'
  }
})
