/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  "device-exact": {
    width: 16,
    height: 16,
    viewBox: "0 0 342 304",
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M234 0H7a7 7 0 00-7 7v212a19 19 0 0019 19h10.86A78.98 78.98 0 0085 281v18a5 5 0 005 5h184a5 5 0 005-5v-18a79 79 0 0063-77v-68H59V21h179V4a4 4 0 00-4-4zm-45.5 165.22v-5a8 8 0 00-15 0v5a37 37 0 1015 0z" fill="#000"/>'
  }
});
