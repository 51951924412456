import { ref } from "vue";
import { generateBusySlotForOccurrence } from "../utils/generateBusySlotForOccurrence";
import { generateSampleForOccurrence } from "../utils/generateSampleForOccurrence";
import { EventState } from "../shared/ICloudCalendarEvent";
import generateSampleStates from "../utils/generateSampleStates";
import { generateOccurrenceAsCloudCalendarEvent } from "../utils/generateOccurrenceAsCloudCalendarEvent";
import { setupProvider } from "@/globals/setupProvider";
import { DeviceType } from "../shared/enums";
/**
 * If there is something wrong when it comes to generating events on the calendar,
 * probably the issue is in this file.
 */
export function withCalendarEvents() {
    // refers to scans on experiment and reservation
    const sampleEvents = ref([]);
    // referes to events in between samples (for omni device), when omni pro these are not valid anymore
    const busySlotEvents = ref([]);
    // referes to experiments, reservations with start and end time, these events are visible only on montly view.
    const occurrenceEvents = ref([]);
    /**
     * This function is general, for adding a new event to either sampleEvents or busySlotEvents
     * @param events - samples or busyslots, which array to add event
     * @param event - event to be added to the array
     */
    function addEvent(events, event) {
        const index = events.findIndex(e => e.id === event.id);
        // add event only if it does not exist already
        if (index > -1) {
            events[index] = { ...event };
        }
        else {
            events.push(event);
        }
    }
    // This function is used to remove all editable events on "remove" or "X" button click (editable - the orange events);
    function removeEditableEvents() {
        sampleEvents.value = sampleEvents.value.filter(s => !s.editable);
        occurrenceEvents.value = occurrenceEvents.value.filter(s => !s.editable);
    }
    // This function is used to remove all non-editable events on "cancel" button click (non-editable - the axion blue events);
    function removeNonEditableCalendarEventsForOccurrence(occurrence) {
        sampleEvents.value = sampleEvents.value.filter(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) !== occurrence.reservationId; });
        occurrenceEvents.value = occurrenceEvents.value.filter(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) !== occurrence.reservationId; });
        busySlotEvents.value = busySlotEvents.value.filter(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) !== occurrence.reservationId; });
    }
    // To see the usage of this function, please, refer to setupAndProvide.ts
    // After fetching reservations and experiments, the code below generates samples and busy slots
    function calculateNonEditableCalendarEvents(device, occurrence, scanStates, toRemoveEditableEvents = false) {
        var _a;
        if (toRemoveEditableEvents) {
            removeEditableEvents();
        }
        // occurrenceEvent is the one shown on monthly view of calendar
        const occurrenceEvent = generateOccurrenceAsCloudCalendarEvent(device, occurrence);
        // if there are no scan states, generate these - needed for Omni2 experiments
        if (!scanStates) {
            scanStates = generateSampleStates(occurrence);
        }
        addEvent(occurrenceEvents.value, occurrenceEvent);
        // if the device is lux, you don't need to generate samples (lux snapshot continues for less than a minute)
        if (device.type === DeviceType.Lux) {
            return;
        }
        occurrence.numberOfEvents = scanStates.numberOfActiveScans;
        occurrence.startOffset = scanStates.startedAt;
        let index = (_a = occurrence.numberOfCompletedEvents) !== null && _a !== void 0 ? _a : 0;
        let startTime = new Date(occurrence.startOffset);
        scanStates.states.forEach((st, i) => {
            var _a, _b;
            index += 1;
            //for each scan create an event on the calendar
            if (occurrence) {
                const sample = generateSampleForOccurrence(device.id, index, st.scanState, occurrence, startTime, false);
                const busySlot = generateBusySlotForOccurrence(device.id, index, occurrence, startTime);
                addEvent(sampleEvents.value, sample);
                startTime = new Date((_a = sample.end) !== null && _a !== void 0 ? _a : sample.start);
                if (scanStates && i === scanStates.numberOfActiveScans - 1) {
                    return;
                }
                if (occurrence.eventsInterval === 0) {
                    startTime = new Date((_b = sample.end) !== null && _b !== void 0 ? _b : sample.start);
                }
                else if (!device.hasRobotArmAttached) {
                    addEvent(busySlotEvents.value, busySlot);
                }
                // start time of next event in the for loop
                startTime = new Date(busySlot.end ? busySlot.end : busySlot.start);
            }
        });
    }
    // whenever the user clicks on the calendar, he can configure his reservation
    // the orange events that appear on the calendar, are generated here (thus, the name editable)
    function calculateEditableCalendarEvents(device, occurrence) {
        var _a, _b, _c;
        const startingIndexSample = sampleEvents.value.findIndex(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) === occurrence.id; });
        const startingIndexBusySlot = busySlotEvents.value.findIndex(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) === occurrence.id; });
        const count = sampleEvents.value.filter(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) === occurrence.id; }).length;
        const countBs = busySlotEvents.value.filter(s => { var _a; return ((_a = s.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) === occurrence.id; }).length;
        // if the user decreases the number of samples (scans)
        if (startingIndexSample > -1 &&
            sampleEvents.value.length > occurrence.numberOfEvents) {
            sampleEvents.value.splice(startingIndexSample, count);
        }
        // when decreasing the number of samples, the busy slots (if any) should also be affected
        if (startingIndexBusySlot > -1) {
            busySlotEvents.value.splice(startingIndexBusySlot, countBs);
        }
        const occurrenceEvent = occurrenceEvents.value.find(o => { var _a; return ((_a = o.extendedProps) === null || _a === void 0 ? void 0 : _a.relationId) === occurrence.id; });
        // set the state to be editable, as otherwise it cannot be modified on the calendar
        if (occurrenceEvent) {
            occurrenceEvent.editable = true;
            addEvent(occurrenceEvents.value, occurrenceEvent);
        }
        // the logic is similar to the one with editable events
        let startTime = new Date(occurrence.startOffset);
        for (let i = 0; i < occurrence.numberOfEvents; i++) {
            const editableSample = generateSampleForOccurrence(device.id, i, EventState.Waiting, occurrence, startTime, true // to be editable
            );
            const editableBusySlot = generateBusySlotForOccurrence(device.id, startingIndexBusySlot, occurrence, startTime);
            addEvent(sampleEvents.value, editableSample);
            startTime = new Date((_a = editableSample.end) !== null && _a !== void 0 ? _a : editableSample.start);
            if (i === occurrence.numberOfEvents - 1) {
                return;
            }
            if (occurrence.eventsInterval === 0) {
                startTime = new Date((_b = editableSample.end) !== null && _b !== void 0 ? _b : editableSample.start);
            }
            else {
                startTime = new Date((_c = editableBusySlot.end) !== null && _c !== void 0 ? _c : editableSample.start);
            }
        }
    }
    return {
        calculateNonEditableCalendarEvents,
        calculateEditableCalendarEvents,
        removeEditableEvents,
        removeNonEditableCalendarEventsForOccurrence,
        sampleEvents,
        busySlotEvents,
        occurrenceEvents
    };
}
export const { provide: provideCalendarEvents, use: useCalendarEvents } = setupProvider();
