import Repository from "@/api/repository";
const resourceUserPreferences = `/notification/userpreferences`;
export default {
    getNotificationUserPreferencesOrderedByGroup() {
        return Repository.get(`${resourceUserPreferences}`);
    },
    updateNotificationUserPreference(notificationId, emailPreference) {
        return Repository.post(`${resourceUserPreferences}/${notificationId}/${emailPreference}`);
    },
    updateAllNotificationUserPreferences(emailPreference) {
        return Repository.post(`${resourceUserPreferences}/all/${emailPreference}`);
    }
};
