/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-document-box-multiple-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 15H9v-2h7v2m3-4H9V9h10v2m0-4H9V5h10v2M3 5v16h16v2H3a2 2 0 01-2-2V5h2m18-4a2 2 0 012 2v14c0 1.11-.89 2-2 2H7a2 2 0 01-2-2V3c0-1.11.89-2 2-2h14M7 3v14h14V3H7z"/>'
  }
})
