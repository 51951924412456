import { customSort } from "@/pages/wellPlate/_providers/helpers";
import { findGroupByAreasIds } from "@/pages/wellPlate/_providers/withGroups/helpers/findGroupByAreasIds";
export function useSelectingItemsActions(groups, selectedAreasIds, selectedGroupsIds, modifierKeyActive) {
    const clearAreaIds = () => {
        selectedAreasIds.value = [];
    };
    const appendAreaIds = (newAreaIds) => {
        // prevent adding existing id
        const filteredNewAreaIds = newAreaIds.filter(id => !selectedAreasIds.value.includes(id));
        selectedAreasIds.value = customSort(selectedAreasIds.value.concat(filteredNewAreaIds));
    };
    const removeAreaIds = (ids) => {
        selectedAreasIds.value = selectedAreasIds.value.filter(id => !ids.includes(id));
    };
    const clearGroupIds = () => {
        selectedGroupsIds.value = [];
    };
    const appendGroup = (group) => {
        const groupIsSelected = selectedGroupsIds.value.includes(group.Id.toString());
        if (!groupIsSelected) {
            selectedGroupsIds.value.push(group.Id.toString());
            appendAreaIds(group.areas);
        }
    };
    const removeGroup = (group) => {
        selectedGroupsIds.value = selectedGroupsIds.value.filter(id => id !== group.Id.toString());
        removeAreaIds(group.areas);
    };
    const deselectAll = () => {
        clearGroupIds();
        clearAreaIds();
    };
    const toggleSelectedAreasAndGroups = (ids, ignoreGroups) => {
        const interactedGroups = [];
        ids.forEach(id => {
            if (findGroupByAreasIds(interactedGroups, [id])) {
                // area that is part of already deselected group should be ignored, since they are already deselected
                return;
            }
            const areaIsSelected = selectedAreasIds.value.includes(id);
            const group = findGroupByAreasIds(groups.value, [id]);
            if (areaIsSelected) {
                removeAreaIds([id]);
                if (group) {
                    removeGroup(group);
                    interactedGroups.push(group);
                }
            }
            else {
                appendAreaIds([id]);
                if (ignoreGroups) {
                    return;
                }
                if (group) {
                    appendGroup(group);
                    interactedGroups.push(group);
                }
            }
        });
    };
    const selectItemsWithModifier = (ids, ignoreGroups) => {
        toggleSelectedAreasAndGroups(ids, ignoreGroups);
    };
    const selectItemsWithoutModifier = (ids, ignoreGroups) => {
        deselectAll();
        toggleSelectedAreasAndGroups(ids, ignoreGroups);
    };
    const selectItems = (ids, options) => {
        if (modifierKeyActive.value && !(options === null || options === void 0 ? void 0 : options.ignoreModifierKey)) {
            selectItemsWithModifier(ids, options === null || options === void 0 ? void 0 : options.ignoreGroups);
        }
        else {
            selectItemsWithoutModifier(ids, options === null || options === void 0 ? void 0 : options.ignoreGroups);
        }
    };
    return {
        selectItems,
        deselectAll,
        clearAreaIds,
        appendAreaIds,
        removeAreaIds,
        clearGroupIds,
        appendGroup,
        removeGroup,
        toggleSelectedAreasAndGroups
    };
}
