import Repository from "@/api/repository";
import { controllerRoutePrefix } from "../controllerRoutePrefix";
const resource = `${controllerRoutePrefix}/export`;
export default {
    getExportContinues(BaseExportFunctionAppUrl, experimentId) {
        return Repository.get(`${BaseExportFunctionAppUrl}api/omni3/v1/massExport/${experimentId}/status`);
    },
    getMassExportContinues(BaseExportFunctionAppUrl, experimentId) {
        return Repository.get(`${BaseExportFunctionAppUrl}api/massExport/${experimentId}/status`);
    },
    getExportTimeEstimation(experimentId, exportType) {
        return Repository.get(`/lux3/v1/experiments/${experimentId}/ExportTimeEstimation`, {
            params: { experimentId, exportType }
        });
    },
    requestProcessedZip(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/requestprocessedzip`, model);
    },
    requestImageZip(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/requestimagezip`, model);
    },
    requestImageZipOmni(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/requestimagezip`, model);
    },
    requestVideo(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/requestvideo`, model);
    }
};
