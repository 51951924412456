import Repository from "@/api/repository";
const resource = `/Notes`;
export default {
    getNotes(experimentId) {
        return Repository.get(`${resource}/${experimentId}`);
    },
    postNote(model) {
        return Repository.post(`${resource}`, model);
    },
    deleteNote(id) {
        return Repository.delete(`${resource}/${id}`);
    }
};
