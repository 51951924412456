/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-multiple-marked-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 16v-6h2v6a2 2 0 01-2 2H8a2 2 0 01-2-2V4c0-1.11.89-2 2-2h8v2H8v12h12m-9.09-8.92L14 10.17l6.59-6.59L22 5l-8 8-4.5-4.5 1.41-1.42M16 20v2H4a2 2 0 01-2-2V7h2v13h12z"/>'
  }
})
