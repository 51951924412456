/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blur': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 8.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5A1.5 1.5 0 0014 8.5m0 4a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5M10 17a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0-8.5A1.5 1.5 0 008.5 10a1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5A1.5 1.5 0 0010 8.5m4 12a.5.5 0 00-.5.5.5.5 0 00.5.5.5.5 0 00.5-.5.5.5 0 00-.5-.5m0-3.5a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m7-3.5a.5.5 0 00-.5.5.5.5 0 00.5.5.5.5 0 00.5-.5.5.5 0 00-.5-.5M18 5a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0 4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0 8a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0-4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m-8-.5A1.5 1.5 0 008.5 14a1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5M10 7a1 1 0 001-1 1 1 0 00-1-1 1 1 0 00-1 1 1 1 0 001 1m0-3.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5.5.5 0 00-.5.5.5.5 0 00.5.5m0 17a.5.5 0 00-.5.5.5.5 0 00.5.5.5.5 0 00.5-.5.5.5 0 00-.5-.5m-7-7a.5.5 0 00-.5.5.5.5 0 00.5.5.5.5 0 00.5-.5.5.5 0 00-.5-.5m11-10a.5.5 0 00.5-.5.5.5 0 00-.5-.5.5.5 0 00-.5.5.5.5 0 00.5.5M14 7a1 1 0 001-1 1 1 0 00-1-1 1 1 0 00-1 1 1 1 0 001 1m7 3.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5.5.5 0 00-.5.5.5.5 0 00.5.5M6 5a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1M3 9.5a.5.5 0 00-.5.5.5.5 0 00.5.5.5.5 0 00.5-.5.5.5 0 00-.5-.5M6 9a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0 8a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m0-4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1z"/>'
  }
})
