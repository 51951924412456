// Module includes DateTime manipulating functions using Luxon
import { pluralize } from "@axisvue/utils";
import { DateTime } from "luxon";
export const Now = () => DateTime.now();
// Default Parameter -> incase of argument-less execution operate on Now
export const dateStringFormatter = (input = Now()) => input.toISO().replace(/T.*$/, "");
export const formatDateToTime = (input = Now()) => input.toFormat("HH:mm:ss");
// Tranform DateTime to  "27 Oct 2021, 12:55" format
export const formatDate = (input = Now(), format = DateTime.DATETIME_MED) => input.setLocale("en").toLocaleString(format);
// Transform a ISO-8601 DateTime String to Luxon DateTime
export const parseFromISOStr = (input) => DateTime.fromISO(input);
// Check if given dateTime is already passed compare to now
export const isDatePassed = (input) => input < Now();
// Return difference of given time with today in day scale
export const diffFromNowInDays = (input) => (input.startOf("days").toMillis() - Now().startOf("days").toMillis()) /
    86400000;
/**
 * Round up given input to the nearest time based on the given increment in minutes. To understant the increment, let's refer to it as A,
 * and the starting time of each day, expressed in minutes, as B (00:00:00).
 * After A minutes pass once, B == A. After A minutes pass again, B == 2 * A. After one more time, B == 3 * A.
 * This method will round any time X where 2 * A < x < 3 * A, up to 3 * A.
 * Similarly, if A < X < 2 * A, then X will be rounded up to 2 * A.
 * @param input The time to round up.
 * @param increment Up to which increment in minutes to round up to.
 * @returns Rounded up DateTime
 */
export const roundUpTimeByXMin = (input = Now(), increment = 5) => {
    const coeff = 1000 * 60 * increment;
    const roundedDateTime = new Date(Math.ceil(input.toMillis() / coeff) * coeff);
    return DateTime.fromJSDate(roundedDateTime).set({
        second: 0,
        millisecond: 0
    });
};
/**
 * Format DateTime relatively to current-time in customized manner
 * @param input take input DateTime format
 * @param use12HourFormat flag to decide to use 12 hour format
 * @param duration number, takes duration in minutes format, default is 15
 * */
export const formatRelatively = (input, use12HourFormat = false, duration = 0) => {
    const format = use12HourFormat ? "hh:mm a" : "HH:mm";
    const eventEndDt = input.plus({ minutes: duration });
    const diffInDays = diffFromNowInDays(eventEndDt);
    const endTime = eventEndDt.toFormat(format);
    let finalResult = "";
    if (diffInDays == 0)
        finalResult = "Today, ";
    else if (diffInDays == 1)
        finalResult = "Tomorrow, ";
    else
        finalResult = formatDate(eventEndDt, DateTime.DATE_HUGE) + ", ";
    return `${finalResult} ${endTime}`;
};
const timeNumberWithTwoPlaces = (timeNumber) => `${timeNumber.length == 1 ? "0" : ""}${timeNumber}`;
/**
 * Returns string time format in "00:00:00" from minutes floating number
 * @param {number} minutes floating number
 * @returns string time format "01:12:30"
 */
export const formatMinutesToTimeString = (minutes) => {
    const hour = Math.floor(minutes / 60).toString();
    const min = Math.floor(minutes % 60).toString();
    const seconds = Math.floor((minutes % 1) * 60).toString();
    return `${timeNumberWithTwoPlaces(hour)}:${timeNumberWithTwoPlaces(min)}:${timeNumberWithTwoPlaces(seconds)}`;
};
/**
 * Returns (now + additionalMinutes)
 * @param additionalMinutes Additional minutes to add to the current time
 */
export const getASAPDateTime = (additionalMinutes = 1) => DateTime.now()
    .set({ second: 0, millisecond: 0 })
    .plus({ minutes: additionalMinutes });
/**
 * Returns (now + additionalMinutes)
 * @param additionalMinutes Additional minutes to add to the current time
 * @returns DateTime as ISO string
 */
export const getASAPDateTimeISO = (additionalMinutes = 1) => getASAPDateTime(additionalMinutes).toISO();
/**
 * Returns time for next scan based on interval, amount of scans and start time.
 * @param {[string, string, string, string]} time array of string containing time
 * @returns formatted time: XX day(s), YY hour(s), WW minute(s) and ZZ second(s)
 */
export const formatTime = (time) => {
    const conjuction = (hasFurtherText) => hasFurtherText ? ", " : " and ";
    let formattedTime = "";
    const [days, hours, minutes, seconds] = time;
    const hasDays = days !== "00";
    const hasHours = hours !== "00";
    const hasMinutes = minutes !== "00";
    const hasSeconds = seconds !== "00";
    if (!hasDays && !hasHours && !hasMinutes) {
        return "less than a minute";
    }
    if (hasDays) {
        formattedTime += pluralize("day", Number(days));
    }
    if (hasHours) {
        formattedTime +=
            formattedTime === "" ? "" : conjuction(hasMinutes || hasSeconds);
        formattedTime += pluralize("hour", Number(hours));
    }
    if (hasMinutes) {
        formattedTime += formattedTime === "" ? "" : conjuction(hasSeconds);
        formattedTime += pluralize("minute", Number(minutes));
    }
    if (hasSeconds) {
        formattedTime += formattedTime === "" ? "" : " and ";
        formattedTime += pluralize("second", Number(seconds));
    }
    return formattedTime;
};
