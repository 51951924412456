/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'greater-than-or-equal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path d="M6.5,2.27L20,10.14L6.5,18L5.5,16.27L16.03,10.14L5.5,4L6.5,2.27M20,20V22H5V20H20Z" />'
  }
})
