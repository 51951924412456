import { ref } from "vue";
import { DeviceType } from "../shared/enums";
import { generateBusySlotForExperiment } from "../utils/generateBusySlotForExperiment";
import { generateScanForExperiment } from "../utils/generateScanForExperiment";
import { CalendarEventState } from "./types/ICloudCalendarEvent";
import { generateExperiment } from "../utils/generateExperiment";
export default function withCalendarEvents() {
    /* scans to show as events on the calendar */
    const scans = ref([]);
    /* busy slots between scans to show as events on the calendar */
    const busySlots = ref([]);
    /* experimentEvents to show lux data and monthly view of calendar */
    const experimentEvents = ref([]);
    /**
     * Helper function - adding an event to specific events array.
     * @param events - an array passed - busy slots or scans.
     * @param event - event to be added.
     */
    function addEvent(events, event) {
        const index = events.findIndex(e => e.id === event.id);
        // add event only if it does not exist already
        if (index > -1) {
            events[index] = { ...event };
        }
        else {
            events.push(event);
        }
    }
    /**
     * Helper function - generates scan states for omni2 experiments.
     * The back-end does not provide scan states information about these. Therefore, have to be mocked.
     *
     * @param experiment - omni2 experiment.
     * @returns IScanEstimate
     */
    function generateScanStatesForOmni2(experiment) {
        const tempStates = [];
        for (let i = 0; i < experiment.estimateNumberOfSamples; i++) {
            tempStates.push({
                id: i,
                scanState: CalendarEventState.NO_DATA // no data means it will not be shown on the front-end
            });
        }
        // of type scan estimate
        return {
            states: tempStates,
            numberOfActiveScans: experiment.estimateNumberOfSamples,
            startedAt: experiment.startOffset,
            startedBy: {
                name: experiment.owner.name,
                email: experiment.owner.email
            }
        };
    }
    function generateCalendarEvents(device, experiment, scanStates) {
        // on monthly view all experiments have associated complete events
        const experimentEvent = generateExperiment(device, experiment);
        addEvent(experimentEvents.value, experimentEvent);
        // for a lux device, the calendar shows only complete events - experiments
        // lux experiment snapshot duration is only a few seconds
        if (device.type === DeviceType.Lux) {
            return;
        }
        // if there are no scan states, then the device is omni2
        if (!scanStates) {
            scanStates = generateScanStatesForOmni2(experiment);
        }
        // take information about number of events on the calendar from scan states object
        experiment.estimateNumberOfSamples = scanStates.numberOfActiveScans;
        //minimum possible duration of event on the calendar is 5 minutes // if less it becomes too small
        const minEventDuration = 5;
        const sampleEstimate = experiment.estimateSampleDuration > minEventDuration
            ? experiment.estimateSampleDuration
            : minEventDuration;
        const sampleInterval = experiment.sampleInterval > minEventDuration ||
            experiment.sampleInterval === 0
            ? experiment.sampleInterval
            : minEventDuration;
        let eventStartTime = new Date(scanStates.startedAt);
        let index = experiment.previouslyCompletedScans; // continue experiment starting scan number
        scanStates.states.forEach((st, i) => {
            var _a, _b;
            index += 1;
            //for each scan create an event on the calendar
            if (experiment) {
                const eventConstructorOptions = {
                    eventId: index,
                    deviceId: device.id,
                    experimentId: experiment.experimentId,
                    projectId: experiment.projectDetails.projectId,
                    eventEstimateStartTime: eventStartTime,
                    eventEstimateDuration: sampleEstimate,
                    eventsBetweenInterval: sampleInterval > sampleEstimate
                        ? sampleInterval - sampleEstimate
                        : 0,
                    experimentSampleEstimateDuration: experiment.estimateSampleDuration,
                    projectIsShared: experiment.projectDetails.isShared,
                    experimentOwner: experiment.owner,
                    eventState: st.scanState
                };
                const scan = generateScanForExperiment(eventConstructorOptions);
                const busySlot = generateBusySlotForExperiment(eventConstructorOptions);
                addEvent(scans.value, scan);
                eventStartTime = new Date((_a = scan.end) !== null && _a !== void 0 ? _a : scan.start);
                if (scanStates && i === scanStates.numberOfActiveScans - 1) {
                    return;
                }
                // sample interval contains the sample estimate, the back-end returns these separately, thus the if statement.
                // 60 === 60 + 30 // electron value for sample interval 60, actual value is 30.
                if (sampleEstimate === sampleInterval + sampleEstimate ||
                    sampleInterval === 0) {
                    eventStartTime = new Date((_b = scan.end) !== null && _b !== void 0 ? _b : scan.start);
                }
                else if (eventConstructorOptions.eventsBetweenInterval > 0) {
                    if (!device.hasRobotArmAttached) {
                        addEvent(busySlots.value, busySlot);
                    }
                    eventStartTime = new Date(busySlot.end ? busySlot.end : busySlot.start);
                }
            }
        });
    }
    return {
        generateCalendarEvents,
        scans,
        busySlots,
        experimentEvents
    };
}
