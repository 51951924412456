import { computed, defineComponent, inject } from "vue";
import { AxionButton } from "@axisvue/buttons";
import { CalendarEventState } from "../_providers/types/ICloudCalendarEvent";
import { useActiveExperiments } from "@/shared/providers/withActiveExperiments/withActiveExperiments";
import StateInjection from "../_providers/types/StateInjection";
import { formatOffsetInHoursAndMinutes } from "../utils/formatOffsetInHoursAndMinutes";
export default defineComponent({
    components: {
        AxionButton
    },
    props: {
        event: {
            type: Object,
            required: true
        },
        isTimeFormat12: {
            type: Boolean,
            required: true
        },
        eventOffsetInMinutes: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const state = inject(StateInjection);
        const { activeExperiments } = useActiveExperiments();
        const experiment = computed(() => activeExperiments.value.find(e => { var _a; return e.experimentId === ((_a = props.event.extendedProps) === null || _a === void 0 ? void 0 : _a.experimentId); }));
        const isShared = computed(() => { var _a, _b; return (_b = (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.projectDetails) === null || _b === void 0 ? void 0 : _b.isShared; });
        const experimentScans = computed(() => {
            var _a;
            return (_a = state === null || state === void 0 ? void 0 : state.scans.value) === null || _a === void 0 ? void 0 : _a.filter(a => { var _a, _b; return ((_a = a.extendedProps) === null || _a === void 0 ? void 0 : _a.experimentId) === ((_b = experiment.value) === null || _b === void 0 ? void 0 : _b.experimentId); });
        });
        const projectName = computed(() => { var _a, _b; return (_b = (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.projectDetails) === null || _b === void 0 ? void 0 : _b.projectName; });
        const isOmni3 = computed(() => { var _a; return (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.isOmni3; });
        const timeFormat = computed(() => props.isTimeFormat12 ? "en-US" : "en-GB");
        // offset value for selected experiment
        const timezoneOffset = computed(() => {
            return formatOffsetInHoursAndMinutes(props.eventOffsetInMinutes);
        });
        const interval = computed(() => { var _a; return (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.sampleInterval; });
        const description = computed(() => { var _a; return (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.description; });
        const owner = computed(() => { var _a, _b; return (_b = (_a = experiment.value) === null || _a === void 0 ? void 0 : _a.owner) === null || _b === void 0 ? void 0 : _b.name; });
        const scanInfo = computed(() => {
            var _a, _b;
            const actualScans = (_a = experimentScans.value) === null || _a === void 0 ? void 0 : _a.filter(es => { var _a; return ((_a = es.extendedProps) === null || _a === void 0 ? void 0 : _a.eventState) !== CalendarEventState.SKIPPED; });
            const completedScans = (_b = experimentScans.value) === null || _b === void 0 ? void 0 : _b.filter(es => { var _a; return ((_a = es.extendedProps) === null || _a === void 0 ? void 0 : _a.eventState) === CalendarEventState.COMPLETED; });
            return (completedScans === null || completedScans === void 0 ? void 0 : completedScans.length) + " of " + (actualScans === null || actualScans === void 0 ? void 0 : actualScans.length);
        });
        /* helper function */
        function formatToLocaleDateString(date) {
            return date.toLocaleDateString(timeFormat.value, {
                weekday: "short",
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric"
            });
        }
        const finishTime = computed(() => {
            var _a, _b, _c;
            const lastScanFinishTime = (_c = (_a = experimentScans.value) === null || _a === void 0 ? void 0 : _a.at(((_b = experimentScans.value) === null || _b === void 0 ? void 0 : _b.length) - 1)) === null || _c === void 0 ? void 0 : _c.end;
            if (lastScanFinishTime) {
                return formatToLocaleDateString(new Date(lastScanFinishTime));
            }
            return "unknown finish time.";
        });
        const startTime = computed(() => {
            var _a, _b;
            const firstScanStartTime = (_b = (_a = experimentScans.value) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.start;
            if (firstScanStartTime) {
                return formatToLocaleDateString(new Date(firstScanStartTime));
            }
            return "unknown start time.";
        });
        function close() {
            emit("close");
        }
        /* helper function */
        function padToTwoDigits(num) {
            return num.toString().padStart(2, "0");
        }
        function toHoursAndMinutes(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
        }
        function openExperiment() {
            var _a, _b, _c;
            window.open(`${(_c = (_b = (_a = experimentScans.value) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.extendedProps) === null || _c === void 0 ? void 0 : _c.hiddenUrl}`, "_blank");
        }
        return {
            experiment,
            description,
            projectName,
            owner,
            close,
            startTime,
            finishTime,
            interval,
            toHoursAndMinutes,
            scanInfo,
            timezoneOffset,
            isShared,
            isOmni3,
            openExperiment
        };
    }
});
