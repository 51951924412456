/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mouse': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 1.07C7.05 1.56 4 4.92 4 9h7m-7 6a8 8 0 008 8 8 8 0 008-8v-4H4m9-9.93V9h7c0-4.08-3.06-7.44-7-7.93z"/>'
  }
})
