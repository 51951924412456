import Vue from "vue";
import truncate from "./truncate";
import exponent from "./exponent";
import { formatDateMilliseconds, formatDateString, formatDateTime } from "./format";
import confluence from "./data";
import concentration from "./concentration";
import viability from "./viability";
import { date, datetime, dateOnly, timeOnly } from "./date";
Vue.filter("date", date);
Vue.filter("concentration", concentration);
Vue.filter("viability", viability);
Vue.filter("datetime", datetime);
Vue.filter("dateOnly", dateOnly);
Vue.filter("truncate", truncate);
Vue.filter("exponent", exponent);
Vue.filter("formatDateMilliseconds", formatDateMilliseconds);
Vue.filter("formatDateString", formatDateString);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("confluence", confluence);
Vue.filter("timeOnly", timeOnly);
