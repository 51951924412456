import Axios from "axios";
import Repository from "@/api/repository";
import { baseUrl } from "../base";
const resource = `${baseUrl}/experiments`;
const areas = `${baseUrl}/areas`;
export default {
    getScans(experimentId) {
        return Repository.get(`${resource}/${experimentId}/scans`);
    },
    getScanStates(experimentId) {
        return Repository.get(`${resource}/${experimentId}/scans/states`);
    },
    getDetails(experimentId) {
        return Repository.get(`${resource}/${experimentId}/details`);
    },
    getConfluence(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/confluence`);
    },
    getObjectCount(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/objectcount`);
    },
    getScratch(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/scratch`);
    },
    getOrganoid(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/organoid`);
    },
    getOrganoidSettings(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/organoid/gating`);
    },
    getClonogenic(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/colony`);
    },
    getClonogenicSettings(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/colony/gating`);
    },
    getPSC(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/psc`);
    },
    getPSCSettings(experimentId) {
        return Repository.get(`${resource}/${experimentId}/data/psc/gating`);
    },
    getAreas(experimentId) {
        return Repository.get(`${resource}/${experimentId}/areas`);
    },
    getAreasStatus(experimentId, timePoint) {
        return Repository.get(`${areas}/${experimentId}/${timePoint.UnixTime}/status`);
    },
    getAreasPositionsRelative(experimentId, timePoint) {
        return Repository.get(`${resource}/${experimentId}/areas/positions/${timePoint.UnixTime}/relative`);
    },
    getAreasPositionsAbsolute(experimentId) {
        return Repository.get(`${resource}/${experimentId}/areas/positions/absolute`);
    },
    getRelatedRoisForArea(areaExperimentId) {
        return Repository.get(`${resource}/${areaExperimentId}/relatedRois`);
    },
    getSliceProperties(experimentId, timePoint) {
        return Repository.get(`${resource}/${experimentId}/sliceprops/${timePoint.UnixTime}`);
    },
    getPrevAndNextWellExperiments(experimentId, wellExperimentId) {
        return Repository.get(`${resource}/${experimentId}/getWells`, {
            params: {
                wellExperimentId,
                nrOfPrevAndNext: 999
            }
        });
    },
    postCreateAreas(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/areas`, model);
    },
    postAreasReprocess(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/overlaysetting`, model);
    },
    getGroups(experimentId) {
        return Repository.get(`${resource}/${experimentId}/groups`);
    },
    createGroup(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/groups`, model);
    },
    deleteGroup(experimentId, groupId) {
        return Repository.delete(`${resource}/${experimentId}/groups/${groupId}`);
    },
    editGroup(experimentId, group) {
        return Repository.put(`${resource}/${experimentId}/groups`, group);
    },
    postExistingAreasProcess(experimentId, model) {
        return Repository.post(`${resource}/${experimentId}/areas/algorithms/attach`, model);
    },
    postOrganoidGatingSettings(experimentId, gating) {
        return Repository.post(`${resource}/${experimentId}/data/organoid/gating`, gating);
    },
    postClonogenicGatingSettings(experimentId, gating) {
        return Repository.post(`${resource}/${experimentId}/data/colony/gating`, gating);
    },
    postPSCGatingSettings(experimentId, gating) {
        return Repository.post(`${resource}/${experimentId}/data/psc/gating`, gating);
    },
    deleteAreas(experimentId, areasToDelete) {
        return Repository.delete(`${resource}/${experimentId}/deleteareas`, areasToDelete);
    },
    fetchPolygonData(url) {
        return Repository.get(url);
    },
    getPatternSnapshots(experimentId, timestamp) {
        return Repository.get(`${resource}/${experimentId}/snapshots/original/${timestamp}`);
    },
    getPatternConfluenceSnapshot(experimentId, timestamp) {
        return Repository.get(`${resource}/${experimentId}/snapshots/confluence/${timestamp}`);
    },
    fetchSummarizedData(omniSummaryApiEndpoint, requestBody) {
        return Axios.post(omniSummaryApiEndpoint, JSON.parse(JSON.stringify(requestBody)));
    },
    getAreasSettings(experimentId) {
        return Repository.get(`${areas}/${experimentId}/settings`);
    }
};
