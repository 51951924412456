<template>
  <select
    class="old-form-input cursor-pointer border border-gray-300 disabled:bg-gray-100"
    :class="{
      readonly: $attrs.readonly,
      'border-red-500 bg-red-100': !isValid
    }"
    v-bind="$attrs"
    :disabled="inputDisabled"
    v-on="listeners"
  >
    <option
      v-for="(o, i) in normalizedOptions"
      :key="i"
      :value="o.value"
      :disabled="o.disabled"
    >
      {{ o[itemTextProperty] }}
    </option>
  </select>
</template>

<script>
/**
 * @module forms/SelectInput
 * @vue-prop {Object[]} [options = []] - List of options of type: {text: String, value: any, disabled: Boolean}
 */
export default {
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    inputDisabled: {
      type: Boolean,
      default: false
    },
    // in case that passed options is array of objects,
    // you can specify which nested property key contains the value for displaying text
    itemTextProperty: {
      type: String,
      required: false,
      default: () => "text"
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit("input", event.target.value);
        },
        change: event => {
          this.$emit("select", event.target.value);
        }
      };
    },
    normalizedOptions() {
      if (this.options.length === 0) {
        return [];
      }

      if (typeof this.options[0] === "object") {
        return this.options;
      } else {
        return this.options.map(o => ({
          [this.itemTextProperty]: o,
          value: o
        }));
      }
    }
  }
};
</script>
