import autoTable from "jspdf-autoTable";
import { offset, margins, imageSize } from "./constants";
import { statisticsTableTitle } from "./titles";
/**
 * Function that generates table for experiment data.
 * @param doc The PDF document.
 * @param experimentData The data necessary for the table. Format is list of [label, value] pair.
 */
export function experimentDataTable(doc, experimentData) {
    autoTable(doc, {
        theme: "grid",
        startY: 30,
        tableWidth: doc.internal.pageSize.width - 20,
        styles: { fillColor: [0, 131, 148] },
        columnStyles: {
            0: {
                halign: "center",
                fillColor: [0, 131, 148],
                textColor: [255, 255, 255]
            },
            1: { fillColor: [255, 255, 255] }
        },
        margin: { top: 10, left: 10 },
        showHead: "never",
        head: [["Property", "Value"]],
        body: experimentData
    });
}
/**
 * Function that generates table for count statistics data.
 * @param doc The PDF document.
 * @param index The current count index.
 * @param data The data necessary for the table. Format is list of [label, value] pair.
 */
export function countDataTable(doc, index, data, startY) {
    autoTable(doc, {
        theme: "grid",
        startY: startY ? startY : offset.v + 5 * margins.v + imageSize.height * 2,
        tableWidth: doc.internal.pageSize.width - 2 * offset.h,
        styles: { fillColor: [0, 131, 148] },
        columnStyles: {
            0: {
                halign: "center",
                fillColor: [0, 131, 148],
                textColor: [255, 255, 255]
            },
            1: { fillColor: [255, 255, 255] }
        },
        margin: { top: 10, left: 10 },
        head: [
            [
                {
                    content: `Count ${index}`,
                    colSpan: 2,
                    styles: { halign: "center", fillColor: [161, 161, 161] }
                }
            ]
        ],
        body: data
    });
}
/**
 * Function that generates table for experiment notes data.
 * @param doc The PDF document.
 * @param offset Offset from the top of the page
 * @param data The list of notes associated with the experiment
 */
export function notesTable(doc, offset, data) {
    autoTable(doc, {
        theme: "grid",
        startY: offset.v + 5,
        tableWidth: doc.internal.pageSize.width - 2 * offset.h,
        didParseCell: function (data) {
            if (data.section !== "body")
                return;
            if (data.row.index % 2 === 0) {
                data.cell.styles.fillColor = [255, 255, 255];
            }
            else {
                data.cell.styles.fillColor = [244, 244, 244];
                data.cell.styles.textColor = [117, 117, 117];
            }
        },
        columnStyles: {
            0: { fillColor: [255, 255, 255] }
        },
        margin: { top: 10, left: 10 },
        head: [
            [
                {
                    content: `Experiment Notes`,
                    colSpan: 1,
                    styles: { halign: "center", fillColor: [161, 161, 161] }
                }
            ]
        ],
        body: data
    });
}
/**
 * Function that generates table for average count statistics data.
 * @param doc The PDF document.
 * @param isMultiCount Check if experiment has multiple counts.
 * @param nrOfCounts The number of counts.
 * @param countId The current count id - necessary if single count.
 * @param averageData The data necessary for the table. Format is list of [label, value] pair.
 */
export function averageDataTable(doc, isMultiCount, nrOfCounts, countId, averageData, startY) {
    autoTable(doc, {
        theme: "grid",
        startY: startY ? startY : offset.v + 5 * margins.v + imageSize.height * 2,
        tableWidth: doc.internal.pageSize.width - 20,
        styles: { fillColor: [0, 131, 148] },
        columnStyles: {
            0: {
                halign: "center",
                fillColor: [0, 131, 148],
                textColor: [255, 255, 255]
            },
            1: { fillColor: [255, 255, 255] }
        },
        margin: { top: 10, left: 10 },
        head: [
            [
                {
                    content: statisticsTableTitle(isMultiCount, nrOfCounts, countId),
                    colSpan: 2,
                    styles: { halign: "center", fillColor: [161, 161, 161] }
                }
            ]
        ],
        body: averageData
    });
}
