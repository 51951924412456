import Repository from "@/api/repository";
const resource = `/experiments`;
const barcodeResource = `/barcodes`;
export default {
    getExperiments(experimentId) {
        return Repository.get(`${resource}/GetPreviousAndNextExperiments`, {
            params: {
                experimentId,
                nrOfPrevAndNext: 5
            }
        });
    },
    getExperimentsWithinDaysRange(deviceId, days, deviceType) {
        return Repository.get(`${resource}/getExperimentsWithinDaysRange`, {
            params: { deviceId, days, deviceType }
        });
    },
    getProjectExperiments({ projectId, page, pageSize, name, sortBy, resourceFilter, bin }) {
        return Repository.get(`${resource}/getFilteredPagedExperiments`, {
            params: {
                projectId,
                page,
                pageSize,
                name,
                sortBy,
                resourceFilter,
                bin
            }
        });
    },
    deleteExperiment(id) {
        return Repository.post(`${resource}/${id}/delete`);
    },
    moveExperiment({ experimentId, projectId }) {
        return Repository.post(`${resource}/moveExperiment`, {
            experimentId,
            projectId
        });
    },
    editExperiment({ id, name, description }) {
        return Repository.post(`${resource}/edit`, {
            id,
            name,
            description
        });
    },
    getExperimentBarcode(id) {
        return Repository.get(`${barcodeResource}/${id}`);
    },
    deleteExperimentBarcode(id) {
        return Repository.delete(`${barcodeResource}/${id}`);
    },
    createExperimentBarcode({ id, barcode }) {
        return Repository.post(`${barcodeResource}/${id}`, {
            barcode
        });
    },
    editExperimentBarcode({ id, barcode }) {
        return Repository.put(`${barcodeResource}/${id}`, {
            barcode
        });
    },
    getDeletedExperiments() {
        return Repository.get(`${resource}/getDeletedExperiments`, {
            params: {
                filter: 2
            }
        });
    },
    restoreExperiment({ experimentId, projectId }) {
        return Repository.post(`${resource}/${experimentId}/restore?projectId=${projectId}`);
    },
    removeExperiment({ experimentId }) {
        return Repository.post(`${resource}/${experimentId}/delete?permanentDelete=true`);
    },
    emptyExperimentsBin() {
        return Repository.delete(`${resource}/emptybin`);
    }
};
