import { makeInjectionKey, transformObjectKeysPascalToCamelCase } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
const ExperimentsRepository = RepositoryFactory.experiments;
/**
 * This Provider is responsible for handling bin mode operations
 */
export function withBinMode() {
    const isBinMode = ref(false);
    const setBinMode = (value) => (isBinMode.value = value);
    const deletedExperiments = ref([]);
    const fetchDeletedExperiments = async () => {
        try {
            const { data } = await ExperimentsRepository.getDeletedExperiments();
            deletedExperiments.value = transformObjectKeysPascalToCamelCase(data);
        }
        catch (error) {
            throw new Error(error);
        }
    };
    const emptyBin = async () => {
        try {
            await ExperimentsRepository.emptyExperimentsBin();
        }
        catch (error) {
            throw new Error(error);
        }
    };
    return {
        deletedExperiments,
        isBinMode,
        emptyBin,
        fetchDeletedExperiments,
        setBinMode
    };
}
// HELPERS
/**
 * @deprecated - Don't use directly unless there's a good reason or for unit testing.
 * Use `provideProjects` and `useProjects` instead.
 */
export const BinModeInjectionKey = makeInjectionKey();
/**
 * Provides `BinMode` to all descendants of a component.
 *
 * Use in conjunction with `useProjects`.
 */
export function provideBinMode(binMode) {
    provide(BinModeInjectionKey, binMode);
}
/**
 * Injects `Binned Projects` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideBinMode`, otherwise an error will
 * be thrown
 */
export function useBinMode() {
    const binMode = inject(BinModeInjectionKey);
    if (binMode === undefined)
        throw new Error("Projects was not provided.");
    return binMode;
}
