/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z"/>'
  }
})
