import { computed, defineComponent } from "vue";
import { useSelect } from "../_providers/withSelect/withSelect";
import { useAreas } from "../_providers/withAreas";
import { LControl } from "vue2-leaflet";
export default defineComponent({
    name: "WellPlateSelectionToggle",
    components: {
        LControl
    },
    setup() {
        const { areaNames } = useAreas();
        const selector = useSelect();
        const everyAreaSelected = computed(() => areaNames.value.length === selector.selectedAreasIds.value.length);
        const disabled = computed(() => !areaNames.value.length);
        const title = computed(() => {
            if (disabled.value) {
                return "There are no areas to select";
            }
            if (everyAreaSelected.value) {
                return "Deselect well plate areas";
            }
            return "Select all well plate areas";
        });
        const toggleMultipleSelection = () => {
            if (everyAreaSelected.value) {
                selector.deselectAll();
            }
            else {
                selector.selectItems(areaNames.value, {
                    ignoreModifierKey: true
                });
            }
        };
        return {
            everyAreaSelected,
            disabled,
            title,
            toggleMultipleSelection
        };
    }
});
