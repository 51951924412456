/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.56 5.44l-1.45 1.45A5.969 5.969 0 0118 12a6 6 0 01-6 6 6 6 0 01-6-6c0-2.17 1.16-4.06 2.88-5.12L7.44 5.44A7.961 7.961 0 004 12a8 8 0 008 8 8 8 0 008-8c0-2.72-1.36-5.12-3.44-6.56M13 3h-2v10h2"/>'
  }
})
