/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z"/>'
  }
})
