/* eslint-disable */
var icon = require("vue-svgicon");
icon.register({
  school: {
    width: 16,
    height: 16,
    viewBox: "0 0 24 24",
    data: '<path pid="0" fill="currentColor" d="M12 3L1 9l11 6 9-4.91V17h2V9M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82z"/>'
  }
});
