/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 5v2h2v12h2V7h6v9a3 3 0 003 3 3 3 0 003-3h-2a1 1 0 01-1 1 1 1 0 01-1-1V7h2V5"/>'
  }
})
