import Vue from "vue";
import { DialogType } from "../types/DialogType";
import DialogWrapper from "./DialogWrapper.vue";
export const DialogRoot = Vue.extend({
    props: {
        dialogProps: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        resolve: Promise.resolve,
        input: "",
        visible: false
    }),
    methods: {
        closeDialog() {
            this.visible = false;
        },
        inputHandler(value) {
            this.input = value;
        },
        show() {
            this.visible = true;
            return new Promise(resolve => {
                this.resolve = resolve;
            });
        },
        submit() {
            this.closeDialog();
            switch (this.dialogProps.type) {
                case DialogType.Alert:
                    this.resolve();
                    break;
                case DialogType.Confirm:
                    this.resolve(true);
                    break;
                case DialogType.Prompt:
                    this.resolve(this.input);
                    break;
            }
        },
        cancel() {
            this.closeDialog();
            switch (this.dialogProps.type) {
                case DialogType.Alert:
                    this.resolve();
                    break;
                case DialogType.Confirm:
                    this.resolve(false);
                    break;
                case DialogType.Prompt:
                    this.resolve(null);
                    break;
            }
        }
    },
    render(createElement) {
        return createElement(DialogWrapper, {
            props: {
                dialogProps: this.dialogProps,
                inputValue: this.input,
                visible: this.visible
            },
            on: {
                input: (value) => this.inputHandler(value),
                submit: () => this.submit(),
                cancel: () => this.cancel()
            }
        });
    }
});
