<script>
import { debounce } from "@axisvue/utils";

import BSelect from "@/shared/components/forms/BSelect";

import BaseRemainingItems from "./BaseRemainingItems.vue";
import PaginationButton from "./PaginationButton.vue";

const debouncedScrollToTop = debounce(() => {
  window.scrollTo(0, 0);
}, 500);

export default {
  components: { PaginationButton, BaseRemainingItems, BSelect },
  props: {
    options: {
      type: Object,
      default: () => ({
        itemsPerPage: [10, 20, 40, 60]
      })
    }
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        itemsPerPage: this.options.itemsPerPage[0],
        currentPage: 1,
        totalPages: 0
      }
    };
  },
  computed: {
    computedTotalPages() {
      return Math.ceil(
        this.pagination.totalItems / this.pagination.itemsPerPage
      );
    }
  },
  watch: {
    "pagination.currentPage": {
      handler(page) {
        if (page === 1) {
          this.$router.replace({
            path: this.$router.currentRoute.fullPath,
            query: { page: undefined }
          });
        } else if (page != this.$router.history.current.query.page)
          this.$router.push({
            path: this.$router.currentRoute.fullPath,
            query: { page }
          });
        debouncedScrollToTop();
      }
    },
    "pagination.itemsPerPage"() {
      this.pagination.currentPage = 1;
    },
    $route(to) {
      if (to.query.page !== undefined) {
        const page = Number(to.query.page);
        if (page !== this.pagination.currentPage) {
          this.pagination.currentPage = page;
        }
      } else {
        this.pagination.currentPage = 1;
      }
    },
    computedTotalPages(val) {
      this.pagination.totalPages = val;
    }
  },
  created() {
    if (this.$router.history.current.query.page !== undefined) {
      this.pagination.currentPage = Number(
        this.$router.history.current.query.page
      );
    }
  },
  render() {
    return this.$scopedSlots.default({
      pagination: this.pagination
    });
  }
};
</script>
