/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crosshairs-off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.94 11C20.5 6.83 17.17 3.5 13 3.06V1h-2v2.06a8.38 8.38 0 00-3.16.97l1.5 1.5A6.995 6.995 0 0119 12c0 .94-.19 1.84-.5 2.65l1.5 1.5c.5-.96.82-2.02.95-3.15H23v-2h-2.06M3 4.27l2.04 2.04A8.914 8.914 0 003.06 11H1v2h2.06c.44 4.17 3.77 7.5 7.94 7.94V23h2v-2.06c1.77-.2 3.38-.91 4.69-1.98L19.73 21 21 19.73 4.27 3 3 4.27m13.27 13.27a6.995 6.995 0 01-9.81-9.81l9.81 9.81z"/>'
  }
})
