/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radius': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m5 13v-2h-3.28c-.36.62-1.01 1-1.72 1a2 2 0 01-2-2 2 2 0 012-2c.71 0 1.36.38 1.72 1H17V9l3 3-3 3z"/>'
  }
})
