/**
 * @example
 *
 * const animation = useRequestAnimation();
 *
 * animation.forEachFrame(array, item => {
 *   console.log(item)
 * })
 *
 * or with async/await until each frame is executed
 *
 * await animation.forEachFrame(array, item => {
 *   console.log(item)
 * })
 *
 */
export const useRequestAnimation = () => {
    let animationRef;
    const forEachFrame = (array, callback) => {
        const clonedArray = [...array];
        return new Promise(resolve => {
            function loop() {
                if (clonedArray.length) {
                    callback(clonedArray[0]);
                    clonedArray.shift();
                    animationRef = requestAnimationFrame(loop);
                }
                else {
                    resolve(null);
                }
            }
            animationRef = requestAnimationFrame(loop);
        });
    };
    const stop = () => {
        cancelAnimationFrame(animationRef);
    };
    return {
        forEachFrame,
        stop
    };
};
