/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'select-search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.31 18.9c.44-.69.69-1.52.69-2.4 0-2.5-2-4.5-4.5-4.5S11 14 11 16.5s2 4.5 4.5 4.5c.87 0 1.69-.25 2.38-.68L21 23.39 22.39 22l-3.08-3.1m-3.81.1a2.5 2.5 0 010-5 2.5 2.5 0 010 5M21 9h-2V7h2v2m0-4h-2V3h1c.55 0 1 .45 1 1v1m-2 6.03V11h2v2h-.03A6.608 6.608 0 0019 11.03M17 5h-2V3h2v2m-4 0h-2V3h2v2M3 7h2v2H3V7m4 12h2v2H7v-2m-4-8h2v2H3v-2m1-8h1v2H3V4c0-.55.45-1 1-1m5 2H7V3h2v2M3 19h2v2H4c-.55 0-1-.45-1-1v-1m0-4h2v2H3v-2z"/>'
  }
})
