import { removeFromArray } from "@axisvue/utils";
export default class CommandPasteShape {
    constructor(shape) {
        this.shape = shape;
    }
    do(shapes) {
        if (this.shape.type == "group") {
            const group = this.shape.clone(shapes);
            group.shapes.forEach(shape => {
                shapes.byId[shape.id] = shape;
            });
            this.shape = group;
        }
        else {
            const newShape = this.shape.clone(shapes);
            shapes.byId[newShape.id] = newShape;
            shapes.ids.unshift(newShape.id);
            this.shape = newShape;
        }
    }
    undo(shapes) {
        if (this.shape.type == "group") {
            const group = this.shape;
            group.shapes.forEach(shape => {
                shape.delete();
                delete shapes.byId[shape.id];
                shapes.ids = removeFromArray(shapes.ids, shape.id);
            });
        }
        else {
            shapes.byId[this.shape.id].delete();
            delete shapes.byId[this.shape.id];
            shapes.ids = removeFromArray(shapes.ids, this.shape.id);
        }
    }
}
