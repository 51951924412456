import { defineComponent } from "vue";
import { AxionInputBase } from "../AxionInputBase";
export default defineComponent({
    name: "AxionInputEmail",
    components: { AxionInputBase },
    props: {
        value: {
            required: true,
            type: null,
            validator: (value) => typeof value === "string" || value === null
        }
    }
});
