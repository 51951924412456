import Vue from "vue";
import App from "./App.vue";
//ROUTER
import "./setupVue";
import router from "./router";
new Vue({
    router,
    provide() {
        const toastr = this.$toastr;
        return { toastr };
    },
    render: h => h(App)
}).$mount("#app");
