export default function addFooter(doc, offset, margins, name) {
    doc.line(offset.v, doc.internal.pageSize.height - offset.v, doc.internal.pageSize.width - margins.h, doc.internal.pageSize.height - offset.v);
    doc
        .setFontSize(10)
        .text(`${name}`, offset.v, doc.internal.pageSize.height - 5);
    const currPageNr = doc.getCurrentPageInfo().pageNumber;
    const str = `Page ${currPageNr}`;
    doc
        .setFontSize(10)
        .text(str, doc.internal.pageSize.width - 24, doc.internal.pageSize.height - 5);
}
