function forceDownload(blob, filename) {
    const a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}
export function downloadBlob(blob, filename) {
    const blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
}
export default function downloadResource(url, filename) {
    var _a;
    if (!filename)
        filename = (_a = url.split("\\").pop()) === null || _a === void 0 ? void 0 : _a.split("/").pop();
    fetch(url, {
        headers: new Headers({
            Origin: location.origin
        }),
        mode: "cors"
    })
        .then(response => response.blob())
        .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
    })
        .catch(e => console.error(e));
}
