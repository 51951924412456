<template>
  <div
    class="relative"
    :class="context.classes.element"
    :data-type="context.type"
  >
    <input
      v-model="context.model"
      :type="showText ? 'text' : 'password'"
      v-bind="context.attributes"
      @blur="context.blurHandler"
      v-on="$listeners"
    />
    <div
      v-if="context.model.length > 0"
      class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5"
    >
      <b-icon
        :name="showText ? 'eye-off' : 'eye'"
        class="w-8 cursor-pointer text-blue-700"
        @click="showText = !showText"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showText: false
    };
  }
};
</script>
