import { ref, onUnmounted } from "vue";
import { DEFAULT_TIMEOUT } from "./constants";
/**
 * Composable that handles polling for algorithms.
 *
 * Contain unmounted hook that will prevent fetching when component is destroyed (to prevent memory leaks).
 * Note: this can be prevented with optional parameter disableAbortOnUnmounted, please use with caution.
 *
 * To use properly, pass the callback (usually method that fetch specific algorithm).
 *
 * There are two ways to prevent polling for algorithms:
 *    1. (automatic) Pass optional parameter `fetched` that should be reactive boolean variable (ref or computed).
 *       In this way, polling will automatically not continue when `fetched` gets assigned true value.
 *
 *    2. (manual) Use composable method stop() to manually prevent polling
 */
export function useAlgorithmPolling(callback, fetched, timeout, disableAbortOnUnmounted) {
    const aborted = ref(false);
    const trigger = async () => {
        if (!(fetched === null || fetched === void 0 ? void 0 : fetched.value) && !aborted.value) {
            await callback();
            setTimeout(() => {
                trigger();
            }, timeout || DEFAULT_TIMEOUT);
        }
    };
    const stop = () => {
        aborted.value = true;
    };
    onUnmounted(() => {
        if (!disableAbortOnUnmounted) {
            stop();
        }
    });
    return {
        trigger,
        stop
    };
}
