import { defineComponent } from "vue";
import { AxionButton } from "@axisvue/buttons";
export default defineComponent({
    components: {
        AxionButton
    },
    props: {
        itemsNumber: {
            type: Number,
            required: true
        }
    }
});
