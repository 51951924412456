import Repository from "@/api/repository";
import { exact3Base } from "../base";
const resource = (experimentId) => `${exact3Base}/experiments/${experimentId}/snapshots`;
export const snapshotsRepository = {
    getResults(experimentId) {
        return Repository.get(`${resource(experimentId)}/results`);
    },
    postExcluded(experimentId, excludedModel) {
        return Repository.post(`${resource(experimentId)}/setexcluded`, excludedModel);
    }
};
