/**
 * Get string date from timestamp.
 * Example: 1673568000 => 13-01-2023
 * Example: null => "-"
 * @param value
 */
export default function getDateFromStamp(value) {
    if (value === null) {
        return "-";
    }
    const rawDate = new Date(value);
    let formattedDate = rawDate.getFullYear() + "-";
    if (rawDate.getMonth() < 9) {
        formattedDate += "0";
    }
    formattedDate += rawDate.getMonth() + 1;
    formattedDate += "-";
    if (rawDate.getDate() < 10) {
        formattedDate += "0";
    }
    formattedDate += rawDate.getDate();
    return formattedDate;
}
