import { defineComponent } from "vue";
import { useLayoutType } from "@/shared/providers/withLayoutType/withLayoutType";
import { LayoutTypes } from "@/shared/providers/withLayoutType/types/LayoutTypes";
import BProjectsListItem from "./BProjectsListItem.vue";
export default defineComponent({
    components: {
        BProjectsListItem
    },
    props: {
        projects: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const { layoutType } = useLayoutType();
        return {
            layoutType,
            LayoutTypes
        };
    }
});
