export const PROJECT_TYPES_COLORS = {
    Blue: {
        bg: "bg-blue-200",
        light: "text-blue-400",
        dark: "text-blue-700"
    },
    BrightRed: {
        bg: "bg-red-200",
        light: "text-red-400",
        dark: "text-red-700"
    },
    Brown: {
        bg: "bg-red-200",
        light: "text-red-400",
        dark: "text-red-700"
    },
    DarkBlue: {
        bg: "bg-blue-200",
        light: "text-blue-400",
        dark: "text-blue-700"
    },
    DarkGreen: {
        bg: "bg-green-300",
        light: "text-green-500",
        dark: "text-green-800"
    },
    Green: {
        bg: "bg-green-200",
        light: "text-green-300",
        dark: "text-green-500"
    },
    LightGreen: {
        bg: "bg-green-200",
        light: "text-green-300",
        dark: "text-green-500"
    },
    Magenta: {
        bg: "bg-pink-200",
        light: "text-pink-400",
        dark: "text-pink-700"
    },
    Purple: {
        bg: "bg-purple-200",
        light: "text-purple-400",
        dark: "text-purple-700"
    },
    Orange: {
        bg: "bg-orange-200",
        light: "text-orange-400",
        dark: "text-orange-700"
    },
    Pink: {
        bg: "bg-pink-200",
        light: "text-pink-400",
        dark: "text-pink-700"
    },
    Red: {
        bg: "bg-red-200",
        light: "text-red-400",
        dark: "text-red-700"
    },
    Yellow: {
        bg: "bg-yellow-300",
        light: "text-yellow-500",
        dark: "text-yellow-700"
    }
};
