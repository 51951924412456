<script>
import { formValidators } from "../../index";
import { mapValueToFunctions } from "@axisvue/utils";

function stringToFunction(str) {
  const [functionName, ...args] = str.split(":");
  return [functionName, args];
}

const mapValidators = arr =>
  arr.map(v => {
    const [validator, args] = stringToFunction(v);
    return function (input) {
      return formValidators[validator](input, ...args);
    };
  });

export default {
  props: {
    validators: {
      type: [Object, Array],
      default: () => ({})
    },
    data: {
      type: undefined,
      required: true
    }
  },
  computed: {
    isArray() {
      return Array.isArray(this.validators);
    },
    errors() {
      if (this.isArray) {
        return this.validators.reduce((a, _) => {
          a = mapValueToFunctions(
            mapValidators(this.validators),
            this.data
          ).filter(e => e);

          return a;
        }, []);
      }

      return Object.keys(this.validators).reduce((a, c) => {
        a[c] =
          this.validators[c] !== undefined
            ? mapValueToFunctions(
                mapValidators(this.validators[c]),
                this.data[c]
              ).filter(e => e)
            : [];
        return a;
      }, {});
    },
    disabled() {
      if (this.isArray)
        return this.validators.some(() => this.errors.length > 0);
      return Object.keys(this.validators).some(i => this.errors[i].length > 0);
    }
  },
  render() {
    return this.$scopedSlots.default({
      errors: this.errors,
      disabled: this.disabled
    });
  }
};
</script>
